.cancel-mark-popup-content-wrapper {
  width: 500px;
  padding: 20px 40px 20px 40px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.cancel-mark-popup-btn{
    margin-top: 20px;
}