.multiple-exchange-rate-wrapper {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.18px;
  }
  .multiple-exchange-date-rate-wrapper .multiple-exchange-date-wrapper {
    margin-right: 5px !important;
    color: var(--battleship-grey);
  }
  .multiple-exchange-rate-wrapper img,
  .multiple-exchange-rate-wrapper svg {
    margin-bottom: 2px;
    width: 18px;
  }
  .multiple-exchange-rate-wrapper svg {
    height: 18px;
  }
  .multiple-exchange-rate-wrapper .form-button-wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0 !important;
  }
  
  #multipleExchangeRateEdit .form-button-wrapper {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0 !important;
  }
  
  #multipleExchangeRateEdit, 
  #multipleExchangeRateEditCoa {
    max-width: 243px;
    width: 100%;
    right: 50px;
  }
  
  #multipleExchangeRateEdit .card {
    padding: 16px 14px 15px !important;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1);
    box-shadow: 0 2px 6px 0 var(---black-shadow1);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    width:237px;
  }
  
  #multipleExchangeRateEdit label.required::after {
    right: auto;
  }
  
  #multipleExchangeRateEdit .save-button,
  #multipleExchangeRateEdit .cancel-button {
    height: 32px;
    padding: 0 24px;
  }
  
  #multipleExchangeRateEdit .input-group-prepend div {
    border-radius: 5px 0 0 5px;
    background-color: rgba(var(--peak-point-rgb), 0.08);
    border: solid 1px var(--new-whisper);
    border-right: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 0.77;
    letter-spacing: normal;
    color: var(--battleship-grey);
    padding: 14px 12px;
    height: 40px;
  }
  
  #multipleExchangeRateEdit .input-group input {
    border-radius: 0 5px 5px 0;
    border: solid 1px var(--new-whisper);
    border-left: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 0.71;
    letter-spacing: normal;
    height: 40px;
  }
  .multiple-exchange-rate-edit-wrap{
    display: inline-block;
  }
  .multiple-exchange-rate-display-wrapper{
    display: flex;
    flex-direction: row;
    font-size: 13px !important;
    margin-bottom: 10px;
  }
  .multiple-exchange-date-rate-wrapper-multiline{
    display: flex;
    flex-direction: column;
    justify-content: right;
    text-align: end;
    font-size: 12px !important;
  }
  .multiple-exchange-date-rate-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
  }
  .multiple-exchange-date-rate-wrapper-multiline.multiple-exchange-converted-rate-wrapper{
    width: 150px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: end;
    font-size: 12px !important;
  }
  .multiple-exchange-converted-rate-wrapper{
    width: fit-content;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;}
  .multiple-exchange-rate-icon-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: end
  }
  #multipleExchangeRateEdit label{
    text-align: start;
    width:100%;
  }
  /* #multipleExchangeRateEdit.coa-multiple-exchange .card {
    padding: 16px 14px 15px !important;
   
  } */