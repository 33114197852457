.delete-bank-account-alert {
  width: 500px;
  height: fit-content;
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.delete-bank-account-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 20px;
  align-items: center;
  margin-top: 30px;
}
