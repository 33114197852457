.list-ul {
    list-style: none;
    text-align: left;
    margin: 0px;
    padding: 14px 0 14px 0;
    border-radius: 4px; 
}
.list-ul .dropdown-menu {
    inset: initial;
    transform: translate3d(0px, 44px, 0px);
    padding: 0;
    min-width: 200px;
    box-shadow: 0 6px 12px 0 var(---black-shadow1);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
    max-height: 198px;
    overflow-x: hidden;
    overflow-y: auto;
}
.drop-up .dropdown-menu {
    transform: translate3d(0px, 44px, 0px);
}