.edit-input-popup .form-button-wrapper {
    width: 100%;
    border-top: 0;
    padding-top: 20px;
    padding-bottom: 0 !important;
    float: left;
  }
  
  .edit-input-popup {
    position: absolute;
    width: 232px;
    z-index: 11;
    right: 0;
    bottom: 30px;
  }
  
  .edit-input-popup .card {
    padding: 16px 14px 20px !important;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
  }
  .edit-input-popup label.required:after {
    right: auto;
  }
  .edit-input-popup .save-button,
  .edit-input-popup .cancel-button {
    height: 32px;
    padding: 0 24px;
  }
  .edit-input-popup input {
    border-radius: 6px !important;
    border: solid 1px var(--new-whisper);
    border-left: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 40px;
    float: left;
  }
  .edit-input-popup input.edit-prefix {
    border: solid 1px var(--new-whisper);
  }