.signatury-wrapper {
  width: 200px;
  padding-top: 80px;
}
.signatury-wrapper .signature-label {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  clear: both;
  float: left;
}
.signatury-wrapper .signature {
  width: 100%;
  height: 37px;
  border: 0;
  border-bottom: solid 1px var(--slate);
  border-radius: 0;
  float: left;
}
