.ri-form .edit-address-wrapper {
    float: left;
}
.ri-form .address-list-container {
    width: 332px;
    height: auto;
    max-height: 454px;
    padding: 16px 15px 16px 16px !important;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 9px 0 var(--black-shadow6);
    box-shadow: 0 5px 9px 0 var(--black-shadow6);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    overflow: hidden;
    overflow-y: auto; 
    top: 0;
    left: 0;   
    z-index: 1;
    transform: translate3d(0, 30px, 0) !important;
}
.ri-form .address-list-container.dropdown-menu {
    inset: 0 auto auto 0 !important;
}
.ri-form .address-list-container .address-list {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}
.ri-form .address-list-container .address-list li {
    width: 100%;
    margin: 0 0 12px 0;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    float: left;
}
.ri-form .address-list-container .address-list li.current {
    border: solid 1px rgba(var(--cerulean-blue-rgb), 0.11);
    background-color: rgba(var(--cerulean-blue-rgb), 0.05);
}
.ri-form .address-list-container .radio-container {
    width: calc(100% - 45px) !important;
    float: left;
}
.ri-form .address-list-container .edit-address-link {
    margin-top: 3px;
}
.ri-form .address-list-container .radio-container .form-check {
    float: left;
    margin: 0 !important;
    padding: 0 !important;
}

.ri-form .address-list-container .radio-container .form-check .form-check-input {
    margin: 3px 12px 0 0 !important;
}
.ri-form .address-list-container .edit-selected-address {
    float: left;
} 
.ri-form .address-list-container .radio-container label,
.ri-form .address-list-container .radio-container label span {
    width: 100%;
    font-size: 14px;
    font-weight: normal;    
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    float: left;
}
.ri-form .address-list-container .radio-container label {
    width: calc(100% - 28px) !important;    
}
.ri-form .address-list-container .radio-container label span {
    display: block;
}
.ri-form .address-list-container .add-address-btn {
    font-size: 15px;
    font-weight: bold;    
    line-height: normal;
    letter-spacing: normal;
    color: var(--white-fill);
    padding: 10px;
    text-align: center;
    margin-top: 2px;
}
.ri-form .address-list-container .add-address-btn img {
    width: 24px;
    height: auto;
    position: relative;
    top: -2px;
}