.input-dropdown-container {
  width: 100%;
  clear: both;
  float: left;
  border-radius: 4px;
}
.input-dropdown-container input {
  width: calc(100% - 125px);
  float: left;
  border: 0;
  box-shadow: none;
  border-radius: 6px 0 0 6px;
  color: var(--charcoal-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 0.71;
  max-width: none;
  padding: 11px 12px;
  border: 1px solid var(--new-whisper);
  border-right: 0;
}
.input-dropdown-container input:focus {
  border: 1px solid var(--cerulean-blue);
}
.input-dropdown-container .select-container {
  width: 125px;
  float: left;
  margin: 0;
}
.input-dropdown-container .select-container .reusable-form-select-field {
  margin: 0 !important;
}
.arrow-dropdown-btn {
  width: 40px;
  height: 41px;
  padding-left: 8px;
  padding-right: 8px;
  float: left;
  background-color: rgba(var(--peak-point-rgb), 0.08);
  border: 1px solid var(--new-whisper);
  border-radius: 0 6px 6px 0;
}
.arrow-dropdown-btn:focus {
  box-shadow: none !important;
  outline: 2px solid var(--cerulean-blue) !important;
  outline-offset: 3px !important;
}
.arrow-dropdown-btn svg {
  width: 14px;
  height: 14px;
}
.arrow-dropdown-btn.dropdown-toggle:after {
  display: none;
}
.arrow-dropdown .dropdown-menu {
  inset: initial !important;
  min-width: 200px !important;
  transform: translate(0, 46px) !important;
  padding: 0;
  right: -1px !important;
  border-radius: 4px;
  box-shadow: 0 6px 12px 0 var(---black-shadow1);
  box-shadow: 0 6px 12px 0 var(---black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 198px;
  overflow-x: hidden;
  overflow-y: auto;
}
.arrow-dropdown .dropdown-menu li {
  padding: 0;
  width: 100%;
}
.arrow-dropdown .dropdown-menu li:first-child,
.arrow-dropdown .dropdown-menu li:first-child a {
  border-radius: 4px 4px 0 0 !important;
}
.arrow-dropdown .dropdown-menu li:last-child,
.arrow-dropdown .dropdown-menu li:last-child a {
  border-radius: 0 0 4px 4px !important;
}
.arrow-dropdown .dropdown-menu a {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 12px 10px 12px;
  background-color: var(--white-fill) !important;
  border-bottom: 1px solid var(--approx-whisper) !important;
}
.arrow-dropdown .dropdown-menu li:last-child a {
  border-bottom: 0 !important;
}
.arrow-dropdown .dropdown-menu a.active,
.arrow-dropdown .dropdown-menu a:hover,
.arrow-dropdown .dropdown-menu a:focus {
  background-color: var(--whisper) !important;
}
.arrow-dropdown .dropdown-menu a span {
  display: block;
  width: 100%;
  padding: 2px 1px 2px 0;
}
.arrow-dropdown .dropdown-menu .dropdown-item {
  line-height: normal;
  text-align: left;
}
.input-dropdown-container input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-dropdown-container input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-dropdown-container input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-dropdown-container input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-dropdown-container input::placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-dropdown-container.error input,
.input-dropdown-container.error .arrow-dropdown-btn {
  border: 0;
}
.input-dropdown-container.error input {
  background-color: var(--rose-white);
}
.input-dropdown-container.error .arrow-dropdown-btn {
  border: 0;
  border-left: 1px solid var(--new-whisper);
  background-color: var(--rose-white);
}
.input-dropdown-container.error {
  border: solid 1px var(--red);
}
.input-dropdown-wrapper span {
  clear: both;
}