.tds-form-modal-wrapper {
  right: 0;
  width: 480px;
}
.tds-form-body-wrapper {
  padding: 0 40px 40px 40px;
}
.tds-edit-side-modal .reusable-modal-btn {
  justify-content: flex-start !important;
  width: 100% !important;
}
.tds-form-full-screen {
  right: 0;
  position: fixed;
  width: 480px !important;
  max-width: none;
  height: 100%;
  margin: 0;
}
.tds-form-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}
.tds-form-body {
  overflow-y: auto;
  max-width: 480px;
  padding: 0 40px 40px 40px;
  position: relative;
  flex: 1 1 auto;
}
.tds-form-content h4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48;
  font-family: Lato !important;
  font-style: normal !important;
  font-stretch: normal !important;
}
.tds-form-input-wrapper {
  width: 100%;
  margin-bottom: 20px;
  clear: both;
}
.tds-terms-and-condition {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48 !important;
  padding: 0 0 8px 0;
}
.terms-and-condition-label {
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #323e48;
}
.tds-form-check-input {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  border-color: var(--slate) !important;
}
.tds-form-check-input[type="checkbox"] {
  border-radius: 2px !important;
}
.tds-form-button-wrapper {
  padding: 24px 50px 0;
  padding: 24px 0px 0;
  margin-top: 4px;
  font-size: 0;
}
.tds-form-button-wrapper .cancel-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.tds-form input.error,
.tds-form select.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
#tds-form input,
#tds-form .form-select,
#tds-form label {
  clear: both;
}
#tds-form input,
#tds-form .form-select {
  width: 100%;
}
#tds-form input:focus,
#tds-form input[type="text"]:focus,
#tds-form .form-select:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
#tds-form label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
  width: fit-content;
}
#tds-form input[type="text"] {
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.save-button[disabled] {
  opacity: 0.5;
}
.tds-form-input-wrapper .form-check-input {
  margin-right: 16px;
}
.tds-form-input-wrapper  .terms-and-condition-label {
  display: inline-block;
  width: calc(100% - 32px);
}
.tds-edit-side-modal .reusable-modal-btn > button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: left;
}
.tds-edit-side-modal .reusable-modal-btn .green-button {
  padding: 0 15px 0 8px;
}
.tds-edit-side-modal .reusable-modal-btn .green-button svg {
  margin-right: 8px;
}
.tds-edit-side-modal .reusable-modal-btn .green-button
.radio-btn-tds{
  color: var(--cerulean-blue) !important;
}
.tds-checkbox-wrapper{
  display: flex;
}
.tds-terms-checkbox{
  margin-top: 6px !important;
}
.tds-edit-side-modal .reusable-modal-btn .green-button img {
  position: relative;
  top: -1px;
  margin-right: 8px;
}