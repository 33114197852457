.settings-link {
    display: block;
    text-decoration: none;
    float: left;
    width: 40px;
    height: 40px;
    /* margin-right: 16px; */
    margin-right: 30px;
}
.nav-profile .settings-link {
    width: 100%;
    height: auto;
}
.nav-profile .nav-oval-Copy {
    display: flex;
    width: 100%;
    margin-right: 0;
    text-align: left;
    align-items: center;
}
.nav-profile .nav-oval-Copy img {
    position: static;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    transform: translate(0,0);
}
.nav-profile .settings-link span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 2.71;
    padding: 0;
    text-align: left;
    text-decoration: none;
    color: var(--black);
}
.nav-oval-Copy {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 1px 16px 0.2px 0px;
    padding: 6px;
    background-color: var(--white-fill);
    border-radius: 50%;
    text-align: center;
    float: left;
}
.nav-oval-Copy img {
    position: absolute;
    width: 20px;
    height: 20px;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.nav-oval-Copy .red-bubble {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--coral-red) 0;
    top: -2px;
    right: 2px;
}
@media (max-width: 1180px) {
    .settings-link {
        margin-right: 15px;
    }
}
@media (max-width: 1024px) {
    .settings-link {
        margin-right: 25px;
    }
}