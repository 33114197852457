#searchbar_wrapper {
    display: flex;
    width: 100%;  
    z-index: 1134;
    height: 75px;
    align-items: center;
    padding: 0 20px 0 12px;
  }
  #searchbar_wrapper .close-btn {
    margin-right: 8px;
    width: 30px;
    height: 30px;
    background-color: transparent;  
  }
  #searchbar_wrapper .close-btn svg path:last-child  {
    fill: var(--white-fill);
  }
  #searchbar_wrapper .close-btn:hover {
    border-color: var(--red);
  }
  #searchbar_wrapper .close-btn:hover svg path:last-child {
    fill: var(--red);
  }
  @media (max-width: 767px) {
    #searchbar_wrapper {
        padding: 0 16px 0 10px;
    }
  }
  @media (max-width: 360px) {
    #searchbar_wrapper {
        padding: 0 10px 0 5px;
    }
  }