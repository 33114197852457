.search-popper .MuiAutocomplete-paper {
    box-shadow: none;
    border: 0;
}
.global-search-field.search-header .search-popper li {
    padding: 0 !important;
}
.search-popper li a {
    display: flex;
    text-decoration: none;
    padding: 7px 12px 12px 12px;
    flex-direction: column;
}
.search-popper #global_search_autocomplete-listbox {
    max-height: 532px !important;
    padding: 0 !important;
    overflow: hidden;  
}
.view-all-results-btn {
    width: 100% !important;
    font-size: 13px !important;
    font-weight: bold !important;  
    line-height: 1.92 !important;
    letter-spacing: normal !important;
    color: var(--cerulean-blue) !important;
    padding: 10px 12px 19px 12px !important;
    justify-content: start !important;
    text-transform: none !important;
    text-align: left !important;
}

.search-popper li a .search-data span {
    font-size: 13px;
    line-height: 1.85;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    font-weight: bold;  
    flex: 0 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search-popper li a .search-data .label {
    font-weight: bold;
    color: var(--battleship-grey);
    flex: 0 0 auto;
}
.search-popper li a .search-data .value {
    font-weight: bold;  
}
.search-popper {
    width: 336px !important;
    padding: 0px !important;
    margin-top: 4px !important;
    border-radius: 6px !important;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
    box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
    border: solid 1px var(--new-whisper) !important;
    background-color: var(--white-fill) !important;   
    left: -12px !important;
    top: -4px !important;
}

@media (max-width: 1024px) {
    .global-search-field.search-header .search-popper {
        max-height: 84vh !important;
        overflow: hidden;
        overflow-y: auto;
    }    
}
@media (max-width: 767px) {
    .global-search-field.search-header .search-popper {
        max-height: 80vh !important;
    }    
}