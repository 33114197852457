.aging-report .page-type-label {
  font-size: 24px;
}
.multi-line-title {
  display: flex;
  flex-direction: column;
}
.aging-report tr:last-child td {
  font-weight: bold;
}
.aging-report .customRowTitle td {
  font-weight: bold;
}
.report-table.aging-report-table tr th {
  vertical-align: top;
  padding-top: 12px !important;
  padding-bottom: 4px !important;
}
.report-table.aging-report-table tr td,
.report-table.aging-report-table tr th {
  white-space: nowrap !important;
}
.report-table.aging-report-table tr td:first-child {
  white-space: normal !important;
}

.report-table.aging-report-table tbody tr td.column-navigation {
  cursor: pointer;
}
.report-table.aging-report-table tbody tr:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
}
.report-table.aging-report-table tr th:last-child {
  line-height: 2;
}
.report-table.aging-report-table tr th.consumer-name,
.report-table.aging-report-table tr td.consumer-name {
  width: 18% !important;
  min-width: 220px;
}
.report-table.aging-report-table tr td.total-currency,
.report-table.aging-report-table tr th.total-currency,
.report-table.aging-report-table tr td.current,
.report-table.aging-report-table tr th.current,
.report-table.aging-report-table tr td.days-range {
  width: 10% !important;
  min-width: 100px;
}
.aging-report .aging-report-advanced-filter {
  float: right;
  margin-top: -41px;
  margin-right: 0%;
}

.addition-filter-settings {
  float: right;
  width: auto;
  display: flex;
  width: auto;
  margin-right: 21px;
}
.addition-filter-settings .filter-btn-col {
  width: auto;
}
.addition-filter-settings .filter-btn-col  .custom-dropdown {
  width: auto;
}
@media (max-width: 920px) {
  .addition-filter-settings {
    clear: both;
    float: left;
    margin-top: 60px;
  }
  .aging-report-card .top-filter-container.filter-box {
    padding-bottom: 90px;
  }
}