.dn-pm-detail-bill thead th{
    border-top: none !important;
}
.transaction-number-dn-pm{
    color: var(--cerulean-blue);
    text-decoration: none;
}
.dn-pm-action-btn{
    width:75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.dn-pm-detail-bill{
    width: 100%;
    height: fit-content;
    border: 1px solid var(--warm-white);
    border-radius: 10px;
    padding-top: 10px;
    margin-bottom: 30px;
}