.consumer-payment-card {
  width: fit-content;
  max-width: 450px;
  height: fit-content;
  padding: 40px 20px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.consumer-payment-title {
  width: 100%;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.consumer-exchange-rate-wrap {
  width: 100%;
  height: fit-content;
  padding: 10px;
  border: 1px solid var(--warm-white);
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.consumer-exchange-date,
.consumer-amount-edit-info {
  color: var(--battleship-grey);
}
.consumer-exchange-rate-display-wrap {
  margin: 10px 0px 0px 0px;
}

.consumer-exchange-date {
  margin-right: 5px;
}
.consumer-amount-edit-info {
  margin-bottom: 30px;
}
.endorment-btn {
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
