.variace-report-daterange-wrapper {
    height: 70px;
}
.variace-report-daterange-wrapper #reportrange {
  max-width: 100% !important;
  height: 41px;
  padding: 9px 6px 8px 9px !important;
  border-radius: 6px !important;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.daterange-wrapper-for-invoice {
  width: 325px !important;
  /* margin-top: 20px; */
}
.variace-report-daterange-wrapper #reportrange span {
  display: inherit;
  
  font-size: 14px !important;
  font-weight: normal !important;
  
 
  line-height: 20px !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 56px);
  padding-left: 12px;
}
.variace-report-daterange-wrapper #reportrange svg {
  float: right;
  margin-right: 6px;
  width: 14px !important;
  height: 14px !important; 
  margin-top: 3px !important;
}
.daterange-wrapper-for-invoice .error-field {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
.daterangepicker.opensright:before {
  display: none !important;
}

.daterangepicker.opensright:after {
  display: none !important;
}
.variace-report-daterange-wrapper #reportrange * {
  float: left;
}
.variace-report-daterange-wrapper #reportrange img {
  width: 20px;
  height: 20px;
  margin-left: 9px;
}

.variace-report-daterange-wrapper  .daterangepicker {
  z-index: 1001 !important;
  margin-top: 8px !important;
  width: 31.4815%;
  max-width: 340px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: solid 1px var(--new-whisper);
}
.daterangepicker:not(.show-calendar) .ranges {
  width: 100%;
}
.daterangepicker:not(.show-calendar) .ranges ul {
  width: 100%;
}
.daterangepicker.show-calendar {
  width: auto;
  max-width: none;
}

.daterangepicker * {
  
}
.daterangepicker th,
.daterangepicker td {
  padding: 0 !important;
}

.daterangepicker .ranges li {
  font-size: 14px;
  font-weight: normal;
  
  
  line-height: 2.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li:focus,
.daterangepicker .ranges li.active {
  background-color: transparent !important;
  color: var(--cerulean-blue) !important;
}
.daterangepicker.show-calendar .drp-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.daterangepicker .drp-buttons {
  border-top: solid 1px var(--new-whisper);
}
.daterangepicker .drp-selected {
  font-size: 14px;
}
.daterangepicker .drp-buttons .btn {
  font-size: 14px;
}
.daterangepicker .drp-buttons .btn.applyBtn {
  background-color: var(--cerulean-blue) !important;
  font-size: 14px;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-size: 14px;
  font-weight: normal;
  
  
  line-height: 0.76;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
}
.daterangepicker .calendar-table th {
  font-weight: bold;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--cerulean-blue) !important;
  border-color: transparent !important;
  color: var(--white-fill) !important;
}
.daterangepicker .calendar-table table tr:hover {
  box-shadow: none !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .daterangepicker.show-calendar.show-ranges {
    right: 10px !important;
    z-index: 1315 !important;
  }
}