.card-lock-transaction .card-content-wrapper {
  width: 100%;
  max-width: 903px;
}
.card-lock-transaction .card-content-wrapper p {
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-bottom: 30px;
}

.btn-sect button {
  white-space: nowrap;
}

.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.status-tag {
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding: 5px 8px 4px 8px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: var(--solitude);
}

.bg-battleship-grey-light {
  background-color: rgba(var(--peak-point-rgb), 0.07) !important;
}
.mtop-60 {
  margin-top: 60px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
