.organization-list-modal .modal-dialog {
  width: 380px;
}
.org-list-modal-content {
  border-radius: 10px !important;
}
.organization-list-modal .modal-header,
.organization-list-modal .modal-body,
.organization-list-modal .modal-footer {
  padding: 0 40px;
  clear: both;
}
.organization-list-modal .modal-header {
  padding-top: 40px;
  /* padding-bottom: 25px;
  padding-left: 75px; */
}
.organization-list-modal .modal-footer {
  padding-top: 0;
  padding-bottom: 30px;
  border-top: 0;
}
.organization-list-modal .modal-header span {
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #323e48;
}
.organization-list-modal .modal-header .close-button-container {
  position: relative;
  top: -20px;
  right: -30px;
  padding: 0;
  float: right;
}
.organization-list-modal .modal-header .close-button-container button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 0;
}
.organization-list-modal .modal-header .close-button-container button svg {
  width: 24px;
  height: 24px;
}
.organization-list-wrapper {
  list-style-type: none;
}
.multiple-organization-list {
  width: 300px;
  margin: 25px 0 20px;
  padding: 21px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.05);
}
.org-selected {
  border: solid 1px var(--cerulean-blue);
  background-color: var(--new-approx-alice-blue3);
}
.multiple-organization-list:hover {
  border: solid 1px var(--cerulean-blue);
  background-color: var(--new-approx-alice-blue3);
}
.multiple-organization-list:focus {
  border: solid 1px var(--cerulean-blue);
  background-color: var(--new-approx-alice-blue3);
}
.organization-list-modal .organization-list-orgname {
  display: inline-block;
  width: auto;
  max-width: calc(100% - 40px);
  margin-left: 5px;
}

.organization-list-modal .organization-name-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
