.tds-preference-form-modal-wrapper {
  right: 0;
  width: 480px;
}
.tds-preference-full-screen {
  right: 0;
  position: fixed;
  width: 480px !important;
  max-width: none;
  height: 100%;
  margin: 0;
}
.tds-preference-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}
.tds-preference-body {
  overflow-y: auto;
  max-width: 480px;
  padding: 0 40px 40px 40px;
  position: relative;
  flex: 1 1 auto;
}
.tds-preference-content h4 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48;
  font-family: Lato !important;
  font-style: normal !important;
  font-stretch: normal !important;
}
.enable-tds-label {
  margin: 0 44px 3px 12px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48;
}
.tds-preference-input-wrapper {
  width: 100%;
  margin-bottom: 20px;
  clear: both;
}
.preference-modal-content {
  width: 413px;
  height: 96px;
  margin: 16px 0 32px;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #323e48;
}
.tds-preference {
  padding: 0 40px 40px 40px;
}
#tds-preference label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
  width: fit-content;
}
.tds-radio-wrapper {
  display: flex;
}
.tds-radio-wrapper .form-check-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
.tds-radio-wrapper .form-check-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
.tds-radio-wrapper .form-check-inline:last-child {
  padding-right: 0;
}
.tds-radio-wrapper .form-check-input[type="radio"] {
  margin-right: 6px;
}
.tds-radio-wrapper .form-check-input[type="radio"]:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.tds-radio-wrapper label {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey);
  float: none !important;
}
.tds-preference-button-wrapper {
  margin-top: 4px;
  font-size: 0;
  padding-top: 10px;
}
.tds-preference-button-wrapper .cancel-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
