.system-voucher-configuration-field-wrapper {
    width: 480px;
    padding: 0 40px;
}
.add-module-item-form.system-voucher-configuration-form {
    box-shadow: none;
    border: 0;
}
.configure-system-voucher-btn {
    line-height: 20px;
    padding-left: 0;
}
.configure-system-voucher-btn img {
    margin-right: 6px;
}