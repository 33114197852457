.tag-category-mapping-wrapper {
  width: 450px !important;
  min-height: 500px !important;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
}
.tags-options-mapping-header-wrapper {
  margin-bottom: 15px;
}
.tag-category-mapping-categories-wrapper {
  margin-top: 20px;
  max-height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
}
.tag-category-mapping-category-name {
  width: 100%;
  /* margin-bottom: 10px !important; */
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tag-category-mapping-category-name > span {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0px;
  text-align: left;
}
.tag-category-mapping-disabled-category {
  width: 100%;
  /* margin-bottom: 10px !important; */
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tag-category-mapping-edit {
  width: fit-content !important;
}
.tag-category-mapping-disabled-category > span {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0px;
  text-align: left;
}

.tag-category-mapping-category-name > span:hover {
  background-color: var(--warm-white);
}
.tag-category-mapping-category-name:hover {
  background-color: var(--warm-white);
  color: var(--cerulean-blue);
}
