.list-card-header {
  background-color: var(--approx-white) !important;
}

.invoice-list-card-header {
  padding-bottom: 23px !important;
}
.invoice-list-card-header h1 {
  margin-top: 7px !important;
}
.create-invoice-button {
  width: auto;
  padding: 0 15px 0 8px;
}
.create-invoice-button svg.MuiSvgIcon-root {
  margin-top: -4px !important;
  margin-right: 6px;
}
  
.table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.card-list #invoice_list_table .action .dropdown-menu {
  inset: 19px -10px auto auto !important;
}


.card-customer-header h1 {
  
  font-size: 24px;
  font-weight: 900;
  
  
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 0;
}

.tag-box {
  width: auto;
  min-width: 47px;
  height: 20px;
  margin: 0 8px 8px 0;
  padding: 0 8px;
  border-radius: 4px;
  border: solid 1px var(--pattens-blue);
  background-color: var(--approx-alice-blue);
  
  font-size: 13px;
  line-height: 1.54;
  color: var(--battleship-grey);
  float: left;
}

.tag-count {
  margin: 0;
  padding: 0 6px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--cerulean-blue-shadow2);
  background-color: var(--new-alice-blue);
  
  font-size: 13px;
  font-weight: normal;
  
  
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
#invoice-table-wrapper th.tags,
#invoice-table-wrapper td.tags,
#invoice-table-wrapper th.email,
#invoice-table-wrapper td.email {
  white-space: nowrap;
}
.popover {
  border-radius: 1px;
  -webkit-box-shadow: 0 2px 8px 0 var(--black-shadow5);
  box-shadow: 0 2px 8px 0 var(--black-shadow5);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  max-width: 50%;
}

.popover-body {
  width: 100%;
  padding: 10px 12px 0 12px;
  float: left;
  border-radius: 1px;
}

.popover .tag-box {
  margin-bottom: 10px;
}

/* create invoice */




/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}
@media (min-width: 768px) and (max-width: 991px) {
  /* .invoice-list-card-header .filter-componet-wrapper .filter-menu {
    left: auto !important;
    right: 0 !important;
  } */
}
@media (min-width: 992px) and (max-width: 1024px) {
  
}
@media (min-width: 768px) and (max-width: 1024px) {    
  #addVendorModal .expanded-submenu #add_customer_form .form-button-wrapper.docked-button-container,
  #addVendorModal .expanded-submenu #add_vendor_form .form-button-wrapper.docked-button-container {
    position: static !important;
    width: 100% !important; 
    padding-left: 25px !important;    
    box-shadow: none !important;
    padding-bottom: 24px !important;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
}
