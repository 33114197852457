.payment-gateway-config-view-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--charcoal-grey);
  margin-bottom: 5px;
}
.payment-gateway-config-view-value {
  font-size: 14px;
  color: var(--battleship-grey);
  display: flex;
  align-items: center;
  line-height: 30px;
}
.payment-gateway-config-view-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.payment-gateway-config-card{
  overflow-x: auto !important;
}