.list-card-header {
  border: 0px;
  background-color: var(--approx-white);
  padding: 0 0 24px 0;
}
.list-card-header h1 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 0;
  margin-top: 5px;
}

.card-list .top-action-lists li {
  margin-bottom: 0;
}
.right-button {
  float: right;
}
.list-table-wrapper {
  width: 100%;
  padding: 19px 0 0 0;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  white-space: break-spaces;
}
.kebabMenu {
  white-space: normal !important;
}
.table-info {
  padding: 0 24px 16px 24px;
  --bs-gutter-x: 0 !important;
}
.data-info {
  font-size: 14px;
  color: var(--charcoal-grey);
  padding: 0 100px 0 0;
  height: 32px !important;
}

.list-table-wrapper div.dataTables_info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-top: 3px;
}
.list-table-wrapper div.dataTables_info b {
  font-weight: bold;
}
.results-per-page {
  font-size: 14px;
  color: var(--slate);
  text-align: right;
  height: 32px !important;
}
.list-table-wrapper div.dataTables_length label {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 1.71 !important;
  letter-spacing: normal !important;
  color: var(--slate) !important;
  text-align: left !important;
  white-space: nowrap !important;
  float: initial !important;
  margin: 0 !important;
}

/* Number of rows length */
div.dataTables_length .bootstrap-select,
.row-count-wrapper {
  display: inline-block;
  width: 75px !important;
  height: 33px;
  padding: 0 !important;
  margin: 0 0 0 8px !important;
  border-radius: 6px;
  box-shadow: none;
  border: 0;
  background-color: var(--white-fill) !important;
}
.row-count-wrapper input,
.row-count-wrapper input:focus {
  border: 0 !important;
}
div.dataTables_length select {
  display: inline-block;
}
div.dataTables_length select + .dropdown-toggle {
  display: block;
  position: relative;
  width: 100%;
  height: 32px;
  padding: 5px 3px 3px 11px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  word-wrap: normal;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  border: solid 1px var(--approx-whisper);
  background-color: var(--white-fill) !important;
  outline: 0 !important;
}
div.dataTables_length select + .dropdown-toggle:focus {
  outline: 0 !important;
}
div.dataTables_length select + .dropdown-toggle .filter-option-inner-inner {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
div.dataTables_length select + .dropdown-toggle:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url(../../../assets/images/rows-count-select-arrow-down.svg) !important;
  background-size: 24px 24px !important;
  background-position: center;
  background-repeat: no-repeat;
  right: 2px;
  top: 50%;
  margin-top: -12px;
  border: 0 !important;
}
div.dataTables_length select + .dropdown-toggle.show:after {
  transform: rotate(-180deg);
}
div.dataTables_length div.dropdown-menu {
  padding: 0 !important;
  inset: initial !important;
  transform: translate3d(0, 3px, 0) !important;
  min-height: auto !important;
}
div.dataTables_length .dropdown-menu {
  min-width: auto !important;
  width: 75px !important;
}
div.dataTables_length .dropdown-menu .inner {
  width: 75px;
}
div.dataTables_length .dropdown-menu li a,
div.dataTables_length .dropdown-menu li a span {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  outline: 0 !important;
}
div.dataTables_length .dropdown-menu li a:active,
div.dataTables_length .dropdown-menu li a:active span,
div.dataTables_length .dropdown-menu li a.active,
div.dataTables_length .dropdown-menu li a.active span {
  color: var(--cerulean-blue);
}

div.dataTables_length .dropdown-menu li a {
  padding-top: 5px;
  padding-bottom: 5px;
}
div.dataTables_length .dropdown-menu li a:hover span {
  color: var(--cerulean-blue);
}

div.dataTables_length .dropdown-menu li a.active:hover span {
  color: var(--cerulean-blue);
}
/* Number of rows length */

.list-table {
  min-width: 1132px;
  margin-bottom: 0 !important;
  border: 0;
  border-radius: 0 0 12px 12px;
}
.list-table th,
.list-table td {
  padding: 13px 12px !important;
  min-width: 100px;
}
.list-table th:first-child,
.list-table td:first-child {
  padding-left: 24px !important;
}
.list-table th {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
  white-space: nowrap;
}
.list-table td {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
  box-shadow: none !important;
  background-color: transparent;
}
.list-table tbody tr:nth-child(even) td {
  box-shadow: none !important;
  background-color: rgba(var(--peak-point-rgb), 0.07) !important;
}
.list-table tr:last-child td:first-child {
  border-radius: 0 0 0 12px;
}
.list-table tr:last-child td:last-child {
  border-radius: 0 0 12px 0;
}

.list-table tbody tr:hover td {
  background-color: var(--alice-blue);
  box-shadow: none !important;
}
.list-table thead tr:hover,
.list-table tbody tr:hover td {
  box-shadow: none !important;
}
.list-table tbody tr.no-item td {
  background-color: var(--white-fill) !important;
  box-shadow: none !important;
}
.list-table tr td.action {
  width: 64px !important;
  min-width: 64px !important;
  text-align: right;
  padding: 9px 24px 9px 0 !important;
}

.list-table th.itemdate,
.list-table td.itemdate {
  width: 144px !important;
}
.list-table th.inv_num,
.list-table td.inv_num {
  min-width: 114px !important;
}
.list-table th.ref_num,
.list-table td.ref_num {
  min-width: 154px;
}
.list-table th.ref_type,
.list-table td.ref_type {
  min-width: 100px;
}
.list-table th.inv_status,
.list-table td.inv_status {
  min-width: 90px !important;
}
.list-table th.total,
.list-table td.total {
  min-width: 125px !important;
}
.list-table th.balance_due,
.list-table td.balance_due {
  min-width: 116px !important;
}

.list-table th.due_days,
.list-table td.due_days {
  min-width: 120px;
  padding: 13px 9px 13px 8px !important;
}

.list-table th.email,
.list-table td.email {
  min-width: 120px;
  padding: 13px 9px 13px 8px !important;
}
.list-table th.billing_addr,
.list-table td.billing_addr {
  padding: 13px 12px !important;
}
.list-table th.shipping_addr,
.list-table td.shipping_addr {
  min-width: 151px !important;
}
.list-table th.total_taxable_amt,
.list-table td.total_taxable_amt {
  min-width: 164px !important;
}
.list-table th.tds,
.list-table td.tds {
  min-width: 140px !important;
}
.list-table th.email,
.list-table td.email,
.list-table th.cust_name,
.list-table td.cust_name,
.list-table th.billing_addr,
.list-table td.billing_addr .list-table th.created_by,
.list-table td.created_by {
  min-width: 130px !important;
}
.list-table td.cust_name .custom-tooltip-wrapper,
.list-table td.billing_addr .custom-tooltip-wrapper,
.list-table td.created_by .custom-tooltip-wrapper,
.list-table td.shipping_addr .custom-tooltip-wrapper {
  max-width: 160px;
}
.list-table td.date,
.list-table td.inv_num,
.list-table td.due_date,
.list-table td.total,
.list-table td.balance_due,
.list-table td.tds,
.list-table td.cr_note_balance,
.list-table td.dt_note_balance,
.list-table td.total_taxable_amt,
.list-table td.cr_note_num,
.list-table td.bill_num,
.list-table td.ref_num,
.list-table td.pay_num {
  white-space: nowrap !important;
}
.list-table th.org,
.list-table td.org {
  min-width: 155px !important;
  padding: 13px 12px !important;
}
.list-table th.tags,
.list-table td.tags {
  min-width: 170px;
}
.list-table .tag-count {
  min-width: 28px !important;
  padding: 0 5px 0 6px !important;
}

.list-table th[data-orderable="true"] {
  cursor: pointer;
}
.list-table th[data-orderable="true"] span {
  width: auto !important;
  position: relative;
  padding-right: 19px;
}
.list-table th[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  bottom: -1px;
  right: 8px;
}
.list-table th[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  right: 0;
  bottom: -1px;
}

.list-table th.ascending span:before {
  opacity: 1;
}
.list-table th.ascending span:after {
  opacity: 0.5;
}
.list-table th.descending span:before {
  opacity: 0.5;
}
.list-table th.descending span:after {
  opacity: 1;
}
.list-table th[data-orderable="true"] {
  cursor: pointer;
}
.list-table th[data-orderable="true"] span {
  width: auto !important;
  position: relative;
  padding-right: 19px;
}
.list-table th[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  bottom: -1px;
  right: 8px;
}
.list-table th[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  right: 0;
  bottom: -1px;
}
.list-table th.vend_name,
.list-table td.vend_name {
  min-width: 130px;
}
.list-table td.vend_name .custom-tooltip-wrapper {
  max-width: 160px;
}

.list-table th.action,
.list-table td.action {
  width: 64px !important;
  min-width: 64px !important;
  text-align: right;
  padding: 9px 24px 9px 0 !important;
}
.action span {
  position: relative;
  top: 2px;
}

.tag-box {
  width: auto;
  min-width: 47px;
  height: 20px;
  margin: 0 7px 8px 0;
  padding: 0 8px;
  border-radius: 4px;
  border: solid 1px var(--pattens-blue);
  background-color: var(--approx-alice-blue);
  font-size: 13px;
  line-height: 1.54;
  color: var(--battleship-grey);
  float: left;
}
.list-Box {
  height: 20px;
  padding: 0 2px;
  font-size: 14px;
  float: left !important;
}
.tag-count {
  margin: 0;
  padding: 0 6px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  border: solid 1px var(--cerulean-blue-shadow2);
  background-color: var(--new-alice-blue);
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  margin: 0 !important;
}
.card-list table .action .dropdown-menu {
  min-width: 130px;
  height: auto !important;
  text-align: left;
  position: absolute;
  inset: 25px -13px auto auto !important;
  margin: 0px !important;
  padding: 14px 0 14px 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  margin-left: -65px;
}
.card-list table .action .dropdown-menu li {
  margin-bottom: 0;
}
.card-list table .action .dropdown-item,
.card-list table .action .dropdown-item span {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left;
}

.card-list table .action .dropdown-item {
  padding: 8px 14px;
}

.action .dropstart,
.action .dropdown {
  width: 40px;
  margin-left: auto;
}
.action .dropstart .more-btn,
.action .dropdown .more-btn {
  text-align: center;
}
.action img,
.action svg {
  cursor: pointer;
  margin-right: 0;
}
.card-list table .action .dropdown-item:focus,
.card-list table .action .dropdown-item:hover,
.card-list table .action .dropdown-item:active {
  color: var(--cerulean-blue) !important;
  background-color: transparent !important;
}
.card-list table .action .dropdown-item.delete,
.card-list table .action .dropdown-item.delete:active,
.card-list table .action .dropdown-item.delete:focus,
.card-list table .action .dropdown-item.delete:hover {
  color: var(--red) !important;
}
.s-blue,
.list-table td.s-blue {
  color: var(--cornflower-blue) !important;
}
.s-red,
.list-table td.s-red {
  color: var(--red) !important;
}

.s-gray,
.list-table td.s-gray {
  color: var(--slate) !important;
}

.s-orange,
.list-table td.s-orange {
  color: var(--gamboge) !important;
}
.s-purple,
.list-table td.s-purple {
  color: var(--amethyst) !important;
}
.s-green,
.list-table td.s-green {
  color: var(--crusoe) !important;
}

.popover-body .list-box {
  display: block;
  float: left;
  margin-right: 5px;
  padding: 0 2px 5px 2px;

  font-size: 13px;
  line-height: 1.54;
  color: var(--battleship-grey);
}

.subscription-unbilled-table tbody tr:nth-child(even) td {
  box-shadow: none !important;
  background-color: transparent !important;
}
