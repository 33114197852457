.img-upload-wrapper {
  display: flex;
  width: 100%;
}
.img-upload-wrapper .preview-block {
  width: 100px;
  flex: 0 0 100px;
}
.img-upload-wrapper .preview-block .img-container {
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: 9px;
  float: left;
  clear: both;
  border-radius: 4px;
  background-color: rgba(var(--peak-point-rgb), 0.1);
  text-align: center;
  position: relative;
}
.img-upload-wrapper .preview-block img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.img-upload-wrapper .preview-block a {
  display: block;
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--booger);
  text-decoration: none;
  clear: both;
}
.img-upload-wrapper .upload-block {
  width: calc(100% - 100px);
  padding-left: 22px;
}
.img-upload-wrapper.edit-wrapper .upload-block {
  padding: 48px 0 48px 22px;
}
.img-upload-wrapper .upload-block #file-upload {
  display: none;
}
.img-upload-wrapper .upload-block .upload-file-btn {
  height: 40px;
  box-shadow: none;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 24px;
  border-radius: 6px;
  background-color: var(--booger);
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: normal;
  color: var(--white-fill);
  margin: 0 0 8px 0;
}
.img-upload-wrapper .upload-block p {
  width: 100%;
  margin-bottom: 0;
}
.img-upload-wrapper .upload-block p span {
  display: block;
  width: 100%;
  float: left;
  line-height: 1.38;
  letter-spacing: normal;
}
.img-upload-wrapper .upload-block p span.boldText {
  font-size: 13px;
  font-weight: bold;
  color: var(--battleship-grey);
}
.img-upload-wrapper .upload-block p span.semiBoldText {
  font-size: 12px;
  font-weight: 600;
  color: var(--slate);
}
