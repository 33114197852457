.reusable-report-table-wrapper {
  width: 100% !important;
}
.reusable-report-table-wrapper table {
  width: 100% !important;
}
.reusable-report-table-wrapper thead {
  width: 100% !important;
  background-color: #fff !important;
  z-index: 1 !important;
}

.profit-and-loss-report-table tr td:first-child {
  z-index: 1000 !important;
}
.profit-and-loss-report-table tr:not(tr.disabled):hover {
  box-shadow: none !important;
}
.profit-and-loss-report-table tr th:first-child {
  background-color: #fff !important;
  z-index: 1000 !important;
}
.reusable-report-table-wrapper table tbody tr td:last-child {
  padding-right: 30px !important;
}
.reusable-report-table-wrapper table thead th:last-child {
  padding-right: 30px !important;
}
.reusable-report-table-wrapper table tbody tr td:first-child {
  padding-left: 30px !important;
}
.reusable-report-table-wrapper table thead th:first-child {
  padding-left: 30px !important;
}
