.contact-persons-wrapper {
    border-radius: 4px;
    position: relative;
    /* padding: 20px 16px 24px 16px; */
    padding: 20px 16px 15px 16px;
    border: solid 1px rgba(var(--cerulean-blue-rgb), 0.11);
    margin-bottom: 16px;
}

.main-contact-highlight {
    background-color: rgba(var(--cerulean-blue-rgb), 0.05);
}
.contact-persons-wrapper .mw-325 {
    max-width: calc(324px + 2rem) !important;
}

.contact-border {
    border-bottom: 1px solid var(--approx-whisper);
    margin: 0 -24px;
}
.delete-icon {
    position: absolute;
    right: 1em;
}


@media (max-width: 1040px) {
    .contact-persons-wrapper .mw-325 {
        max-width: calc(294px + 2rem) !important;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .contact-persons-wrapper .mw-325 {
        max-width: calc(50% - 2rem) !important;    
    }
}