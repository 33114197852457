.nav-add-button {
    position: relative;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--booger);
    border: 0;
    font-size: 15px;
    font-weight: bold;
    line-height: 0.67;
    letter-spacing: normal;
    color: var(--white-fill);
    padding: 10px 8px 10px 10px;
    /* margin: 1px 24px 0.2px 0; */
}
.nav-add-button:hover {
    background-color: var(--booger);
    color: var(--white-fill);
}
.nav-add-button:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: rgba(var(--black-rgb), 0.08);
}
.nav-add-button:hover {
    /* background-color: linear-gradient(rgba(0,0,0,0.08)); */
    color: var(--white-fill);
}
.top-nav .nav-add-button:after {
    position: absolute;
    border: 0;
    width: 20px;
    height: 20px;
    background: url("../../../../../assets/images/arrow_dropdown.svg") no-repeat right
        center;
    background-size: 20px 20px;
    margin: 0;
    right: 0;
    top: 50%;
    transform: translate(-8px, -50%);
}
.nav-add-button img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin: 0;
    float: left;
}
.nav-add-button-text {
    line-height: 0.67;
    margin: 5px 11px 5px 3px;
    font-size: 15px;
    font-weight: bold;
    color: var(--white-fill);
    float: left;
}
.dropdown-list-item {
    position: relative;
}
.add-items-link {
    margin-right: 40px;
}
.nav-item .mega-menu {
    /* min-width: 527px; */
    width: auto;
    padding: 16px 17px 7px 16px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px 0 var(--black-shadow11);
    box-shadow: 0 2px 8px 0 var(--black-shadow11);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    right: 0 !important;
    top: 46px !important;
}
.dropdown-menu.mega-menu.show {
    display: flex;
    justify-content: space-between;
}
.nav-item .mega-menu ul {
    padding: 0 !important;
}
.nav-item .mega-menu ul li {
    width: 100%;
}
.nav-item .mega-menu ul li a {
    padding: 13px 0 13px 3px;
}
.nav-item .mega-menu .mMenu-List {
    list-style: none;
    padding: 0 !important;
    text-align: left;
    float: left;
    margin-right: 20px;
    white-space: nowrap;
}
.nav-item .mega-menu .mMenu-List:last-child {
    margin-right: 0;
}
.mMenu-link,
.mMenu-link .link-text {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--battleship-grey);
    text-decoration: none;
}
.mMenu-link svg {
    width: 12px;
    height: 12px;
    margin: 0 6px 0 0;
}
.mMenu-link .link-text {
    display: inline-block;
}

.mMenu-link:focus,
.mMenu-link:focus .link-text,
.mMenu-link:hover,
.mMenu-link:hover .link-text {
    color: var(--cerulean-blue);
}
.mMenu-link:focus svg path:last-child,
.mMenu-link:hover svg path:last-child {
    fill: var(--cerulean-blue);
}
.mMenu-List .section-title,
.mMenu-List .section-title h5 {
    font-size: 12px;
    font-weight: bold;
    line-height: 0.83;
    letter-spacing: normal;
    color: var(--battleship-grey);
    margin: 0 !important;
}
.mMenu-List .section-title h5 {
    display: flex;
    align-items: center;
    margin-bottom: 7px !important;
}
.mMenu-List .section-title img {
    width: 16px;
    height: auto;
    margin: 0 6px 0 0;
    object-fit: contain;
}
@media (max-width: 1180px) {
    .add-items-link {
        margin-right: 20px;
    }
}
@media (max-width: 1050px) {
    .add-items-link {
        margin-right: 15px;
    }
}
@media (max-width: 1024px) {
    .add-items-link {
        margin-right: 30px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {  
    .dropdown-menu.mega-menu.show {
        left: 0 !important;
        right: auto !important;
    }
    .sidebar-togglebtn {
        right: -11px;
    }  
}
@media (max-width: 768px) {
    
}
@media (max-width: 767px) {
    .add-items-link {
        margin-right: 20px !important;
        position: static !important;
    }
    .nav-add-button {
        position: relative;
        display: inline-block;
        width: auto !important;
        top: 2px;
        padding: 0;
        height: auto;
        border-radius: 50% !important;
    }
    .nav-add-button img {
        margin: 9px;
    }
    .nav-add-button:hover:before {
        display: none;
    }
    .nav-add-button-text,
    .top-nav .nav-add-button:after {
        display: none;
    }
    .nav-item  .mega-menu {
        width: 100vw;
        flex-wrap: wrap;
        left: 0 !important;
        right: 0 !important;
        top: 75px !important;
        justify-content: space-between;
    }
    .mMenu-List .section-title, 
    .mMenu-List .section-title h5 {
        font-size: 14px;
        line-height: normal;
    }
    .mMenu-link, 
    .mMenu-link .link-text {
        font-size: 14px;
    }
    .nav-item .mega-menu .mMenu-List {
        width: 155px !important;
        margin-right: 0 !important;
        padding: 0 14px 14px 0 !important;
    }
    .dropdown-menu.mega-menu {
        max-height: calc(100vh - 75px);
        overflow: hidden;
        overflow-y: auto;
    }
}