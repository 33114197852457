.jv-detail-header .invoice-details {
  width: 100%;
  text-align: left;
}
.jv-detail-header .invoice-details * {
  text-align: left !important;
}
.detail-header {
  width: 100%;
  border-radius: 6px;
  background-color: var(--white-smoke);
  padding: 30px;
  margin-bottom: 40px;
}
.header-details {
  width: 100%;
}
.detil-page-title {
  font-size: 36px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: var(--charcoal-grey);
  margin-bottom: 16px;
}
.header-item-summary {
  width: 100%;
  display: flex;
}
.header-item-summary .item-summary-col {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}
.header-item-summary .item-summary-col.jv-total-amount-in-words {
  max-width: 400px;
}
.header-item-summary
  .item-summary-col.jv-total-amount-in-words
  .summary-item-value {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
}
.header-item-summary .item-summary-col .summary-item-title {
  width: 100%;

  font-size: 14px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin: 0 0 8px 0;
  padding: 0;
}
.header-item-summary .item-summary-col .summary-item-value {
  width: 100%;

  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding: 0;
  margin: 0;
}
.voucher-number {
  width: auto;
  min-width: 153px;
}
.jv-amount {
  width: auto;
  min-width: 112px;
}
.jv-date {
  width: 124px;
}
.jv-ref {
  width: auto;
  min-width: 180px;
  /* width: 115px; */
}
.jv-createdby {
  width: auto;
}
.table-section.jv-table-section table tr th,
.table-section.jv-table-section table tr td {
  padding: 12px 24px !important;
}
.table-section.jv-table-section table tr th:last-child,
.table-section.jv-table-section table tr td:last-child {
  padding-right: 30px !important;
}
.table-section.jv-table-section table tr td:first-child,
.table-section.jv-table-section table tr td:nth-child(2) {
  text-align: left !important;
}
.table-section.jv-table-section table tr td:last-child {
  font-weight: normal !important;
}
.table-section.jv-table-section table tr:last-child td {
  font-size: 18px !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: right !important;
  border-bottom: 0 !important;
}
#invoice .table-section.jv-table-section table tr:last-child td {
  border-bottom: 0 !important;
  white-space: nowrap;
}
.table-section.jv-table-section table tr:last-child td:nth-child(2) {
  font-weight: bold !important;
  letter-spacing: -0.23px !important;
  color: var(--battleship-grey) !important;
  padding: 20px 10px 20px 24px !important;
}
.table-section.jv-table-section table tr:last-child td:nth-child(3),
.table-section.jv-table-section table tr:last-child td:nth-child(4) {
  font-weight: 900 !important;
  color: var(--charcoal-grey) !important;
  padding: 20px 10px 20px 0 !important;
}
.table-section.jv-table-section table tr:last-child td:nth-child(4) {
  padding-right: 30px !important;
}
.table-section.jv-table-section table tr th:first-child,
.table-section.jv-table-section table tr td:first-child {
  width: 26.3757% !important;
}
.table-section.jv-table-section table tr th:nth-child(2),
.table-section.jv-table-section table tr td:nth-child(2) {
  width: 35.0095% !important;
}
.table-section.jv-table-section table tr td:nth-child(2) {
  line-height: 1 !important;
  word-break: break-all;
}
.table-section.jv-table-section table tr td:nth-child(3),
.table-section.jv-table-section table tr td:nth-child(4) {
  white-space: nowrap;
}
.jv-table-section {
  overflow: hidden;
  overflow-x: auto;
}
.jv-table-section table {
  table-layout: initial;
  min-width: 1030px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .jv-detail-header.detail-header {
    padding-bottom: 10px;
  }
  .jv-record-details.header-item-summary {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .jv-record-details.header-item-summary .item-summary-col {
    margin-bottom: 20px;
  }
  .header-item-summary .item-summary-col .summary-item-value {
    white-space: nowrap;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .jv-record-details.header-item-summary {
    flex-direction: column;
  }
  .jv-record-details.header-item-summary .item-summary-col * {
    text-align: left;
  }
}
