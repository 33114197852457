#calendar-billing-button {
  line-height: 20px !important;
  margin: 0 !important;
  padding: 0 10px !important;
  border: none;
  background: transparent;
  color: blue;
  font-weight: 700;
  background: var(--booger);
}

.calendar-billing-button {
  margin-top: 0 !important;
  justify-content: end;
  padding: 0px 9px 18px 16px;
  margin-top: 19px;
}

.billing-and-proration-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.billing-and-proration-modal-container
  .billing-and-proration-modal-inner-container {
  width: 85%;
}

.billing-and-proration-modal-container .select-field-wrap {
  margin-bottom: 23px !important;
}

.billing-and-proration-modal #reusableSideModalBody {
  border: none;
  margin-top: 40px !important;
}

.billing-and-proration-modal .side-modal-title {
  padding-left: 26px;
}

.billing-and-proration-modal .billing-and-proration-modal-footer {
  position: fixed;
  bottom: 0;
  margin-bottom: 38px;
  right: 145px;
}

.billing-and-proration-datepicker-monthly {
  padding-top: 30px;
}

.billing-and-proration-datepicker-monthly .MuiPickersCalendarHeader-root {
  display: none !important;
}

.billing-and-proration-datepicker-monthly .MuiDayPicker-weekDayLabel {
  display: none !important;
}

.billing-and-proration-datepicker-yearly .MuiDayPicker-weekDayLabel {
  display: none !important;
}

.billing-and-proration-datepicker-yearly .MuiPickersArrowSwitcher-root {
  display: none !important;
}
.calendar-billing-button .add-tags-button {
  padding: 0px;
  margin: 0px;
  line-height: 20px !important;
  border: 0px !important;
  background-color: white;
}
.calendar-billing-button .add-coupon-button span {
  padding: 0px 10px;
}
.calendar-billing-button .add-coupon-button img {
  pointer-events: none;
  cursor: pointer;
  margin-top: -3px !important;
}

.calendar-billing {
  width: 400px;
}
.m-t-20 {
  margin-top: 20px;
}

.calendar-billing .side-modal-title {
  flex-direction: column;
}

.calendar-billing .modal-body {
  padding: 0 30px 40px 30px;
  border-top: 1px solid var(--approx-whisper);
}
.calendar-billing .modal-body .MuiList-root {
  padding: 0px;
}
.calendar-billing .modal-body .MuiButtonBase-root {
  border-bottom: 1px solid var(--approx-whisper);
  margin: 0px 24px;
}

.calendar-billing .modal-body .MuiButtonBase-root:hover {
  background-color: #e9f2fd;
}

.calendar-billing .billing-and-proration-plan-multiselect {
  width: 101% !important;
}

.calendar-billing .choose-plan-wrapper {
  display: flex;
  flex-direction: column;
}

.calendar-billing .choose-day-select-mb {
  margin-bottom: 25px;
}

.calendar-billing .MuiInputAdornment-outlined {
  width: 14% !important;
}

.calendar-billing .multiSelectContainer .searchWrapper {
  padding: 5px 34px 5px 11px !important;
}
