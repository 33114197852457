.file-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 0 0;
}
.file-list-container .fileCount {
  width: 100%;
  clear: both;
  float: left;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  padding: 0 0 6px 0;
}
.file-list-container .file-list {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
.file-list-container .file-list li {
  float: left;
  width: 100%;
  padding: 10px 0;
}
.file-list-container .file-list .file-item {
  display: block;
  text-decoration: none;
  float: left;
  align-items: center;
  align-content: center;
}
.file-list-container .file-list .file-item img {
  width: 16px;
  height: auto;
  margin: 0 8px 0 0;
  object-fit: contain;
}
.file-list-container .file-list .file-item .file-name {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  margin: 0 6px 0 0;
}
.file-list-container .file-list .file-item .file-size {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.15px;
  color: var(--battleship-grey);
}
