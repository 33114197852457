.close-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.close-btn img {
  float: left;
  width: 100%;
  object-fit: contain;
}
.close-btn svg {
  float: left;
  width: 100%;
  height: 100%;
}

.save-button,
.cancel-button {
  width: auto;
  height: auto;
  padding: 11px 24px;
  border-radius: 6px;
  border: none;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
}

.save-button {
  position: relative;
  background-color: var(--booger);
  color: var(--white-fill);
  margin-right: 20px;
}

.save-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.save-button:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}
.cancel-button,
.cancel-button.no-appearance {
  border: solid 1px var(--booger);
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1);
  box-shadow: 0 2px 6px 0 var(---black-shadow1);
  color: var(--booger);
  background-color: var(--white);
  position: relative;
  outline: 0;
}
.cancel-button:focus,
.cancel-button.no-appearance:focus,
.save-button:focus {
  box-shadow: none !important;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px !important;
}
.save-button[disabled] {
  opacity: 0.5;
}
.blue-edit-btn {
  color: var(--cerulean-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50px;
  background-size: 16px 16px;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}
.edit-button {
  padding: 11px 42px 11px 33px;
  border-radius: 6px;
  border: solid 1px var(--booger) !important;
}
.edit-button .green-button-text {
  letter-spacing: normal;
  color: var(--booger) !important;
}
.edit-button:hover {
  background-color: var(--booger) !important;
}
.edit-button:hover svg path:last-child {
  fill: var(--white-fill) !important;
}
.edit-button:hover .green-button-text {
  color: var(--white-fill) !important;
}
.edit-button:focus {
  box-shadow: none;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px;
}

.tick-save-btn {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.red-cross-cancel-btn {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text-btn {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.cancel-button svg {
  margin-right: 8px;
}
.cancel-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.cancel-button:hover svg path:last-child {
  fill: var(--white-fill);
}
.blue-button-text {
  color: var(--cerulean-blue) !important;
}

.docked-button-container.docked-bottom {
  position: fixed;
  width: calc(100% - 96px) !important;
  padding-left: 64px !important;
  right: 0 !important;
  bottom: 0 !important;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border-top: solid 1px var(--warm-white);
  background-color: var(--white-fill) !important;
  opacity: 1 !important;
  z-index: 99;
  padding-top: 16px !important;
  padding-bottom: 20px !important;
  transition: 0.5s;
  margin-bottom: 0px;
}
.docked-button-container.docked-bottom.btn-group-disabled:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--white-fill);
  opacity: 0.5;
  z-index: 1;
}
.expanded-submenu .docked-button-container.docked-bottom {
  /* width: calc(100% - 226px) !important; */
  width: calc(100% - 236px) !important;
}
.docked-button-container.docked-bottom .dropdown-menu {
  position: absolute !important;
  inset: auto auto 0px 0px !important;
  margin: 0px !important;
  transform: translate3d(8px, -47px, 0px) !important;
}
.delete-btn {
  position: absolute;
  right: 50px;
}
.button-start-icon {
  margin-right: 5px !important;
}
.button-end-icon {
  margin-left: 5px !important;
}
.docked-button-container .delete-item-button {
  float: right;
  margin-right: 40px;
}
.modal-btn-docked-bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  border-top: 0;
}
.primary-btn {
  min-width: 80px;
  padding: 11px 24px;
  border-radius: 6px;
  background-color: var(--booger);
  color: var(--white-fill);
}
.primary-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}

@media (min-width: 768px) and (max-width: 991px) {
  .save-button,
  .cancel-button {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .expanded-submenu .docked-button-container.docked-bottom {
    position: static !important;
    width: 100% !important;
    padding-left: 25px !important;
    box-shadow: none !important;
    padding-bottom: 24px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .expanded-submenu .docked-button-container.docked-bottom .form-btn-container {
    flex-wrap: wrap;
  }
  .expanded-submenu .docked-button-container.docked-bottom {
    padding-bottom: 10px;
  }
  .expanded-submenu
    .docked-button-container.docked-bottom
    .form-btn-container
    > button,
  .expanded-submenu
    .docked-button-container.docked-bottom
    .form-btn-container
    .btn-group
    > button,
  .expanded-submenu
    .docked-button-container.docked-bottom
    .form-btn-container
    > a {
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .expanded-submenu .docked-button-container.docked-bottom .delete-item-button {
    margin-left: 0 !important;
    float: left;
  }
}
