  .edit-payment-term-form-wrap .col:nth-child(2) {
    padding-left: 10px;
  }
  
  .edit-payment-term-form-wrap .row {
    border-bottom: solid 1px var(--new-whisper);
  }
  .edit-payment-term-form-wrap .title-row {
    border-top: solid 1px var(--new-whisper);
  }
  .edit-payment-term-form-wrap .col {
    flex: 0 0 auto;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 10px; 
  }
  .edit-payment-term-form-wrap .title-row .col:first-child,
  .edit-payment-term-form-wrap .tbody-container .row .col:first-child {
    width: 202px;
  }
  .edit-payment-term-form-wrap .title-row .col:nth-child(2),
  .edit-payment-term-form-wrap .tbody-container .row .col:nth-child(2) {
    width: 180px;
  }
  .edit-payment-term-form-wrap .title-row .col:last-child,
  .edit-payment-term-form-wrap .tbody-container .row .col:last-child {
    width: auto;
    align-self: center;
  }
  
  .edit-payment-term-form-wrap input,
  .edit-payment-term-form-wrap .tagName {
    width: 80px;
  
    font-size: 14px;
    font-weight: normal;
  
    line-height: 0.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 6px;
    border-radius: 4px;
    border: solid 1px var(--new-whisper);
    /* border: solid 1px blue; */
    background-color: var(--white-fill);
    pointer-events: auto !important;
  }
  .edit-payment-term-form-wrap input {
    display: block;
  }
  .edit-payment-term-form-wrap input.terms {
    width: 143px !important;
  }
  
  .edit-payment-term-form-wrap input:disabled {
    border: 0;
    background: transparent;
    opacity: 1;
    pointer-events: none !important;
    padding-left: 0;
    display: none;
  }
  .edit-payment-term-form-wrap .tagName {
    width: 143px;
    display: block;
    border: 0;
    background: transparent;
    opacity: 1;
    pointer-events: none !important;
    padding: 8px 6px 8px 0;
    line-height: 16px;
  }
  .edit-payment-term-form-wrap .tagName.hidden {
    display: none;
  }
  
  .edit-payment-term-form-wrap .term-title {
    width: 143px;
  }
  .edit-payment-term-form-wrap .row.editing {
    background-color: var(--solitude);
  }
  .edit-payment-term-form-wrap .row:not([class^="title-row"]):hover {
    background-color: var(--solitude);
  }
