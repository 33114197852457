#customer_table {    
    border-radius: 12px;
    -webkit-box-shadow: 0 6px 0 0 var(--black-rgb-percent1);
    box-shadow: 0 6px 0 0 var(--black-rgb-percent1);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
    margin-bottom: 0 !important;
    border: 0;
}
#customer_table_wrapper {
    width: 100%;
    padding: 19px 0 0 0;
    border-radius: 12px;
    -webkit-box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
    box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
    border: solid 1px var(--warm-white) !important;
    background-color: var(--white-fill);
}

#customer_table_wrapper .dataTables_info,
#customer_table_wrapper .results-per-page {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}
#customer_table_wrapper .results-per-page label {
    color: var(--slate);
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.71 !important;
    letter-spacing: normal !important;    
    text-align: left !important;
    white-space: nowrap !important;
    float: initial !important;
    margin: 0 !important;
}
#customer_table_wrapper div.dataTables_length select {
    width: 75px;
    height: 32px;
    margin: 0 0 0 8px;
    padding: 7px 28px 7px 11px;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--approx-whisper);
    background-color: var(--white-fill) !important;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    background: url("../../../../../assets/images/dropdwon-arrow-down.svg") no-repeat !important;
    background-size: 24px 24px !important;
    background-position: right 3px center !important;
}
#customer_table_wrapper thead,
#customer_table_wrapper th {
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: normal;
    color: var(--battleship-grey) !important;
    text-transform: uppercase;
}
#customer_table_wrapper td,
#customer_table_wrapper th {
    padding-left: 0 !important;
}
#customer_table_wrapper td,
#customer_table_wrapper td span {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    box-shadow: none;
}
#customer_table_wrapper td .ellipsis {
    max-width: 100%;
    display: block;
}
#customer_table_wrapper tr th.name,
#customer_table_wrapper tr td.name {
    width: 24% !important;
    padding-left: 23px !important;
}
#customer_table_wrapper tr th.email,
#customer_table_wrapper tr td.email {
    width: 25% !important;
    padding-left: 12px !important;
}
#customer_table_wrapper tr th.phone_num,
#customer_table_wrapper tr td.phone_num {
    min-width: 200px !important;
}
#customer_table_wrapper tr th.tags,
#customer_table_wrapper tr td.tags {
    width: 28% !important;
}
#customer_table_wrapper tr th.balance,
#customer_table_wrapper tr td.balance {  
    min-width: 140px !important;
    white-space: nowrap;
}

table.dataTable.table-striped > tbody > tr.even > * {
    box-shadow: none;
}
table.dataTable.table-striped > tbody > tr > td,
table.dataTable.table-striped > thead > th {
    padding: 13px 12px;
}
table.dataTable.table-striped > tbody > tr > td:first-child,
table.dataTable.table-striped > thead > th:first-child {
    padding: 13px 12px 13px 24px;
    min-width: 150px;
}
table.dataTable.table-striped > tbody > tr > td:last-child,
table.dataTable.table-striped > thead > th:last-child {
    padding: 13px 24px 13px 12px;
    width: 56px;
}
table.dataTable.table-striped > tbody > tr.even > td {
    background-color: rgba(var(--peak-point-rgb), 0.07);
}
table.dataTable.table-striped > tbody > tr:hover > td {
    background-color: var(--alice-blue);
    box-shadow: none !important;
}
table.dataTable.table-striped > thead > tr:hover {
    box-shadow: none !important;
}

table tbody tr:nth-child(even) td {
    box-shadow: none !important;
    background-color: rgba(var(--peak-point-rgb), 0.07);
}
.create-invoice-container .preview-img {
    width: 116px !important;
}
table tbody tr.no-item,
table tbody tr.no-item td {
    background-color: transparent !important;
    box-shadow: none !important;
}
