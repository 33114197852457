.confirmation-popup-body-wrapper {
  min-width: 500px;
  height: fit-content;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.confirmation-popup-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.confirmation-popup-message-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
