.major-search-attribute {
  display: block;
  width: fit-content;  
  padding: 5px 4px;
  border-radius: 4px;
  background-color: var(--new-white-smoke);
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-top: 6px;
  clear: both;
}
