.tags-options-mapping-wrapper {
  width: 450px;
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.tags-options-mapping-header-wrapper {
  display: flex;
  justify-content: start;
}
.tags-options-mapping-header-wrapper > span {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
}
.tags-options-parent-tag-name-wrapper {
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 36px;
}
.tags-options-parent-tag-name-wrapper > span {
  font-size: 18px;
  font-weight: 500;
}

.tags-options-mapping-option-wrapper {
  padding-left: 30px;
  margin-top: 15px;
  max-height: 400px;
  
}
.tags-options-mapping-select-all-wrapper {
  /* padding-left: 20px; */
  margin-top: 15px;
  clear: both;

}
.tags-options-mapping-option-array-wrapper {
  display:flex;
  flex-direction: column;
  width: 100% !important;
  max-height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
}
.tag-options-mapping-btn-wrapper {
  width: 100%;
  max-width: 450px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--warm-white);
}
.tag-options-mapping-action-btn-wrapper {
  flex: 4;
  display: flex;
  max-width: 230px;
  justify-content: space-between;
}

.tag-options-mapping-text-btn-wrapper {
  flex: 3;
}
