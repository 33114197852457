.page-heading {
  
  font-size: 24px;
  font-weight: 900;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  background: var(--white-fill);
  margin-bottom: 30px;
}

.daterangepicker .drp-calendar.left {
  display: none !important;
}

.daterangepicker.show-calendar .drp-calendar.left {
  display: block !important;
}

.daterange-wrapper {
  width: 340px;
}

.balance-header-wrap {
  margin-bottom: 30px;
}

.daterangepicker.single .ranges li {
  padding-top: 0;
  padding-bottom: 0;
}
.trial-balance-report-section.tb-report-section .report-table-accordion .item-row .item-col.debit-col, 
.trial-balance-report-section.tb-report-section .report-table-accordion .item-row .item-col.credit-col {
  flex: 1 1 300px;
}
.trial-balance-report-section.tb-report-section .item-label {
  width: calc(100% - 160px);
}
.trial-balance-report-section.tb-report-section .report-table-accordion .item-row, 
.trial-balance-report-section.tb-report-section .report-table-accordion .item-row .item-col {
  height: auto !important;
  min-height: 36px;
}