.change-account-modal {
  z-index: 1325 !important;
}
.change-branch-modal {
  width: 750px !important;
}
.change-account-modal .change-branch-modal-content .modal-body {
  padding: 0 30px 15px 30px;
}
.change-account-modal .change-branch-modal-content .modal-body .MuiTreeView-root {
  width: 100%;
  max-width: none;
}
.change-account-modal .change-branch-modal-content .modal-body .change-branch-modal-form-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: end;
  clear: both;
  border-top: 0 !important;
  margin: 15px 0;
}
.change-account-modal .change-branch-modal-content .MuiTreeItem-content.Mui-expanded .MuiTreeItem-label {
  font-weight: bold;
}
.change-account-modal .change-branch-modal-content .tree-view-container {
  max-height: 64vh;
}
.change-account-modal .change-branch-modal-content .tree-view-container .MuiSvgIcon-root {
  width: 22px !important;
  height: 22px !important;
}
.change-account-modal .change-branch-modal-content .tree-view-container .MuiTreeView-root > li .MuiTreeItem-content > div > .MuiSvgIcon-root {
  transform: rotate(-180deg);  
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content .MuiTreeItem-label.chosen-branch {
  color: var(--cerulean-blue) !important;
}