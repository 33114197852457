.bills-list-table-wrapper #invoice_list_table th.bill_num,
.bills-list-table-wrapper #invoice_list_table td.bill_num {
  min-width: 114px !important;
}
.bills-list-table-wrapper #invoice_list_table th.vend_name,
.bills-list-table-wrapper #invoice_list_table td.vend_name {
  min-width: 128px !important;
}
.bills-list-table-wrapper #invoice_list_table th.bill_status,
.bills-list-table-wrapper #invoice_list_table td.bill_status {
  min-width: 137px !important;
}
.bills-list-table-wrapper #invoice_list_table th.total,
.bills-list-table-wrapper #invoice_list_table td.total {
  min-width: 124px !important;
}
.bills-list-table-wrapper #invoice_list_table th.balance_due,
.bills-list-table-wrapper #invoice_list_table td.balance_due {
  min-width: 124px !important;
}


/* create invoice */


/* .form-tab-section .billing-address::after {
  height: 100%;
} */




.bill-address {
  font-size: 14px;
  font-weight: normal;
  line-height: 155%;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 14px;
}


.ship-form #s_region,
.ship-form .region-select {
  min-height: 40px;
}



.delete-item-button {
  padding: 11px 24px;
  border-radius: 6px;
  border: none;  
  font-size: 15px;
  font-weight: bold; 
  line-height: normal;
  letter-spacing: normal;
}

.delete-item-button {
  border: solid 1px var(--red);
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  color: var(--red);
  background-color: var(--white);
  position: relative;
  margin-left: auto;
}
.delete-item-button:hover {
  color: var(--white);
  background-color: var(--red);
}

/* add customer add contact common */

.form-button-wrapper {
  border-top: 1px solid var(--approx-whisper);
  /* padding: 24px 50px 0; */
  padding: 24px 0px 0;
  margin-top: 4px;
  font-size: 0;
}

.add-module-item-form .form-button-wrapper {
  padding-bottom: 39px;
}

#editAddresModal .form-button-wrapper,
#exchangeRateEdit .form-button-wrapper,
.sidebar-modal .form-button-wrapper {
  border-top: 0;
  padding-top: 0;
}

.column-align-end {
  align-items: flex-end;
  height: 50px;
}


.discount-selector .accounts-dropdown li {
  margin-bottom: 0 !important;
}


.radio-wrapper .form-check .form-check-input[type=radio] {
  border-radius: 50% !important;
}
.radio-wrapper .form-check .form-check-input[type=radio]:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.tax-selector input:nth-child(1):not(#taxCreateModal .radio-wrapper input) {
  /* border-radius: 5px 0 0 5px !important; */
}
.tax-selector .taxcode-dropdown input.search-dropdown:not(#taxCreateModal input) {
  /* border-radius: 0 6px 6px 0 !important; */
  border-radius: 6px !important;
  border-left: solid 1px var(--new-whisper) !important;
}
.tax-selector .taxcode-dropdown input.search-dropdown:not(#taxCreateModal input):focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.tax-selector .taxcode-dropdown input.search-dropdown.error:not(#taxCreateModal input) {
  border-left: 1px solid var(--red) !important;
}
#taxCreateModal .radio-wrapper label {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 16px !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  text-transform: none !important;
  margin-bottom: 0 !important;
}
.tax-selector input:nth-child(2):not(#taxCreateModal .form-outline input) {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  width: 42% !important;
}
#addItem-form label {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin-bottom: 12px;
    float: left;
    width: -moz-fit-content;
    width: fit-content;
}
#taxCreateModal .create-user-form-wrap label {
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: -0.18px !important;
  color: var(--charcoal-grey) !important;
  margin-bottom: 12px !important;
}
#addItem-form input, #addItem-form .form-select, #addItem-form label {
    clear: both;
}
.create-settings-form-wrap label {
    margin-bottom: 12px;
}

#editAddresModal,
.sidebar-modal {
  right: 0;
  width: 480px;
}

#printInvoiceModal h1,
.card-customer-header h1 {
  color: var(--charcoal-grey);
  float: left;
  font-size: 24px;
  font-weight: 900 !important;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 0;
}

#upload_section {
  padding: 30px 24px 0 24px; 
}

.upload-header {
  
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  padding-bottom: 21px;
}

.bill-item-container .accounts-dropdown {
  /* height: 40px;   */
  /* padding: 11px 6px 9px 12px; */
}

.card-bill-management #upload_section {
  border-bottom: 0;
}
.card-bill-management .email-reciepients-wrapper {
  position: relative;
}
/* .card-bill-management .email-reciepients-wrapper:before {
  content: "";
  position: absolute;
  width: calc(100% - 48px);
  height: 1px;
  top: 0;
  left: 24px;
  border-top: 1px solid var(--approx-whisper);
} */

.tax-dropdown-label{
  font-size: 14px;
  font-weight: bold;
  padding: 14px 5px 4px 7px;
  margin-bottom: inherit;
  cursor: not-allowed;
}
.non-taxable-link {
  padding: 0 !important;
  text-decoration: none;
}

/** Media Queries **/
@media (max-width: 1040px) {
 
}

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}


@media (min-width: 768px) and (max-width: 991px) {
  .delete-item-button {
    font-size: 13px;
  } 
  
  .add-module-item-form .row {
    margin-bottom: 0;
  }
  .add-module-item-form .item-grid-account .row {
    margin-bottom: 20px;
  }
  .add-module-item-form .row .col-12 {
    margin-bottom: 20px;
  } 
  
}
@media (min-width: 992px) and (max-width: 1024px) {
  .mw-325 {
    max-width: calc(308px + 2rem) !important;
  }
 
  
}
@media (min-width: 768px) and (max-width: 1024px) {
  .note-wrapper {
    width: calc(100% - 48px);
    margin: 0 auto 30px auto;    
  }
  .total-sect-row {
    flex-direction: column !important;
    margin-bottom: 0;
  }
  .total-sect-row .col {
    margin-bottom: 20px;
  }
  .total-sect-row .col.right-details {
    margin-left: 0;
    margin-bottom: 0;
    max-width: none;
    width: 100%;
  }
  .add-module-item-form .total-sect-row .customer-notes textarea {
    max-width: calc(70% + 1.5rem) !important;
    margin-bottom: 8px !important;
  }
  .filter-componet-wrapper .filter-menu {
    right: auto !important;
    left: 0 !important;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .note-wrapper {
    width: calc(100% - 48px);
    margin: 0 auto 30px auto;    
  }
  .total-sect-row {
    flex-direction: column !important;
    margin-bottom: 0;
  }
  .total-sect-row .col {
    margin-bottom: 20px;
  }
  .total-sect-row .col.right-details {
    margin-left: 0;
    margin-bottom: 0;
    max-width: none;
    width: 100%;
  }
  .add-module-item-form .total-sect-row .customer-notes label {
    margin-bottom: 8px !important;
  }
  .add-module-item-form .total-sect-row .customer-notes textarea {
    max-width: calc(70% + 1.5rem) !important;    
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
}
