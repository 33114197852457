.refund-customer-name {
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    text-align: start;
    margin: 0;
    width: 100%;
  }
  .refund-balance-amount {
    color: var(--battleship-grey);
    font-weight: normal;
    width:100%;
  }