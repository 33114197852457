.select-wrap {
    width: 100%;
    clear: both;
}
.select-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    clear: both;
    font-size: 14px;
}
/* CSS to handle focus state */
/* .select-wrap .select-field .select__option--is-focused {
    background-color: #f0f0f0;
}

.select-wrap .select-field .select__option {
    background-color: #ffffff; 
} */
.select-wrap .select-field .select-option {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: normal;
    /* padding: 0px 5px; */
    padding: 8px 18px;
    color: var(--charcoal-grey);
    background-color: var(--white-fill);
    cursor: pointer;
}
.select-wrap .select-field .select-option:focus,
.select-wrap .select-field .select-option:focus-within,
.select-wrap .select-field .select-option:focus-visible {
    outline: 0;
    box-shadow: none;
}
.select-wrap .select-field .select-option:hover,
.select-wrap .select-field .select-option:focus,
.select-wrap .select-field .select-option[aria-selected="true"],
.select-wrap .select-field .select-option-focused,
.select-wrap .select-field .select-option-selected {
    background-color: var(--white-fill);
    color: var(--cerulean-blue);
}
.select-wrap span.error {
    display: block;
    width: 100%;
    color: var(--red);
    font-size: 13px;
    display: block;
    line-height: 19px;
    clear: both;
}
.select-wrap .select-field .select-option .additional-text {
    font-size: 12px;
    padding-top: 2px;
    color: var(--battleship-grey) !important;
}