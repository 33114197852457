.tagchip {
  display: flex;
  padding: 3px 7px;
  border-radius: 4px;
  background-color: var(--approx-alice-blue) !important;
  border: 1px solid var(--pattens-blue) !important;
  color: var(--battleship-grey);
  margin-bottom: 5px;
  width: fit-content;
  padding: 5px;
}
