.add_payment_form .unpaid-inv-table thead tr th.withholding-tax-col {
  text-align: right;
  min-width: 160px !important;
  width: 160px !important;
  text-align: right;
}
.add_payment_form .unpaid-inv-table tbody tr td.withholding-tax-col {
  padding-top: 12px !important;
  min-width: 170px !important;
  width: 170px !important;
  text-align: left;
}
.add_payment_form .unpaid-inv-table thead tr th.allocation-date-col {
  text-align: left;
  min-width: 172px !important;
  width: 172px !important;
}
.add_payment_form .unpaid-inv-table tbody tr td.allocation-date-col {
  padding-top: 12px !important;
  min-width: 178px !important;
  width: 178px !important;
  text-align: left;
}
.add_payment_form .unpaid-inv-table thead tr th.payment-col {
  text-align: right;
  width: 170px !important;
  text-align: right;
}
.add_payment_form .unpaid-inv-table tbody tr td.payment-col {
  padding-top: 12px !important;
  width: 170px !important;
  text-align: right;
  vertical-align: middle;
}
/* .add_payment_form .unpaid-inv-table tbody tr td:nth-child(7) {
  width: 50px !important;
  text-align: right;
} */

.add_payment_form .unpaid-inv-table tbody tr td .payment-entry {
  margin-left: auto;
}
thead.unpaid-payments-header th {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
}
span.total-unpaid-error {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 50px;
}
span.inv-balance {
  padding-top: 4px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.add_payment_form .unpaid-inv-table .date-container.allocation_date {
  width: 178px !important;
  min-width: 178px !important;
}
.add_payment_form .unpaid-inv-table .MuiInputAdornment-root {
  padding-right: 10px !important;
}
