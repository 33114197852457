.singleTag {
  padding: 20px;
  margin-bottom: 10px;
}
.option-not-set-error{
  border: solid 1px var(--red) !important;
  border-radius: 5px !important;
  background-color: var(--rose-white) !important;
}
.option-field-and-error-wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.singleTag .action-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.singleTag .transaction_amount_wrapper {
  width: 400px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.singleTag .transaction_amount_field_wrapper {
  display: flex;
  flex-direction: column;
}
.singleTag .reporting-tag-title-row {
  width: 100%;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.singleTag .reporting-tag-title-row label {
  display: inline-block;
  margin-bottom: 0px;
}
.singleTag .reporting-tag-title-row input {
  /* display: inline-block; */
}

.singleTag .reporting-tag-title-row .splittype-group {
  display: inline-block;
}

.singleTag .reporting-tag-title-row .form-control-reusable-radio-button {
}

.collapsible-btn.active {
  transform: rotate(-180deg);
}

.singleTag .delete-container-btn {
  background: transparent;
  border: 0;
}
.singleTag .align-center {
  align-items: center;
}
.singleTag .option-wrapper {
  margin-bottom: 10px;
  margin-left: 20px;
}
.singleTag .option-wrapper label {
  margin-bottom: 0px;
  margin-right: 20px;
}
.singleTag .all-options-wrapper {
  margin-top: 20px;
  margin-left: 37px;
  display: flex;
  flex-wrap: wrap;
}
.singleTag .w-200 {
  width: 200px;
}
