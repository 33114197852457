.regenerate-invoice-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.regenerate-invoice-modal {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  padding: 24px;
}
.regenerate-invoice-preview-modal {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  width: 100%;
  padding: 40px;
}
.modal-title-regenerate-invoice {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.modal-body-regenerate-invoice {
  margin-top: 24px;
  margin-bottom: 24px;
  height: auto;
}
.radio-group-regenerate-invoice {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0px;
}
.radio-option-regenerate-invoice {
  display: flex;
  align-items: center;
  gap: 8px;
}
.radio-option-regenerate-invoice label {
  font-weight: normal;
  margin-bottom: 0 !important;
}
.regenerate-invoices-checkbox {
  display: inline-block;
  width: 100%;
  margin: 5px 0 0px 0 !important;
}
.regenerate-invoices-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  float: left;
}
.regenerate-invoices-checkbox label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-right: 4px;
  float: left;
}
.modal-sub-part-regenerate-invoice {
  font-weight: bold;
  margin-bottom: 10px;
}
.radio-group-regenerate-invoice-2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 15px 0px 30px 0px;
}
.preview-table-regenerating-invoices {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  border: solid 1px var(--approx-whisper);
  border-radius: 8px;
}
.preview-table-regenerating-invoices th,
.preview-table-regenerating-invoices td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.preview-table-regenerating-invoices th:last-child,
.preview-table-regenerating-invoices td:last-child {
  text-align: right;
}
.align-right {
  margin-left: auto;
  max-width: 300px;
  width: 100%;
}
.regenerate-invoice-summary-container {
  margin-left: 350px;
}
