.banking-landing-page-wrapper {
  width: 100%;
  height: auto;
  padding: 30px;
}
.banking-landing-page-header {
  color: var(--charcoal-grey);
  float: left;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  padding-bottom: 10px;
}
.banking-landing-page-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banking-landing-page-content-wrapper {
  padding-top: 30px;
}
.left-aligned-text{
  text-align: left !important;
}
.right-aligned-text{
  text-align: right !important;
}