.invoice-allocation-list-table-wrapper {
  overflow: auto;
  max-height: 60vh;
  max-width: 40vw !important;
}
.invoice-allocation-table {
  border: 1px solid var(--warm-white);
  border-radius: 0.3rem;
}
.invoice-allocation-table td {
  vertical-align: middle;
}
.cd-modal .apply-creds-table td.allocation-date {
  width: 200px;
  min-width: 200px;
}
.cd-modal .apply-creds-table input {
  width: 124px !important;
  height: auto !important;
  border-radius: 0;
}
.cd-modal
  .apply-creds-table
  .date-container
  .MuiFormControl-root
  .MuiInputBase-formControl {
  background: #fff;
}
.invoice-credit-allocation-modal .modal-header {
  padding: 40px !important;
}
.invoice-credit-allocation-modal h5 {
  font-size: 24px;
  font-weight: 900;
  color: var(--charcoal-grey);
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  padding-bottom: 20px;
}
.invoice-credit-allocation-modal h6 {
  color: var(--charcoal-grey);
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  padding-top: 20px;
}
.apply-creds-table .apply-credit-amount-to-credit-col input {
  max-width: 112px !important;
}
.apply-creds-table .apply-credit-allocation-date-col input {
  min-width: 100px !important;
}
.apply-creds-table .apply-credit-credit-type-col {
  min-width: 120px !important;
}
.apply-creds-table .apply-credit-date-col {
  min-width: 120px !important;
}
.apply-creds-table .apply-credit-amount-col {
  min-width: 120px !important;
}
.apply-creds-table .apply-credit-balance-col input {
  min-width: 120px !important;
}
.apply-credit-allocation-date-col .MuiInputAdornment-root {
  padding-right: 10px !important;
}

.apply-credit-allocation-date-col .MuiFormControl-root {
  background-color: white;
}
