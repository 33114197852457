#horizontalTab .pills-tab {
    padding: 0;
    border-bottom: 2px solid var(--warm-white);
    background-color: var(--white-fill);
}
#horizontalTab .pills-tab .nav-item .nav-link {
    padding: 8px 35px;
}
#horizontalTab .pills-tab .nav-item:first-child .nav-link {
    padding: 8px 12px;
}
#horizontalTab .pills-tab .nav-link {
    position: relative;
    color: var(--battleship-grey);    
    margin: 0;
    height: auto;
    min-width: auto;
}
#horizontalTab .pills-tab .nav-link.active,
#horizontalTab .pills-tab .nav-link:hover,
#horizontalTab .pills-tab .nav-link:focus {
    background: transparent;
    color: var(--cerulean-blue);
}
#horizontalTab .pills-tab .nav-link:focus {
    opacity: 1 !important;
}
#horizontalTab .pills-tab .nav-link.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    background-color: var(--cerulean-blue);
}
#horizontalTab .tab-content {
    padding: 30px 0;
}
#horizontalTab .tab-content-body {
    padding: 30px 0px 32px 0px !important;
    
}
#horizontalTab .customer-detail-card .tab-content-body {
    border-radius: 12px;
    -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
    box-shadow: 0 6px 12px 0 var(--black-shadow1);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
    padding-bottom: 0 !important;
}
#horizontalTab.invoice-cn-pr-detail-tab .tab-content-body,
#horizontalTab.bill-dn-pm-detail-tab .tab-content-body {
    padding: 0px 0px 0px 0px !important;
    max-height: 300px;
    overflow-y: auto;
    
}
.invoice-cn-pr-detail-tab .tab-content,
.bill-dn-pm-detail-tab .tab-content {
    padding: 10px 0px 0px 0px !important;
}
#horizontalTab.invoice-cn-pr-detail-tab .tab-content .no-list-item .no-allocation,
#horizontalTab.bill-dn-pm-detail-tab .tab-content .no-list-item .no-allocation {
    border-top: 0;    
}
#horizontalTab.invoice-cn-pr-detail-tab  .pills-tab .nav-link {
    position: relative;
    color: var(--battleship-grey);    
    margin: 0;
    height: auto;
    min-width: auto;
    font-size: 17px;
}
#horizontalTab.invoice-cn-pr-detail-tab .pills-tab {
    padding: 0px 10px;
    border-bottom: 2px solid var(--warm-white);
    background-color: var(--white-fill);
}
#horizontalTab.bill-dn-pm-detail-tab .tab-content-body {
    padding: 0px 0px 0px 0px !important;
    max-height: 300px;
    overflow-y: auto;
    
}
.invoice-cn-pr-detail-tab .tab-content {
    padding: 10px 0px 0px 0px !important;
}
#horizontalTab.bill-dn-pm-detail-tab  .pills-tab .nav-link {
    position: relative;
    color: var(--battleship-grey);    
    margin: 0;
    height: auto;
    min-width: auto;
    font-size: 17px;
}
#horizontalTab.bill-dn-pm-detail-tab .pills-tab {
    padding: 0px 10px;
    border-bottom: 2px solid var(--warm-white);
    background-color: var(--white-fill);
}
#horizontalTab.invoice-cn-pr-detail-tab .tab-content-body.tab-body-overflow-initial,
#horizontalTab.bill-dn-pm-detail-tab .tab-content-body.tab-body-overflow-initial {
    overflow: initial;
}
#horizontalTab .tab-content-body.tab-body-overflow-initial .table-container {
    overflow: auto;
    max-height: 300px;
}
/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}


@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1024px) {    
}
@media (min-width: 768px) and (max-width: 1024px) {
   
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
}
