.info-alert-masg {
  background-color: var(--pale-conflower-blue);
  max-width: 722px;
  min-height: 26px;
  border-radius: 4px;
  padding: 4px 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  flex: 0 0 100%;
  gap: 5px;
}
.reminder-email-frequency-switch-checked span {
  color: #1976d2 !important;
}
.reminder-email-frequency-switch-unchecked span {
  color: var(--white) !important;
}
.reminder-email-frequency-name {
  vertical-align: middle;
}
.reminder-email-frequency-description {
  vertical-align: middle;
  width: 40%;
}
.reminder-email-frequency-switch {
  vertical-align: middle;
  text-align: center;
}
.reminder-email-frequency-action {
  vertical-align: middle;
  text-align: right;
  padding-right: 30px !important;
}
.reminder-email-frequency-table thead {
  display: none;
}
.reminder-email-frequency-top-border {
  border-top: 1px solid var(--warm-white);
  margin: 0 24px;
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-left: 0px;
}
