.recurring-invoice .list-card-header {
    background-color: var(--approx-white) !important;
  }
  
  .recurring-invoice .invoice-list-card-header {
    padding-bottom: 23px !important;
  }
  .recurring-invoice .invoice-list-card-header h1 {
    margin-top: 7px !important;
  }
  .recurring-invoice .create-invoice-button {
    width: auto;
    padding: 0 15px 0 8px;
  }
  .recurring-invoice .create-invoice-button svg.MuiSvgIcon-root {
    margin-top: -4px !important;
    margin-right: 6px;
  }
    
  .recurring-invoice .table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .recurring-invoice .card-list #invoice_list_table .action .dropdown-menu {
    inset: 19px -10px auto auto !important;
  }
  
  
  .recurring-invoice .card-customer-header h1 {
    
    font-size: 24px;
    font-weight: 900;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    float: left;
    margin-bottom: 0;
  }
  
  .recurring-invoice .tag-box {
    width: auto;
    min-width: 47px;
    height: 20px;
    margin: 0 8px 8px 0;
    padding: 0 8px;
    border-radius: 4px;
    border: solid 1px var(--pattens-blue);
    background-color: var(--approx-alice-blue);
    
    font-size: 13px;
    line-height: 1.54;
    color: var(--battleship-grey);
    float: left;
  }
  
  .recurring-invoice .tag-count {
    margin: 0;
    padding: 0 6px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--cerulean-blue-shadow2);
    background-color: var(--new-alice-blue);
    
    font-size: 13px;
    font-weight: normal;
    
    
    line-height: 1.54;
    letter-spacing: normal;
    color: var(--cerulean-blue);
  }
  .recurring-invoice #invoice-table-wrapper th.tags,
  .recurring-invoice #invoice-table-wrapper td.tags,
  .recurring-invoice #invoice-table-wrapper th.email,
  .recurring-invoice #invoice-table-wrapper td.email {
    white-space: nowrap;
  }
  .recurring-invoice .popover {
    border-radius: 1px;
    -webkit-box-shadow: 0 2px 8px 0 var(--black-shadow5);
    box-shadow: 0 2px 8px 0 var(--black-shadow5);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    max-width: 50%;
  }
  
  .recurring-invoice .popover-body {
    width: 100%;
    padding: 10px 12px 0 12px;
    float: left;
    border-radius: 1px;
  }
  
  .recurring-invoice .popover .tag-box {
    margin-bottom: 10px;
  }
  
  /* create invoice */

  
.disable-edit {
  color: gray !important;
  cursor: pointer;
}

.disable-edit:hover {
  color: gray !important;
  cursor: pointer;
}

.recurring-invoice .disable-btn {
  pointer-events: none;
  cursor: default;
}

.recurring-invoice  .disable-btn > span {
  color: #91919c !important;
}

.recurring-invoice  .sendReminder-p0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
