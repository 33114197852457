.budget-summary-report-filter-container{
    width: 100%;
    padding: 10px 20px 40px 20px;
    margin-bottom: 30px;
}
.budget-summary-report-filter-container-with-border{
    width: 100%;
    padding: 10px 20px 40px 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
}
.filter-values-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 80vw;
    flex-wrap: wrap;
}