.add-customer-link {
  margin-top: 0;
  appearance: none;
  border: 0;
  box-shadow: none;
  padding-right: 0px;
  background-color: transparent;
  color: var(--cerulean-blue);
  float: right;
}
.reusable-modal-btn {
  justify-content: flex-end !important;
}
.add-customer-link span {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.view-impact-details-modal .modal-title {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.view-impact-details-modal .modal-header {
  padding-bottom: 10px !important;
}
