.gateway-mapping-label-container {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: start;
  padding: 20px 10px;
  border-radius: 15px;
  border: 1px solid rgba(224, 224, 224, 0.8);
}
.gateway-mapping-label-container-checked {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: start;
  padding: 20px 10px;
  border-radius: 15px;
  border: 1px solid var(--cerulean-blue);
  background-color: var(--new-alice-blue);
}
.gateway-mapping-label-container-logo {
  margin-left: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.gateway-mapping-label-container-logo img {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 15px;
  margin-right: 20px;
  border: 1px solid rgba(224, 224, 224, 0.8);
}

.gateway-mapping-label-container-name > span {
  font-size: 18px;
  font-weight: 600;
  color: var(--charcoal-grey);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.gateway-mapping-label-container-name-checked > span {
  font-size: 18px;
  font-weight: 600;
  color: var(--cerulean-blue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.gateway-mapping-label-container-description {
  max-width: 400px;
  display: block;
  font-size: 14px !important;
  font-weight: normal !important;
  color: var(--battleship-grey) !important;
  margin-top: 5px;
}
.gateway-mapping-label-component {
  align-items: flex-start !important;
}
.gateway-mapping-label-container-button {
  margin-top: 10px;
}
