.creditnote-list-wrapper #invoice_list_table.cd-note-table th.cr_note_num,
.creditnote-list-wrapper #invoice_list_table.cd-note-table td.cr_note_num {  
  min-width: 114px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th.ref_num,
.creditnote-list-wrapper #invoice_list_table.cd-note-table td.ref_num {  
  padding-right: 12px !important;
}


#invoice_list_table th.cr_status,
#invoice_list_table td.cr_status {  
  min-width: 90px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th.cr_note_balance,
.creditnote-list-wrapper #invoice_list_table.cd-note-table td.cr_note_balance {
  min-width: 160px !important;
}
#invoice_list_table th.dt_status,
#invoice_list_table td.dt_status {  
  min-width: 90px !important;
}
#invoice_list_table th.dt_note_balance,
#invoice_list_table td.dt_note_balance {
  min-width: 160px !important;
}
#invoice_list_table th.bill_num,
#invoice_list_table td.bill_num {
  min-width: 114px !important;
}
.label-wrapper .info-btn:not(.item-grid-account .label-wrapper .info-btn) {
  top: -3px;
}
.label-wrapper.cn-subject-wrapper .info-btn {
  top: 0 !important;
}
.label-wrapper.dn-subject-wrapper .info-btn {
  top: 0 !important;
}
.form-top-section textarea {
  height: 78px;
  clear: both;
}
.bold-text {
  font-weight: bold;
}

form.inactive {
  position: relative;
}
form.inactive:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 202px;
  bottom: 81px;
  width: 100%;
  height: calc(100% - 200px);
  opacity: 0.5;
  background-color: var(--white-fill);
  z-index: 10;
}
form.cn-form.inactive:before {
  top: 200px;
}

.preview-top-butons {
  margin-top: -10px;
  padding-bottom: 30px;
}
.inv-bill-credited-btn {
  margin-right: 20px;
}
.cn-subject-col {
  padding-bottom: 24px;
}
.cn-subject-col .cn-subject-header {
  display: block;
  width: 100%;
  max-width: 647px;
  
  font-size: 18px;
  font-weight: 600;
  
  
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-bottom: 8px;
}
.cn-subject-col p {
  width: 100%;
  max-width: 647px;
  
  font-size: 15px;
  font-weight: 500;
  
  
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 8px !important;
}
.cn-subject-col p:last-child {
  margin-bottom: 0 !important;
}
.cd-detail-card .table-section table th,
.cd-detail-card .table-section table td {
  width: auto !important;
}
.creditamt {
  max-width: calc(100% - 25px);
  display: inline-block;
}
.form-error-container {
  flex-wrap: wrap;
}

.multi-items-container .note-container p {
  min-height: auto;
}
.multi-items-container .signatury-wrapper {
  padding-top: 24px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .preview-top-butons {
    margin-top: 0;
  }
}