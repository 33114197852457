.impact-details-modal-content-wrapper {
  padding: 0 15px !important;
}

.impact-details-modal-content-wrapper .changes-container {
  width: 100%;
  margin: auto;
  padding: 20px 20px 30px 20px;
}
.impact-details-modal-content-wrapper .change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.impact-details-modal-content-wrapper .change-item:last-child {
  border-bottom: none;
}
.impact-details-modal-content-wrapper .status {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.9em;
  color: white;
  min-width: 87px;
  text-align: center;
  margin-right: 90px;
}
.impact-details-modal-content-wrapper .status.modified {
  background-color: var(--gamboge);
}
.impact-details-modal-content-wrapper .status.added {
  background-color: var(--crusoe);
}
.impact-details-modal-content-wrapper .status.removed {
  background-color: #e57373;
}

.impact-details-modal-content-wrapper .change-details {
  flex-grow: 1;
  margin-left: 10px;
}
.impact-details-modal-content-wrapper .change-details span {
  display: block;
  color: #555;
}
