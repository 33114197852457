.export-data-wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px 10px 10px 20px;
}
.export-data-wrapper h1 {
    color: var(--charcoal-grey) !important;
    float: left;
    font-size: 24px;
    font-weight: 900 !important;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 30px;
    margin-top: 5px;
}
.export-data-wrapper .export-module-wrapper {
    padding: 30px 40px;
    background-color: var(--white-fill);
    border: 1px solid var(--warm-white);
    border-radius: 12px;
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
    margin-top: 15px;
}
.export-data-wrapper h2 {
    color: var(--charcoal-grey);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    padding-bottom: 8px;
    margin-bottom: 12px;
    width: 100%;
    border-bottom: 1px solid var(--approx-whisper);
}
.export-data-wrapper .reusable-modal-btn {
    justify-content: flex-start !important;
    margin-top: 30px !important;
}
.subscription-export-filter-modal-wrapper {
    padding: 0px 30px 20px 30px;
    width: fit-content;
    min-width: 725px !important;
    max-width: 725px !important;
}
.subscription-export-filter-modal-wrapper .select-field-wrap {
    width: 32% !important;
    flex: 0 0 32% !important;
}
.subscription-export-filter-modal-wrapper .transaction-type-select {
    width: 47% !important;
    flex: 0 0 47% !important;
}
.subscription-export-filter-modal-wrapper .trans-type-item {
    width: 95% !important;
    flex: 0 0 95% !important;
}