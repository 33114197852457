.overflow-coloumn {
  height: auto;
  overflow-y: hidden;
  overflow-x: auto;
}

.item-det-container .item-det-row {
  margin-bottom: 0 !important;
}

.item-grid-account {
  width: 100%;
  min-width: 1080px !important;
  background-color: rgba(var(--cerulean-blue-rgb), 0.04);
}
