.nav-profile a {
  color: var(--white-fill) f;
  text-decoration: none;
  float: left;
}
.nav-profile .profile-button {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 13px 0px 12px 0px;
  object-fit: contain;
  background: url("../../../../../assets/images/dp_background.svg") no-repeat
    center;
  background-color: var(--new-cornflower-blue);
  background-size: 40px 40px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
  line-height: 0.75;
  letter-spacing: -0.03px;
  text-align: center;
  color: var(--white-fill);
  text-transform: uppercase;
  margin-top: 1px;
}
.nav-profile .profile-button span {
  float: left;
  display: block;
}
.nav-profile .profile-button .p_name {
  font-size: 20px;
  font-weight: 500;
  line-height: 0.75;
  letter-spacing: -0.03px;
  text-align: center;
  color: var(--white-fill);
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.nav-profile .profile-button .no-prof-image {
  margin-top: -2px;
  width: 100%;
  height: 20px;
}
.nav-profile .profile-button .no-prof-image img {
  float: initial !important;
  width: 20px;
  height: 20px;
  margin: 0 !important;
}
.nav-profile a:hover {
  color: var(--white-fill);
}
.nav-profile img {
  width: 40px;
  height: 40px;
  margin: 0 8px 0 0;
  object-fit: contain;
  float: left;
}
.nav-profile-name {
  margin: 15px 8px 0 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: normal;
  text-decoration: none;
  float: left;
}
.nav-profile .dropdown-menu {
  position: absolute !important;
  right: -24px;
  margin-top: 0;
  top: 48px;
  padding: 16px 0 0 12px;
  width: fit-content;
  max-width: 300px;
  min-width: 200px;
}
.nav-profile .dropdown-item {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 2.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-decoration: none;
  float: left;
  padding: 0 12px;
}
.nav-profile .dropdown-item:hover {
  background: transparent;
  color: var(--navy-blue);
}
.nav-profile .profile-button:after {
  content: "";
  position: absolute;
  border: 0 !important;
  width: 24px;
  height: 24px;
  background: url(../../../../../assets/images/arrow_dropdown.svg) transparent
    no-repeat center;
  background-size: 24px 24px;
  margin: 0;
  top: 50%;
  margin-top: -12px;
  left: 47px;
}

.nav-profile .profile-button.show:after {
  transform: rotate(-180deg);
}
.nav-profile ul.dropdown-menu li a:focus {
  outline: 0 !important;
}
.profile-navbar-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.nav-profile .dropdown-menu li a {
  display: flex;
  align-items: center;
}
.nav-profile .dropdown-menu li img:not(.org-icon) {
  width: 20px !important;
  height: auto !important;
}
.nav-profile .dropdown-menu li.no-padding a {
  padding: 0 !important;
}
@media (max-width: 767px) {
  .nav-profile .dropdown-menu li a {
    padding: 12px 6px;
  }
}
.org-name-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.org-name-header {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.11px;
  color: #323e48;
  margin-bottom: 4px;
  text-align: left;
}
.org-name-parent {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #747d84;
  text-align: left;
}
.change-org-wrapper {
  text-align: left;
  margin-bottom: 0;
}
.change-org-content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.11px;
  color: var(--cerulean-blue);
  cursor: pointer;
}
.org-det-wrapper {
  position: relative;
  padding-bottom: 12px;
  padding-right: 12px;
}
.org-det-wrapper:after {
  content: "";
  position: absolute;
  width: calc(100% + 12px);
  height: 1px;
  border: solid 1px var(--approx-whisper);
  left: -12px;
  bottom: 0;
}
.logout-item {
  padding: 12px 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.07;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey) !important;
  width: 100%;
  text-align: left;
}
.nav-profile ul.dropdown-menu li.nav-item {
  width: 100%;
  float: left;
}
