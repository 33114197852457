.filter-componet-wrapper {
    width: auto;
    float: right;
    margin-right: 25px;
}
.filter-componet-wrapper .filter-btn {
    width: 114px;
    height: 42px;
    padding: 0 34px 0 13px;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--cerulean-blue-shadow2);
    background-color: var(--new-alice-blue);  
    line-height: 42px; 
     
}
.filter-componet-wrapper .filter-btn.open {
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--cerulean-blue);
    background-color: var(--white-fill);  
    position: relative;
}
.filter-componet-wrapper .filter-btn.open:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--red);
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    border-radius: 50%;
}
.filter-componet-wrapper .filter-btn,
.filter-componet-wrapper .filter-btn span {
    font-size: 15px;
    font-weight: bold;      
    letter-spacing: normal;
    text-align: center;
    color: var(--navy-blue);
}
.filter-componet-wrapper .filter-btn span {
    line-height: normal;  
}
.filter-componet-wrapper .filter-btn img {
    width: 16px;
    height: auto;    
    margin-right: 8px;
}
.filter-componet-wrapper .filter-menu {
    position: absolute !important;
    width: auto !important;
    /* min-width: 343px !important; */
    width: 460px !important;
    right: 0 !important;
    top: 48px !important;
    z-index: 10;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow15);
    box-shadow: 0 2px 6px 0 var(--black-shadow15);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
}
.filter-componet-wrapper .filter-menu .tab-wrapper {
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: solid 1px var(--warm-white) !important;
    background-color: transparent !important;
}
.filter-componet-wrapper .filter-menu .tab-container {
    width: 180px !important;
    padding: 16px 10px !important;
    flex: 0 0 140px !important;
}
.filter-componet-wrapper .filter-menu .tab-content {
    padding: 0!important;
    width: calc(100% - 155px) !important;
    padding: 20px 10px 20px 20px !important;
}
.filter-componet-wrapper .filter-menu .vertical-tab li {
    padding-bottom: 10px;
}
.filter-componet-wrapper .filter-menu .vertical-tab li button span {
    padding: 3px 15px 4px 10px !important;    
}
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link {
    border-radius: 4px !important;
    background-color: var(--white-fill) !important;
    position: relative !important;
    padding: 0 !important;
    border-bottom: 0 !important;
}
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link.active,
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link:hover,
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link:focus {    
    background-color: var(--solitude) !important;    
}
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link.unsaved{
    position: relative !important;
}
.filter-componet-wrapper .filter-menu .vertical-tab li button.nav-link.unsaved:after {
    content: '' !important;
    position: absolute !important;
    width: 5px !important;
    height: 5px !important;    
    background-color: var(--red) !important;
    border-radius: 50% !important;
    top: 50% !important;
    right: 10px !important;
    transform: translate(0, -50%) !important;
}
.filter-componet-wrapper .filter-menu .vertical-tab li button,
.filter-componet-wrapper .filter-menu .vertical-tab li button span {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: var(--charcoal-grey) !important;
    border-radius: 4px !important;
}
.filter-componet-wrapper .filter-menu .tab-content .checkbox-conatiner {
    width: 100% !important;
    float: left !important;
    clear: both !important;
}
.filter-componet-wrapper .filter-menu .tab-content .form-check{
    max-width: 260px;
}
.filter-componet-wrapper .filter-menu .tab-content .form-check-input {
    margin-top: 0;
}
.filter-componet-wrapper .filter-menu .tab-content .form-check label {
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    color: var(--charcoal-grey) !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 24px);
}
.filter-componet-wrapper .filter-menu #filterTab {
    width: 160px;
}
.filter-componet-wrapper .filter-menu .tab-content .form-check {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1;
    min-height: 16px;
    margin-bottom: 24px !important;
}
.filter-componet-wrapper .filter-menu .menu-footer {
    width: 100%;
    float: left;
    padding: 19px 0;
}
.filter-componet-wrapper .filter-menu .menu-footer .reset-filter {
    height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background-color: transparent;  
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  text-decoration: underline;
  background: url("../../../assets/images/reset-icon-blue.svg") no-repeat left
    center;
  background-size: 14px 14px;
  margin-left: 20px;
  padding: 0 0 0 20px;
  float: left;
  text-decoration: none;
}
.filter-componet-wrapper .filter-menu .menu-footer .filter-button-wrapper {
    width: auto;
    float: right;
    margin-right: 20px;
}
.filter-componet-wrapper .filter-menu .menu-footer .save-btn {
    margin-left: 20px;
}
.filter-componet-wrapper .filter-menu .menu-footer .filter-button-wrapper button {
    height: 30px;
    width: 70px;
    padding: 0 8px !important;
    line-height: 30px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
.filter-componet-wrapper .filter-menu .menu-footer .filter-button-wrapper button:disabled {
    opacity: 0.8;
    background-color: var(--booger) !important;
}
.filter-componet-wrapper .filter-menu .menu-footer .filter-button-wrapper button:disabled:hover{
    background-color: var(--booger);
}
.filter-componet-wrapper .filter-menu .menu-footer .cancel-button:hover {
    background-color: var(--booger);
    color: var(--white-fill);
}
.filter-componet-wrapper .filter-menu .tab-content{
    max-height: 440px;
    overflow: hidden;
    overflow-y: auto;
}
.filter-componet-wrapper .MuiDivider-root {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--new-whisper);
}
.filter-componet-wrapper .selected-filters{
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 16px;
}
.main-card-header.filter-search {
    padding: 0 !important;
}
.main-card-header.filter-search #search_table{
    margin-bottom: 16px !important;
    padding: 0px 7px 0px 40px !important;
    line-height: 32px !important;
    height: 32px !important;

}
.card-customer-header.filter-search #search_table {    
    width: 100%;
    height: 32px; 
    line-height: 32px;
    margin-bottom: 16px!important;
    margin-left: 0!important;
    padding: 0 7px 0 40px!important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: 6px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    font-size: 14px;
    font-weight: normal; 
    letter-spacing: normal;
    color: var(--charcoal-grey);
    background-image: url("../../../assets/images/search.svg");
    background-position: left 14px center;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    
}
.filter-componet-wrapper .filter-menu .tab-content .date-picker-wrapper {
    margin-bottom: 24px;
}
.filter-componet-wrapper .filter-menu .tab-content .date-picker-wrapper:last-child {
    margin-bottom: 0;
}
.filter-componet-wrapper .filter-menu .tab-content .date-picker-wrapper .MuiInputBase-input {
    width: 68% !important;
}
.date-selector-wrapper.filterDatePicker .date-container {
    width: 186px;
}
/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}


@media (min-width: 768px) and (max-width: 991px) {
    .filter-componet-wrapper .filter-menu {
        /* left: -176px !important; */
        left: -166px !important;
        right: auto !important;
    }    
}
@media (min-width: 992px) and (max-width: 1024px) {
    
}
@media (min-width: 768px) and (max-width: 1024px) {
    /* .filter-componet-wrapper .filter-menu {
        width: 460px !important;
    } */
  
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
}