.reopen-fiscal-year-modal-wrapper {
  padding: 0px 40px 40px 40px;
}
.reopen-fiscal-year-modal-wrapper .select-groups {
  font-size: 15px;
  line-height: 23px;
  color: #747d84;
  margin-bottom: 32px;
}
.reopen-fiscal-year-modal-wrapper .button-grp {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 11px;
  column-gap: 8px;
  margin-bottom: 32px;
}
.reopen-fiscal-year-modal-wrapper .button {
  width: 174px;
  padding: 12px 16px;
  border-radius: 32px;
  border: solid 1px #9daabb;
  font-size: 14px;
  line-height: 18px;
  color: var(--charcoal-grey);
  background-color: #fff;
}
.reopen-fiscal-year-modal-wrapper .button-selected {
  width: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px 12px 2px;
  border-radius: 32px;
  border: solid 1px var(--cerulean-blue);
  font-size: 14px;
  line-height: 18px;
  color: var(--cerulean-blue);
  background-color: rgba(13, 90, 229, 0.1);
}
.reopen-fiscal-year-modal-wrapper .blue-check {
  padding-right: 6px;
}
.reopen-fiscal-year-modal-wrapper .button-disabled {
  width: 174px;
  padding: 12px 16px;
  border-radius: 32px;
  border: solid 1px #c9d0da;
  font-size: 14px;
  line-height: 18px;
  color: #b9c2cf;
  background-color: #fff;
}
