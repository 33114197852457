.applytags .newtag-btn-text {
  padding: 3px !important;
  color: #0d5ae5 !important;
}
.applytags .flex {
  display: flex;
}

.applytags .header-accountName {
  font-weight: 600;
  font-size: 16px;
}

.applytags .header-amount {
  font-weight: normal;
  font-size: 16px;
}

.table-wrap.content-screen-center {
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}
