.actions-wrap a.delete-item,
a.delete-item span {
  color: var(--red) !important;
}
#invoice .table-section .table-wrap {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 10px;
}
#invoice .table-section table.inv-preview-table,
#invoice .table-section table.bills-preview-table,
#invoice .table-section table.cnote-preview-table,
#invoice .table-section table.dnote-preview-table {
  width: 100%;
  min-width: 1054px;
  margin-bottom: 0;
}
#invoice .table-section table.cnote-preview-table thead th,
#invoice .table-section table.cnote-preview-table tbody td,
#invoice .table-section table.dnote-preview-table thead th,
#invoice .table-section table.dnote-preview-table tbody td,
#invoice .table-section table.inv-preview-table thead th,
#invoice .table-section table.inv-preview-table tbody td,
#invoice .table-section table.bills-preview-table thead th,
#invoice .table-section table.bills-preview-table tbody td {
  padding-left: 9px !important;
  padding-right: 9px !important;
}
#invoice .table-section table.cnote-preview-table thead th:first-child,
#invoice .table-section table.cnote-preview-table tbody td:first-child,
#invoice .table-section table.dnote-preview-table thead th:first-child,
#invoice .table-section table.dnote-preview-table tbody td:first-child,
#invoice .table-section table.inv-preview-table thead th:first-child,
#invoice .table-section table.inv-preview-table tbody td:first-child,
#invoice .table-section table.bills-preview-table thead th:first-child,
#invoice .table-section table.bills-preview-table tbody td:first-child {
  padding-left: 24px !important;
  width: auto !important;
  min-width: 156px !important;  
}
#invoice .table-section table.cnote-preview-table thead th.description,
#invoice .table-section table.cnote-preview-table tbody td.description,
#invoice .table-section table.dnote-preview-table thead th.description,
#invoice .table-section table.dnote-preview-table tbody td.description,
#invoice .table-section table.inv-preview-table thead th.description,
#invoice .table-section table.inv-preview-table tbody td.description,
#invoice .table-section table.bills-preview-table thead th.description,
#invoice .table-section table.bills-preview-table tbody td.description {
  padding-left: 24px !important;
  width: auto !important;
  min-width: 156px !important;
  max-width: 278px !important;
}
#invoice .table-section table.cnote-preview-table thead th.description span,
#invoice .table-section table.cnote-preview-table tbody td.description span,
#invoice .table-section table.dnote-preview-table thead th.description span,
#invoice .table-section table.dnote-preview-table tbody td.description span,
#invoice .table-section table.inv-preview-table thead th.description span,
#invoice .table-section table.inv-preview-table tbody td.description span,
#invoice .table-section table.bills-preview-table thead th.description span,
#invoice .table-section table.bills-preview-table tbody td.description span {
  display: block;
  width: auto;
  max-width: 100%;
  word-wrap: normal;
}

#invoice .table-section table.cnote-preview-table thead th:nth-child(5),
#invoice .table-section table.cnote-preview-table thead th:nth-child(5) span,
#invoice .table-section table.cnote-preview-table tbody td:nth-child(5),
#invoice .table-section table.cnote-preview-table tbody td:nth-child(5) span {
  text-align: right !important;
}
#invoice .table-section table.dnote-preview-table thead th:nth-child(5),
#invoice .table-section table.dnote-preview-table thead th:nth-child(5) span,
#invoice .table-section table.dnote-preview-table tbody td:nth-child(5),
#invoice .table-section table.dnote-preview-table tbody td:nth-child(5) span {
  text-align: right !important;
}
#invoice .table-section table.inv-preview-table thead th:nth-child(6),
#invoice .table-section table.inv-preview-table thead th:nth-child(6) span,
#invoice .table-section table.inv-preview-table tbody td:nth-child(6),
#invoice .table-section table.inv-preview-table tbody td:nth-child(6) span {
  /* text-align: right !important; */
}
#invoice .table-section table.bills-preview-table thead th:nth-child(6),
#invoice .table-section table.bills-preview-table thead th:nth-child(6) span,
#invoice .table-section table.bills-preview-table tbody td:nth-child(6),
#invoice .table-section table.bills-preview-table tbody td:nth-child(6) span {
  text-align: right !important;
}

#invoice .table-section table.bills-preview-table tbody td,
#invoice .table-section table.inv-preview-table tbody td,
#invoice .table-section table.dnote-preview-table tbody td,
#invoice .table-section table.cnote-preview-table tbody td {
  line-height: 1 !important;
  /* word-break: break-all; */
}
#invoice .table-section table.inv-preview-table thead th:nth-child(5),
#invoice .table-section table.inv-preview-table tbody td:nth-child(5),
#invoice .table-section table.bills-preview-table thead th:nth-child(5),
#invoice .table-section table.bills-preview-table tbody td:nth-child(5) {
  min-width: 112px;
}
#invoice .table-section table.cnote-preview-table thead th:nth-child(5),
#invoice .table-section table.cnote-preview-table tbody td:nth-child(5),
#invoice .table-section table.dnote-preview-table thead th:nth-child(5),
#invoice .table-section table.dnote-preview-table tbody td:nth-child(5),
#invoice .table-section table.inv-preview-table thead th:nth-child(6),
#invoice .table-section table.inv-preview-table tbody td:nth-child(6),
#invoice .table-section table.bills-preview-table thead th:nth-child(6),
#invoice .table-section table.bills-preview-table tbody td:nth-child(6) {
  min-width: 90px !important;
}
#invoice .table-section table.cnote-preview-table thead th:last-child,
#invoice .table-section table.cnote-preview-table tbody td:last-child,
#invoice .table-section table.dnote-preview-table thead th:last-child,
#invoice .table-section table.dnote-preview-table tbody td:last-child,
#invoice .table-section table.inv-preview-table thead th:last-child,
#invoice .table-section table.inv-preview-table tbody td:last-child,
#invoice .table-section table.bills-preview-table thead th:last-child,
#invoice .table-section table.bills-preview-table tbody td:last-child {
  min-width: 100px !important;
  padding-left: 9px !important;
}

#invoice .table-section table.inv-preview-table thead th:nth-child(2),
#invoice .table-section table.inv-preview-table tbody td:nth-child(2),
#invoice .table-section table.bills-preview-table thead th:nth-child(2),
#invoice .table-section table.bills-preview-table tbody td:nth-child(2) {
  width: 115px !important;
}
#invoice .table-section table.cnote-preview-table thead th:nth-child(5),
#invoice .table-section table.cnote-preview-table tr td:nth-child(5),
#invoice .table-section table.dnote-preview-table thead th:nth-child(5),
#invoice .table-section table.dnote-preview-table tr td:nth-child(5) {
  padding-right: 12px !important;
}
#invoice .table-section table.cnote-preview-table thead th:nth-child(6),
#invoice .table-section table.cnote-preview-table tr td:nth-child(6),
#invoice .table-section table.dnote-preview-table thead th:nth-child(6),
#invoice .table-section table.dnote-preview-table tr td:nth-child(6) {
  padding-left: 12px !important;
}
#invoice .table-section table.cnote-preview-table thead th.discount,
#invoice .table-section table.cnote-preview-table tr td.discount,
#invoice .table-section table.dnote-preview-table thead th.discount,
#invoice .table-section table.dnote-preview-table tr td.discount {
  padding-right: 24px !important;
}
#invoice .table-section table.cnote-preview-table thead th.tds,
#invoice .table-section table.cnote-preview-table tbody td.tds,
#invoice .table-section table.dnote-preview-table thead th.tds,
#invoice .table-section table.dnote-preview-table tbody td.tds,
#invoice .table-section table.inv-preview-table thead th.tds,
#invoice .table-section table.inv-preview-table tbody td.tds,
#invoice .table-section table.bills-preview-table thead th.tds,
#invoice .table-section table.bills-preview-table tbody td.tds {
  min-width: 100px !important;
}

.gst-info {
  color: var(--charcoal-grey) !important;
  font-weight: bold !important;
}
.w-47 {
  width: 47%;
  flex: 0 0 47%;
}
.thankyou-note .note-container {
  width: 100%;
}
.thankyou-note .note-container .label {
  display: block;
  width: 100%;

  font-size: 18px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}
.thankyou-note .note-container p {
  min-height: 85px;

  font-size: 15px;
  font-weight: 500;

  line-height: 1.33;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin: 0 !important;
}
#invoice .invoice-header .company-details .img-container {
  padding-bottom: 10px;
}
.img-container > img {
  object-fit: contain;
}
#invoice.invoice-preview .billed-to,
#invoice.invoice-preview .ship-to {
  margin-bottom: 10px;
}
.credits-available-label {
  font-weight: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-right: 20px;
  float: left;
  line-height: 42px;
}
.credits-available .available-amount {
  font-size: 14px;
  font-weight: bold;
  color: var(--charcoal-grey);
}

.bill-details-section .invoice-supply-wrap {
  width: 23%;
  min-width: 100px;
  flex: 0 0 19%;
}
.bill-details-section .invoice-supply .invoice-details-header {
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.invoice-supply-wrap .invoice-supply div {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-top: 11px;
}
@media (min-width: 1025px) and (max--width: 1130px) {
  #invoice.invoice-preview .ship-to {
    padding-right: 0;
    text-align: right;
  }
}
@media (max-width: 1024px) {
  #invoice.invoice-preview .billed-to,
  #invoice.invoice-preview .ship-to {
    margin-bottom: 20px;
  }
}