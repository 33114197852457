.add-customer-link {
  margin-top: 0;
  appearance: none;
  border: 0;
  box-shadow: none;
  padding-right: 0px;
  background-color: transparent;
  color: var(--cerulean-blue);
  float: right;
}
.reusable-modal-btn {
  justify-content: flex-end !important;
}
.disable-center-modal-button {
  cursor: default !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
  background-color: var(--white-fill) !important;
  color: var(--cerulean-blue) !important;
}
.add-customer-link span{
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.central-modal.large-form-modal.add-customer-modal .modal-content {
  min-height: 750px;
}