/* React Multi Email style */

.react-multi-email {
  max-width: 682px;
  min-height: 92px;
  width: 100%;
  padding: 14px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  clear: both;
}
.react-multi-email-wrapper {
  width: 100%;
  max-width: 682px;
}

.react-multi-email [data-tag] {
  padding: 3.6px 8px;
  border-radius: 4px;
  border: solid 1px var(--pattens-blue);
  background-color: var(--approx-alice-blue);
  font-size: 13px;
  font-weight: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-right: 14px;
}

.react-multi-email > span[data-placeholder] {
  top: 1em;
}

.react-multi-email [data-tag] [data-tag-handle] {
  font-size: 19px;
  margin-left: 8px;
}
.react-multi-email.focused {
  border-color: var(--new-whisper);
}

/* React Multi Email style ends */
