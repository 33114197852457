.kebabMenu .dropdown-menu {
  background-color: var(--white-fill);
  border: 1px solid var(--new-whisper);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  height: auto !important;
  /* inset: 25px -13px auto auto !important; */
  right: 20px !important;
  margin: 0 !important;
  margin-left: -65px;
  min-width: 135px;
  padding: 14px 0;
  position: absolute;
  text-align: left;
}
.kebabMenu .dropdown-menu li {
  margin-bottom: 0;
}
.kebabMenu .dropdown-item {
  padding: 8px 14px;
}
.kebabMenu .dropdown-item,
.kebabMenu .dropdown-item span {
  color: var(--charcoal-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 0.71;
}

.kebabMenu .dropdown-item span.delete {
  color: var(--red);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 0.71;
}

.kebabMenu .dropdown-item img {
  margin-right: 8px !important;
}
.kebabMenu {
  width: 40px;
  margin-left: auto;
}
.kebabMenu .more-btn {
  text-align: center;
  padding: 0;
  width: 100%;
}
.detail-top-action-menu.kebabMenu {
  width: auto;
  margin-left: 30px;
}
.kebabMenu .dropdown-menu .dropdown-item {
  text-align: left; 
}
.kebabMenu .tds-edit-side-modal .dropdown-item svg {
  margin-right: 5px;
}
.kebabMenu .dropdown-menu .dropdown-item,
.kebabMenu .dropdown-menu .dropdown-item>span {
  display: flex;
  align-items: center;
}
.kebabMenu .dropdown-item.active,
.kebabMenu .dropdown-item:active,
.kebabMenu .dropdown-item:hover,
.kebabMenu .dropdown-item.delete.active,
.kebabMenu .dropdown-item.delete:active,
.kebabMenu .dropdown-item.delete:hover,
.kebabMenu .dropdown-item:focus,
.kebabMenu .dropdown-item.delete:focus {
  background-color: #e9ecef;
  color: var(--cerulean-blue);
}
.detail-top-action-menu.kebabMenu .more-btn {
  width: 30px;
  height: auto;
  padding: 5px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--cerulean-blue-shadow2);
  background-color: #f3f8ff;
}
.detail-top-action-menu.kebabMenu .more-btn svg {
  width: 18px;
  height: 18px;
  float: left;
}
.detail-top-action-menu.kebabMenu .more-btn svg path:last-child {
  fill: var(--cerulean-blue) !important;
}
.detail-top-action-menu.kebabMenu .dropdown-menu {
  right: 0 !important;
  top: 2px !important;
}
.detail-top-action-menu.kebabMenu .dropdown-menu .dropdown-item-label {
  /* display: inline-block; */
  /* margin-left: 5px; */
  margin-left: 0;
  /* top: -2px; */
}
.detail-top-action-menu.kebabMenu .dropdown-menu .dropdown-item svg,
.detail-top-action-menu.kebabMenu .reusable-modal-btn .dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 8px !important;
}
.detail-top-action-menu.kebabMenu .reusable-modal-btn .dropdown-item svg {
  position: relative;
  /* top: -1px; */    
}
.kebab-menu-dropdown-wrap a {
  line-height: 0.71 !important;
}
.kebabMenu .dropdown-menu .dropdown-item svg {
margin-right: 8px !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .detail-top-action-menu.kebabMenu {
    margin-left: 14px;
  }
  .detail-top-action-menu.kebabMenu .more-btn {
    float: right;
    margin-left: 0 !important;
  }
}
