.search-item .view-more-btn {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: normal;
    color: var(--cerulean-blue);
    left: -6px;
}
.search-item .view-more-btn.show-less svg,
.search-item .view-more-btn svg.show-less {
    transform: rotate(-180deg);
}