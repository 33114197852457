.transaction-edit-tag-removal-alert-modal-body-wrapper {
  min-width: 470px;
  width: fit-content;
  min-height: 120px;
  height: fit-content;
}
.transaction-edit-tag-removal-alert-modal-body-wrapper span {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 0;
  margin-bottom: 30px;
  width: 100%;
}
.transaction-edit-tag-removal-alert-modal-body-wrapper .modal-footer {
  padding: 10px 0px 24px 0px;
  border-top: 0;
  width: 100% !important;
}
.transaction-edit-tag-removal-alert-content {
  padding: 20px 40px 30px 40px;
}
