.customer-detail-card .invoice-actions-wrapper {
  margin-bottom: 36px;
}
.customer-detail-card .pills-tab {
  padding: 0;
  border-bottom: 2px solid var(--warm-white);
  background-color: var(--white-fill);
}
.customer-detail-card .pills-tab .nav-item .nav-link {
  padding: 8px 35px;
}
.customer-detail-card .pills-tab .nav-item:first-child .nav-link {
  padding: 8px 12px;
}
.customer-detail-card .pills-tab .nav-link {
  position: relative;
  color: var(--battleship-grey);
  margin: 0;
  height: auto;
  min-width: auto;
}
.customer-detail-card .pills-tab .nav-link.active,
.customer-detail-card .pills-tab .nav-link:hover,
.customer-detail-card .pills-tab .nav-link:focus {
  background: transparent;
  color: var(--cerulean-blue);
}
.customer-detail-card .pills-tab .nav-link:focus {
  opacity: 1 !important;
}
.customer-detail-card .pills-tab .nav-link.active:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: var(--cerulean-blue);
}
.customer-detail-card .tab-content {
  padding: 30px 0;
}
.customer-detail-card .filter-wrapper {
  width: 49.7758%;
  justify-content: flex-start !important;
}
.customer-detail-card .filter-col {
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.customer-detail-card .filter-wrapper .filter-item {
  width: 46.8127% !important;
  margin-right: 12px !important;
}
.customer-detail-card .filter-wrapper .filter-item:last-child {
  margin-right: 0 !important;
}
.customer-detail-card .filter-wrapper .actions-wrap {
  max-width: 400px !important;
  margin-left: auto;
}
.customer-detail-card .filter-wrapper .actions-wrap a {
  margin-right: 20px;
}
.customer-detail-card .card-dashboard {
  margin-top: 0 !important;
}
.customer-detail-card .card-dashboard .report-body,
.customer-detail-card .tab-content-overview {
  padding: 30px 32px 32px 32px !important;
}
.customer-detail-card .card-dashboard .report-body .report-section {
  border: 0 !important;
}
.customer-detail-card .report-body .report-section .report-body-links {
  display: none;
}
.customer-detail-card .report-body .report-section .report-header,
.customer-detail-card .report-body .report-section .report-table-section {
  padding: 0 !important;
}
.customer-detail-card .report-body .report-body-links {
  padding: 0 0 16px 0;
}
.customer-detail-card .report-body .filter-box {
  padding: 0 0 15px 0;
}
.customer-detail-card .report-table-section .table-wrapper {
  margin-bottom: 0;
}

.customer-detail-card .tab-content-overview {
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  padding-bottom: 0 !important;
}
.customer-detail-card .tab-content-overview.outstanding-data {
  margin-bottom: 30px;
}
.customer-detail-card .tab-content-overview.purchase-defaults {
  margin-bottom: 30px;
}
.customer-detail-card
  .tab-content-overview.outstanding-data
  .outstanding-red
  span {
  color: var(--red);
}
.customer-detail-card
  .tab-content-overview.outstanding-data
  .outstanding-green
  span {
  color: var(--apple);
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .customer-detail-card .filter-wrapper {
    /* width: 52% !important; */
    width: 345px !important;
  }
  .customer-detail-card .filter-wrapper .filter-item {
    width: 100% !important;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
