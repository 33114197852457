 .recurring-invoice-org-note-wrapper {
    width: 682px;
    padding: 20px 39px 15px 20px;
    border-radius: 4px;
    background-color: rgba(var(--peak-point-rgb), 0.07);
    margin: 0 24px 30px;
  }
  
  .recurring-invoice-org .note-header {
    
    font-size: 15px;
    font-weight: bold;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
  }
  
  .recurring-invoice-org-note-wrapper p {
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--battleship-grey);
  }
  
.recurring-invoice-org-note-wrapper .notes-link {
    color: var(--cerulean-blue);
    text-decoration: none;
  }