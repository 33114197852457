.configure-coupon-options {
  line-height: 16px;
}

.configure-coupon-options .grey-color {
  color: var(--battleship-grey) !important;
}

label:has(.configure-coupon-options) {
  padding-bottom: 8px;
}
