.search-filter-wrapper {
  width: auto;
  max-width: 336px;
  /* height: 40px; */
  margin: 1px 16px 0 24px;
  /* padding: 9px 6px 8px 12px; */
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  background-color: var(--atlantis);
}
.search-filter-wrapper form {
  padding: 9px 0 8px 0;
}

.search-filter-wrapper form {
  width: 210px;
  position: relative;
}
.search-filter-wrapper form input,
.filter-dropdown select {
  width: 100%;
  height: 23px;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  font-size: 13px;
  line-height: 0.77;
  letter-spacing: normal;
}
.search-box-icon {
  position: absolute;
  left: 15px;
}
.search-filter-wrapper form input {
  background: transparent no-repeat left 10px center;
  background-size: 20px auto;
  padding: 6px 20px 7px 40px;
  font-weight: normal;
  color: rgba(var(--white-rgb), 0.8);
  border-right: 1px solid rgba(var(--white-rgb), 0.4);
}
.filter-dropdown select:focus,
.search-filter-wrapper form input:focus-visible {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}
.search-filter-wrapper form input:focus {
  color: rgba(var(--white-rgb), 0.8);
}
.search-filter-wrapper form input::-webkit-input-placeholder {
  color: rgba(var(--white-rgb), 0.8);
}
.search-filter-wrapper form input::-moz-placeholder {
  color: rgba(var(--white-rgb), 0.8);
}
.search-filter-wrapper form input:-ms-input-placeholder {
  color: rgba(var(--white-rgb), 0.8);
}
.search-filter-wrapper form input:-moz-placeholder {
  color: rgba(var(--white-rgb), 0.8);
}
.filter-dropdown {
  width: 125px;
  padding: 7px 0 8px 0;
}

.filter-dropdown select option {
  background: var(--white-fill); /* White background for the dropdown options */
  color: var(--black); /* Text color for the options */
  padding: 30px; /* Padding for the options */
}
.global-search-field {
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--new-whisper);
  margin-right: 40px;
}
.global-search-field.search-page {
  background: var(--white-fill);
}
.global-search-field.search-header {
  background: var(--atlantis);
  border: none;
  width: 336px;
}
.global-search-field .MuiInputBase-root input:focus {
  border: none !important;
}
.MuiInput-input {
  display: flex !important;
  align-items: center !important;
}
.MuiInput-input:focus {
  background-color: transparent !important;
}
.MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
  color: var(--cerulean-blue) !important;
}
.MuiList-root.MuiMenu-list {
  width: 230px !important;
  right: 0px !important;
}
.global-search-field.search-header .MuiInputBase-input {
  color: var(--white-fill) !important;
}
.global-search-field .MuiInputBase-input {
  font-weight: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
.global-search-field.search-page .MuiInputBase-input {
  font-size: 14px !important;
  color: var(--charcoal-grey);
}
.global-search-field.search-header .MuiInputBase-input {
  font-size: 13px !important;
  color: var(--white-shadow8) !important;
}
.global-search-field.search-header .MuiInputBase-input {
  padding-right: 0px !important;
}
.global-search-field .MuiPaper-root-MuiPopover-paper {
  z-index: 5000 !important;
}
.global-search-field .MuiInputBase-root {
  padding-right: 0px !important;
}
.global-search-field.search-page .MuiInputBase-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.global-search-field.search-page .MuiInputAdornment-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.global-search-field.search-header svg {
  color: var(--white-fill);
}
.global-search-menu-root {
  margin-top: 6px;
  z-index: 1315 !important;
}
.global-search-field .MuiInput-root .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
}
.global-search-field.search-header .MuiInput-root .MuiSvgIcon-root path {
  fill: var(--white-fill);
}
.global-search-field.search-header .global-search-form-control-root {
  border-left: 0 !important;
  padding: 0 10px 0 12px !important;
}
.global-search-field.search-header
  .global-search-form-control-root
  .search-module {
  position: relative;
}
.global-search-field.search-header
  .global-search-form-control-root
  .search-module:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 23px;
  background: var(--new-whisper);
  top: 50%;
  left: -1px;
  transform: translate(0, -50%);
}
.global-search-form-control-root .MuiInputBase-input {
  line-height: normal;
}
.global-search-field .MuiSelect-select.MuiSelect-standard {
  display: block !important;
  line-height: 40px !important;
  padding: 0 12px !important;
  height: 40px;
  font-size: 13px !important;
  font-weight: bold !important;
  letter-spacing: normal;
  font-size: normal !important;
}
.global-search-field.search-page .global-search-form-control-root {
  border-left: 1px solid var(--new-whisper);
  width: 170px;
}
.global-search-field.search-page .MuiInput-root .MuiSvgIcon-root path {
  fill: var(--arsenic);
}
.global-search-menu-root .global-search-dropdown-item {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.29;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding: 0 16px 0 36px !important;
}
.global-search-field .MuiInputBase-input {
  width: 60% !important;
}
.MuiAutocomplete-popper {
  z-index: 1316;
}
.search-data {
  display: flex;
  flex-direction: row;
}
.global-search-field .MuiAutocomplete-noOptions {
  padding: 0px !important;
}
.global-search-field .global-search-form-control-root .MuiSelect-select {
  width: 100% !important;
}
.query-match-label{
  margin-right: 5px !important;
}
.global-search-field .MuiMenuItem-root.Mui-selected {
  position: relative;
  background-color: transparent !important;
  color: var(--cerulean-blue) !important;
}
.global-search-menu-root .global-search-dropdown-item.Mui-selected:before {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("../../../../assets/images/blue-check.svg") no-repeat center;
}

@media (max-width: 1180px) {
  .global-search-field {
      margin-right: 20px;
  }
}
@media (max-width: 1100px) {
  .global-search-field.search-header {
      width: 288px;
  }
  .global-search-field.search-header .MuiInputBase-input {
    width: 86px !important;
  }
}
@media (max-width: 1050px) {
  .global-search-field {
    margin-right: 15px;
  }
}
@media (max-width: 1024px) {
  .global-search-field {
    margin-right: 0;
  }
  .searchbar-section .global-search-field {
    /* width: 500px; */
    background: var(--white-fill);    
    height: 40px;
    display: flex;
    align-items: center;   
    border-radius: 8px;
    border: 1px solid var(--new-whisper);
    margin-right: 0;
  }
  .searchbar-section .global-search-field .MuiInputBase-input {
    width: 74% !important;
    font-size: 14px !important;
    color: var(--charcoal-grey) !important;
  }
  .searchbar-section .global-search-field .MuiAutocomplete-root {
    /* width: calc(100% - 170px); */
  }
  .searchbar-section .global-search-field .global-search-form-control-root {
    /* width: 170px !important; */
  }
  .searchbar-section .global-search-field svg path:last-child{
    fill: var(--nobel);
  }
  .searchbar-section .global-search-field .MuiInput-root .MuiSvgIcon-root path {
    fill: var(--arsenic);
  }
  .searchbar-section .global-search-field .global-search-form-control-root .MuiSelect-select {
    width: 100% !important;
  }
  .global-search-field.search-header {
    width: calc(100% - 38px) !important;
  }
  .global-search-field.search-header .search-popper {
    width: calc(100% - 100px) !important;
    
  }
  .global-search-field.search-header .MuiAutocomplete-root {
    width: calc(100% - 125px);
  }
  .global-search-field.search-header .MuiAutocomplete-root .MuiInputBase-input {
    width: calc(100% - 95px) !important;
  }
   
}
@media (max-width: 767px) {
  .global-search-field.search-header .search-popper {
    width: calc(100% - 70px) !important;
  }
}
@media (max-width: 360px) {
  .global-search-field.search-header .search-popper {
    width: calc(100% - 60px) !important;
  }
}