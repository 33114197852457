.coupon_action {
  width: 40px;
}

#coupon_list_table .discount {
  min-width: 120px;
}

@media screen and (max-width: 1366px) and (max-height: 769px) {
  #coupon-table-wrapper .list-table {
      min-width: 1035px;
  }
}