.jv-detail-table {
}
.jv-item-container .item-table-wrapper {
  width: 100% !important;
  min-width: auto !important;
  overflow: hidden;
  overflow-x: auto;
}
.jv-detail-table {
  min-width: 1086px !important;
}
.jv-detail-table thead,
.jv-detail-table th {
  border: 0 !important;
}
.jv-detail-table th {
  padding: 16px 0 8px 0 !important;

  font-size: 12px;
  font-weight: bold;

  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
}
.jv-detail-table tr td {
  padding: 8px 0 !important;
  vertical-align: top !important;
}
.jv-detail-table tr:last-child td {
  padding-bottom: 20px !important;
}
.jv-detail-table tr td .delete-btn {
  margin-top: 18px;
}
.jv-detail-table th,
.jv-detail-table tr td {
  padding-left: 30px !important;
  padding-right: 0 !important;
  vertical-align: middle;
  background-color: rgba(var(--cerulean-blue-rgb), 0.04) !important;
  min-width: auto !important;
}
.jv-detail-table th:first-child,
.jv-detail-table tr td:first-child {
  padding-left: 16px !important;
  padding-right: 0 !important;
  width: 19.4265% !important;
  /* min-width: 226px; */
}
.jv-detail-table th:nth-child(2),
.jv-detail-table tr td:nth-child(2) {
  width: 23% !important;
  padding-left: 24px !important;
  /* min-width: 443px; */
}
.jv-detail-table th:nth-child(3),
.jv-detail-table tr td:nth-child(3) {
  min-width: 23% !important;
  /* min-width: 443px; */
}
.jv-detail-table th:nth-child(4),
.jv-detail-table tr td:nth-child(4) {
  width: 13.876% !important;
  /* min-width: 183px; */
}
.jv-detail-table th:nth-child(5),
.jv-detail-table tr td:nth-child(5) {
  width: 13.876% !important;
  /* min-width: 183px; */
}
.jv-detail-table th:last-child,
.jv-detail-table tr td:last-child {
  padding: 0 16px !important;
  width: 50px !important;
}
.jv-detail-table tr td .delete-btn {
  display: block;
  float: left;
  width: 18px;
  height: auto;
  text-decoration: none;
}
.jv-detail-table tr td .delete-btn img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  float: left;
}
.jv-detail-table tr td input[type="text"] {
  width: 100%;
  max-width: none !important;
  height: 40px;
  padding: 11px 12px;
}
.jv-detail-table tr td input[type="text"]::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.jv-detail-table tr td input[type="text"]::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.jv-detail-table tr td input[type="text"]:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.jv-detail-table tr td input[type="text"]:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.jv-detail-table tr:hover {
  box-shadow: none !important;
}
.jv-toatal {
  min-width: 418px;
  margin-left: auto;
  margin-right: 29px;
  padding: 0;
  float: right;
}
.jv-toatal .calc-item {
  padding: 16px 0;
  border-bottom: 1px solid var(--approx-whisper);
}
.jv-items-entry-wrapper {
  padding-bottom: 20px !important;
}
.jv-toatal .calctable {
  border: 0 !important;
}
.jv-toatal .calctable thead,
.jv-toatal .calctable th {
  border-top: 0 !important;
  border-bottom-color: var(--approx-whisper);
}
.jv-toatal .calctable th {
  font-size: 16px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey) !important;
  padding: 22px 0 16px 0 !important;
  border: 0 !important;
}
.jv-toatal .calctable th:first-child {
  letter-spacing: -0.2px;
  text-align: left;
}
.jv-toatal .calctable tr td {
  height: 50px;
  overflow: hidden;

  font-size: 16px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--red) !important;
  padding: 3px 0 0 0 !important;
  vertical-align: middle;
}
.jv-toatal .calctable tr td:first-child {
  font-size: 16px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  letter-spacing: -0.2px;
  color: var(--battleship-grey) !important;
}
.jv-toatal .calctable tr td.no-diff {
  color: var(--deep-green) !important;
}
.jv-toatal .calctable tr:hover {
  box-shadow: none !important;
}
.jv-toatal .calctable th:first-child,
.jv-toatal .calctable tr td:first-child {
  width: 149px;
  padding-right: 54px !important;
}
.jv-toatal .calctable th:nth-child(2),
.jv-toatal .calctable tr td:nth-child(2) {
  text-align: left;
  padding-right: 8px !important;
}
.jv-toatal .calctable th:last-child,
.jv-toatal .calctable tr td:last-child {
  padding-left: 8px !important;
}
.jv-toatal .calctable th,
.jv-toatal .calctable tr td {
  white-space: nowrap !important;
}
.jv-detail-table .inv-prefix {
  height: 40px;
  line-height: 1;
}
.info-container {
  width: 100%;

  font-size: 14px;
  font-weight: normal;

  font-style: italic;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--slate);
}
.info-container.table-info,
.info-container.table-info span {
  width: auto;
  padding: 0 !important;

  font-size: 14px;
  font-weight: 500;

  line-height: 1.29;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  vertical-align: middle;
}
.info-container.table-info img {
  margin-right: 6px;
  width: 18px;
  height: 18px;
}
.jv-detail-table .accounts-dropdown,
.jv-detail-table .accounts-dropdown input {
  width: 100% !important;
  flex: 0 0 100%;
}
/* .jv-detail-table .accounts-dropdown .dropdown-menu {
    right: auto !important;
} */
#difference-success {
  color: var(--crusoe);
}

/* .add-module-item-form .accounts-dropdown input.search-dropdown.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
} */
span.jv-required-field {
  position: relative;
  padding-right: 14px;
}
span.jv-required-field:after {
  content: "*";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 1px;
  right: 0;
  color: var(--red);

  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.18px;
}

.jv-detail-table tr td .custom-select input[type="text"] {
  height: auto;
}

/* .jv-detail-table tr td .custom-select svg[class$="-Svg"] {
  width: 12px !important;
  height: 12px !important;
} */

.jv-detail-table tr td .accounts-dropdown .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}
.custom-select-wrapper {
  width: 100%;
  display: block;
  float: left;
}
.jv-converted-amount-display {
  font-size: 12px;
  color: var(--battleship-grey);
  font-style: italic;
}
@media (max-width: 1750px) {
  .jv-detail-table th:nth-child(2),
  .jv-detail-table tr td:nth-child(2) {
    width: 32% !important;
    padding-left: 24px !important;
    /* min-width: 443px; */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .jv-toatal {
    width: 100%;
    min-width: auto !important;
    overflow: hidden;
    overflow-x: auto;
    float: left;
    margin-right: auto;
  }
  .jv-toatal .calctable th,
  .jv-toatal .calctable tr td {
    white-space: nowrap;
  }
  .jv-toatal .info-container {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .jv-item-details-header-wrapper {
    flex-direction: column;
  }
  .jv-item-details-header-wrapper h2 {
    width: 100%;
    text-align: left;
  }
  .jv-item-details-header-wrapper .info-container.table-info {
    width: 100%;
    text-align: left;
    margin-top: 16px;
  }
}
