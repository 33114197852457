.cutomize-prefix-form {
  width: 100%;
}
.cutomize-prefix-form .fields-wrapper {
  width: 100%;
  padding: 30px 40px;
}
.cutomize-prefix-form .fields-wrapper .col {
  margin-bottom: 35px;
}
.cutomize-prefix-form .fields-wrapper .row:last-child .col {
  margin-bottom: 7px;
}
.cutomize-prefix-form .fields-wrapper label {  
  font-size: 14px;
  font-weight: bold;  
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
}
.cutomize-prefix-form .fields-wrapper .current-value-wrapper {
  clear: both;
}
.cutomize-prefix-form .fields-wrapper .action-buttons {
  display: inline-block;
  position: relative;
  top: 0;
  padding: 0 !important;
}
.cutomize-prefix-form .fields-wrapper .action-buttons button {
  top: 10px !important; 
  font-size: 0;
  color: transparent;
  float: left; 
}
.cutomize-prefix-form .fields-wrapper .action-buttons .edit {
  position: relative;
  top: 3px !important;
  margin-left: 6px;
  width: 16px; 
  height: 16px;
  background-position: center !important;
}
.cutomize-prefix-form .fields-wrapper .current-value {
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding-top: 3px;
}
.cutomize-prefix-form .fields-wrapper .input-wrapper {
  display: none;
  float: left;
}
.cutomize-prefix-form .fields-wrapper .input-wrapper input {
  width: 100%;
  max-width: 230px;
  padding: 0 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-right: 10px;
  float: left;
}
.cutomize-prefix-form .fields-wrapper .input-wrapper input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.cutomize-prefix-form .fields-wrapper .custom-tooltip-wrapper .info-btn {
  right: -7px;
  top: 2px;
}
.card-prefix-customization .invoice-list-card-header h1 span {
  position: relative;
  top: -1px;
}