.add-account-form-modal-br .add_account_wrapper {
  padding-right: 15px !important;
}

.add-account-form-modal-br .input-prefix-wrap {
  max-width: 100% !important;
}

.add-account-form-modal-br .info-account-name {
  right: 150px;
  top: 5px;
  z-index: 9999;
}
.add-account-form-modal-br{
  padding: 10px 30px 30px 30px;
}
.add-account-form-modal-br .inv-group.d-flex.w-100.input-prefix-wrap.focused {
  outline: none !important;
  box-shadow: none !important;
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  width: 100% !important;
}

.add-account-form-modal-br-wrapper h5 {
  padding-left: 22px;
}
