.fields-wrapper-budget-summary-report-table {
    margin-top: 0px;
    padding: 24px 24px 24px 24px;
    border-radius: 12px;
    /* box-shadow: 0 8px 20px 0 var(--black-shadow3); */
    /* border: solid 2px var(--warm-white); */
    background-color: var(--white-fill);
  }
  .fields-wrapper-budget-summary-report-table .table-container {
    width: 100%;
    overflow-x: auto;
    border-radius: 4px;
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table {
    width: 100%;
    border-collapse: collapse;
    border: solid 1px var(--approx-whisper);
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table th,
  .fields-wrapper-budget-summary-report-table .budget-entry-table td {
    padding: 12px !important;
    text-align: left;
    min-width: 100px;
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table th:first-child,
  .fields-wrapper-budget-summary-report-table .budget-entry-table td:first-child {
    position: sticky;
    left: 0;
    /* background-color: #fff; */
    z-index: 2;
    min-width: 300px !important;
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table td:first-child {
    border-right: 0px solid #f2f2f2 !important;
  }
  .fields-wrapper-budget-summary-report-table .table-cell {
    width: fit-content;
    min-width: 150px !important;
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
  }
  .fields-wrapper-budget-summary-report-table .budget-entry-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  .fields-wrapper-budget-summary-report-table .budget-table-btn-wrapper {
    margin-top: 24px;
  }
  