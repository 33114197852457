.card-customer-header h1 {
  font-weight: 900;
  font-size: 24px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 0;
  margin-top: 5px;
}
.add-customer-button {
  width: auto;
  padding: 0 15px 0 13px;
}
#coa_table_wrapper {
  width: 100%;
  padding: 19px 0 0 0;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  border: solid 1px var(--warm-white) !important;
  background-color: var(--white-fill);
}
#coa_table_wrapper table {
  margin-bottom: 0;
  min-width: 1080px;
}
#coa_table_wrapper .dataTables_info,
#coa_table_wrapper .results-per-page {
  font-size: 14px;
  font-weight: normal;

  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
#coa_table_wrapper .results-per-page label {
  color: var(--slate);
  float: initial;
}
#coa_table_wrapper div.dataTables_length select {
  width: 75px;
  height: 32px;
  margin: 0 0 0 8px;
  padding: 7px 28px 7px 11px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--approx-whisper);
  background-color: var(--white-fill) !important;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  background-image: url("../../../assets/images/dropdwon-arrow-down.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 24px 24px !important;
  background-position: right 3px center !important;
}
#coa_table_wrapper thead,
#coa_table_wrapper th {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
}
#coa_table_wrapper .item_name {
  min-width: 152px !important;
  max-width: 230px;
}
#coa_table_wrapper .account_type {
  min-width: 146px !important;
  max-width: 379px;
}
#coa_table_wrapper .coa_description {
  max-width: 287px !important;
}
#coa_table_wrapper .ledger_code {
  min-width: 117px !important;
  text-align: left;
}
#coa_table_wrapper .balance_amt {
  min-width: 120px !important;
  text-align: right;
  padding-right: 32px !important;
}
#coa_table_wrapper .action {
  width: 70px !important;
}
#coa_table_wrapper td,
#coa_table_wrapper td .coa_description,
#coa_table_wrapper td .acc-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  box-shadow: none;
}
#coa_table_wrapper td .acc-name {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}
#coa_table_wrapper td .acc-name img {
  width: 16px;
  height: auto;
  margin: -4px 8px 0 0;
  object-fit: contain;
}
#coa_table_wrapper td .coa_description {
  display: block;
  width: 100%;
}
table.dataTable.table-striped > tbody > tr.even > * {
  box-shadow: none;
}
table.dataTable.table-striped > tbody > tr > td,
table.dataTable.table-striped > thead > th {
  padding: 13px 12px;
}
table.dataTable.table-striped > tbody > tr > .item_name {
  padding: 13px 12px 13px 24px;
  min-width: 150px;
}
table.dataTable.table-striped > tbody > tr > .action {
  padding: 13px 24px 13px 12px;
  width: 70px;
}
table.dataTable.table-striped > tbody > tr.even > td {
  background-color: rgba(var(--peak-point-rgb), 0.07);
}
table.dataTable.table-striped > tbody > tr:hover > td {
  background-color: var(--alice-blue);
}
table.dataTable.table-striped > thead > tr:hover {
  box-shadow: none !important;
}
#coa_table_wrapper td.coa_description {
  /* max-width: 259px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 81px !important;
}
/* #coa_table_wrapper td.balance_amt {
  padding-right: 32px !important;
} */
.talign-right {
  text-align: right;
}
#coa_table_wrapper td.action .dropstart {
  float: right;
  width: 20px;
}
.toggle-view {
  margin-left: 20px;
  font-size: 0;
}
.list-view,
.tree-view {
  height: 40px;
  border-radius: 6px;
  line-height: 1;
  background: none;
  border: none;
  padding: 0 9px;
  background-repeat: no-repeat;
  background-position: center;
}
.list-view {
  background-color: var(--new-alice-blue);
  border-radius: 6px 0 0 6px;
  border: solid 1px var(--cerulean-blue);
}
.list-view #list_view {
  fill: var(--cerulean-blue);
}
.list-view:hover {
  border: solid 1px rgba(var(--cerulean-blue-rgb), 0.2);
}
.tree-view {
  border-radius: 0 6px 6px 0;
  border: solid 1px rgba(var(--cerulean-blue-rgb), 0.2);
  background-color: var(--new-alice-blue);
}
.tree-view.active #tree_view,
.list-view.active #list_view,
.list-view:hover #list_view,
.tree-view:hover #tree_view,
.list-view:hover #tree_view {
  fill: var(--white-fill);
}
.tree-view.active,
.list-view.active,
.tree-view:hover,
.list-view:hover {
  background-color: var(--cerulean-blue);
}

#coa_table th[data-orderable="true"] {
  cursor: pointer;
}
#coa_table th[data-orderable="true"] span {
  width: auto !important;
  position: relative;
  padding-right: 20px;
}
#coa_table th[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  background-size: 16px 16px;
  bottom: 0;
  right: 8px;
}
#coa_table th[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  background-size: 16px 16px;
  right: 0;
  bottom: 0;
}
#coa_table th.ascending span:before {
  opacity: 1;
}
#coa_table th.ascending span:after {
  opacity: 0.5;
}
#coa_table th.descending span:before {
  opacity: 0.5;
}
#coa_table th.descending span:after {
  opacity: 1;
}

/* Add account Modal */
.add-modal {
  width: 100%;
}
.add-modal .modal-dialog {
  width: 100% !important;
  max-width: 825px;
}
.add-modal h5 {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.add-modal .modal-header {
  padding: 0;
}
.add-modal .tree-section,
.add-modal .form-section {
  padding: 30px;
}
.add-modal .popup-header {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-bottom: 16px;
}
.add-modal .modal-body {
  display: flex;
  padding: 0;
}

.add-modal .form-labels {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  letter-spacing: -0.18px;
  margin-bottom: 12px;
}
.add-modal .tree-section {
  width: 48%;
  border-right: 1px solid var(--approx-whisper);
}
.add-modal .alert-msg {
  background-color: var(--pale-conflower-blue);
  padding: 10px 18px 10px 8px;
  border-radius: 6px;
  margin-bottom: 16px;
}
.add-modal .alert-msg p {
  font-size: 13px;
  font-weight: normal;
  line-height: 0.77;
  letter-spacing: normal;
  color: var(--chambray);
  white-space: nowrap;
  max-width: 318px;
}
.add-modal input::placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.add-modal textarea::placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.add-modal .form-button-wrapper {
  padding: 0;
  margin-top: 0;
  border-top: 0;
}
.add-account-form-wrapper .form-control {
  padding: 8.4px 15px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.add-account-form-wrapper input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}

.form-section-disable {
  padding: 30px;
  opacity: 0.5;
}
/* Add account modal ends*/

/* Tree view */
/* Tree View Style */
.tree-view-container {
  border-radius: 5px;
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 500px;
  overflow-y: auto;
}
.tree-view-container .MuiTreeView-root {
  height: auto;
}
.tree-view-container .MuiTreeView-root > li {
  border-bottom: 1px solid var(--approx-whisper);
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content {
  padding: 0 8px;
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content > div:first-child {
  padding: 7px 6px 7px 0;
  height: 100%;
  border-right: 1px solid var(--approx-whisper);
  margin-right: 10px;
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content > div:empty {
  padding: 19.5px 15px;
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content .MuiTreeItem-label {
  font-size: 14px;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
  padding: 15.04px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tree-view-container .MuiTreeView-root .MuiTreeItem-content.Mui-expanded {
  border-bottom: 1px solid var(--approx-whisper);
}
.tree-view-container
  .MuiTreeView-root
  .MuiTreeItem-content
  .MuiTreeItem-iconContainer {
  width: 8px;
  height: 4px;
  background-position: center;
  transform: rotate(180deg);
  transition: 0.5s;
  margin-right: 14px;
}
.tree-view-container
  .MuiTreeView-root
  .MuiTreeItem-content.Mui-expanded
  .MuiTreeItem-iconContainer {
  transform: rotate(0);
  transition: 0.5s;
}
.tree-view-container
  .MuiTreeView-root
  .MuiTreeItem-content
  .MuiTreeItem-iconContainer
  svg {
  display: none;
}
.tree-view-container .MuiTreeView-root .MuiCollapse-root {
  margin-left: 38px;
}
.tree-view-container .MuiTreeView-root .MuiCollapse-root .MuiTreeItem-root {
  border-bottom: 1px solid var(--approx-whisper);
  border-left: 1px solid var(--approx-whisper);
}
.tree-view-container
  .MuiTreeView-root
  .MuiCollapse-root
  .MuiTreeItem-root
  .MuiTreeItem-content.Mui-expanded {
  border-bottom: 1px solid var(--approx-whisper);
}
.tree-view-container
  .MuiTreeView-root
  .MuiTreeItem-content.Mui-expanded
  .MuiTreeItem-label {
  font-weight: bold;
}
/* Tree view ends*/

/* Edit account pop up*/
#editAccountModal {
  right: 0;
  width: 480px;
}
#editAccountModal .modal.fade:not(.in).right .modal-dialog {
  transform: translate3d(0%, 0, 0);
}
#editAccountModal .modal-header {
  border-bottom: none;
}
#editAccountModal .close-button-container {
  justify-content: flex-end;
  padding: 14px 14px 4px 40px;
}
.edit-form {
  max-width: 480px;
}
#editAccountModal .modal-body {
  padding: 0 40px 40px 40px;
}
.edit-form-head h4 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.selected-branch-wrapper {
  margin: 0 0 13px;
  font-size: 14px;
  line-height: normal;
  color: var(--charcoal-grey);
  letter-spacing: normal;
  margin-bottom: 33px;
}
.selected-branch {
  font-weight: normal;
}
.branch {
  font-weight: 600;
}
#editAccountModal .form-labels {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  letter-spacing: -0.18px;
  margin-bottom: 12px;
  line-height: normal;
}
#editAccountModal input::placeholder::placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  line-height: normal;
}
#editAccountModal textarea::placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  line-height: normal;
}
#editAccountModal input {
  border-radius: 6px;
}
#editAccountModal textarea {
  min-height: 100px;
  border-radius: 6px;
  padding: 15px 12px;
}
#editAccountModal .row.custom-row.exchange-rate-container {
  margin-top: -12px;
  margin-bottom: 0px;
}
#editAccountModal .row.custom-row .exchange-rate-wrapper {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  float: right;
}
#editAccountModal .exchange-rate-wrapper .form-button-wrapper {
  margin-bottom: 0 !important;
  float: none;
  clear: none;
}
#editAccountModal #exchangeRateEdit .col:last-child {
  padding-right: 10px !important;
  padding-left: 10px;
}
#editAccountModal .form-button-wrapper {
  border-top: none;
  padding: 24px 0;
  margin-top: 0;
}
.edit-account-form-wrapper .form-control {
  padding: 8.4px 15px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.edit-account-form-wrapper input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
/* Edit account pop up ends*/

.add-account-form-section-disable,
.update-account-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}
#editLedgerRangeModal {
  right: 0;
  width: 480px;
}
.edit-ledger-code-range-form-wrap .tbody-container .row {
  padding: 16px 0 14px 0;
  border-bottom: solid 1px var(--new-whisper);
}
.edit-ledger-code-range-form-wrap .tbody-container .row:nth-last-child(2) {
  border-bottom: 0 !important;
}
.edit-ledger-code-range-form-wrap .tbody-container .col {
  display: flex;
  align-items: center;
}
.edit-ledger-code-range-form-wrap .tbody-container .col:first-child,
.edit-ledger-code-range-form-wrap .title-row .col:first-child {
  width: 186px !important;
  flex: 0 0 186px !important;
}
.hyphonWrapper {
  padding: 0 10px;
  position: relative;
}
.hyphonWrapper:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  background-color: var(--slate);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.edit-ledger-code-range-form-wrap .title-row .col:last-child,
.edit-ledger-code-range-form-wrap .tbody-container .col.input-col {
  width: 254px;
  flex: 0 0 254px;
}
.edit-ledger-code-range-form-wrap .tbody-container .col.input-col input {
  width: 108px;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding: 16px 12px 14px 12px;
  -moz-appearance: textfield;
}
.edit-ledger-code-range-form-wrap
  .tbody-container
  .col.input-col
  input::-webkit-outer-spin-button,
.edit-ledger-code-range-form-wrap
  .tbody-container
  .col.input-col
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.with-border-bottom {
  border-bottom: solid 1px var(--new-whisper);
}
.edit-ledger-code-range-form-wrap .title {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--battleship-grey);
}
.edit-ledger-code-range-form-wrap .tbody-container label {
  font-size: 14px;
  font-weight: 600;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.edit-ledger-code-range-form-wrap .tbody-container .button-wrapper {
  padding: 32px 40px;
}
.save-btn {
  width: 82px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 11px 24px;
  border-radius: 6px;
  background-color: var(--booger);
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white-fill);
}
.mbottom-20 {
  margin-bottom: 20px !important;
}
#editLedgerRangeModal .info-box {
  display: flex;
  width: 392px;
  height: 34px;
  align-items: center;
  margin: 0 auto 20px 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
}
.error-info {
  background-color: var(--pink);
}
.info-box .info-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--chambray);
}
.error-info .info-text {
  color: var(--red);
}
.info-box img {
  width: 18px;
  height: 18px;
  margin: 0 12px 0 0;
  object-fit: contain;
}
.editLedgerRangeModal .user-form-head {
  padding-left: 40px;
}
.edit-ledger-code-range-form-wrap .tbody-container .col.input-col input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.coa-treeview .account-col,
.coa-treeview .table-body .account-col {
  width: calc(100% - 582px);
}
.coa-treeview .bal-col {
  width: 180px;
}
.coa-treeview .bal-col > span {
  float: right;
}
.coa-treeview .table-body .bal-col > span {
  padding-right: 24px;
}
.coa-treeview .desc-col {
  width: 260px;
}
.coa-treeview .desc-col > span {
  width: 52.3%;
  padding-right: 24px !important;
}
.coa-treeview .item-col.ledger-col {
  width: 82px;
}
.coa-treeview .act-col {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  padding-left: 26px;
}
.form-select.accounts-select {
  margin-top: 0;
}
#addAccountModal
  .tree-view-container
  .MuiTreeView-root
  > li
  .MuiTreeItem-content
  > div
  > .MuiSvgIcon-root {
  transform: rotate(-180deg);
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 6px;
}
#addAccountModal .row.custom-row .exchange-rate-container {
  margin-top: -12px;
  margin-bottom: 0px;
}
#addAccountModal .row.custom-row .exchange-rate-wrapper {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  float: right;
}
#addAccountModal .exchange-rate-wrapper .form-button-wrapper {
  margin-bottom: 0 !important;
  float: none;
  clear: none;
}
#addAccountModal #exchangeRateEdit .col:last-child {
  padding-right: 10px !important;
  padding-left: 10px;
}
.branch-select-active,
.branch-select-active * {
  font-weight: bold !important;
}
.account-category-checkbox {
  display: inline-block;
  width: 100%;
  margin: 11px 0 20px 0 !important;
}
.account-category-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  float: left;
}
.account-category-checkbox input[type="checkbox"]:disabled {
  opacity: 0.5;
}
.account-category-checkbox label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-right: 4px;
  float: left;
}
.account-category-checkbox input[type="checkbox"]:checked {
  background-color: var(--cerulean-blue) !important;
  background-image: url("../../../assets/images/checkbox-checked.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 6px;
}
.account-category-checkbox .info-btn {
  top: 2px;
  right: 0;
}
.grid-table.coa-treeview {
  /* margin-bottom: 70px; */
  min-width: 1123px;
}
.sub-label {
  color: var(--battleship-grey);
  font-weight: normal;
  font-style: italic !important;
}
.opening-balance-wrap {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
.opening-balance-wrap.form-label {
  width: 200px;
}
.opening-balance-exchange-rate {
  width: 180px;
  text-align: center;
}
.hide-base-currency {
  display: none;
}
.coa-opening-balance-label {
  width: 200px;
}
/** Media Queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (max-width:991px) {
  .add-modal .alert-msg p {
    white-space: normal;
    line-height: normal;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .add-modal .modal-dialog {
    max-width: 750px;
  }  
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
