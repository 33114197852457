.link-label-grouped {
    width: 100%;
    max-width: 325px;
    float: left;
}
.MuiFormLabel-root.Mui-focused {
    color: #000;
  }
  .custom-field-wrapper{
    padding: 0 40px;
    width:480px;
  }
  .custom-field-form{
    width: 100%;
  }
  #field-name-input{
    width: 100%;
    padding-left: 0 !important;
  }
  .form-control-custom-field-radio-button{
    width:fit-content !important;
    padding: 0px !important;
    margin: 0px 50px 0px 0px !important;
  }
  .custom-field-radio-group{
    margin: 0px !important;
    padding: 0px !important;
  }
  .custom-field-radio-btn span{
    padding-left: 0px !important;

  }
  .custom-field-radio-btn.Mui-checked{
    color:var(--cerulean-blue) !important;
  }
  .custom-field-form-control-label{
    width: auto !important;
    padding: 0px !important;
    margin: 0px 21px 0px 0px!important;
  }
  #field_name{
    width:400px !important;
  }
  .MuiRadio-root{
    padding: 9px 0px !important;
  }
  .custom-field-wrapper .MuiTypography-root{
    color: var(--charcoal-grey) !important;
  }
  .form-control-custom-field-radio-button label{
    margin-bottom: 0px !important;
    /* margin-top: 20px !important; */
  }
  .field-name-input{
    margin-bottom: 15px !important;
  }