.card-main-header {
  border: 0px;
  background-color: var(--approx-white);
  padding: 0 0 24px 0;
}
.card-main-header #search_table:focus-visible {
  outline: 0;
}
.main-card .top-action-lists {
  margin-top: 0 !important;
}
.main-card .top-action-lists li {
  margin-bottom: 0;
}
.top-action-lists {
  margin-top: -7px !important;
}
.nav.tab-link + .top-action-lists.button-list li {
  margin-bottom: 23px;
}
.top-action-lists .nav-link {
  padding: 0 !important;
}
.top-action-lists .card-link {
  margin: 4px 35px 7px 0px;
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate);
}
.top-action-lists .card-link span {
  font-size: 15px;
  display: inline-block;
  margin-left: 3px;
}
.top-action-lists .card-link.card-active {
  color: var(--charcoal-grey);
}
.system-voucher-buttons .system-voucher-list-modal {
  float: right;
  width: auto !important;
  min-width: auto;
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-card .top-action-items-wrapper {
    /* max-width: 300px !important; */
    width: 100% !important;
    clear: both !important;
    justify-content: space-between;
    float: left;
    flex-direction: column-reverse;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .main-card .top-action-items-wrapper {
    justify-content: space-between;
    margin-top: 14px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main-card .top-action-items-wrapper {
    display: flex;
    width: 100%;
    float: left;
  }
}
