.refund-form-wrapper {
  right: 0;
  width: 480px;
}

.refund-form-wrapper .form-btn-group-wrapper {
  margin-top: 30px;
  width: 100%;
  float: left;
}
.refund-form-wrapper .save-butn {
  width: 80px;
}
.refund-form-wrapper .custom-select input {
  height: auto !important;
}
.refund-form-wrapper.add-tax-form {
  padding: 0 40px 40px;
}

.MuiPopover-root.MuiModal-root:not(.MuiMenu-root) {
  z-index: 1324 !important;
}
.refund-form-wrapper .date-container button{
  margin-right: 0;
}

.refund-form-wrapper .account-full-width{
  width: 100%!important;
}

.refund-form-wrapper .w-49{
  width: 49%!important;
}

