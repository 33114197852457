.account-transaction-card .preview-date-field {
  display: flex;
  flex-direction: column;
}
.account-transaction-card .report-header .account-transaction-org-name {
  margin-top: 8px;
}
.account-transaction-card .report-table-section tr th:first-child,
.account-transaction-card .report-table-section tr td:first-child {
  width: 130px !important;
  min-width: 130px;
  white-space: nowrap;
}
.account-transaction-card .report-table-section tr th:nth-child(2),
.account-transaction-card .report-table-section tr td:nth-child(2) {
  max-width: 12%;
}
.account-transaction-card .report-table-section tr th:nth-child(3),
.account-transaction-card .report-table-section tr td:nth-child(3) {
  width: 17% !important;
  min-width: 17%;
  white-space: nowrap;
}
.account-transaction-card .report-table-section tr th:nth-last-child(4),
.account-transaction-card .report-table-section tr td:nth-last-child(4) {
  width: 15%;
  min-width: 15%;
  white-space: nowrap;
}
.account-transaction-card .report-table-section tr th:nth-last-child(3),
.account-transaction-card .report-table-section tr td:nth-last-child(3) {
  width: 12%;
  min-width: 12%;
  white-space: nowrap;
}
.account-transaction-card .report-table-section tr th:nth-last-child(2),
.account-transaction-card .report-table-section tr td:nth-last-child(2) {
  width: 12%;
  min-width: 12%;
  white-space: nowrap;
}
.account-transaction-card .report-table-section tr th:last-child,
.account-transaction-card .report-table-section tr td:last-child {
  width: 13%;
  min-width: 13%;
  white-space: nowrap;
}
.report-table.acc-transaction {
  min-width: 1076px;
}

.report-table.acc-transaction tbody tr.table-navigation.row-navigation {
  cursor: pointer;
}
.report-table.acc-transaction
  tbody
  tr.table-navigation.row-navigation:not(tr.disabled):hover
  td {
  color: var(--cerulean-blue) !important;
}
.back-navigation {
  margin-bottom: 12px;
}
.back-navigation .back-nav-label {
  font-size: 14px;
  font-weight: normal;
  line-height: 10px;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
.back-navigation a {
  text-decoration: none;
  line-height: 30px;
}
.report-table.acc-transaction tbody tr.customRowTitle td {
  font-weight: bold;
  text-align: left;
  background-color: var(--white-smoke);
}
