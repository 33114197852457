.alert-modal .modal-header {
  padding: 24px 24px 12px 24px;
}
.alert-modal .modal-body {
  padding: 12px 46px 12px 24px;
}
.alert-modal .modal-footer {
  padding: 12px 24px 24px 24px;
  border-top: 0;
}
.alert-modal .modal-dialog {
  width: 100% !important;
}
.alert-modal h5 {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

.alert-modal p {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 0;
}
.alert-modal p strong {
  font-weight: bold;
}
.alert-modal .btn-green {
  position: relative;
  width: auto;
  min-width: 124px;
  height: 40px;
  border-radius: 6px;
  background-color: var(--booger);
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white-fill);
  margin: 0;
}

.btn-color-green {
  background-color: var(--booger) !important;
}

.btn-clr-green {
  background-color: var(--booger) !important;
}

.alert-modal .btn-green:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.alert-modal .btn-green:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}
.alert-modal .btn-red {
  width: auto;
  min-width: 124px;
  height: 40px;
  margin: 0 0 0 20px;
  border-radius: 6px;
  background-color: var(--red);
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white-fill);
}

.btn-color-red {
  background-color: var(--red) !important;
}

.btn-clr-red {
  background-color: var(--red) !important;
}
.alert-modal .close-btn {
  top: 6px;
  right: 6px;
}
.alert-modal .btn.cancel-button {
  margin-right: 20px;
}
.alert-modal .btn.cancel-button:hover {
  color: var(--white-fill);
  background-color: var(--booger);
  border: 1px solid var(--booger);
}
.alert-modal .close-btn svg {
  width: 24px;
  height: 24px;
  float: left;
}
.alert-modal .close-btn:focus svg path:last-child {
  fill: var(--red) !important;
}

.void-green-button {
  height: 42px !important;
}

.modal-border-radius {
  border-radius: calc(0.7rem - 1px);
}

.subscription-modal-body .css-1nrlq1o-MuiFormControl-root {
  width: 104%;
}

.subscription-modal-body .pause-date-label {
  font-weight: 400;
}

.subscription-modal-footer {
  justify-content: left;
  margin-top: 14px;
}

.subscription-modal-footer .subscription-save-btn {
  margin-right: 20px;
}

.subscription-update-pricing-modal-footer {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.subscription-update-pricing-modal-footer .subscription-save-btn {
  margin-right: 20px;
}
.void-subscription-margin-container {
  margin-top: 5px;
}
.void-subscription-margin-container .void-subscription-margin {
  margin-bottom: 25px;
}

.void-subscription-margin-container .reactive-subscription-margin {
  margin-bottom: 1rem;
}
.subscription-modal-void-footer {
  margin-top: 0px !important;
}
.subscription-void-cancel-btn {
  background-color: white !important;
  border: 1px solid var(--booger);
  color: var(--booger) !important;
}
.subscription-void-cancel-btn:hover {
  background-color: var(--booger) !important;
  color: white !important;
}
.void-subscription-cancellation-date-include {
  margin-left: 0 !important;
  margin-top: -10px !important;
}
.void-subscription-cancellation-date-include .MuiFormControlLabel-label {
  max-width: 296px !important;
}

.void-subscription-apply-to-unpaid {
  margin-left: 0 !important;
  margin-top: -10px !important;
}
.void-subscription-apply-to-unpaid .MuiFormControlLabel-label {
  max-width: 287.66px !important;
}
.subscription-modal-body {
  padding: 12px 35px 12px 24px !important;
}

.subscription-cancel-select-account .css-108tvy7-MenuList {
  max-height: 120px !important;
}

.subscription_cancel_modal_position .relative-wrapper {
  position: static !important;
}

.subscription_cancel_modal_position .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey) !important;
}

.edit-next-invoice-date-modal .edit-next-invoice-date-show-date {
  font-weight: 400;
  margin-top: 7px;
}

.edit-next-invoice-date-modal .edit-next-invoice-invoicing-option {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
}

.edit-next-invoice-date-modal .MuiFormControlLabel-label {
  color: #323e48 !important;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
}

.edit-next-invoicing-date-edit-breakdown button {
  padding-left: 0 !important;
}
