.customer-detail-card .invoice-actions-wrapper {
  margin-bottom: 36px;
}
.customer-detail-card .pills-tab {
  padding: 0;
  border-bottom: 2px solid var(--warm-white);
  background-color: var(--white-fill);
}
.customer-detail-card .pills-tab .nav-item .nav-link {
  padding: 8px 35px;
}
.customer-detail-card .pills-tab .nav-item:first-child .nav-link {
  padding: 8px 12px;
}
.customer-detail-card .pills-tab .nav-link {
  position: relative;
  color: var(--battleship-grey);
  margin: 0;
  height: auto;
  min-width: auto;
}
.customer-detail-card .pills-tab .nav-link.active,
.customer-detail-card .pills-tab .nav-link:hover,
.customer-detail-card .pills-tab .nav-link:focus {
  background: transparent;
  color: var(--cerulean-blue);
}
.customer-detail-card .pills-tab .nav-link:focus {
  opacity: 1 !important;
}
.customer-detail-card .pills-tab .nav-link.active:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: var(--cerulean-blue);
}
.customer-detail-card .tab-content {
  padding: 30px 0;
}
.customer-detail-card .filter-wrapper {
  width: 49.7758%;
  justify-content: flex-start !important;
}
.customer-detail-card .filter-col {
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.customer-detail-card .filter-wrapper .filter-item {
  width: 46.8127% !important;
  margin-right: 12px !important;
}
.customer-detail-card .filter-wrapper .filter-item:last-child {
  margin-right: 0 !important;
}
.customer-detail-card .filter-wrapper .actions-wrap {
  max-width: 400px !important;
  margin-left: auto;
}
.customer-detail-card .filter-wrapper .actions-wrap a {
  margin-right: 20px;
}
.customer-detail-card .card-dashboard {
  margin-top: 0 !important;
}
.customer-detail-card .card-dashboard .report-body,
.customer-detail-card .tab-content-overview {
  padding: 30px 32px 32px 32px !important;
}
.customer-detail-card .card-dashboard .report-body .report-section {
  border: 0 !important;
}
.customer-detail-card .card-dashboard .report-body .report-section:before {
  display: none;
}
.customer-detail-card .report-body .report-section .report-body-links {
  display: none;
}
.customer-detail-card .report-body .report-section .report-header,
.customer-detail-card .report-body .report-section .report-table-section {
  padding: 0 !important;
}
.customer-detail-card .report-body .report-body-links {
  padding: 0 0 16px 0;
}
.customer-detail-card .report-body .filter-box {
  padding: 0 0 15px 0;
}
.customer-detail-card .report-table-section .table-wrapper {
  margin-bottom: 0;
}

.customer-detail-card .tab-content-overview {
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  padding-bottom: 0 !important;
}
.customer-detail-card .tab-content-overview.purchase-sales-info {
  margin-bottom: 30px;
}
.customer-detail-card
  .tab-content-overview.purchase-sales-info
  .outstanding-red
  span {
  color: var(--red);
}
.customer-detail-card
  .tab-content-overview.purchase-sales-info
  .outstanding-green
  span {
  color: var(--apple);
}
.customer-detail-card .tab-content-overview-without-border {
  padding: 30px 32px 32px 32px !important;
  background-color: var(--white-fill);
  padding-bottom: 0 !important;
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}


.overview-wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.section-blocks {
  width: 100%;
  padding: 24px 0;
  margin: 0;
  border-top: 1px solid var(--approx-whisper);
}
.section-blocks:first-child {
  padding-top: 0;
  border-top: 0;
}
.section-blocks:last-child {
  padding-bottom: 0;
}
.tab-content-wrapper .section-blocks h2,
.tab-content-wrapper .section-blocks h2 span {
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tab-content-wrapper .section-blocks h2 {
  margin-bottom: 15px;
}
.tab-content-wrapper .section-blocks.no-contact {
  padding-bottom: 17px;
}
.tab-content-wrapper .section-blocks h2 span {
  display: inline-block;
  margin-left: 5px;
}
.tab-content-wrapper .section-blocks h2 img {
  width: 24px;
  height: auto !important;
  margin: 0 10px 0 0;
  object-fit: contain;
  float: left;
}
.tab-content-wrapper .section-blocks .section-content-wrapper {
  width: 100%;
  display: block;
  display: flex;
  padding-bottom: 30px;
}
.vendor-detail-card .tab-content-wrapper .section-blocks .section-content-wrapper {
  display: flex;
} 
.tab-content-wrapper .section-blocks .address-wrapper .address-container {
  display: inline-block;
  position: relative;
  /* width: auto;
  min-width: 270px; 
  max-width: 50%;  */  
  /* max-width: calc(51% - 1px); */
  width: 50%;
  flex: 1;
}
.tab-content-wrapper .section-blocks .address-wrapper .address-container .address-box-wrapper {
  width: 47%;
  min-width: 200px;
  max-width: 250px;  
  flex: 1;
}
.tab-content-wrapper .section-blocks .address-wrapper .address-container .boxed-item.address-item {
  width: 100%;
}

.vendor-detail-tab-container .tab-content-wrapper .section-blocks .address-wrapper .address-container {
  max-width: 50%;
  padding-right: 20px;
}
/* .tab-content-wrapper .billing-address-container .boxed-item.address-item, */
.tab-content-wrapper .billing-address-container .address-box-wrapper {
  margin: 0 26px 26px 0;
}
/* .tab-content-wrapper .shipping-address-container .boxed-item.address-item, */
.tab-content-wrapper .shipping-address-container .address-box-wrapper {
  margin: 0 0 26px 26px;
}
.tab-content-wrapper .section-blocks .address-wrapper .billing-address-container:after {
  border-right: 1px solid var(--approx-whisper);
  content: "";
  height: calc(100% - 90px);
  position: absolute;
  right: 0;
  top: 34px;
  width: 1px;
}
.tab-content-wrapper .section-blocks .address-wrapper .address-box-container {
  flex-wrap: wrap;
  clear: both;
  width: 100%;
  /* justify-content: space-between; */
}
.tab-content-wrapper .address-wrapper .address-container h3 {
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-bottom: 16px;
  clear: both;
}
.tab-content-wrapper .address-wrapper .shipping-address-container h3 {
  padding-left: 30px;
}
.tab-content-wrapper .address-wrapper .address-container p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tab-content-wrapper .section-blocks .field-row {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 16px;
}
.tab-content-wrapper .section-blocks .field-row:last-child {
  margin-bottom: 0;
}
.tab-content-wrapper .section-blocks .field-row .field-title {
  width: 262px;
  margin-right: 100px;
}
.tab-content-wrapper .section-blocks .field-row span {
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tab-content-wrapper .section-blocks .field-row .field-title span {
  font-weight: 500;
}
.tab-content-wrapper .section-blocks .field-row .field-value span {
  font-weight: bold;
}
.tab-content-wrapper .section-blocks .section-content-wrapper.other-wrapper {
  display: block;
}
.tab-content-wrapper .contacts-wrapper {
  display: flex;
}
.tab-content-wrapper .contact-box {
  width: 262px;
  margin-right: 100px !important;
}
.tab-content-wrapper .contact-box span {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tab-content-wrapper .contact-box > span {
  display: inline-block;
  width: 100%;
  clear: both;
  line-height: 16px;
  margin-bottom: 8px;
}
.tab-content-wrapper .contact-box > span:last-child {
  margin-bottom: 0;
}
.tab-content-wrapper .contact-box h3 {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tab-content-wrapper .contact-box h3 span {
  font-weight: 500;
  margin-left: 5px;
}
.tab-content-wrapper .contact-box span img {
  width: 16px;
  height: auto;
  float: left;
  margin-right: 8px;
}
.tab-content-wrapper .contacts-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.tab-content-wrapper .contact-box {
  width: 262px;
  margin-right: 100px !important;
  padding-bottom: 32px;
}
.billing-address-container .boxed-item.address-item {
  margin: 0 20px 20px 0;
  padding: 16px 14px 16px 16px;
}
.shipping-address-container .boxed-item.address-item {
  margin: 0 0 20px 20px;
  padding: 16px 14px 16px 16px;
}
.address-wrapper .address-box-container .boxed-item h5 {
  position: relative;
  display: flex;
  width: 145px;
  left: -16px;
  align-items: center;
  border-radius: 0 4px 4px 0;
  background-color: var(--solitude);
  padding: 1px 14px 1px 16px;
}
.address-wrapper .address-box-container .boxed-item h5 img {
  width: 12px;
  height: auto !important;
  margin-right: 8px;
}
.address-wrapper .address-box-container .boxed-item h5 span {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.69;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.add-new.addnew-address {
  margin: 0 !important;
  margin-bottom: 32px;
}
.add-new.addnew-address.addnew-shipping-address {
  left: 20px;
}
.tab-content-wrapper .addr-section .address-wrapper .address-container p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.tab-content-wrapper .section-blocks .address-wrapper .address-container .add-new.addnew-address {
  position: relative;
  top: -10px;
}
.tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container .add-new.addnew-address {
  left: 30px;
}

/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}


@media (min-width: 768px) and (max-width: 991px) {
  .tab-content-wrapper .section-blocks .field-row .field-title {
    width: 182px;
    margin-right: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
    
}
@media (min-width: 768px) and (max-width: 1024px) {
  .tab-content-wrapper .section-blocks .section-content-wrapper {
    flex-direction: column;
  }
  .tab-content-wrapper .section-blocks .address-wrapper .address-container {
    width: 100%;
  }
  .tab-content-wrapper .section-blocks .address-wrapper .billing-address-container:after {
    display: none;
  }
  .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container {
    position: relative;
    margin-top: 20px;
  }
  /* .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container:before {
    border-top: 1px solid var(--approx-whisper);
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    right: auto;
    left: 0;
    top: -15px;    
  } */
  .tab-content-wrapper .shipping-address-container .address-box-wrapper {
    margin: 0 26px 26px 0;
  }
  .tab-content-wrapper .address-wrapper .shipping-address-container h3 {
    padding-left: 0;
  }
  .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container .add-new.addnew-address {
    left: 0;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
}