.address-container {
    flex-wrap: wrap;
    clear: both;
    width: 100%;
    justify-content: space-between;
}
.boxed-addresses .billing-address:after {
    display: none;
    top: 43px !important;
    height: calc(100% - 62px);
}
.billing-address:after {
    top: auto;
    bottom: 0;
}
.boxed-addresses .billing-address:after {
    display: none;
}

.boxed-addresses .billing-address,
.boxed-addresses .shipping-address {
    display: flex;
    flex-direction: column;
}
.boxed-addresses .address-container-wrapper {
    width: 100%;
    clear: both;
    height: 100%;
    position: relative;
}
.boxed-addresses .address-container-wrapper:after {
    border-right: 1px solid var(--approx-whisper);
    content: "";
    height: calc(100% - 20px);
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
}
.boxed-addresses .shipping-address .address-container-wrapper:after {
    display: none;
}
.boxed-addresses .billing-address,
.boxed-addresses .shipping-address {
    display: inline-block;
    /* width: auto;  
    min-width: 270px; */
    width: 50%;
    flex: 1;
}
.boxed-addresses .address-tab-pane h2 {
    margin-right: 0;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .boxed-addresses  > .row > .col-lg-12 > .row {
        flex-direction: column;
    }
    .boxed-addresses .billing-address, 
    .boxed-addresses .shipping-address {
        width: 100% !important;
        flex: 0 !important;
    }  
    .boxed-addresses .address-container {
        justify-content: flex-start;
    }
    .boxed-addresses .address-container-wrapper:after {
        display: none;
    }
    .boxed-addresses .shipping-address {
        position: relative;
    }  
    .boxed-addresses .billing-address {
        margin-bottom: 20px !important;
    }
    .boxed-addresses .shipping-address:before {
        border-top: 1px solid var(--approx-whisper);
        content: "";
        height: 1px;
        position: absolute;
        right: auto;
        left: 0;
        top: -14px;
        width: 100%;
    }
    .shipping-address .boxed-item.address-item {
        margin: 0 16px 16px 0;
    }
}