.transactions-accordion {
  width: 100%;
}
.transactions-accordion .accordion-item {
  width: 100%;
  padding: 0;
  border-radius: 8px;
  -webkit-box-shadow: 0 8px 16px -2px var(--black-shadow3);
  box-shadow: 0 8px 16px -2px var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  margin-bottom: 16px;
  float: left;
}
.transactions-accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.transactions-accordion .accordion-item h2 {
  padding: 0 16px;
}
.transactions-accordion .accordion-item h2 button {
  width: calc(100% - 94px);
  background-color: var(--white-fill);
  border-radius: 0;
  padding: 17px 0 14px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  outline: 0;
  box-shadow: none;
  float: left;
}
.transactions-accordion .accordion-item h2 button:after {
  display: none;
}
.transactions-accordion .accordion-item .accord-item-content {
  clear: both;
  border-top: 1px solidvar(--approx-whisper);
}
.transactions-accordion .accordion-item .accord-item-content .accordion-body {
  padding: 0;
}
.transactions-accordion .accordion-item .pagination-container {
  width: 100%;
}
.transactions-accordion .accordion-item .pagination-wrapper {
  padding: 16px;
}
.transactions-accordion #invoice_list_table {
  table-layout: auto !important;
}
/* .transactions-accordion #invoice_list_table tr th:first-child,
.transactions-accordion #invoice_list_table tr td:first-child {
  padding-left: 48px !important;
}
.transactions-accordion #invoice_list_table tr th:nth-child(3),
.transactions-accordion #invoice_list_table tr td:nth-child(3) {
  max-width: 210px;
} */
.transactions-accordion #invoice_list_table tr th:last-child,
.transactions-accordion #invoice_list_table tr td:last-child {
  padding-right: 16px !important;
}
.transactions-accordion #invoice_list_table tr th:last-child,
.transactions-accordion #invoice_list_table tr td:last-child,
.transactions-accordion #invoice_list_table tr td:last-child span {
  text-align: right !important;
}
.transactions-accordion #invoice_list_table tr td:last-child span {
  float: right;
}
.transactions-accordion .list-table tr:last-child td:last-child,
.transactions-accordion .list-table tr:last-child td:first-child {
  border-radius: 0 !important;
}
.transactions-accordion #invoice_list_table tr th,
.transactions-accordion #invoice_list_table tr td {
  width: auto !important; 
}
.transactions-accordion #invoice_list_table tr th.dt_status,
.transactions-accordion #invoice_list_table tr td.dt_status,
.transactions-accordion #invoice_list_table tr th.inv_status,
.transactions-accordion #invoice_list_table tr td.inv_status,
.transactions-accordion #invoice_list_table tr th.cr_status,
.transactions-accordion #invoice_list_table tr td.cr_status,
.transactions-accordion #invoice_list_table tr th.pay_status,
.transactions-accordion #invoice_list_table tr td.pay_status {
  min-width: 100px !important;
}

.transactions-accordion #invoice_list_table tr td.tags .tag-box {
  margin: 0 0 8px 7px !important;
}
.transactions-accordion #invoice_list_table tr td .pills-wrapper {
  width: auto;  
  white-space: nowrap;
}
.transactions-accordion #invoice_list_table tr td:last-child .pills-wrapper { 
  float: right;
}
.transactions-accordion #invoice_list_table tr td.tags,

.plus-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
.plus-icon .horizontal-line {
  width: 12px;
  height: 2px;
  background-color: var(--charcoal-grey);
  top: 50%;
  left: 4px;
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  opacity: 1;
}
.plus-icon .vertical-line {
  width: 12px;
  height: 2px;
  background-color: var(--black);
  left: 4px;
  top: 50%;
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  opacity: 0;
}
.transactions-accordion
  .accordion-item
  h2
  button.collapsed
  .plus-icon
  .horizontal-line {
  background-color: var(--charcoal-grey);
  transform: rotate(0deg);
}
.transactions-accordion
  .accordion-item
  h2
  button.collapsed
  .plus-icon
  .vertical-line {
  background-color: var(--charcoal-grey);
  transform: rotate(-90deg);
  opacity: 1;
}
.green-text {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--booger);
  text-decoration: none;
}
.add-new-item {
  display: block;
  width: 94px;
  float: right;
  margin-top: 17px;
}
.green-text img {
  width: 20px;
  height: 20px;
  margin-top: -5px;
  margin-right: 10px;
}
.green-text:hover {
  color: var(--booger);
}
.green-text:focus {
  opacity: 0.8;
}
/* .transactions-accordion #invoice_list_table tr th,
.transactions-accordion #invoice_list_table tr td {
  width: auto !important;
  max-width: none !important;
}
.transactions-accordion
  #invoice_list_table.PR-Overview-table
  thead
  th:first-child,
.transactions-accordion #invoice_list_table.PR-Overview-table tr td:first-child,
.transactions-accordion
  #invoice_list_table.PM-Overview-table
  thead
  th:first-child,
.transactions-accordion
  #invoice_list_table.PM-Overview-table
  tr
  td:first-child {
  width: 29% !important;
} */
/* .transactions-accordion
  #invoice_list_table.PR-Overview-table
  thead
  th:nth-child(3),
.transactions-accordion
  #invoice_list_table.PR-Overview-table
  tr
  td:nth-child(3), */
/* .transactions-accordion
  #invoice_list_table.PM-Overview-table
  thead
  th:nth-child(3),
.transactions-accordion
  #invoice_list_table.PM-Overview-table
  tr
  td:nth-child(3) {
  width: 160px !important;
} */
/* .transactions-accordion
  #invoice_list_table.PR-Overview-table
  thead
  th:last-child,
.transactions-accordion #invoice_list_table.PR-Overview-table tr td:last-child,
.transactions-accordion
  #invoice_list_table.PM-Overview-table
  thead
  th:last-child,
.transactions-accordion #invoice_list_table.PM-Overview-table tr td:last-child {
  width: 24% !important;
} */
.transactions-accordion #invoice_list_table.PR-Overview-table,
.transactions-accordion #invoice_list_table.PM-Overview-table {
  min-width: 100% !important;
}
.transactions-accordion .accordion-item .accord-item-content .accordion-body .no-list-item {
  border-top: 1px solid var(--approx-whisper);
}