.search-result {
  font-size: 15px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

.card-search-results .filter-block {
  width: 100%;
  height: 88px;
  margin: 0 0 24px 0;
  padding: 21px;
  border-radius: 12px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #f2f2f2;
  background-color: var(--white-fill);
}
.card-search-results .results-block {
  width: 100%;
}
.results-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #f2f2f2;
  background-color: var(--white-fill);
}
.card-search-results .results-block .table-info {
  padding: 0 20px 20px 19px;
}
.results-list {
  width: 100%;
  padding: 0;
}
.results-list ul {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
.card-search-results .results-list li {
  width: 100%;
  border-bottom: 1px solid #eee;
  float: left;
}
.card-search-results .results-list li:last-child {
  border-bottom: 0;
}
.card-search-results .results-list li .search-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  float: left;
  padding: 16px 20px 18px 20px;
}
.card-search-results .results-list li .search-item:hover,
.card-search-results .results-list li .search-item:focus {
  background-color: #e9e9e9;
}
.card-search-results .results-list li .search-item .bread-crumbs {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: var(--slate);
  margin-bottom: 16px;
}
.card-search-results .search-item .item-summary {
  height: 100%;
  min-height: 24px;
  margin-bottom: 2px;
  overflow: hidden;
}
.card-search-results .search-item .item-summary.fixed-height {
  height: 24px;
}
.card-search-results .search-item .item-summary span {
  display: inline-block;
  position: relative;
  height: 13px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding: 0 4px 0 8px;
}
.card-search-results .search-item .item-summary .verticals-item {
  padding: 0 4px 0 0px !important;
  margin-right: 8px;
}
.card-search-results .search-item .item-summary .verticals-item > span {
  padding: 0 5px 0 0px !important;
}
.card-search-results .search-item .item-summary span:first-child {
  padding-left: 0;
}
.search-item .item-summary .status.blue {
  color: #a879d5;
}
.search-item .item-summary .status.red {
  color: var(--red);
}
.search-item .item-summary .status.deepgreen {
  color: var(--deep-green);
}
.search-item .item-summary .status.blue {
  color: #a879d5;
}
.card-search-results .search-item .item-summary .verticals-item {
  margin-bottom: 8px;
}
.card-search-results .search-item .item-summary .verticals-item:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
  border-right: solid 1px var(--slate);
}
.card-search-results
  .search-item
  .item-summary
  .verticals-item:last-child:after {
  display: none;
}
.card-search-results .search-item span {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.card-search-results .search-item .label {
  font-weight: normal;
  margin-right: 5px;
}
.card-search-results .search-item .value,
.card-search-results .search-item .highlight {
  font-weight: bold;
}
.results-footer-wrapper {
  width: 100%;
  padding: 20px 20px 20px 19px;
  float: left;
}
.card-search-results .search-item .btn-wrap {
  margin-bottom: -6px;
}

.card-search-results .search-item .search-data {
  margin-bottom: 16px;
}
/* .verticals-item {
  margin-bottom: 10px;
} */
.search-result-details {
  width: 100%;
}
.search-result-details span {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.matched-query-string {
  font-size: 15px;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
}
@media (max-width: 767px) {
  .card-search-results .filter-block {
    padding: 20px 15px;
  }
  .global-search-field.search-page .MuiAutocomplete-root .MuiInputBase-input {
    width: calc(100% - 76px) !important;
  }
}
