.fiscal-year-closing-header-wrapper .lock-item-list li {
  width: 100%;
  border-radius: 8px;
  -webkit-box-shadow: 0 8px 16px -2px var(--black-shadow3);
  box-shadow: 0 8px 16px -2px var(--black-shadow3);
  border: solid 1px var(--tinted-grey);
  background-color: var(--white-fill);
  margin-bottom: 30px;
}
.fiscal-year-closing-header-wrapper .lock-item-list li:last-child {
  margin-bottom: 0;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .item-wrapper {
  padding: 20px;
  min-height: 120px;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .left-sect {
  display: flex;
  align-content: center;
  width: calc(100% - 128px);
  padding-right: 10px;
}
.fiscal-year-closing-header-wrapper .v-top {
  align-content: normal !important;
  align-items: flex-start !important;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .left-sect img {
  width: 60px;
  height: auto;
  margin-right: 16px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect {
  display: flex;
  width: calc(100% - 76px);
  min-height: 60px;
  align-content: center;
  flex-wrap: wrap;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  .details-wrapper {
  position: relative;
  width: 100%;
  clear: both;
  float: left;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  .details-wrapper
  + .details-wrapper {
  margin-top: 22px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  .details-wrapper
  + .details-wrapper:before {
  content: "";
  position: absolute;
  width: 95%;
  left: 0;
  right: 0;
  top: -11px;
  height: 1px;
  border-top: 1px solid var(--approx-whisper);
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  h5 {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  p,
.fiscal-year-closing-header-wrapper
  .unlock-all-transacts
  .more-detail-section
  p {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin: 0;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  p
  .date,
.fiscal-year-closing-header-wrapper
  .unlock-all-transacts
  .more-detail-section
  p
  .date {
  font-weight: bold;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .left-sect
  .item-det-sect
  .reason,
.fiscal-year-closing-header-wrapper
  .unlock-all-transacts
  .more-detail-section
  .reason {
  font-weight: 600;
  color: var(--charcoal-grey);
}
.fiscal-year-closing-header-wrapper .lock-item-list li .btn-sect {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 165px;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .btn-sect-cancelling {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  min-width: 197px;
  max-width: 227px;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .btn-sect button {
  margin-left: auto;
  height: 40px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  button.lock-button {
  min-width: 165px;
  padding-left: 10px;
  padding-right: 10px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  button
  + button,
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  + button {
  margin-top: 10px;
}
.fiscal-year-closing-header-wrapper .lock-item-list li .btn-sect .dropdown {
  margin-left: auto;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  > button {
  height: 40px;
  position: relative !important;
  padding: 11px 39px 9px 17px;
  border-radius: 6px;
  background-color: var(--booger);
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  > button:after {
  content: "";
  position: absolute;
  border: 0;
  width: 20px;
  height: 20px;
  background: url("../../../../assets/images/arrow_dropdown.svg") no-repeat right
    center;
  background-size: 20px 20px;
  margin: 0;
  right: 8px;
  top: 50%;
  margin-top: -10px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  .dropdown-menu {
  min-width: 154px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  right: 0 !important;
  left: auto !important;
  inset: auto 0 auto auto !important;
  transform: translate3d(0px, 45px, 0px) !important;
  padding: 8px 0 7px 0 !important;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  .dropdown-menu
  li {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  .dropdown-menu
  button {
  padding: 11px 16px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  .dropdown-menu
  button:hover,
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .dropdown
  .dropdown-menu
  button:focus {
  color: var(--cerulean-blue);
}

.fiscal-year-closing-header-wrapper .lock-item-list li .btn-sect button svg {
  margin-top: -3px;
  margin-right: 6px;
}
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .edit-button
  img,
.fiscal-year-closing-header-wrapper
  .lock-item-list
  li
  .btn-sect
  .edit-button
  svg {
  margin-top: -4px;
}
.fiscal-year-closing-header-wrapper
  .card-content-wrapper-fiscal-year
  .list-style-none {
  list-style-type: none;
}
.fiscal-year-closing-header-wrapper .main-card {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  border: 0;
  margin: 35px auto 40px auto;
}
