.reusable-text-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.reusable-text-button:focus {
  border-radius: 5px;
  border: none;
  box-shadow: none;
}
.reusable-text-button:focus-visible {
  border-radius: 5px;
  border: none;
  box-shadow: none;
}
