/** modal styles **/

/** Apply Credits modal style **/
.cd-modal .modal-header {
  padding: 0;
}
.cd-modal .modal-content {
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(---black-shadow3);
  box-shadow: 0 8px 20px 0 var(---black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
#applyCreditModal .modal-dialog {
  max-width: 800px;
}
.applyCreditModal .modal-dialog {
  max-width: 800px;
}
#applyCreditModal .modal-content {
  padding: 30px 40px;
}
.applyCreditModal .modal-content {
  padding: 30px 40px;
}
.cd-modal .modal-body {
  padding: 0;
  overflow-x: hidden;
}
.cd-modal .allocation-button-wrapper {
  justify-content: flex-start;
  padding: 30px 0 0 0;
  border-top: 0;
  border-radius: 0 !important;
}
.cd-modal .allocation-button-wrapper button {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.cd-modal .modal-header {
  position: relative;
}
.cd-modal .modal-header .close-btn {
  position: absolute;
  top: -23px;
  right: -36px;
}
/* .cd-modal .modal-header .close-btn img {
  float: left;
  width: 100%;
} */
.cd-modal .modal-header h5 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 30px;
  text-align: left;
}
.cd-modal .balance-amt {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-bottom: 20px;
  text-align: left;
}
.content-wrapper {
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
}
.cd-modal table {
  border: 0;
  margin: 0 auto;
}
.cd-modal table tr {
  height: auto !important;
}
.cd-modal table th {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  box-shadow: none !important;
  text-transform: uppercase;
  border-top: 0 !important;
  border-bottom-color: var(--approx-whisper) !important;
}
.cd-modal table thead {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.cd-modal table td {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
  vertical-align: middle;
}
.apply-creds-table th,
.apply-creds-table td {
  padding: 12px 14px 11px 14px !important;
  white-space: nowrap;
}
.cd-modal .apply-creds-table input {
  width: 100%;
  height: 33px !important;
  padding: 5px 8px 4px 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0 6px 6px 0 !important;
  /* border: solid 1px var(--new-whisper); */
  border: 0 !important;
  background-color: var(--white-fill);
}
.cd-modal .apply-creds-table .inv-group {
  border: solid 1px var(--new-whisper);
  border-radius: 6px;
}
/* .cd-modal .apply-creds-table input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.cd-modal .apply-creds-table input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.cd-modal .apply-creds-table input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.cd-modal .apply-creds-table input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
.amt-summary .amt-grid {
  min-width: 317px;
  padding: 10px 0;
}
.amt-summary .amt-grid .row {
  padding: 12px 0;
}
.amt-summary .item-column {
  width: 164px;
  padding: 0 5px 0 14px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey);
  text-align: left;
  white-space: nowrap;
}
.amt-summary .amt-column {
  width: calc(100% - 164px);
  min-width: 153px;
  padding: 0 14px 0 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  white-space: nowrap;
}
.amt-summary .negative-amt {
  color: var(--red) !important;
}

/* invoice credited */
.cd-list-table th,
.cd-list-table td {
  padding: 12px 14px 11px 14px !important;
}
#creditedListModal .modal-dialog {
  max-width: 600px;
}
#creditedListModal .modal-content {
  padding: 30px 40px 40px 40px;
}
.cd-list-table tr th:last-child,
.cd-list-table tr td:last-child {
  width: 146px;
}

.table-section .table tbody td .item-bold {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left;
  float: left;
}

#form-default-section input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}

.cd-modal .inv-prefix {
  padding: 0 6px !important;
  height: 40px !important;
  line-height: 40px;
  border: 0 !important;
}
.apply-creds-table .inv-group {
  width: 165px !important;
}
.cd-modal .modal-body .table-wrapper {
  overflow: hidden;
  overflow-x: auto;
}
#creditedListModal tr.table-navigation:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
  cursor: pointer !important;
}
#applyCreditModal tr.table-navigation:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
  cursor: pointer !important;
}
.applyCreditModal tr.table-navigation:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
  cursor: pointer !important;
}
.applyCreditModal .modal-content .modal-body .content-wrapper {
  max-height: calc(100vh - 500px);
  overflow: hidden;
  overflow-y: auto;
}
.cd-modal .apply-creds-table td.allocation-date {
  width: 200px;
  min-width: 200px;
}
.cd-modal .apply-creds-table .allocation_date input {
  width: 110px !important;
  height: auto !important;
  border-radius: 0;
}
.cd-modal .apply-creds-table .date-container .MuiFormControl-root .MuiInputBase-formControl {
  background: #fff;
}
/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  #applyCreditModal .modal-dialog {
    max-width: 700px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .applyCreditModal .modal-dialog {
    max-width: 700px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
