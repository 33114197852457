.confirmation-message-modal .confirmation-modal-card {
    max-width: 500px;
    min-width: 470px;
    margin: 0px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    padding: 10px 15px 32px 32px !important;
    background-color: var(--white-fill);
    border-radius: 12px;
    border: solid 1px var(--warm-white);
}
.confirmation-message-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    min-width: 470px;
    max-width: 500px;
    border: solid 1px var(--warm-white);
    box-shadow: none
}

.confirmation-message-modal .confirmation-modal-card .card-header {
    margin-bottom: 12px;
}

.confirmation-message-modal .confirmation-modal-card .confirmation-modal-card-header-wrapper {
    width: 100% !important;
}
.confirmation-modal-card .confirmation-modal-card-header-wrapper .justify-content-center {
    width: 100% !important;
}
.confirmation-modal-card .confirmation-modal-card-header-wrapper .justify-content-center h1  {
    width: 100% !important;
}
.confirmation-message-modal .form-button-wrapper {
    border: 0px;
    padding-left: 4px !important;
    margin-top: 20px;
    height: 70px;
}
.confirmation-modal-card-header-wrapper .popover-close-button .close-btn {
    float: right;
}
.confirmation-modal-card .card-body{
    margin-right: 17px;
}

  .confirmation-message-modal .custom-tooltip-wrapper {
    float: right;
    margin-right: 2px;
  }
  
  .confirmation-message-modal .custom-tooltip-wrapper .info-btn {
    left: 0;
  }

  .p-r-l-20 {
   padding: 10px 0px 0px 16px;
  }

 .confirmation-message-modal .header {
    font-size: 16px;
  }

  .confirmation-message-modal .confirmation-modal-card .confirmation-modal-card-header-wrapper {
    padding-bottom: 0px !important;
  }
  .confirmation-message-modal  .form-button-wrapper {
    margin-top: 0px;
    height: 70px;
}

.confirmation-message-modal .save-button,
.confirmation-message-modal .cancel-button{
    height: 40px;
}