.no-search-result-wrapper.popper {
  width: 100%;
  height: 335px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #f2f2f2;
  background-color: var(--white-fill);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-search-result-wrapper.page {
  width: 100%;
  height: 500px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-search-result-icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  background-color: rgba(116, 125, 132, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-search-result-message-title {
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 10px 0px;
}
.no-search-result-wrapper.popper .no-search-result-message {
  width: 200px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--battleship-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-search-result-wrapper.page .no-search-result-message {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--battleship-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
