#addAddressModal {
  z-index: 1330 !important;
}
#addAddressModal .user-form-head h4 {
  margin-bottom: 24px !important;
}
.right-partial-modal {
  width: 480px;
  right: 0 !important;
  left: auto !important;
}
#same_as_billing {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
}
#addAddressModal form .form-check-label {
  font-weight: 600;
  line-height: 19px;
}
#addAddressModal .form-outline {
  margin-bottom: 24px !important;
}
#addAddressModal .form-field-container {
  clear: both;
  display: flex;
  flex-direction: column;
}
#addAddressModal .form-field-container .form-outline:last-child {
  margin: 0 !important;
}
#addAddressModal .form-button-wrapper {
  clear: both;
  width: 100%;
  float: left;
  margin-top: 0;
  padding: 30px 0 30px 0;
  border-top: 0 !important;
}
.add-address-backdrop.custom-backdrop.show {
  opacity: 0.49;
}
.invoice-create .add-address-backdrop.custom-backdrop.show {
  opacity: 0;
}
#addCustomerModal .billing-address.mw-545 {
  max-width: 272px !important;
}
.right-partial-modal input:focus {
  outline: 0;
  box-shadow: none;
}
#addAddressModal
  form
  input[type="text"]:not(#addAddressModal form .form-select input),
#addAddressModal form input[type="number"] {
  height: 41px !important;
}
#addAddressModal form input.form-check-input {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
}
#addAddressModal form input.form-check-input[type=checkbox] {
  border-radius: 2px !important;
}
#addAddressModal form .form-select {
  margin-top: 12px !important;
}
