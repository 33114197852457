.write-off-form-wrapper {
  width: 430px;
  height: fit-content;
  padding: 0px 40px 40px 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  background-color: var(--white-fill);
}
.write-off-form-button-wrap-cancel-write-off {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.write-off-form-button-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
}
.write-off-form-button-wrap .save-button {
  margin-right: 30px !important;
}
.write-off-form-wrapper button {
  margin: 0px !important;
}
.write-off-form-wrapper .search-dropdown {
  width: 100% !important;
}
.write-off-form-wrapper .accounts-dropdown .dropdown-menu a span {
  text-align: left;
}
.form-control-account-select.MuiFormControl-root {
  margin-bottom: 20px;
}
.form-control-account-select label {
  width: fit-content;
}
.write-off-amount-disabled {
  background-color: var(--warm-white) !important;
}
.write-off-amount {
  margin-bottom: 20px;
}
