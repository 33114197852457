.invoice-actions-wrapper {
    margin-left: 36px;
    
    margin-bottom: 30px;
  }
  
  .invoice-actions-wrapper .left-group,
  .invoice-number-label {
    
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: var(--battleship-grey);
    letter-spacing: normal;
  }
  .invoice-actions-wrapper .left-group {
    display: flex;
  }
  .invoice-actions-wrapper .left-group .biller-label {
    max-width: 300px;
  }
  .invoice-actions-wrapper .left-group .bill-date {
    white-space: nowrap;
  }

  .invoice-actions-wrapper .plan-left-group {
    display: grid;
    width: 60%;
  }

  .invoice-actions-wrapper .left-group .plan-description-label {
   padding-top: 4px;
  }
  .invoice-actions-wrapper .left-group .plan-description {
    align-content: center;
    display: grid;
    position: relative;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    padding: 8.4px 0px;
  }
  .invoice-actions-wrapper .addon-left-group {
    display: grid;
    width: 60%;
  }
  .invoice-actions-wrapper .left-group .addon-description-label {
    padding-top: 4px;
   }
   .invoice-actions-wrapper .left-group .addon-description {
     align-content: center;
     display: grid;
     position: relative;
     font-size: 14px;
     font-weight: normal;
     letter-spacing: normal;
     color: var(--charcoal-grey);
     padding: 8.4px 0px;
   }
  .invoice-actions-wrapper .actions-wrap a img {
    width: 20px;
    height: 20px;
  }
 
  .invoice-actions-wrapper .actions-wrap a svg {
    width: 20px !important;
    height: 20px !important;
  }
  
  .invoice-actions-wrapper .actions-wrap img {
    margin-right: 2px;
    vertical-align: text-bottom;
  }

  .invoice-actions-wrapper .actions-wrap svg {
    vertical-align: text-bottom;
  }

  .detail-pg-actions-wrapper .actions-wrap {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -6px;
  }
  .detail-pg-actions-wrapper .actions-wrap .ri a {
    color: gray !important;
    cursor: pointer;
  }
  .detail-pg-actions-wrapper .actions-wrap a {
    margin-left: 30px; 
    height: 30px;
    font-size: 15px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: normal;
    color: var(--cerulean-blue) !important;
    cursor: pointer;
    margin-bottom: 0;
  }
  .detail-pg-actions-wrapper .actions-wrap a:first-child {
    margin-left: 10px;
  }
  .detail-pg-actions-wrapper .actions-wrap a:hover {
    opacity: 0.8;
  }
 
  .detail-pg-actions-wrapper .actions-wrap a.delete-item {
    margin-right: 0 !important;
    color: var(--red) !important;
  }
  .action-top-buttons-wrapper {
    width: auto;
    display: flex;
    align-items: center;
  }
  .card-header .info-tag {
    top: -4px;
    margin-left: 13px;
  }
  .biller-label.clickable {
    text-decoration: none;
    color: var(--cerulean-blue);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .invoice-actions-wrapper .left-group {
      max-width: 220px;
    } 
    /* .action-top-buttons-wrapper {
      flex-direction: column;
      order: 2;
      margin-top: 14px;
    } 
    .action-top-buttons-wrapper a {
      width: 100%;
      margin-left: 0 !important;
      margin-bottom: 14px !important;
      float: right;
      text-align: right;
    }  */
   
    .action-top-buttons-wrapper a {
      margin-left: 14px !important;      
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {    
    .invoice-actions-wrapper .left-group {
      max-width: 410px;
    }   
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .invoice-actions-wrapper .left-group {
      flex-direction: column;
    }
    .detail-pg-actions-wrapper .actions-wrap {
      align-items: flex-start;
      flex-direction: row !important;
      margin-top: 0;
    }   
    .detail-pg-actions-wrapper .actions-wrap a:first-child {
      margin-left: 0 !important;
    }    
    .invoice-actions-wrapper .left-group > span:nth-child(even) {
      display: none;
    }
    .invoice-actions-wrapper .left-group > span {
      margin-bottom: 14px;
      padding-left: 0 !important;
    }
    .invoice-actions-wrapper .left-group > span:last-child {
      margin-bottom: 0;
    }
    .invoice-actions-wrapper .left-group > span > .org-tag {
      margin-left: 0 !important;
    }
    
  }
  
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
  }