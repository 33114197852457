.reusable-report-table-wrapper {
    width: 100% !important;
  }
  .reusable-report-table-wrapper table {
    width: 100% !important;
  }
  .reusable-report-table-wrapper thead {
    width: 100% !important;
    background-color: #fff !important;
    z-index: 1 !important;
  }
  .reusable-report-table-wrapper table tbody tr td:last-child {
    padding-right: 30px !important;
  }
  .reusable-report-table-wrapper table thead th:last-child {
    padding-right: 30px !important;
  }
  .reusable-report-table-wrapper table tbody tr td:first-child {
    padding-left: 30px !important;
  }
  .reusable-report-table-wrapper table thead th:first-child {
    padding-left: 30px !important;
  }