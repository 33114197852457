.filter-box {
  padding: 0 0 30px 0;
}
.filter-box .filter-wrapper {
  justify-content: space-start !important;
  flex-wrap: wrap;
  gap: 3.3%;
}
.filter-box .filter-wrapper .filter-item,
.filter-box .filter-wrapper.four-col-filter .filter-item {
  /* width: 31.4815%;
    max-width: 340px; */
  width: 22.045%;
  margin-bottom: 20px;
}
.soa-report
  .filter-box
  .filter-wrapper
  .filter-item:not(.filter-box .filter-wrapper.four-col-filter .filter-item),
.balance-sheet-report .filter-box .filter-wrapper .filter-item,
.tb-report .filter-box .filter-wrapper .filter-item {
  /* margin-right: 6.3%; */
  /* width: 29.9824%; */
  width: 22.045%;
}
.filter-item .form-select div[class$="-control"] {
  border-radius: 6px !important;
}
.balance-sheet-report .filter-box .filter-wrapper .filter-item:last-child,
.tb-report .filter-box .filter-wrapper .filter-item:last-child {
  margin-right: 0;
}
.filter-box .filter-wrapper {
  padding-top: 20px;
}
.filter-box .button-container {
  /* padding-top: 20px; */
  padding-top: 10px;
}
.filter-box .button-container button,
.filter-box .button-container a {
  width: auto;
}
.MuiFormControl-root
  .MuiFormGroup-root.MuiFormGroup-row
  .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 30px;
}
.MuiFormControl-root
  .MuiFormGroup-root.MuiFormGroup-row
  .MuiFormControlLabel-root
  .MuiRadio-root {
  margin-right: 6px;
}
.MuiFormControl-root
  .MuiFormGroup-root.MuiFormGroup-row
  .MuiFormControlLabel-root
  .MuiRadio-root.Mui-focusVisible
  .MuiSvgIcon-root {
  fill: var(--cerulean-blue) !important;
}
.MuiFormControl-root
  .MuiFormGroup-root.MuiFormGroup-row
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.filter-item .custom-select {
  margin-top: 0 !important;
}
.filter-item .accounts-dropdown,
.filter-item .accounts-dropdown .search-dropdown {
  width: 100% !important;
}
.filter-item .accounts-dropdown .search-dropdown {
  background: var(--white-fill)
    url("../../../../../assets/images/arrow-drop-account-bottom.svg") no-repeat
    right 12px center !important;
  background-size: 16px 16px !important;

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.filter-item .accounts-dropdown .search-dropdown::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55) !important;
}
.filter-item .accounts-dropdown .search-dropdown::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55) !important;
}
.filter-item .accounts-dropdown .search-dropdown:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55) !important;
}
.filter-item .accounts-dropdown .search-dropdown:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55) !important;
}

.filter-item input[type="text"] {
  width: 100%;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.filter-item input[type="text"]:disabled {
  background-color: var(--warm-white);
}
.filter-item input[type="text"]::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.filter-item input[type="text"]::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.filter-item input[type="text"]:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.filter-item input[type="text"]:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.filter-item .accounts-dropdown .search-dropdown {
  padding: 8px 12px 8px 14px !important;
}
.filter-item .accounts-dropdown input.search-dropdown.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}

.filter-box .filter-item .accounts-dropdown ul.dropdown-menu {
  width: 100%;
  inset: 0px auto auto 0px !important;
  padding: 0 !important;
}
.soa-report .filter-box .filter-item .daterange-wrapper,
.filter-box .filter-item .daterange-wrapper {
  width: 100%;
}
.filter-box .button-container.filter-wrapper {
  justify-content: flex-start;
}
.filter-box .button-container .filter-item {
  margin-right: 30px;
  margin-top: 1px;
}
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle:disabled,
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle:disabled:after {
  background-color: var(--warm-white) !important;
}
.filter-item .accounts-dropdown.error {
  border: 0 !important;
}
table tbody tr.table-navigation.row-navigation:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
}
tr.table-navigation.row-navigation td.column-navigation {
  cursor: pointer !important;
}
#soa-report tr.table-navigation td.column-navigation {
  cursor: default !important;
}
.button-container .filter-item.report-type-filter-tem {
  margin-right: 30px !important;
  width: 22.045% !important;
}
/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .filter-box .filter-wrapper .filter-item {
    width: 75% !important;
  }

  .filter-box .button-container {
    padding-top: 10px;
  }
  .filter-box .filter-wrapper .filter-item:last-child {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .filter-box .filter-wrapper .filter-item {
    width: 60% !important;
  }
  .filter-box .filter-wrapper .filter-item:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .filter-box
    .filter-wrapper:not(.filter-box .button-container.filter-wrapper) {
    flex-direction: column;
  }
  .filter-box .filter-wrapper .filter-item {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .filter-box .button-container.filter-wrapper {
    flex-direction: column;
  }
  .filter-box .button-container {
    padding-top: 20px;
  }
}
@media (max-width: 1200px) {
  .filter-box .filter-wrapper .filter-item,
  .filter-box .filter-wrapper.four-col-filter .filter-item {
    /* width: 31.4815%;
    max-width: 340px; */
    width: 30%;
  }
  .soa-report
    .filter-box
    .filter-wrapper
    .filter-item:not(.filter-box .filter-wrapper.four-col-filter .filter-item),
  .balance-sheet-report .filter-box .filter-wrapper .filter-item,
  .tb-report .filter-box .filter-wrapper .filter-item {
    /* margin-right: 6.3%; */
    /* width: 29.9824%; */
    width: 30%;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
