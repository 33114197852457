.input-field-item {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    padding: 11px 12px;
    border-radius: 6px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    clear: both;
}
.input-field-item.error {
    background-color: var(--rose-white) !important;
    border: 1px solid var(--red) !important;
}
.input-field-item:focus,
.input-field-item:focus-visible {
    outline: 0;
    box-shadow: none;
}
.input-field-item:focus {
    border: 1px solid var(--cerulean-blue);   
}
.input-field-item:disabled {   
    background-color: var(--warm-white);
    border-color: var(--warm-white);
    color: rgba(var(--charcoal-grey-rgb), 0.5);
    cursor: auto;
}
.input-field-item::-webkit-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-field-item::-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-field-item:-ms-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-field-item:-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.input-field-item::placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-input-container{
    position: relative;

}
.input-start-adornment{
    position: absolute;
    left: 0;

    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.input-end-adornment{
    position: absolute;
    right: 0;
 
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.start-adornment-present{
    padding-left: 20px;
}
.end-adornment-present{
    padding-right: 20px;
}