.budget-variance-report-table-wrapper {
  /* padding: 0px 20px; */
  margin: 30px 20px 0px 20px;
  background-color: #fff;
  overflow-x: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px !important;
}
.budget-variance-report-table-header-container {
  background-color: #f5f5f5;
}
.budget-variance-report-table-container {
  width: 100%;
  
}
.budget-variance-report-table-container th:first-child,
.budget-variance-report-table-container td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  min-width: 270px !important;
}
.budget-variance-report-table-container th:first-child{
    background-color: #f5f5f5;
}
.budget-variance-report-table-container tbody tr td:last-child {
  padding-right: 30px !important;
}
.budget-variance-report-table-container thead th:last-child {
  padding-right: 30px !important;
}
.budget-variance-report-table-container tbody tr td:first-child {
  padding-left: 30px !important;
}
.budget-variance-report-table-container thead th:first-child {
  padding-left: 30px !important;
}