.budget-variance-report-section {
    padding: 20px 0px 40px 0px;
    margin: 20px 20px 30px 30px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.budget-variance-report-container {
    width: 100%;
    height: 100%;
    padding: 40px 0px 40px 0px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
}   
.report-note-container{
    padding: 0px 25px;
    margin-top: 20px;
}
.budget-variance-report-section h1 {
    max-width: calc(100% - 234px);
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .budget-variance-report-section {
       padding: 20px 0px 40px 0px;
       margin-bottom: 30px;
    }
}