.recurring-invoice-org #updateOrganizationModal .modal-dialog {
    max-width: 633px;
}

.recurring-invoice-org #updateOrganizationModal .modal-header {
    padding: 30px 30px 20px 30px;
}
.recurring-invoice-org #updateOrganizationModal .modal-content {
    width: 633px;
    border-radius: 12px;
    -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
    border: solid 1px var(--warmWhite);
    background-color: var(--white-fill);
}
.recurring-invoice-org #updateOrganizationModal .modal-header h1 {
    
    font-size: 24px;
    font-weight: 900;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin: 0;
}
.recurring-invoice-org #updateOrganizationModal .modal-body {
    padding: 0;    
}
.recurring-invoice-org #updateOrganizationModal .modal-body .org-settings-form,
.recurring-invoice-org #updateOrganizationModal .modal-body .fields-wrapper {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0 !important;
}
.recurring-invoice-org #updateOrganizationModal .modal-body .org-settings-form {
    border-radius: 12px !important;
}
.recurring-invoice-org #updateOrganizationModal .modal-body .fields-wrapper {
    max-height: 66vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 30px !important;
}
.recurring-invoice-org #updateOrganizationModal .image-upload-container h5 {
    display: none !important;
}
.recurring-invoice-org #updateOrganizationModal .form-button-section:before {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
}
.recurring-invoice-org #updateOrganizationModal .fields-wrapper .image-upload-container {
    min-height: 104px;
}
.recurring-invoice-org #updateOrganizationModal .form-logo-section {
    padding: 10px 0 0 0;
    border-bottom: 0;
}
.recurring-invoice-org #updateOrganizationModal .img-upload-wrapper .preview-block {
    width: 80px;
    flex: 0 0 80px;
}
.recurring-invoice-org #updateOrganizationModal .preview-block .img-container {
    width: 80px;
    height: 80px;
}
.recurring-invoice-org #updateOrganizationModal .img-upload-wrapper .preview-block a {
    font-size: 12px;
}
.recurring-invoice-org #updateOrganizationModal .upload-block {
    width: calc(100% - 80px);
    padding-left: 22px;
}
.recurring-invoice-org #updateOrganizationModal .img-upload-wrapper.edit-wrapper .upload-block {
    padding: 38px 0 38px 22px;
}
.recurring-invoice-org #updateOrganizationModal .form-fields-section {
    padding: 10px 0 2px 0;
}
.recurring-invoice-org #updateOrganizationModal .form-button-section {
    padding: 20px 30px;
    border-radius: 0 0 12px 12px;
}
.recurring-invoice-org .recurring-invoice-org #updateOrganizationModal .fields-wrapper .form-fields .row {
    margin-bottom: 18px;
}
.recurring-invoice-org #updateOrganizationModal #address {
    max-width: none !important;
    clear: both;
}
.recurring-invoice-org #updateOrganizationModal .org-settings-form .fields-wrapper .form-fields,
#updateOrganizationModal .org-settings-form .mw-500,
#updateOrganizationModal .org-settings-form .alert-msg {
    max-width: none !important;
}