.info-box {
  display: flex;
  width: auto;
  height: 34px;
  align-items: center;
  margin: 0 auto 20px 0px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
}
.info-box img {
  width: 18px;
  height: 18px;
  margin: 0 12px 0 0;
  object-fit: contain;
}
.info-box .info-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--chambray);
}
.error-info .info-text {
  color: var(--red);
}
.form-error-container .info-box {
  float: left;
  width: auto;
}
