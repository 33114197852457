.w-100 {
    width: 100%;
}
.d-flex {
    display: flex;
}
.border-start-0 {
    border-right: 0;
}
.border-end-0 {
    border-left: 0;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.form-control {
    display: block;
    width: 100%;   
    background-clip: padding-box;    
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.inv-prefix {
    border-radius: 6px 0 0 6px !important;
    background-color: rgba(var(--peak-point-rgb), 0.08);
    border: 1px solid var(--new-whisper);
    height: 41px;
    padding: 12px;
    line-height: 1;
}
.inv-prefix,
.inv-prefix span {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 20px;
    color: var(--battleship-grey);
    line-height: 15px;
    white-space: nowrap;
}
.inv-prefix span {
    display: inline-block;
    padding-right: 8px;
    margin-right: -4px;
}
.inv-prefix a {
    display: inline-block;
    position: relative; 
    top: -2px;
    text-decoration: none;  
    margin-right: -4px;
}
.inv-prefix a:focus {
    opacity: 0.8;
}
.inv-group .form-control {
    border-radius: 0 6px 6px 0 !important;
    height: 41px !important;
} 
.inv-group > input[type="text"],
.inv-group .prefix-input {
    max-width: 325px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    box-shadow: none !important;
    padding: 11px 12px !important;
    border-radius: 0 6px 6px 0 !important;
    border: solid 1px var(--new-whisper) !important;
    background-color: var(--white-fill) !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: var(--charcoal-grey) !important;
}
.inv-group > input[type="text"]:focus,
.inv-group .prefix-input:focus,
.inv-group > input[type="text"]:focus-visible,
.inv-group .prefix-input:focus-visible {
    outline: 0
}
.inv-group.focused input,
.inv-group.focused .prefix-input,
.inv-group.focused .inv-prefix,
.inv-group.focused input[type="text"] {
    border: 0 !important;
}
.inv-group.focused {
    border: 1px solid var(--cerulean-blue) !important;
    box-shadow: none !important;
    border-radius: 6px;
}
.inv-prefix span.error {
    color: var(--red);
    font-size: 13px;
    font-weight: normal;
    display: block;
    line-height: 19px;
    clear: both;
}
.inv-group input[type="text"]:disabled {
    background-color: var(--warm-white) !important;
    color: var(--battleship-grey) !important;
    border: 0 !important;
}
.inv-group.focused input {
    border: 0 !important;
}