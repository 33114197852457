.preference-popper-content {
  width: 100%;
  max-width: 340px;
  margin: auto;
  text-align: left;
}

.preference-popper-content .heading {
  color: #333;
}

.preference-popper-content .description {
  color: #666;
  text-align: left;
}

.preference-popper-content table {
  width: 100%;
  border-collapse: collapse;
}

.preference-popper-content th{
    color:#a4a4a4!important;
    font-size:13px;
}

.preference-popper-content td{
    font-size:13px;
}

.preference-popper-content th,
.preference-popper-content td {
  padding: 10px;
}

.preference-popper-content th,
.preference-popper-content tr:nth-child(even) {
  background-color: #f2f2f2;
}

.preference-popper-content .header-wrapper {
  padding: 17px 17px 5px 17px;
}
