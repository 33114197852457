.pr-details-refund-table .refund-date{
    text-align: left!important;
}

.pr-details-refund-table .pull-right{
    float:right;
}
#pr_refund_table .action{
    padding-top: 8px!important;
}
.payment-detail-section .refund-amount-wrapper{
    background: #e9e9e9;
    width: fit-content;
    padding: 5px 5px 5px 5px;
    display: flex;
    font-size:16px;
}
.payment-detail-section .refund-amount-wrapper .refund-label{
    padding-right: 5px;
}

.payment-detail-section .refund-amount-wrapper .refund-amount{
    font-weight: 700;
}
