.custom-popper {   
    border-radius: 4px !important;
    background-color: var(--white-fill);   
    border: 1px solid var(--new-whisper) !important;    
    border-radius: 6px !important;
    box-shadow: 0 2px 6px 0 var(--black-shadow1) !important; 
    z-index: 1321;
    margin: 0 !important;
    clear: both;
}
.custom-popper[data-popper-placement="top"] {
    inset: auto auto 0px 0px !important;
    margin-bottom: 8px !important;
}
.custom-popper[data-popper-placement="bottom"] {
    inset: 0px auto auto 0px !important;
    margin-top: 8px !important;
}
.custom-popper .MuiPaper-root {
    font-family: Lato, sans-serif !important;
    font-stretch: normal !important;
    font-style: normal !important;
    box-shadow: none;
    background-image: none;
}
.custom-autocomplete .MuiAutocomplete-inputRoot {
    min-height: 41px !important;
    border: 1px solid var(--new-whisper) !important;   
    padding: 0px 34px 0 12px !important;  
    box-shadow: none !important;
}
.custom-autocomplete:hover .MuiAutocomplete-inputRoot{
    border: 1px solid var(--new-whisper) !important;    
}
.custom-autocomplete:focus .MuiAutocomplete-inputRoot {  
    border: 1px solid var(--cerulean-blue) !important;
}
.custom-autocomplete .MuiAutocomplete-inputRoot:hover {
    border: 1px solid var(--new-whisper) !important;
}
.custom-autocomplete .MuiAutocomplete-inputRoot.Mui-focused,
.custom-autocomplete .MuiAutocomplete-inputRoot.Mui-focusVisible {  
    border: 1px solid var(--cerulean-blue) !important;
}
.auto-complete-error.custom-autocomplete .MuiAutocomplete-inputRoot,
.auto-complete-error.custom-autocomplete .MuiAutocomplete-inputRoot.Mui-focused,
.auto-complete-error.custom-autocomplete .MuiAutocomplete-inputRoot.Mui-focusVisible,
.auto-complete-error.custom-autocomplete .MuiAutocomplete-inputRoot:hover {
    background-color: var(--rose-white) !important;
    border: 1px solid var(--red) !important;
} 
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input {
    width: auto !important;
    border: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none !important;
    background-color: transparent !important;
}
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input::-webkit-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input::-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input:-ms-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input:-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.custom-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input.custom-input::placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.custom-autocomplete .chip {    
    width: auto;
    align-items: center;    
    display: inline-flex; 
    margin-bottom: 5px;   
    white-space: nowrap;
    background-color: var(--approx-alice-blue) !important;    
    border: 1px solid var(--pattens-blue) !important;    
    border-radius: 4px !important;
    color: var(--battleship-grey) !important;   
    font-size: 13px !important;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.54;
    margin: 8px 8px 8px 0 !important;    
    padding: 2px 4px 2px 8px !important;
}
/* .custom-autocomplete .chip + .MuiAutocomplete-input.custom-input {
    margin-bottom: 8px;
} */
.custom-popper .MuiAutocomplete-listbox {
    padding: 0;
    min-height: 260px;
    max-height: 40vh;
}
.custom-autocomplete .chip .MuiChip-deleteIcon {
    width: 14px;
    height: 14px;
}
.custom-autocomplete .chip .MuiChip-deleteIcon:hover polygon:last-child,
.custom-autocomplete .chip .MuiChip-deleteIcon:focus polygon:last-child {
    fill: var(--red) !important;
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
    border-bottom: 1px solid var(--approx-whisper);    
    color: var(--charcoal-grey);     
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1;
    padding: 12px;
    background-color: var(--white-fill) !important;
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused,
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focusVisible {
    background: var(--whisper) !important;    
    color: var(--charcoal-grey);    
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root {
    padding: 0;
    margin-right: 10px;
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root svg {
    width: 18px;
    height: 18px;
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root svg path {
    fill: var(--slate);
}
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root.Mui-checked svg path {
     fill: var(--cerulean-blue);
    
}
.custom-popper .MuiAutocomplete-listbox  .MuiAutocomplete-option[aria-selected="true"] {
    box-shadow: none;
}
/* .custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .option-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
} */
.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .primary-label {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: -0.11px;
    color: var(--charcoal-grey);    
    text-transform: none;
}

.custom-popper .MuiAutocomplete-listbox .MuiAutocomplete-option .secondary-label {
    width: 100%;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: var(--battleship-grey);   
    margin-top: 4px;
    white-space: normal;
}
.custom-autocomplete .MuiAutocomplete-endAdornment .MuiIconButton-root .MuiSvgIcon-root {
    display: block !important;
}
.custom-option {
    display: flex;
    align-items: center;  
}
