.custom-select-org-option-wrapper {
  padding: 12px 12px 10px 12px;
  cursor: pointer;
}
.custom-select-org-option-org-name {
  padding: "2px 1px 2px 0";
  font-size: "14px";
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  text-transform: none;
}

.custom-select-org-option-org-root {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  margin-top: 4px;
  white-space: normal;
}
.custom-select-org-option-org-name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.org-dropdown-container {
  max-width: 340px !important;
  min-width: 340px !important;
    height: 70px;
    /* margin: 20px 0px; */
}
.org-dropdown-container .react-select__control::before {
  content: "";
  background-image: url("../../../../../../../assets/images/org-small.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 0px 0px 0px 10px;
}
