.navbar-brand img {
    width: auto;
    height: 64px;
}
.navbar-expand-lg .navbar-toggler {
    display: none !important;
}
.sidebar-toggler {
    width: 30px;
    height: auto;
    padding: 5px;
    margin-right: 15px;
}
.sidebar-toggler span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--white-fill);
    margin: 4px 0;
}
.sidebar-toggler.show span:last-child {
    display: none;
}
.sidebar-toggler.show span:first-child {
    transform: rotate(45deg);
    top: 6px;
}
.sidebar-toggler.show span:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .navbar-brand img {
        height: 44px;
    }
}
@media (max-width: 767px) {
    .navbar-brand img {
        height: 42px;
    } 
    .sidebar-togglebtn {
        display: none !important;
    }   
    /* .navbar-collapse.top-nav {
        display: block;
    } */
}
@media (max-width: 459px) {
    .navbar-brand img {
        height: 36px;
    }
}