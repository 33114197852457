.custom-dropdown .dropdown-toggle {
  white-space: nowrap;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--navy-blue);
  cursor: pointer;
}
.custom-dropdown .dropdown-toggle::after {
  position: absolute;
  margin-left: 0px;
  margin-top: -11px;
  width: 24px;
  height: 24px;
  top: 50%;
  border: 0 !important;
  background: url("../../../../assets/images/blue-arrow-icon-down.svg")
    no-repeat center;
  cursor: pointer;
}
.custom-dropdown .dropdown-heading {
  font-size: 15px;
  padding: 12px 10px 10px 12px;
}
/* Apply border styles to the dropdown options */
.custom-dropdown .dropdown-options {
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: auto;
  /* min-width: 100%; */
  min-width: 320px;
  /* inset: 0px auto auto 20px !important; */
  inset: 8px auto auto 0 !important;
  padding: 0 !important;
  transform: translate3d(0px, 20px, 0px) !important;
  position: absolute;
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.custom-dropdown .dropdown-options .dropdown-option {
  border-bottom: 1px solid var(--approx-whisper) !important;
  display: flex;
  cursor: pointer;
}
.custom-dropdown .dropdown-options .dropdown-option:hover {
  box-shadow: inset 0 0 0 9999px var(--solitude) !important;
}
.custom-dropdown
  .dropdown-options
  .dropdown-option:hover
  .option-actions
  button.edit,
.custom-dropdown
  .dropdown-options
  .dropdown-option:hover
  .option-actions
  button .delete {
  display: block !important;
}
/* Apply padding to the option content and actions */
.option-content {
  padding: 13px 6px 10px 12px;
  width: 70%;
  font-size: 14px;
}

.option-actions {
  padding: 4px;
  width: 30%;
  display: flex;
  /* display: none; */
}
.option-actions button.edit,
.option-actions button.delete {
  appearance: none;
  padding: 6px 0 9px 14px;
  border: 0;
  background: none;
}
.option-actions button.edit,
.option-actions button .delete {
  display: none !important;
}
/* Style the edit and delete buttons */
.option-actions button {
  margin-right: 4px;
  cursor: pointer;
}
.custom-dropdown {
  float: right;
  /* margin-top: -27px; */
  margin-top: -29px;
  /* margin-right: 13%; */
  margin-right: 45px;
  /* width: 20%; */
  width: auto;
  position: relative;
}
