/* .addItem-form input.error,
.addItem-form select.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
} */

#addItem-form input,
#addItem-form .form-select,
#addItem-form label {
  clear: both;
}
#addItem-form input,
#addItem-form .form-select {
  width: 100%;
}
#addItem-form input:focus,
#addItem-form input[type="text"]:focus,
#addItem-form .form-select:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
#addItem-form label {
  /* font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left; */
  width: fit-content;
}
/* label.required {
  position: relative;
  padding-right: 14px;
}

label.required:after {
  content: "*";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 1px;
  right: 0;
  color: var(--red);
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
} */
#addItem-form .accounts-dropdown .dropdown-menu {
  min-width: 130px;
  height: auto !important;
  text-align: left;
  position: absolute;
  inset: 25px -13px auto auto !important;
  margin: 0px !important;
  padding: 14px 0 14px 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  margin-left: -65px;
}
.accounts-dropdown .dropdown-menu {
  /* inset: initial !important;
  min-width: 200px !important;
  transform: translate(0, 44px) !important;
  padding: 0;
  right: -1px !important; */
  /* border-radius: 12px; */
  /* border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 198px;
  overflow-x: hidden;
  overflow-y: auto; */
}
#addItem-form .accounts-dropdown .search-dropdown {
  width: 100% !important;
  height: 40px;
  border-radius: 6px !important;
  border: solid 1px var(--new-whisper) !important;  
  position: relative !important;
  padding: 15px 31px 14px 12px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  text-align: left !important;
  background: url("../../../../../assets/images/arrow-drop-right.svg") no-repeat right 4px
    center !important;
  background-size: 20px 20px;
}
/* #addItem-form .accounts-dropdown .search-dropdown:not(.error) {
  background-color: var(--white-fill) !important;
} */
#addItem-form .accounts-dropdown .dropdown-toggle[aria-expanded="true"],
#addItem-form .taxcode-dropdown .search-dropdown[aria-expanded="true"] {
  background: var(--white-fill)
    url("../../../../../assets/images/arrow-drop-right-inverted.svg") no-repeat
    right 4px center !important;
  background-size: 20px 20px;
}
#addItem-form .accounts-dropdown .dropdown-menu {
  /* inset: initial !important;
  min-width: 100% !important;
  transform: translate(0, 44px) !important;
  padding: 0;
  top: 0 !important;
  right: -1px !important; */
  /* border-radius: 12px; */
  /* border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 198px;
  overflow-x: hidden;
  overflow-y: auto; */
}
#addItem-form .accounts-dropdown .dropdown-menu li {
  padding: 0;
  width: 100%;
}
#addItem-form .accounts-dropdown .dropdown-menu li:first-child,
#addItem-form .accounts-dropdown .dropdown-menu li:first-child a {
  /* border-radius: 12px 12px 0 0!important; */
  border-radius: 4px 4px 0 0 !important;
}
#addItem-form .accounts-dropdown .dropdown-menu li:last-child,
.accounts-dropdown .dropdown-menu li:last-child a {
  /* border-radius: 0 0 12px 12px!important; */
  border-radius: 0 0 4px 4px !important;
}
#addItem-form .accounts-dropdown .dropdown-menu a {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 12px 10px 12px;
  background-color: var(--white-fill) !important;
  border-bottom: 1px solid var(--approx-whisper) !important;
}
#addItem-form .accounts-dropdown .dropdown-menu li:last-child a {
  border-bottom: 0 !important;
}
#addItem-form .accounts-dropdown .dropdown-menu a.active,
#addItem-form .accounts-dropdown .dropdown-menu a:hover {
  background-color: var(--whisper) !important;
}
#addItem-form .accounts-dropdown .dropdown-menu a span {
  display: block;
  width: 100%;
  padding: 2px 1px 2px 0;
}
#addItem-form .accounts-dropdown .dropdown-menu a .account {
  font-size: 14px;
  font-weight: 600;

  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  text-transform: none;
}
#addItem-form .accounts-dropdown .dropdown-menu a .account-type {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  margin-top: 4px;
}
#addItem-form .taxcode-dropdown .search-dropdown {
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
  border: solid 1px var(--new-whisper) !important;
  border-left: 0 !important;
  position: relative !important;
  padding: 0 31px 0 12px !important;
  border-radius: 0 6px 6px 0 !important;
}
#addItem-form .tax-selector input.tax_percentage {
  height: 41px !important;
}
#addItem-form .tax-selector {
  clear: both;
  /* margin-bottom: 16px; */
}
#addItem-form input[type="text"] {
  /* max-width: 325px; */
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
#addItem-form .tax-selector .tax_percentage {
  border: solid 1px var(--new-whisper) !important;
  border-radius: 6px 0 0 6px !important;
}
#addItem-form .tax-selector .tax_percentage {
  width: 61px !important;
}
#addItem-form .form-button-wrapper {
  border-top: 0;
  padding-top: 0;
}
#addItem-form .add-tags-button {
  float: left;
  /* margin-top: 16px; */
}
#addItem-form .error {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
}
#addItem-form .item-tax-section span.error {
  margin-bottom: 16px !important;
}
/* #addItem-form .accounts-dropdown input.search-dropdown.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
} */
#addItem-form .tax-selector.error input {
  color: var(--red) !important;
  font-size: 13px;
  display: block;
  line-height: 19px;
  background-color: var(--rose-white) !important;
  border: solid 1px var(--red) !important;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
}
#addItem-form .tax-selector.error input.tax_percentage {
  border-right: 0 !important;
}
#addItem-form .tax-selector.error .taxcode-dropdown .search-dropdown {
  border-left: solid 1px var(--new-whisper) !important;
}

#addItem-form .radio-wrapper .form-check-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
#addItem-form .radio-wrapper label {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
#addItem-form .radio-wrapper .form-check-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
#addItem-form .radio-wrapper .form-check-input[type="radio"] {
  margin-right: 6px;
}
#addItem-form .radio-wrapper label {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: none;
  margin-bottom: 0;
}
#addItem-form .create-settings-form-wrap span.error {
  margin-bottom: 0px !important;
}