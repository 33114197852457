.pagination-wrapper {
    float: left;
}
.pagination-wrapper ul {
    list-style: none;
    display: block;
    float: right;
    padding: 0;
    margin: 0;
}
.pagination-wrapper ul li {
    display: block;
    margin: 0 4px 0 0;
    border: 0;
    float: left;
}
.pagination-active {
    background-color: var(--cerulean-blue);
    color: var(--white-fill) !important;
    border-radius: 6px;
}
.pagination-wrapper ul li:first-child a, .pagination-wrapper ul li:last-child a, .pagination-wrapper ul li:first-child a i, .pagination-wrapper ul li:last-child a i {
    font-size: 0;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
}
.pagination-wrapper ul li:first-child a, .pagination-wrapper ul li:last-child a {
    padding: 0;
}
.pagination-wrapper .pagination__link--disabled a {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    background: var(--white-fill) !important;
}
.pagination-wrapper ul li a {
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 6px;
    border: solid 1px var(--link-water);
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: normal;
}
.pagination-wrapper ul li:last-child {
    margin: 0 0 0 8px;
}