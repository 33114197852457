.add-customer-link {
  margin-top: 0;
  appearance: none;
  border: 0;
  box-shadow: none;
  padding-right: 0px;
  background-color: transparent;
  color: var(--cerulean-blue);
  float: right;
}
.reusable-modal-btn {
  justify-content: flex-end !important;
}
.add-customer-link span {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.cancel-impact-details-modal .modal-title {
  font-size: 17px !important;
  font-weight: 900 !important;
}
.cancel-impact-details-modal .modal-header {
  padding-bottom: 10px !important;
  padding-left: 20px !important;
  padding-top: 25px !important;
}
.cancel-impact-details-modal .center-modal-content {
  max-width: 500px;
  border-radius: 5px !important;
  transition: top 0.5s ease-out;
}

.cancel-impact-details-modal .container {
  padding-top: 10px;
}

.subscription-scheduler-cancel {
  color: var(--bs-danger);
}

.subscription-scheduler-cancel-P-15 {
  padding-left: 15px;
  cursor: pointer;
}
