.report-header-container {
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background-color: var(--white-smoke);
}
.page-type-label {
  font-size: 36px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}
.preview-date-field,
.preview-date-field span {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
}
.preview-date-field {
  margin-bottom: 8px;
}
.account-name {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}
.account-summary-section {
  min-width: 267px;
  margin-top: 12px;
  text-align: right;
}
.summary-label {
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--battleship-grey);
  margin-bottom: 17px;
}
.account-summary-section .summary-wrapper {
  width: 100%;
}
.account-summary-section .summary-wrapper .summary-item {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  justify-content: space-between;
}
.account-summary-section .summary-item .summary-label {
  width: 168px;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.19px;
  text-align: right;
  color: var(--battleship-grey);
  white-space: nowrap;
}
.account-summary-section .summary-item .summary-value {
  min-width: 132px;
  width: auto;
  font-size: 15px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  white-space: nowrap;
}
.account-summary-section .summary-wrapper .summary-item.due-amt {
  padding: 16px 0;
  border-top: 1px solid var(--slate);
  border-bottom: 1px solid var(--slate);
  margin-bottom: 16px;
}
.account-summary-section .summary-item .summary-label.due-label {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.23px;
  margin-bottom: 0;
}
.account-summary-section .summary-item .summary-value.due-amt {
  font-size: 18px;
}
.report-header-container .company-logo {
  height: 61px;
  width: 100px;
  background-color: var(--black);
  margin-bottom: 18px;
}
.report-header-container .company-address,
.report-header-container .company-address span {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--charcoal-grey);
}
.report-header-container .company-address span.company-name {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .report-header-container {
    flex-direction: column;
  }
  .report-header-container .report-details {
    margin-top: 20px;
  }
  .page-type-label,
  .preview-date-field,
  .preview-date-field span,
  .account-name,
  .summary-label,
  .account-summary-section .summary-item .summary-label {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
