.fiscal-year-closing-card-year-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(98px, 1fr));
  gap: 8px;
  margin-top: 18px;
}
.fiscal-year-closing-card-year-list-single {
  width: 98px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #e3efff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: var(--charcoal-grey);
}
.fiscal-year-closing-card-wrapper .green-button {
  background-color: var(--booger);
  color: white;
  font-size: 15px;
  font-weight: bold;
}
