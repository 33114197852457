.payment-action-table#invoice_list_table th.pay_through,
.payment-action-table#invoice_list_table td.pay_through {
  min-width: 145px !important;
}

.payment-action-table td .excess {
  color: var(--red);
}
.payment-action-table td .allocated {
  color: var(--apple);
}

.w-150,
.payment_method.w-150 {
  width: 150px !important;
}
.w-130 {
  width: 130px !important;
}
.w-325 {
  width: 325px !important;
}
.form-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.add_payment_form .inv-prefix,
.add_payment_form .inv-group > input[type="text"] {
  height: 40px;
}
/* .add_payment_form .unpaid-items-table {
  min-width: 1px !important;
}
.add_payment_form .unpaid-items-table th,
.add_payment_form .unpaid-items-table td {
  white-space: nowrap;
} */
/* .add_payment_form .unpaid-items-table th:first-child,
.add_payment_form .unpaid-items-table tr td:first-child {
  padding-left: 16px !important;
  padding-right: 16px !important;
} */
/* .add_payment_form .unpaid-items-table th:first-child,
.add_payment_form .unpaid-items-table tr td:first-child, */
/* .add_payment_form .unpaid-items-table tr .item_number  {
  padding-left: 16px !important;
  padding-right: 20px !important;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(2),
.add_payment_form .unpaid-items-table th:nth-child(3),
.add_payment_form .unpaid-items-table th:nth-child(4),
.add_payment_form .unpaid-items-table tr td:nth-child(2),
.add_payment_form .unpaid-items-table tr td:nth-child(3),
.add_payment_form .unpaid-items-table tr td:nth-child(4), */

/* .add_payment_form .unpaid-items-table tr .item_date,
.add_payment_form .unpaid-items-table tr .item_amount,
.add_payment_form .unpaid-items-table tr .amount_due {
  padding-left: 16px !important;
  padding-right: 16px !important;
} */

/* .add_payment_form .unpaid-items-table th:nth-child(1),
.add_payment_form .unpaid-items-table th:nth-child(2),
.add_payment_form .unpaid-items-table th:nth-child(3),
.add_payment_form .unpaid-items-table th:nth-child(4), */
/* .add_payment_form .unpaid-items-table th:nth-child(5), */
/* .add_payment_form .unpaid-items-table tr td:nth-child(1),
.add_payment_form .unpaid-items-table tr td:nth-child(2),
.add_payment_form .unpaid-items-table tr td:nth-child(3),
.add_payment_form .unpaid-items-table tr td:nth-child(4), */

/* .add_payment_form .unpaid-items-table tr .item_number, 
.add_payment_form .unpaid-items-table tr .item_date,
.add_payment_form .unpaid-items-table tr .item_amount,
.add_payment_form .unpaid-items-table tr .amount_due {
  width: auto !important;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(1),
.add_payment_form .unpaid-items-table tr td:nth-child(1), */
/* .add_payment_form .unpaid-items-table tr .item_number {
  min-width: 110px;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(2),
.add_payment_form .unpaid-items-table tr td:nth-child(2), */
/* .add_payment_form .unpaid-items-table tr .item_date {
  min-width: 107px;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(3),
.add_payment_form .unpaid-items-table tr td:nth-child(3), */
/* .add_payment_form .unpaid-items-table tr .item_amount {
  min-width: 113px;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(4),
.add_payment_form .unpaid-items-table tr td:nth-child(4), */
/* .add_payment_form .unpaid-items-table tr .amount_due  {
  min-width: 114px;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(5),
.add_payment_form .unpaid-items-table tr td:nth-child(5), */
/* .add_payment_form .unpaid-items-table tr .payment-col {
  min-width: 176px;
  width: 16.2212% !important;
  text-align: right;
} */
/* .add_payment_form .unpaid-items-table tr td:nth-child(5) .payment-entry, */
/* .add_payment_form .unpaid-items-table tr .payment-col .payment-entry {
  margin-left: auto;
} */
/* .add_payment_form .unpaid-items-table th:nth-child(6),
.add_payment_form .unpaid-items-table tr td:nth-child(6), */
/* .add_payment_form .unpaid-items-table tr .forex_gain_loss {
  min-width: 176px;
  width: 16.2212% !important;
  text-align: right;
} */
.add_payment_form .unpaid-items-table .payment-entry .inv-prefix {   
  line-height: 0.77;
  color: var(--battleship-grey);
  padding: 15px 8px 15px 9px;
}
.add_payment_form .unpaid-items-table tbody tr td {
  background-color: rgba(var(--cerulean-blue-rgb), 0.05);
  padding-top: 27px !important;
  padding-bottom: 70px !important;
  
  font-size: 14px;
  font-weight: normal;
  
  
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
/* .add_payment_form .unpaid-items-table tbody tr td:nth-child(5), */
/* .add_payment_form .unpaid-items-table tr .payment-col {
  padding-top: 12px !important;
  width: 176px !important;
} */

.add_payment_form .calc-row {
  padding: 30px 20px 27px 16px;
}
.add_payment_form .item-text {
  
  font-size: 14px;
  font-weight: 500;
  
  
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: right;
  color: var(--battleship-grey);
  margin-bottom: 15px;
  width: 167px;
}
.add_payment_form .item-amt {
  
  font-size: 14px;
  font-weight: 600;
  
  
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--battleship-grey);
  margin-bottom: 15px;
}
.add_payment_form .item-text.total-text {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey);
  margin-bottom: 21px;
}
.add_payment_form .item-amt.total-amt {
  
  font-size: 16px;
  font-weight: bold;
  color: var(--charcoal-grey);
  margin-bottom: 21px;
}
.add_payment_form .right-details {
  width: 360px;
  flex: 0 0 360px !important;
}
.add_payment_form .right-details .amt-wrapper {
  width: 193px;
  padding-left: 20px;
}
.add_payment_form .right-details .amt-wrapper span {
  display: block;
  width: 100%;
  float: left;
}
.add_payment_form .items-entry-wrapper {
  padding-bottom: 0 !important;
}
.add_payment_form .calculation-section > div:last-child .item-amt,
.add_payment_form .calculation-section > div:last-child .item-text {
  margin-bottom: 0;
}
.add_payment_form .form-button-wrapper {
  padding-top: 30px;
}
.customer-notes .notes-textarea:focus-visible {
  outline: none !important;
}
.add_payment_form .unpaid-items-table tbody tr td.no-item {
  
  font-size: 16px;
  font-weight: bold;
  
  
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--slate) !important;
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}
.add_payment_form.inactive {
  position: relative;
}
.add_payment_form.inactive:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 202px;
  bottom: 81px;
  width: 100%;
  height: calc(100% - 283px);
  opacity: 0.5;
  background-color: var(--white-fill);
  z-index: 10;
}
.disabled-item {
  position: relative;
}
.disabled-item:before {
  content: "";
  position: absolute;
  width: calc(100% + 6px);
  height: calc(100% + 2px);
  top: -1px;
  left: -3px;
  right: -3px;
  bottom: -1px;
  opacity: 0.5;
  background-color: var(--white-fill);
  z-index: 10;
}
.add_payment_form .form-button-wrapper button:disabled {
  opacity: 0.5;
}
.form-button-wrapper .cancel-button:disabled:hover,
.add_payment_form .form-button-wrapper .cancel-button.disabled-item:hover {
  border: solid 1px var(--booger);
  -webkit-box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  box-shadow: 0 2px 6px 0 var(--black-rgb-percent1);
  color: var(--booger);
  background-color: var(--white);
}

.add_payment_form #upload_section {
  padding-top: 43px;
  border-bottom: 0 !important;
}
.add_payment_form #upload_section .file-list li {
  margin-bottom: 0 !important;
  padding: 16px 0;
}
.add_payment_form #upload_section .file-list {
}
.vendor-dropdown {
  z-index: 11;
}
.payment-detail-card {
  margin-bottom: 75px;
}
.payment-detail-card.error-card{
  margin-bottom: 162px;
}
.notification-modal-popup {
  white-space: pre-wrap;
}

.form-error-container {
  flex-wrap: wrap;
}
.form-error-container .info-box {
  width: auto;
  float: left;
}
.add-module-item-form textarea {
  line-height: normal;
}
.add_payment_form .exchange-rate-display-wrapper {
  margin-bottom: 0 !important;
}

.pl-1rem {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.row.custom-row .pm-paymentdetails {
  flex: 0 0 auto;
}
.row.custom-row .pm-paymentdetails .col-lg-4 {
  padding-left: 0;
  padding-right: 1rem;
  width: 100%;
  max-width: calc(325px + 1rem) !important;
  margin: 0 !important;
}
.row.custom-row .pm-paymentdetails .col-lg-4 + .col-lg-4 {
  padding-left: 1rem;
  padding-right: 0;
}
.create-pm-form .row.custom-row .col-lg-4,
.create-pm-form .row.custom-row .pm-paymentdetails {
  margin-bottom: 20px;
}
.form-top-section .payment-details-container {
  clear: both;
  width: 100%;
  max-width: 714px;
}
.unpaid-error{
  display: block;
  white-space: normal;
  float: right;
}
.bill-item-container span.inv-balance {
  padding-top: 4px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
@media (min-width: 768px) and (max-width: 991px) {
  .add_payment_form .calc-row {
    flex-direction: column;
  }
  .add_payment_form .right-details {
    width: 100%;
    max-width: none;
    flex: 0 0 auto !important;
    margin-top: 20px;
  }
  .add_payment_form .item-text.total-text,
  .add_payment_form .item-text {
    text-align: left;
  }
}