.loader-container {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--white-rgb), 0.85);
  z-index: 99999;
}
.full-width-loader-container .loader-container {
  z-index: 999999;
}
.loader-position {
  position: absolute;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%, 0);
}
.loader {
  position: relative;
  display: grid;
  place-items: center;
  width: 80px;
  height: 80px;
  background-color: transparent;
}
.loader span {
  position: absolute;
  border: 6px solid var(--cerulean-blue);
  border-radius: 50%;
  animation: ripple 1.8s ease-out infinite;
}
.loader span:nth-child(2) {
  animation-delay: -0.6s;
}
@keyframes ripple {
  from {
    opacity: 1;
    width: 0;
    height: 0;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
