.item-grid-account {
  width: 100%;
  min-width: 1080px !important;
  background-color: rgba(var(--cerulean-blue-rgb), 0.04);
}
.w-474 {
  max-width: 474px;
}
.w-200 {
  max-width: 200px;
}
.width-100 {
  max-width: 100px;
}
.w-474 input,
.w-380 input,
.w-310 input,
.width-100 input {
  width: 100%;
  max-width: none !important;
}
.pleft-20 {
  padding-left: 20px;
}
.pright-20 {
  padding-right: 20px;
}
.text-upper {
  text-transform: uppercase;
}
#item-details-wrapper .item-grid-account label {
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.pright-23 {
  padding-right: 23px;
}
.pright-16 {
  padding-right: 16px;
}
.w-110 {
  width: 10.14%;
  min-width: 110px;
  flex: 0 0 10.14%;
}
.w-90 {
  width: 8.2%;
  min-width: 90px;
  flex: 0 0 8.2%;
}
.w-130 {
  width: 12%;
  min-width: 130px;
  flex: 0 0 12%;
}
.w-133 {
  width: 12.67874%;
  min-width: 133px;
  flex: 0 0 12.67874%;
}
.w-113 {
  width: 10.415%;
  min-width: 113px;
  flex: 0 0 10.415%;
}
.w-132 {
  width: 12.1659%;
  min-width: 132px;
  flex: 0 0 12.1659%;
}
.w-261 {
  width: 24.0553%;
  min-width: 261px;
  flex: 0 0 24.0553%;
}
.w-170 {
  width: 15.668%;
  min-width: 170px;
  flex: 0 0 15.668%;
}
.w-169 {
  width: 15.668%;
  min-width: 169px;
  flex: 0 0 15.668%;
}
.w-169 .w-151 {
  width: 88.82%;
  min-width: 151px;
  flex: 0 0 88.82%;
}
.item-grid-account .w-145 {
  width: 13.8226%;
  min-width: 145px;
  /* flex: 0 0 13.8226%; */
}
.discount-selector select {
  width: 53px !important;
}
.discount-selector input {
  width: calc(100% - 53px) !important;
  max-width: none !important;
}
.tax-selector {
  clear: both;
}
.tax-selector .tax_percentage {
  width: 61px !important;
}
.tax-selector .tax_label {
  width: calc(100% - 59px) !important;
}
.item-grid-account .add-tags-button {
  float: left;
  margin-top: 16px;
  border: 0;
  background-color: transparent;
}
.taxable-amount span {
  width: calc(100% - 29px) !important;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 0.71;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  white-space: nowrap;
}
.item-grid-account .item-row {
  flex-direction: column;
  padding: 16px 15px 15px 16px;
}
.item-grid-account .item-row:hover {
  background-color: rgba(var(--cerulean-blue-rgb), 0.04);
}
.item-grid-account .accounts-dropdown,
.item-grid-account .accounts-dropdown input {
  width: 100% !important;
  clear: both;
}

.overflow-coloumn {
  height: auto;
  overflow-y: hidden;
  overflow-x: auto;
}
#item-details-wrapper input.error,
#item-details-wrapper select.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
.item-grid-account
  .item-row
  .item-grid-account
  .item-row
  .row:last-child
  .item-col:last-child
  .label-wrapper
  label {
  width: calc(100% - 20px);
  text-align: right;
}
.item-grid-account .item-col .label-wrapper .info-btn {
  right: 0;
}
.item-grid-account .item-col .label-wrapper label:not(".required") {
  padding-right: 6px;
}

.add-line-btn-wrapper {
  display: inline-block;
  width: calc(100% - 32px);
  padding: 18px 0;
  margin: 0;
  margin-left: 16px;
}
.add-line-btn-wrapper a {
  float: left;
  flex-wrap: wrap;
}
.discount-selector input {
  width: 68px !important;
}

.discount-type-wrapper {
  width: 82px !important;
  height: 22px;
  float: right;
  -webkit-box-shadow: 0 2px 1px 0 var(---black-shadow6);
  box-shadow: 0 2px 1px 0 var(---black-shadow6);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  border-radius: 4px;
}
.discount-type-wrapper button {
  width: 50%;
  height: 20px;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  background-color: var(--white-fill);
  transition: 0.2s background ease-in-out;
  border-radius: 4px 0 0 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
}
.discount-type-wrapper button.active {
  border-radius: 3px;
  background-color: var(--cerulean-blue);
  color: var(--white-fill);
}
.item-grid-account .item-col.w-200 {
  width: 19.06578%;
  min-width: 200px;
  max-width: none !important;
  flex: 0 0 19.06578%;
}
.item-grid-account .item-col.width-100 {
  width: 9.5329%;
  min-width: 100px;
  flex: 0 0 9.5329%;
  max-width: none !important;
}
.item-grid-account .item-col.w-310 {
  width: 29.4397%;
  min-width: 310px;
  flex: 0 0 29.4397%;
  max-width: none !important;
}
.item-grid-account .item-col.w-194 {
  width: 18.4236%;
  min-width: 194px;
  flex: 0 0 18.4236%;
  max-width: none !important;
}
.tax-amt-col {
  text-align: right;
}
.tax-amt-col label {
  width: calc(100% - 22px);
  text-align: right;
}
.item-grid-account .label-wrapper label {
  margin-bottom: 0 !important;
}
.item-grid-account .label-wrapper {
  margin-bottom: 8px !important;
}
.item-grid-account .item-col .tax-selector {
  margin-top: 33px;
}
.item-grid-account .item-col .tax-selector:nth-of-type(1) {
  margin-top: 0 !important;
}
.item-grid-account .item-col .tax-amount-label {
  padding-top: 4px !important;
}
.item-grid-account .discount-selector .accounts-dropdown {
  width: calc(100% - 68px);
}
.item-grid-account .discount-selector .accounts-dropdown .search-dropdown {
  height: 41px;
  border-radius: 0 6px 6px 0 !important;
  border-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 41px !important;
}
.item-grid-account .accounts-dropdown .dropdown-toggle[aria-expanded="true"],
.item-grid-account .accounts-dropdown .search-dropdown {
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
}

.tax-selector .tax-select {
  width: calc(100% - 61px);
  margin-top: 0 !important;
}
.tax-selector .tax_percentage {
  border: solid 1px var(--new-whisper) !important;
  border-radius: 6px 0 0 6px !important;
}
.tax-selector .tax-select div[class$="-control"] {
  border-radius: 0 6px 6px 0 !important;
  border-left: 0 !important;
  padding-right: 4px !important;
  height: 41px !important;
}
.tax-selector .tax-select div[class$="-ValueContainer"] {
  padding-left: 7px !important;
  padding-right: 4px !important;
}
.tax-selector .tax-select div[class$="-placeholder"] {
  margin: 0 !important;
}
.tax-selector .tax-select div[class$="-IndicatorsContainer"] {
  display: inline-block !important;
}

.tax-selector .tax-select div[class$="-indicatorContainer"] {
  padding: 13px 0 !important;
  margin: 0 !important;
}
.tax-selector .tax-select div[class$="-Svg"],
.tax-selector .tax-select div[class$="-indicatorContainer"] svg {
  margin: 0 !important;
  width: 15px !important;
  height: 15px !important;
}
.discount-selector .accounts-dropdown .dropdown-menu {
  min-width: calc(100% + 68px) !important;
}
.discount-selector .accounts-dropdown .dropdown-menu a {
  margin-left: 0 !important;
}
.item-grid-account .w-380 {
  width: 36.225%;
  min-width: 380px;
  flex: 0 0 36.225%;
}
.item-grid-account .w-310 {
  width: 29.4397%;
  min-width: 310px;
  flex: 0 0 29.4397%;
}
#item-det-container .discount-selector.error {
  border-radius: 6px !important;
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
  border: solid 1px var(--red) !important;
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
}
#item-det-container .discount-selector.error .error {
  border: 0 !important;
}
#item-det-container .discount-selector.error input[name="discount"] {
  border-right: solid 1px var(--new-whisper) !important;
}
#item-det-container .tax-selector input.tax_percentage {
  height: 41px !important;
}
#item-det-container .tax-selector.error input {
  font-size: 13px;
  display: block;
  line-height: 19px;
  background-color: var(--rose-white) !important;
  border: solid 1px var(--red) !important;
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
}
#item-det-container .tax-selector.error input.tax_percentage {
  border-right: 0 !important;
}
#item-det-container .tax-selector.error .taxcode-dropdown .search-dropdown {
  border-left: solid 1px var(--new-whisper) !important;
}
#item-det-container
  .tax-selector.error.gst_tax_item
  .taxcode-dropdown
  .search-dropdown {
  border-left: solid 1px var(--red) !important;
}
#item-det-container .tax-selector.error .custom-select div[class$="-control"] {
  border: solid 1px var(--red) !important;
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
  border-left: 0 !important;
}
.discount-selector.error input,
#item-det-container .accounts-dropdown input.search-dropdown.error,
.tax-selector.error input {
  /* border: 0 !important; */
  background-color: var(--rose-white) !important;
}
.discount-selector .accounts-dropdown + .error,
.tax-selector .custom-select + .error {
  display: none !important;
}
#item-det-container .item-col span.error {
  padding: 0 !important;
}
.tax-selector .display-none {
  display: none;
}

.add-line-button-wrapper {
  padding-left: 16px;
  padding-right: 20px;
  margin-top: 19px;
}
.add-line-button-wrapper .add-line {
  margin-bottom: 24px;
  padding-bottom: 18px;
}
.item-det-container .item-det-row {
  margin-bottom: 0 !important;
}
.add-line-button-wrapper .add-line-button span {
  line-height: 20px !important;
  margin: 0 !important;
  padding: 0 10px !important;
}
.add-line-button-wrapper .disabled {
  cursor: default !important;
  opacity: 0.5 !important;
  background-color: var(--white-fill) !important;
  color: var(--cerulean-blue) !important;
}
.total-sect-row {
  padding-right: 21px;
  padding-left: 16px;
  clear: both;
}

.item-grid-account .tax-selector .taxcode-dropdown .dropdown-menu {
  min-width: 100%;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1);
  box-shadow: 0 2px 6px 0 var(---black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  padding: "5px 0";
  text-align: "left";
  max-height: 198px;
  overflow-y: auto;
  overflow-x: hidden;
}
.item-grid-account .taxcode-dropdown .search-dropdown:not(.no-border) {
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
  border: solid 1px var(--new-whisper) !important;
  /* border-left: 0 !important; */
  position: relative !important;
  padding: 0 26px 0 12px !important;
  border-radius: 0 6px 6px 0 !important;
}
.item-grid-account .accnt-col .accounts-dropdown .search-dropdown {
  max-width: none !important;
}
.item-grid-account .accnt-col .accounts-dropdown .dropdown-menu {
  left: 0 !important;
  right: auto !important;
  padding: 0 !important;
}
.accounts-dropdown .dropdown-menu .dropdown-item.active span,
.accounts-dropdown .dropdown-menu .dropdown-item:active span,
.accounts-dropdown .dropdown-menu .dropdown-item:focus span,
.accounts-dropdown .dropdown-menu .dropdown-item:hover span {
  color: var(--charcoal-grey) !important;
}
.removeItem {
  padding: 4px;
}
.tax-delete-is-disabled {
  pointer-events: none;
}
.w-359 {
  max-width: 359px;
}
.item-col .min-width {
  min-width: 85px !important;
}
.disabled {
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
  border: 0 !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .total-sect-row {
    padding-right: 16px;
  }
}
.search-dropdown.dropdown-toggle.discount-selector-disabled {
  background: var(--bs-gray-200) !important;
}
.coupon-dropdwon-line-item .coupon-dropdown-wrapper {
  display: flex;
  flex-direction: row;
}
.coupon-dropdwon-line-item .coupon-dropdown-wrapper .remove-item {
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.coupon-dropdwon-line-item .add-coupon-button {
  color: var(--cerulean-blue);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
}
.coupon-dropdwon-line-item .remove-item {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.row .item-coupon-dropdown {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
#coupon-dropdwon-line-item .coupon-label {
  margin-bottom: 0px !important;
}
