.plan-item-add-row-container {
    text-decoration: none;
    margin-left: 12px;
}

.plan-item-add-row-img{
    margin-bottom: 2px;
}

.plan-item-add-row-text{
    margin-left: 4px;
}