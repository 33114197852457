.lock-all-container .btn-sect button svg {
    margin-top: -3px;
    margin-right: 6px;
  }
  .lock-all-container {
    margin-top: 30px;
    padding: 20px 30px 28px 20px;
  }
  .lock-all-container h2 {
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 12px;
  }
  .lock-all-container .detail-section {
    justify-content: space-between;
  }
  .lock-all-container .detail-section .left-sect {
    max-width: 532px;
    padding-right: 10px;
  }
  .lock-all-container .detail-section .left-sect p {
    margin-bottom: 0;
  }
  .lock-all-container h2 + p {
    margin-bottom: 5px !important;
  }
  .lock-all-container .detail-section .left-sect ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    background: transparent;
  }
  .lock-all-container .detail-section ul li {
    position: relative;
    background: transparent;
    border: 0;
    margin-bottom: 8px;
    padding-left: 14px;
    box-shadow: none;
    border-radius: 0;
  }
  .lock-all-container .detail-section ul li:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--battleship-grey);
    left: 0;
    top: 8px;
  }
  .lock-btn-link,
  .lock-btn-link .lock-btn-text {
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cerulean-blue);
    text-decoration: none;
  }
  .lock-btn-link {
    display: inline-block;
    position: relative;
  }
  .lock-btn-link img {
    /* position: relative; */
    margin-right: 8px;
    width: 14px;
    height: auto;
    /* top: -3px; */
    float: left;
  }
  .lock-btn-link .lock-btn-text {
    width: calc(100% - 22px);
    display: inline-block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .lock-item-list li .item-wrapper {
      padding: 15px;
    }
    .lock-all-container {
      padding: 20px 15px;
    }  
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .lock-all-container .detail-section .left-sect {
      max-width: 70%;
    }
  }
