.deferred-income-list-table.list-table th, 
.deferred-income-list-table.list-table td {
    padding-bottom: 9px !important; 
}
.deferred-income-list-table.list-table .kebabMenu .dropdown-menu {
    inset: 5px 25px auto auto !important;
}
.deferred-income-list-table.list-table th {
    background-color: var(--white-fill);
    position: sticky;
    top: 0;
    z-index: 1;
}
.deferred-income-list-table.list-table .kebabMenu .dropdown-item:hover {
    color: var(--cerulean-blue) !important;
    background-color: transparent !important;
}
.deferred-income-list-table.list-table .transaction-number-je-entry {
    color: var(--cerulean-blue);
    text-decoration: none;
}
/* .deferred-income-list-table.list-table .transaction-number-je-entry:hover {
    color: var(--cerulean-blue);
} */