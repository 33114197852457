.exchange-rate-wrapper {
  font-size: 14px;
  font-weight: normal;

  line-height: normal;
  letter-spacing: -0.18px;
}

.exchange-rate-wrapper img,
.exchange-rate-wrapper svg {
  margin-left: 12px;
  margin-bottom: 2px;
  width: 18px;
}
.exchange-rate-wrapper svg {
  height: 18px;
}
.exchange-rate-wrapper .form-button-wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0 !important;
}

#exchangeRateEdit .form-button-wrapper {
  border-top: 0;
  padding-top: 0;
  padding-bottom: 0 !important;
}

#exchangeRateEdit, 
#exchangeRateEditCoa {
  position: absolute;
  max-width: 243px;
  width: 100%;
  z-index: 10;
  right: 50px;
}

#exchangeRateEdit .card {
  padding: 16px 14px 15px !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  width:237px;
}

#exchangeRateEdit label.required::after {
  right: auto;
}

#exchangeRateEdit .save-button,
#exchangeRateEdit .cancel-button {
  height: 32px;
  padding: 0 24px;
}

#exchangeRateEdit .input-group-prepend div {
  border-radius: 5px 0 0 5px;
  background-color: rgba(var(--peak-point-rgb), 0.08);
  border: solid 1px var(--new-whisper);
  border-right: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 0.77;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding: 14px 12px;
  height: 40px;
}

#exchangeRateEdit .input-group input {
  border-radius: 0 5px 5px 0;
  border: solid 1px var(--new-whisper);
  border-left: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  height: 40px;
}
.exchange-rate-edit-wrap{
  display: inline-block;
}
.exchange-rate-display-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 10px;
}
.exchange-date-rate-wrapper-multiline{
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: end;
  font-size: 12px !important;
}
.exchange-date-rate-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.exchange-date-rate-wrapper-multiline.exchange-converted-rate-wrapper{
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: 12px !important;
}
.exchange-rate-icon-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: end
}
#exchangeRateEdit label{
  text-align: start;
  width:100%;
}
/* #exchangeRateEdit.coa-exchange .card {
  padding: 16px 14px 15px !important;
 
} */