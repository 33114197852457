.tax-rate-option-wrapper{
    padding: 12px 12px 10px 12px;
    cursor:pointer;
}
.tax-rate-option-account-name{
    padding: "2px 1px 2px 0";
    font-size: "14px";
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: -0.11px;
    color: var(--charcoal-grey);
    text-transform: none;
}

.tax-rate-option-account-root{
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: var(--battleship-grey);
    margin-top: 4px;
    white-space: normal;
}
.tax-rate-option-wrapper:hover{
    background-color: var(--whisper) !important;
}
