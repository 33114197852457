#short_code {
    max-width: 162px;
    clear: both;
    padding: 15px 22px 15px 12px !important;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill) !important;
    text-transform: uppercase;
}
#short_code:focus {
    border: 1px solid var(--cerulean-blue) !important;
    box-shadow: none !important;
    outline: 0 !important;
}
#short_code.unavailable {
    -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
    box-shadow: 0 2px 6px 0 var(---black-shadow1) !important;
    border: solid 1px var(--red) !important;
    background-color: var(--rose-white) !important;
}
#short_code.available {
    background: url("../../../../assets/images/tick-small.svg") no-repeat right 8px center;
    background-size: 11px 9px !important;
}
.input-with-loader {
    width: auto;
    float: left;
    clear: both;
}
.unavailable-span,
.available-span {
    display: block;
    float: left;
    width: 166px;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: var(--red);
    margin-top: 5px;
    clear: both;
}
.available-span {
    color: var(--crusoe);
}
#short_code.form-control:disabled {
    border-radius: 6px;
    background-color: var(--warm-white) !important;
    border-color: var(--warm-white) !important;
    color: rgba(var(--charcoal-grey-rgb), 0.5) !important;
    cursor: auto;
}
.info-btn {
    display: inline-block;
    width: 16px;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    box-shadow: none;
    border: 0;
    cursor: pointer;
    top: 3px;
    right: -4px;
}
.info-btn img {
    width: 100%;
    float: left;
}
@media (max-width: 1024px) {
    .info-btn {
        width: 22px;        
    }
    .pie-container .header-wrapper .info-btn {        
        top: 0;
    }
    .short-code-label .info-btn,
    .discount-label .info-btn,
    .card-prefix-customization h1 .info-btn {        
        top: 5px;
    }
    .tax-amt-col .info-btn,
    .item-grid-account .item-col.taxes .info-btn,
    .account-category-checkbox .info-btn {
        top: -1px;
    }
    .label-wrapper.cn-subject-wrapper .info-btn,
    .lock-item-form .info-btn {
        top: -2px !important;
    }
    
}