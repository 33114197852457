.form-top-section {
    padding: 24px 24px 12px 24px;    
}
#c_pan, #v_gst_number{
    text-transform: uppercase;
}
.vendor-gst-disable {
    opacity: 0.5;
}

/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}
@media (min-width: 768px) and (max-width: 991px) {   
    
  
    .main-card .top-action-items-wrapper .top-action-lists {
        clear: both;
        float: left !important;
        margin-left: 0 !important;
        margin-top: 14px !important;
    }
   
}

@media (min-width: 768px) and (max-width: 1024px) {
    
    
    .pills-tab .nav-link {
        min-width: 136px;
        margin: 0 !important;
    }   
    
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
}
@media (max-width: 1040px) {
    #pills-tab .mw-325 {
        max-width: calc(295px + 2rem) !important;
    }
}