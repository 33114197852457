.banking-display-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.banking-item-img-cont {
  width: 36px;
  height: 36px;
  background: #edeff4;
  padding: 8px;
  border-radius: 6px;
  overflow: hidden;
}
.banking-item-details {
  display: block;
}
.banking-item-details-title,
.banking-item-details-amount,
.banking-item-details-anchor {
  margin: 0;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 1;
}
.banking-item-details-title {
  margin-bottom: 8px;
  font-size: 12px;
  color: #747d84;
}
.banking-item-details-amount {
  font-size: 18px;
  color: #040404;
}
.banking-item-details-anchor {
  color: #0d5ae5;
  font-size: 12px;
  text-decoration: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.banking-item-details-anchor span {
  font: inherit;
  color: inherit;
}
