/** Add Customer **/
.add-consumer-form {
  width: 100%;
}
.add-consumer-form label {
  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
}
.add-consumer-form .link-label-grouped label {
  float: left;
}
.add-consumer-form .remove-row-padding {
  padding: 0 !important;
}
.add-consumer-form input[type="text"],
.add-consumer-form input[type="email"],
.add-consumer-form textarea,
.gst-select > .dropdown-toggle,
.currency-select > .dropdown-toggle,
.custom-select > .dropdown-toggle {
  /* max-width: 325px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.add-consumer-form input:disabled,
.add-consumer-form textarea:disabled {
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
  border: 0 !important;
}
.gst-select > .dropdown-toggle,
.currency-select > .dropdown-toggle,
.custom-select > .dropdown-toggle {
  padding: 0 12px;
}

.add-consumer-form textarea {
  height: 60px;
  resize: none;
  line-height: 1.2;
  padding: 9px 12px;
}
.currency-select .dropdown-toggle {
}
.gst-select .dropdown-toggle .filter-option-inner-inner,
.currency-select .dropdown-toggle .filter-option-inner-inner,
.custom-select .dropdown-toggle .filter-option-inner-inner {
  height: 37px;

  font-size: 14px;
  font-weight: normal;

  line-height: 37px;
  letter-spacing: normal;
  /* color: var(--charcoal-grey); */
}
/* .add-consumer-form input::-webkit-input-placeholder {
      color: rgba(var(--charcoal-grey-rgb), 0.55);
      text-transform: none;
  }
 .add-consumer-form input::-moz-placeholder { 
      color: rgba(var(--charcoal-grey-rgb), 0.55);
      text-transform: none;
  }
 .add-consumer-form input:-ms-input-placeholder {
      color: rgba(var(--charcoal-grey-rgb), 0.55);
      text-transform: none;
  }
 .add-consumer-form input:-moz-placeholder {
      color: rgba(var(--charcoal-grey-rgb), 0.55);
      text-transform: none;
  } */

/* .add-consumer-form textarea::-webkit-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  }
 .add-consumer-form textarea::-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  }
 .add-consumer-form textarea::-ms-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  }
 .add-consumer-form textarea::-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  } */
.fields-wrapper {
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.add-consumer-form .form-top-section {
  padding: 24px 24px 0 24px;
}
.add-consumer-form .row {
  margin-bottom: 20px;
  clear: both;
}

.configure-payment-terms-link {
  display: block;
  float: right;
  text-decoration: none;

  font-size: 14px;
  font-weight: bold;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
.manage-tags-link {
  margin-top: 0;
}
.configure-payment-terms-link,
.manage-tags-link {
  background-image: url("../../../../../assets/images/settings.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 22px;
  line-height: 16px;
  margin-bottom: 12px;
  width: auto !important;
}
.manage-tags-link:before {
  width: auto !important;
}
.link-label-grouped {
  width: 100%;
  max-width: 325px;
  float: left;
}
.row.custom-row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.row.custom-row .col-lg-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.address-tab-pane h2 {
  font-size: 16px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey);
  margin-bottom: 24px;
  margin-right: 17px;
  float: left;
}
.unsaved-msg {
  font-size: 14px;
  font-weight: normal;
  font-style: italic !important;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--battleship-grey);
}
.address-tab-pane h2 .unsaved-msg {
  margin-left: 8px;
}
.right-aligned-checkbox {
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 3px;
}
.add-consumer-form .right-aligned-checkbox label {
  font-size: 14px;
  font-weight: normal;

  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  padding-left: 0;
  float: left;
  position: relative;
  top: -1px;
}
.add-consumer-form .right-aligned-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px var(--slate);
  /* background-color: var(--white-fill); */
  float: left;
  margin-top: 0;
  margin-right: 8px;
}

.billing-address {
  position: relative;
}
.billing-address:after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc((100%) - 20px);
  top: 0;
  right: 0;
  border-right: 1px solid var(--approx-whisper);
}
.custom-select > .dropdown-toggle:focus-visible {
  outline: 0 !important;
}
.form-select {
  background: none !important;
  padding: 0;
}
.custom-select > .dropdown-toggle {
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.custom-select > .dropdown-toggle:after {
  content: "";
  position: absolute;
  border: 0 !important;
  width: 24px;
  height: 24px;
  background: url("../../../../../assets/images/grey-arrow-icon-down.svg")
    no-repeat center;
  background-size: 24px 24px;
  right: 8px;
  top: 50%;
  margin: -12px 0 0 0 !important;
}
.custom-select > .dropdown-toggle.show:after {
  transform: rotate(-180deg);
}
.custom-select .filter-option {
  padding-top: 1px;
}
.custom-select .filter-option .filter-option-inner-inner {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.custom-select {
  border: 0;
}

.custom-select,
.custom-select.bootstrap-select .dropdown-menu {
  width: 100% !important;
}

.custom-select .dropdown-menu .dropdown-item.active,
.custom-select .dropdown-menu .dropdown-item:active {
  color: var(--cerulean-blue);
  background-color: transparent;
}
.custom-select .dropdown-menu .dropdown-item.disabled,
.custom-select .dropdown-menu .dropdown-item:disabled {
  color: var(--heather) !important;
  pointer-events: none;
  background-color: transparent;
}

.contact-container {
  padding: 20px 16px 24px 16px;
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
}
.contact-container.active {
  padding: 16px 16px 24px;
  border-radius: 4px;
  border: solid 1px rgba(var(--cerulean-blue-rgb), 0.11);
  background-color: rgba(var(--cerulean-blue-rgb), 0.05);
}
.contact-container:not(:first-child) {
  margin-top: 16px;
}

.add-consumer-form .form-button-wrapper {
  padding: 24px 24px 0;
}

/* #addCustomerModal.add-consumer-form .form-button-wrapper {
    padding: 24px 50px;
  }
  
  #addCustomerModal .fields-wrapper {
    border: none;
  } */

/* #configPaymentTermsModal {
    right: 0;
    width: 480px;
  } */
.partial-modal .close-button-container {
  padding-bottom: 4px;
}
.partial-modal .modal-body {
  padding: 0 0 20px 0;
}
.partial-modal .modal-body .row .col:first-child {
  padding-left: 40px;
  padding-right: 10px;
}
.partial-modal .modal-body .row .col:last-child {
  padding-right: 40px;
  padding-left: 10px;
}
.partial-modal .user-form-head {
  padding-left: 36px;
  padding-right: 40px;
}
.partial-modal form {
  width: 100%;
  float: left;
  clear: both;
}
.title {
  display: block;
  width: 100%;
}

.partial-modal .title {
  font-size: 12px;
  font-weight: bold;

  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey);
  text-transform: uppercase;
}

.edit-form-wrap .row:not([class^="title-row"]):hover {
  background-color: var(--solitude);
}

.form-check .form-check-input {
  float: left;
  margin-right: 0.4375em;
  margin-top: 0.05em;
}

.delete-icon {
  position: absolute;
  right: 1em;
}

.contact-border {
  border-bottom: 1px solid var(--approx-whisper);
  margin: 0 -24px;
}

/* .edit-form-wrap .term-title,
  .edit-form-wrap input.terms {
    width: 143px;
  } */
.add-consumer-form input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.add-consumer-form .multiSelectContainer {
  top: -5px;
}
.add-consumer-form .multiSelectContainer .searchBox {
  border: 0 !important;
  width: 105px;
  padding: 0px !important;
}
.add-consumer-form .multiSelectContainer .searchWrapper {
  border: solid 1px var(--new-whisper);
  padding: 4px 34px 4px 12px !important;
}
.custom-select.bootstrap-select .dropdown-menu ul {
  max-height: 350px !important;
}
#c_display_name.form-control:disabled {
  border-radius: 6px;
  background-color: var(--warm-white) !important;
  border-color: var(--warm-white) !important;
  color: rgba(var(--charcoal-grey-rgb), 0.5) !important;
  cursor: auto;
}
.shipping-address .form-control:disabled,
.custom-select.disabled .dropdown-toggle {
  border-radius: 6px;
  background-color: var(--warm-white) !important;
  border-color: var(--warm-white) !important;
  color: rgba(var(--charcoal-grey-rgb), 0.5) !important;
  cursor: auto;
}
.custom-select.disabled {
  cursor: auto !important;
}
.form-check-input[type="radio"] {
  width: 16px;
  height: 16px;
}
.form-check-input[type="radio"]:checked {
  background-color: var(--white-fill) !important;
  border-color: var(--cerulean-blue) !important;
  background-image: url("../../../../../assets/images/oval.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 8px 8px !important;
}
.custom-select.error + .dropdown-toggle {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.customer-form-section-disable,
.tag-form-section-disable,
.term-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}

#c_pan,
#c_gst_number {
  text-transform: uppercase;
}
.gst-disable {
  opacity: 0.5;
}
.autocomplete #search_tableautocomplete-list div span {
  background-color: var(--blanched-almond);
  font-size: 14px;
}
#tax_preference .MuiFormControlLabel-root,
#tax_preference .MuiFormControlLabel-root .MuiRadio-root {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 991px) {
  .add-consumer-form .row {
    margin-bottom: 0;
  }
  .add-consumer-form .row .col-12 {
    margin-bottom: 20px;
  }
  .row.custom-row .subject-col {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .row.custom-row .subject-col {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
