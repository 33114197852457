.disable-btn {
  pointer-events: none;
  cursor: default;
}

.disable-btn > span {
  color: #91919c !important;
}

.sendReminder-p0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
