.tds-fields-wrapper {
  width: 100%;
}
.tds-dropdown {
  width: calc(100% - 110px) !important;
}
.tds-entry {
  width: 110px !important;
}
.tds-entry input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
}
.tds-select.total-section-tds div[id$="-listbox"] {
  top: auto !important;
  bottom: 46px !important;
}

.item-grid-account .tds-dropdown .taxcode-dropdown .search-dropdown {
  border-left: solid 1px var(--new-whisper) !important;
  border-radius: 6px 0 0 6px !important;
}
.item-grid-account .tds-dropdown .taxcode-dropdown .search-dropdown:focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.select-tds-dropdown div.error {
  border-radius: 6px !important;
    color: var(--red);
    font-size: 13px;
    display: block;
    line-height: 19px;
    border: solid 1px var(--red) !important;
}
.error .tds-dropdown input, 
.error .tds-entry input{  
  background-color: var(--rose-white)!important;
}
.tds-fields-wrapper.error {
  border: solid 1px var(--red)!important;
  border-radius: 6px;
}
.tds-fields-wrapper.tds-fields-full-width .tds-dropdown {
  width: 100% !important;
}
.tds-fields-wrapper.tds-fields-full-width .tds-dropdown input {
  border-radius: 6px !important;
}