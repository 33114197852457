.reusable-icon-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
}
.reusable-icon-button:hover {
  border-radius: 50%;
  background-color: #e9ecef !important;
  cursor: pointer;
}
.reusable-icon-button:active {
  border-radius: 50%;
  background-color: #e9ecef !important;
}
.reusable-icon-button:focus {
  border-radius: 50%;
  background-color: #e9ecef !important;
}
