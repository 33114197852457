@media screen and (max-height: 699px) {
  .subscription_cancel_modal_position .css-1h2v4zo-menu {
    bottom: 100%;
    top: auto !important;
  }
}

.form-button-wrapper-cancelled-modal {
  padding-top: 8px !important;
}

.subcription-void-form-field-wrapper {
  overflow: auto !important;
}

.credit-custom-dropdown-info {
  font-weight: normal;
  font-size: 13px;
}
