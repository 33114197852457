.reusable-secondary-button-normal{
    background-color:  var(--white-fill);
    color: var(--booger);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px;
    width: fit-content;
    height: fit-content;
    min-width: 125px;
    border-radius: 6px;
    border: 1px solid var(--booger);
}
.reusable-secondary-button-normal.disabled-btn{
    opacity: 0.7;
}
.reusable-secondary-button-normal:focus{
    box-shadow: none;
    outline: 2px solid var(--booger);
    outline-offset: 3px;
}
.reusable-secondary-button-normal:hover{
    cursor: pointer;
}
.reusable-secondary-button-normal.disabled-btn:hover{
    cursor:not-allowed;
}
.reusable-secondary-button-danger{
    background-color:  var(--white-fill);
    color: var(--red);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px;
    width: fit-content;
    height: fit-content;
    min-width: 125px;
    border-radius: 6px;
    border: 1px solid var(--red);
}
.reusable-secondary-button-danger.disabled-btn{
    opacity: 0.7;
}
.reusable-secondary-button-danger:focus{
    box-shadow: none;
    outline: 2px solid var(--red);
    outline-offset: 3px;
}
.reusable-secondary-button-danger:hover{
    cursor: pointer;
}
.reusable-secondary-button-danger.disabled-btn:hover{
    cursor:not-allowed;
}
