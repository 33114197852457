.date-container {
  height: 41px;
  /* border: 1px solid var(--new-whisper); */
  height: 41px;
  border-radius: 6px;
  display: flex;
  width: 100%;
}
/* .date-container.error {
  box-shadow: 0 2px 6px 0 rgba(var(--black-rgb), 0.01);
  border: solid 1px var(--red);
  background-color: var(--rose-white);
} */

.css-1lwbda4-MuiStack-root,
.date-container > div {
  width: 100%;
}

.css-1lwbda4-MuiStack-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.MuiFormControl-root .MuiInputBase-root,
.date-container .MuiFormControl-root .MuiInputBase-root input:focus {
  border: 0 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  display: none;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.date-container .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--new-whisper) !important;
}
.date-container .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--new-whisper) !important;
}
.date-container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--cerulean-blue) !important;
}
.date-container.error .MuiInputBase-root {
  background-color: var(--rose-white);
}
.date-container.error .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--red) !important;  
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.MuiInputBase-input {
  padding: 9px 12px !important;
 
  font-size: 14px !important;
  font-weight: normal !important;
  
 
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.MuiInputBase-root {
  padding-right: 10px !important;
}
.date-container .MuiFormControl-root .MuiInputBase-formControl {
  height: 40px;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root .css-i4bv87-MuiSvgIcon-root,
.MuiIconButton-root .MuiSvgIcon-root {
  display: none !important;
}

.MuiCalendarPicker-root .MuiIconButton-root .MuiSvgIcon-root {
  display: block !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.MuiInputBase-input {
  width: 84% !important;
}

.date-container button {
  background: url(../../../assets/images/datepicker-icon.svg) no-repeat center !important;
  background-size: 20px 20px !important;
  border-radius: 0 !important;
  width: 20px;
  height: 20px;
  padding: 0;
}

.css-1v994a0,
.PrivatePickersFadeTransitionGroup-root > div {
  color: var(--charcoal-grey);
}

.css-j5h6pi-MuiPopper-root,
div[data-popper-placement="bottom"] {
  inset: 4px auto auto 16px !important;
}

div[data-popper-placement="top"] {
  inset: auto auto 4px 16px !important;
}

.css-1w13o7u-MuiTypography-root,
.MuiTypography-root {
  color: var(--battleship-grey) !important;
}
.date-selector-wrapper {
  clear: both;
}
