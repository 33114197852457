.resusable-form-select-field {
  margin: 0.5rem 0 0 0 !important;
}
.select-field-wrap {
  margin-bottom: 1.2rem !important;
}
.resusable-form-select-field.error {
  /* border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important; */
  /* border-radius: 4px !important; */
}
.resusable-form-select-field.error div {
  /* background-color: var(--rose-white) !important; */
}
.resusable-form-select-field.error div[class*="-control"] {
  border: 0 !important;
}
.relative-wrapper {
  position: relative;
}
.custom-clear-button {
  position: absolute;
  top: 70%;
  right: 45px;
  transform: translateY(-50%);
  cursor: pointer;
}
.multi-select-cust-option {
  background-color: var(--white-fill) !important;
}
.select-field-wrap.supply-state-select-field {
  margin-bottom: 0 !important;
}
