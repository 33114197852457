.reminder-email-config-form-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 700px;
  justify-content: center;
  gap: 20px;
  margin-left: 50px;
  margin-top: 40px;
}
.reminder-email-config-form-wrapper .field-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
}
.reminder-email-config-form-wrapper .field-account-container {
  display: flex;
  flex-direction: column;
}
.reminder-email-config-form-wrapper .reminder-email-config-select-one {
  width: 200px;
}
.reminder-email-config-form-wrapper .reminder-email-config-select-two {
  width: 230px;
}

.reminder-email-config-form-wrapper .account-select {
  min-width: 215px;
  margin-bottom: 5px;
} 
.reminder-email-config-form-wrapper .account-select-wrapper {
  width: 215px;
}
.reminder-email-config-form-wrapper .custom-select-option-wrapper {
  padding: 8px;
  display: flex;
  flex-direction: column;
  color: #323e48 !important;
  background-color: var(--white) !important;
}
.reminder-email-config-form-wrapper .custom-select-option-wrapper:hover {
  color: var(--cerulean-blue) !important;
  background-color: var(--white) !important;
}
.reminder-email-config-form-wrapper .select-option-selected {
  color: var(--cerulean-blue) !important;
  background-color: var(--white) !important;
}
.reminder-email-config-form-wrapper .custom-select-option-description {
  font-size: 12px;
  color: var(--battleship-grey);
}