.form-control-reusable-checkbox {
    margin-left: 0 !important;
}
.form-control-reusable-checkbox span {
    color: var(--charcoal-grey) !important;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.18px;
}
/* .form-control-reusable-checkbox .form-check input[type = "checkbox"] {
    border: 1px solid var(--slate);
    border-radius: 2px;
    float: left;
    margin-right: 8px;
    margin-top: 0;
}
.form-control-reusable-checkbox .form-check input[type="checkbox"]:checked {
    background-color: var(--cerulean-blue) !important;
    background-image: url("../../../../assets/images/checkbox-checked.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 6px;
} */