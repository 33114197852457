#reminder-email-frequency-create-modal {
  z-index: 2000 !important;
}
.reminder-email-frequency-modal {
  padding-bottom: 132px;
}
.reminder-email-frequency-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;
  margin-top: 40px;
  margin-left: 50px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  input {
  appearance: none;
  background-color: var(--white-fill);
  border: 1px solid var(--new-whisper);
  border-radius: 6px;
  box-shadow: none;
  color: var(--charcoal-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 0.71;
  padding: 11px 12px;
  margin-right: 10px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  .field-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 10px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  .label-text {
  margin-right: 10px;
  margin-bottom: 0px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  .days-input {
  width: 100px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  .due-date-dropdown {
  width: 110px;
  margin-right: 10px;
}
.reminder-email-frequency-form-wrapper
  .reminder-email-frequency-form-container
  .applicable-for-dropdown {
  width: 200px;
}

