.reusable-form-input-control{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.reusable-form-input-control label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 12px !important;
}

.reusable-form-input-control input[type="text"],
.reusable-form-input-control input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  clear: both;
}
.reusable-form-input-control input[type="text"]:disabled,
.reusable-form-input-control input[type="number"]:disabled,
.reusable-form-input-control input[type="email"]:disabled {    
  background-color: var(--warm-white);
  border-color: var(--warm-white);  
  color: rgba(var(--charcoal-grey-rgb), 0.5)
}
.reusable-form-input-control input:focus-within {
  border: none;
  box-shadow: none;
}
.reusable-form-input-control input[type="text"]:focus,
.reusable-form-input-control input[type="email"]:focus, 
.reusable-form-input-control input[type="number"]:focus,
.reusable-form-input-control input[type="text"]:focus-within,
.reusable-form-input-control input[type="number"]:focus-within,
.reusable-form-input-control input[type="email"]:focus-within{
  box-shadow: none;
  outline: 0;
}
.reusable-form-input-control input[type="text"]:focus,
.reusable-form-input-control input[type="number"]:focus,
.reusable-form-input-control input[type="email"]:focus {
  border: 1px solid var(--cerulean-blue);    
}
.reusable-form-input-control input[type="text"].error,
.reusable-form-input-control input[type="number"].error,
.reusable-form-input-control input[type="email"].error {
  background-color: var(--rose-white);
  border: 1px solid var(--red);
}
.reusable-form-input-control input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-control input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-control input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-control input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-control input::placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.form-input-icon-wrapper.with-side-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-input-field-icon {
  margin-left: 10px;
}