.add-new.add-sub {
  position: relative;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  font-size: 0;
  color: transparent;
  background-size: 16px 16px;
  margin: 0 auto;
  padding: 0;
  transform: translate(-50%, -50%);
}
.add-sub-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 36px;
}
.addSub {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  text-decoration: none;
}

#deleteSubModal .delete-modal {
  outline: none;
}

.delete-disabled {
  pointer-events: none;
  opacity: 0.5;
}



.currency-col {
  display: flex;
  flex-direction: column;
}

.card-org-hierarchy .level-1 .item-label {
  font-weight: bold;
}

.dropdown-menu.disabled-menu li a {
  pointer-events: none;
  opacity: 0.5;
}

.card-org-hierarchy
  .grid-table
  .table-collapsible-item:first-child
  .parent-row.level-1
  .horizontal-border {
  border-top: 0;
}
.card-org-hierarchy
  .grid-table
  .table-collapsible-item:first-child
  .parent-row
  .bottom-border {
  border-bottom: 0;
}
.card-org-hierarchy
  .grid-table
  .table-collapsible-item:first-child
  .parent-row.expanded
  .bottom-border {
  border-bottom: 1px solid var(--approx-whisper);
}
.card-org-hierarchy
  .grid-table
  .table-collapsible-item
  .parent-row.btn-container
  + .item-label {
  line-height: 1;
  padding: 10px 14px 10px 10px;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.addSubOrg-form input.error,
.addSubOrg-form select.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}

#addSubOrg-form input,
#addSubOrg-form .form-select,
#addSubOrg-form label {
  clear: both;
}
#addSubOrg-form input,
#addSubOrg-form .form-select {
  width: 100%;
}
.grid-table.org-hierarchy-grid-table {
  margin-bottom: 75px;
}
.addSubOrg-form .row input:disabled {
  background-color: var(--warm-white);
  color: var(--battleship-grey);
  border: 0 !important;
}
.sub-org-input{
  width:400px !important;
  max-width: 400px !important;
 margin-bottom: 20px !important;
}
.sub-org-input input{
  font-size: 14px !important;
  font-weight: normal !important;
}

.add-new-blue-btn{
  width: 100%;
  height: 36px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  float: left;
  clear: both;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.form-control{
  color: var(--charcoal-grey) !important;
}
.sub-org-create-edit-form-modal-wrap{
  width:480px !important;
  padding: 0px 40px !important;
}
#addSubOrg-form .form-select-org-hierarchy-wrap{
  margin-top: 0px !important;
  margin-bottom: 10px !important ;
}
#addSubOrg-form .form-select-org-hierarchy{
  margin-top: 0px !important;
  margin-bottom: 0px !important ;
}
#addSubOrg-form .form-select-org-hierarchy-currency{
  margin-top: 0px !important;
  margin-bottom: 25px !important ;
}

#addSubOrg-form label{
  margin-bottom: 12px;
  font-weight: bold;
}
.add-sub-org-modal-wrapper .reusable-modal-btn{
  justify-content: flex-start !important;
}
.addSubOrg-form .form-button-wrapper {
  position: relative;
  border-top: 0;
  padding-top: 0;
}