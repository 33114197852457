.table-settings {
    display: block;
    width: 40px !important;
    height: auto;
    margin-left: auto;
}
.table-settings img {
    width: 100%;
    height: auto;
}

.table-settings-modal {
    text-transform: none !important;
    font-weight: normal;
}
.table-settings-modal .modal-dialog {
    max-width: 800px;
}
.table-settings-modal .modal-content {
    padding: 30px 40px 23px 40px;
    
}
.table-settings-modal .modal-body {
    padding-bottom: 10px !important;
}
.table-settings-modal .modal-footer {
    position: relative;
    padding: 0 !important;
}
.table-settings-modal .modal-footer .form-button-wrapper {
    border-top: 0 !important;
    padding: 24px 0 0 0 !important;
    text-align: left;
    margin: 0 !important;
}
.table-settings-modal .modal-footer .modal-footer-error-center {
    display: block;
    width: auto;
    margin: 16px auto 0 0;
    white-space: normal;
    text-align: left;
}
.table-settings-modal .modal-footer:before {
    content: '';
    width: calc(100% + 80px);
    position: absolute;
    left: -40px;
    top: 0;
    border: 1px solid var(--approx-whisper);
}
.table-settings-modal .col-settings-form {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
}
.table-settings-modal .col-list {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}
.table-settings-modal .col-list li {
    width: 100%;
    margin: 0 4px 14px 0;
    padding: 11px 12px 12px 12px;
    border-radius: 8px;
    -webkit-box-shadow: 0 4px 8px -2px var(---black-shadow3);
    box-shadow: 0 4px 8px -2px var(---black-shadow3);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
}
.table-settings-modal .col-list li.mandatory {
  -webkit-box-shadow: 0 4px 8px -2px var(---black-shadow3);
  box-shadow: 0 4px 8px -2px var(---black-shadow3);
  border: solid 1px var(--light-white-smoke);
  background-color: var(--peak-point-shadow7);
}
.table-settings-modal .col-list li img{
    width: 10px;  
    margin: 0 16px 0 0;
}
.table-settings-modal .col-list li .locked-img {
    width: 14px;
    margin: 0 8px 0 0;
}
.table-settings-modal .col-list li .form-check .form-check-input {    
    border: 1px solid var(--slate);
    border-radius: 2px;
    float: left;    
    margin-right: 8px;
    margin-top: 0;
    width: 14px;
    height: 14px;
}
.table-settings-modal .col-list li .form-check label {
    font-size: 14px;
    font-weight: 600;    
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 0 !important;
}
.table-settings-modal .modal-header .close-btn {
    right: -28px !important;
    top: -18px !important;    
    float: right;    
    margin: 0;    
}
.table-settings-modal .form-button-wrapper .btn.save-button {
    border-radius: 6px !important;
}
.table-settings-modal .modal-header .lower-section {
    padding: 24px 0 16px 0 !important;
}
.table-settings-modal .modal-header h5 {
    margin: 0 !important;
}
.table-settings-modal .modal-header .close-button-container {
    padding: 0 !important;
}
.table-settings-modal .search-wrapper {
    width: 360px;
}
.selected-count,
.selected-count span {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;  
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}
.selected-count .selected-count {
    font-weight: bold;
    margin-right: 4px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .table-settings-modal .modal-dialog {
        max-width: 600px;
    }
}