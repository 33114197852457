.info-tag {
  position: relative;
  padding: 5px 6px 6px 6px;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 0.77;
  letter-spacing: normal;
  color: var(--white-fill);
  border-radius: 4px;
}
.tag-danger {
  background-color: var(--red);
}
.tag-warning {
  background-color: var(--gamboge);
}
.tag-Partial {
  background-color: var(--amethyst);
}
.tag-succuss {
  background-color: var(--crusoe);
}
.tag-primary {
  background-color: var(--cornflower-blue);
}
.tag-void {
  background-color: var(--slate);
}
