.system-voucher-configuration-field-wrapper {
    width: 480px;
    padding: 0 40px;
}
.system-voucher-configuration-field-wrapper .reusable-modal-btn {
    justify-content: flex-start !important;
}
.system-voucher-configuration-field-wrapper .list-container {
    padding-top: 40px;
}
.list-li.system-voucher-type-list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: solid 1px var(--new-whisper);
}
.list-li.system-voucher-type-list-item:last-child {
    border-bottom: 0;
}
.system-voucher-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.icon-buttons-wrapper {
    display: flex;
    align-items: center;
}
/* .icon-buttons-wrapper.edit-delete-icon-buttons-wrapper {
    width: 48px !important;    
} */
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn .dropdown-item {
    padding: 4px 8px;
}
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn .edit-icon-btn {
    padding: 4px 8px;
}
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn button {
    padding: 0;
}
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn button:hover,
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn button:focus {
    background-color: var(--alice-blue);
}
.icon-buttons-wrapper.edit-delete-icon-buttons-wrapper .reusable-modal-btn .dropdown-item:hover {
    background-color: transparent;
}