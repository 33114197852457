.check-left-checkbox {
    display: inline-block;
    width: 100%;
}
.check-left-checkbox label {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin-right: 4px;
    float: left;
}
.check-left-checkbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    border-radius: 2px;
    border: solid 1px var(--slate);
    background-color: var(--white-fill);
    float: left;
  }
  .check-left-checkbox input[type="checkbox"]:disabled {
    opacity: 0.5;
  }
  
  .check-left-checkbox input[type="checkbox"]:checked {
    background-color: var(--cerulean-blue) !important;
    background-image: url("../../../../assets/images/checkbox-checked.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 6px;
  }
