.inv-prefix,
.inv-group > input[type="text"] {
  height: 40px !important;
}
.input-prefix-wrap {
  max-width: calc(302px + 2rem) !important;
  position: relative;
}
.adornment-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 20px; */
  height: 40px;
}
.input-prefix-end-adornment {
  position: absolute;
  right: 10px;
}
.inv-group > input[type="text"]:disabled {
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
  border: 0 !important;
}
.coa-opening-balance-field {
  width: 100% !important;
  max-width: none !important;
  font-size: 12px !important;
}
.coa-opening-balance-field > input[type="text"] {
  width: 100% !important;
  max-width: none !important;
}
.consumer-payment-edit-prefix-input > input[type="text"] {
  width: 100% !important;
  max-width: none !important;
}
.consumer-payment-edit-prefix-input.input-prefix-wrap {
  max-width: none !important;
  width: 100% !important;
}
.hover-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.input-prefix-wrap:hover .hover-icon {
  opacity: 1;
}
