.purchase-order-to,
.ship-to {
  width: 34.4%;
  /* padding-right: 9.5%;  */
  padding-right: 12px;
  flex: 0 0 34.4%;
}

#invoice
  .table-section
  .table.purchase-order-preview-table
  thead
  th:nth-child(3),
#invoice .table-section .table.purchase-order-preview-table tr td:nth-child(3),
#invoice
  .table-section
  .table.purchase-order-preview-table
  thead
  th:nth-child(7),
#invoice .table-section .table.purchase-order-preview-table tr td:nth-child(7) {
  text-align: left !important;
}
#invoice
  .table-section
  .table.purchase-order-preview-table
  thead
  th:nth-child(6),
#invoice .table-section .table.purchase-order-preview-table tr td:nth-child(6) {
  padding-right: 12px !important;
}
#invoice
  .table-section
  .table.purchase-order-preview-table
  thead
  th:nth-child(7),
#invoice .table-section .table.purchase-order-preview-table tr td:nth-child(7) {
  padding-left: 12px !important;
}
#invoice .table-section .table.purchase-order-preview-table tr th.discount,
#invoice .table-section .table.purchase-order-preview-table tr td.discount {
  padding-right: 24px !important;
}
/* .tax-section div:first-child {
  width: 15%;
  padding-left: 25px;
} */

.purchase-order-details-section .inv-date-wrap {
  width: 23%;
  min-width: 100px;
  flex: 0 0 23%;
}
.purchase-order-details-section .payment-terms {
  width: 100%;
  min-width: 153px;
}
.purchase-order-detail-right-align {
  text-align: right;
}

#purchase-order-detail-card .info-tag {
  top: -4px;
  margin-left: 13px;
}
.purchase-order-details-header {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.purchase-order-to .vendor-name {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  padding-top: 10px !important;
}
