.dashboard-half-box .report-table-section {
  margin-top: 4px;
  margin-bottom: 5px;
  width: 100%;
  padding: 0 !important;
}
.dashboard-half-box .report-card .table-wrapper {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.dashboard-half-box .report-table {
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
  /* margin-bottom: 40px; */
  margin-bottom: 0;
}
.dashboard-half-box .report-table tr {
  cursor: pointer;
}
.dashboard-half-box .report-table tr:hover td {
  color: var(--cerulean-blue) !important;
}
