.plan-item-add-row-container {
  text-decoration: none;
  margin-left: 12px;
}

.plan-item-add-row-img {
  margin-bottom: 2px;
}

.plan-item-add-row-text {
  margin-left: 4px;
}

.subscription-view-pricing-item-container .item-cart-header-subscription-view {
  display: flex;
  justify-content: space-between;
}

.subscription-view-pricing-item-container .item-header-subscription-view {
  padding-left: 15px !important;
}
