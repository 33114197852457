.org-tag {
    display: flex;
    align-content: center;
    position: relative;
    padding: 3px 9px 3px 8px;
    border-radius: 4px;
    background-color: var(--solitude);
    top: -3px;
  }
  .org-tag img {
    width: 18px;
    height: auto;
    margin: 0 6px 0 0;
    object-fit: contain;
  }
  .org-tag span {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    line-height: 18px;
  }

  .plan-org-tag {
    display: flex;
    margin-left: -8px;
  }
  
  .addon-org-tag {
    display: flex;
    margin-left: -8px;
  }