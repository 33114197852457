.child-invoice-container{
padding: 0px 0px 30px 24px;
--bs-gutter-x: 0 !important;
}
.ri_table_info_grid{
  padding-left: 0px !important;
}
.child_table_info {
  background: #b4dcff;
  border-radius: 3px;
  padding-left: 1.5em;
  line-height: 1.71;
}

#message-img {
  margin-bottom: 2px;
  margin-right: 2px;
}

.child-data-info {
  font-size: 14px;
  color: var(--slate);
  text-align: right;
  height: 32px !important;
  padding-right:10px !important;
}
