#taxCreateModal {
  right: 0;
  width: 480px;
}
#settings_table th {
  padding: 12px 24px !important;
}

#settings_table tr td {
  padding: 13px 24px !important;
  box-shadow: none !important;
}
#settings_table.tax-conf-table tr th:first-child,
#settings_table.tax-conf-table tr td:first-child,
#settings_table.tax-conf-table tr th:nth-child(2),
#settings_table.tax-conf-table tr td:nth-child(2) {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
#settings_table.tax-conf-table tr th:first-child,
#settings_table.tax-conf-table tr td:first-child {
  padding-left: 24px !important;
  /* width: 33.6893% !important; */
  min-width: 150px !important;
}
#settings_table.tax-conf-table tr th:nth-child(2),
#settings_table.tax-conf-table tr td:nth-child(2) {
  /* width: calc(66.3107% - 370px) !important; */
  min-width: 180px !important;
}
#settings_table.tax-conf-table tr th:nth-child(3),
#settings_table.tax-conf-table tr td:nth-child(3) {
  width: 370px !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
}
#settings_table tr th.action,
#settings_table tr td.action {
  width: 53px !important;
  min-width: 53px !important;
  padding-left: 0 !important;
  padding-right: 24px !important;
}
#settings_table tr td.action .dropstart {
  width: 20px !important;
}
#settings_table tr td.action img {
  /* margin-right: 0 !important; */
}
#taxCreateModal #form-btn-tax {
  margin-top: 30px;
  width: 100%;
  float: left;
}
#taxCreateModal .save-butn {
  width: 80px;
}
.radio-wrapper {
  display: flex;
}
.radio-wrapper .form-check-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
.radio-wrapper .form-check-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
.radio-wrapper .form-check-inline:last-child {
  padding-right: 0;
}
.radio-wrapper .form-check-input[type="radio"] {
  margin-right: 6px;
}
.radio-wrapper label {
  font-size: 14px;
  font-weight: normal;

  line-height: 16px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.create-user-form-wrap.create-settings-form-wrap {
  margin-top: 12px;
}

.create-settings-form-wrap.disabled {
  position: relative;
}
.create-settings-form-wrap.disabled:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 184px;
  background: rgba(var(--white-rgb), 0.5);
  top: 0;
  left: 0;
}
.cancel-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.create-settings-form-wrap label {
  margin-bottom: 12px;
}
.create-settings-form-wrap input {
  width: 100% !important;
  max-width: none !important;
  height: 41px;
  margin-top: 0 !important;
  padding: 8px 12px 9px 12px;

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
}
/* .create-settings-form-wrap input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.create-settings-form-wrap input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.create-settings-form-wrap input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.create-settings-form-wrap input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
#taxCreateModal .custom-select input {
  height: auto !important;
}
.info-icn {
  width: 16px;
  height: auto;
  margin-right: 4px;
  position: relative;
  top: -1px;
}
.create-settings-form-wrap .custom-select {
  width: 100% !important;
  clear: both !important;
}

.settings-list-wrapper th span {
  display: inline-block;
  color: var(--battleship-grey) !important;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 2;
  text-transform: uppercase;
  white-space: nowrap;
}

#taxCreateModal.add-tax-form {
  padding: 0 40px 40px;
}

.add-tax-btn-green .dropdown-toggle {
  background-color: var(--booger);
  border-left: 1px solid var(--white-fill);
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 0 6px 6px 0 !important;
}
.add-tax-btn-green .dropdown-toggle:after {
  display: none;
}
.btn-radius-right-0 {
  border-radius: 6px 0 0 6px;
}
.tax-group-form .form-outline:first-child{ 
  padding-right: 16px;
}
.tax-group-form .col-list {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 12px;
  padding-right: 16px;
}
.tax-group-form .col-list li {
  position: relative;
  width: 100%;
  height: 41px;
  margin: 0 4px 14px 0;
  padding: 8px 12px 9px 12px;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 8px -2px var(--black-shadow3);
  box-shadow: 0 4px 8px -2px var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.tax-group-form .col-list li .dot-icon {  
  position: absolute;
  top: 50%;
  right: -22px;
  width: 10px;
  transform: translate(0, -50%);
}
.tax-group-form .col-list li .form-check .form-check-input {
  border: 1px solid var(--slate);
  border-radius: 2px;
  float: left;
  margin-right: 8px;
  margin-top: 0;
  width: 14px;
  height: 14px;
}
.tax-group-form .col-list li .form-check label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 0 !important;
}
.tax-group-form input.form-check-input {
  width: 14px !important;
  height: 14px !important;
  padding: 0 !important;
  border-color: var(--new-whisper) !important;
  background-color: var(--white-fill);
}
#taxCreateModal .tax-group-form label.form-check-label {
  margin-bottom: 0 !important;
}

.create-settings-form-wrap input:focus {
  border: 1px solid var(--cerulean-blue);
  box-shadow: none;
}
.tax-group-form input.form-check-input:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none;
}
#taxCreateModal .tax-group-form .col-list li.error {
  border-color: red;
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
  height: 41px;
}

#settings_table .tax-group {
  font-weight: bold;
  padding-left: 20px;
}
