#custom_field_table {
  width: auto;
  -webkit-box-shadow: 0 8px 16px -2px var(---black-shadow3);
  box-shadow: 0 8px 16px -2px var(---black-shadow3);
  border-radius: 10px !important;
  border: solid 1px var(--warmWhite);
}
#cn-pr-list-table {
  width: 100% !important;
}
#cn-pr-list-table thead,
th:first-child {
  border-top: none !important;
}
#cn-pr-list-table .itemdate {
  width: auto !important;
}
#dn-pm-list-table {
  width: 100% !important;
}
#dn-pm-list-table thead,
th:first-child {
  border-top: none !important;
}
#dn-pm-list-table .itemdate {
  width: auto !important;
}
#invoice #cn-pr-list-table td {
  padding-top: 12px !important;
  vertical-align: middle;
}
#cn-pr-list-table .action span {
  top: 0;
}
#invoice #dn-pm-list-table td {
  padding-top: 12px !important;
  vertical-align: middle;
}
#dn-pm-list-table .action span {
  top: 0;
}
#invoice-table-wrapper table tbody tr.no-item td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#account-transaction-table-head-row {
  /* margin: 12px; */
  background-color: #f5f5f5;
  padding: 20px 24px !important;
}
#account-transaction-table-head-row .account-transaction-table-head-td {
  border-bottom: solid 1px #e3e3e3 !important;
  padding: 0px !important;
}
.account-transaction-table-head-wrap {
  padding: 20px 24px !important;
}
.account-transaction-table-head-row-1 {
  display: flex;
  justify-content: space-between;
}
.account-transaction-table-head-row-1:hover {
 cursor: pointer;
}
.account-transaction-table-head-row-1-head {
  font-weight: bold;
  font-size: 16px;
}
.arrow-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.arrow-icon.collapsed {
  transform: rotate(0deg);
}
.account-transaction-table-head-row-2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.account-transaction-table-head-row-2-div {
  display: flex;
}
.account-transaction-table-head-row-2-div div {
  white-space: nowrap;
  margin-right: 5px;
  font-size: 14px;
}
.account-transaction-table-head-row-2-div .head {
  white-space: nowrap;
  margin-right: 5px;
  font-weight: bold;
  font-size: 14px;
}
.account-transaction-card .report-table-section .accordion-collapsible-row {
  height: auto !important;
}
.account-transaction-card .report-table-section .accordion-collapsible-row:hover {
  box-shadow: none !important;
}
.account-transaction-card .report-table-section .accordion-collapsible-row .accordion-collapsible-col {  
  padding: 0 !important;
}