.list-li {
    width: 100%;
    padding: 0;
    margin: 0;
}
.list-li .dropdown.list-li {
    border-radius: 0;
}
.list-li .dropdown.list-li:first-child {
    border-radius: 4px 4px 0 0;
}
.list-li .dropdown.list-li:last-child{
    border-radius: 0 0 4px 4px;
}