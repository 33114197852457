.alert-modal-content {
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: calc(0.7rem - 1px);
  position: absolute;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
.resusable-alert-modal {
  box-shadow: none;
  background-color: white;
}
.alert-modal-close-btn {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}
.alert-modal-header {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  padding: 30px 30px 0px 40px;
  width: 100%;
  display: flex;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
#reusableAlertModal .reusable-modal-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
}
#reusableAlertModal .reusable-modal-btn .marked-popup-btn {
  height: fit-content !important;
  width: fit-content;
  color: var(--cerulean-blue);
}

#reusableAlertModal.marked-popup-wrapper-class {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
#reusableAlertModal .reusable-modal-btn .cancel-marked-popup-btn {
  height: fit-content !important;
  width: 100% !important;
}

#reusableAlertModal.cancel-marked-popup-wrapper-class {
  display: flex;
  align-items: center;
}
#reusableAlertModal.cancel-marked-popup-wrapper-class >.reusable-modal-btn{
  width: 100% !important;

}
#reusableAlertModal .reusable-modal-btn .header-action-delete-popup-btn {
  height: fit-content !important;
  width: fit-content;
  color: var(--cerulean-blue);
}

#reusableAlertModal.header-action-delete-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.alert-modal-title h5 {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.write-off-modal h5 {
  font-size: 20px !important;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.excess-amount-alert h5 {
  font-size: 20px !important;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.create-credit-note-invoice-modal h5 {
  font-size: 20px !important;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.create-debit-note-bill-modal h5 {
  font-size: 20px !important;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

#reusableAlertModal .modal-footer {
  width: 100%;
}
.alert-modal-body {
  padding: 0px !important;
  margin: 0px !important;
}
.is-disabled-alert-modal-btn {
  opacity: 0.5;
}

/** Media queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (max-width: 1040px) {
}
@media (min-width: 1025px) and (max-width: 1040px) {
}
