.subscription-update-popover .subscription-update {
    max-width: 550px;
    min-width: 350px;
    margin: 0px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    padding: 10px 15px 32px 32px !important;
    background-color: var(--white-fill);
    border-radius: 12px;
    border: solid 1px var(--warm-white);
}
.subscription-update-popover .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    max-width: 550px;
    min-width: 350px;
    border: solid 1px var(--warm-white);
    box-shadow: none
}

.update-future-popover .subscription-update {
    max-width: 500px;
    min-width: 470px;
}
.update-future-popover .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 470px;
    max-width: 500px;
}
.subscription-update-popover .subscription-update .card-header {
    margin-bottom: 12px;
}

.subscription-update-popover .subscription-update .subscription-update-header-wrapper {
    width: 100% !important;
}
.subscription-update .subscription-update-header-wrapper .justify-content-center {
    width: 100% !important;
}
.subscription-update .subscription-update-header-wrapper .justify-content-center h1  {
    width: 100% !important;
}
.subscription-update-popover .form-button-wrapper {
    border: 0px;
    padding-left: 4px !important;
    margin-top: 20px;
    height: 70px;
}
.subscription-update-header-wrapper .popover-close-button .close-btn {
    float: right;
}
.subscription-update .card-body{
    margin-right: 17px;
}

  .subscription-update-popover .custom-tooltip-wrapper {
    float: right;
    margin-right: 2px;
  }
  
  .subscription-update-popover .custom-tooltip-wrapper .info-btn {
    left: 0;
  }

  .p-r-l-20 {
   padding: 10px 0px 0px 16px;
  }

 .update-future-popover .header {
    font-size: 16px;
  }

  .update-future-popover .subscription-update .subscription-update-header-wrapper {
    padding-bottom: 0px !important;
  }
  .update-future-popover  .form-button-wrapper {
    margin-top: 0px;
    height: 70px;
}

.subscription-update-popover .save-button,
.subscription-update-popover .cancel-button{
    height: 40px;
}

#is_subscription_proration_enabled {
    margin-top: 8px;
    padding-left: 10px;
}

.subscription_proration {
    height: 32px;
}

.form-button-wrapper-cancelled {
    padding-top: 0px !important;
}