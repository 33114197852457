label {  
    font-size: 14px;
    font-weight: bold;  
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin-bottom: 12px;
    float: left;
}
.link-label-grouped {
    width: 100%;
    max-width: 325px;
    float: left;
}
label.required {
    position: relative;
    padding-right: 14px;
}
label.required:after {
    content: "*";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 1px;
    right: 0;
    color: var(--red);
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.18px;
}