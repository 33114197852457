.cropper-btn-wrapper{
    padding-left: 0 !important;
}
.box-cropped{
    width:100%;
    height: 100%;
}   
.box-preview{
    width:100%;
    height: 400px;
    background-color: gray;
  }
.box-cropped>img{
    width:100%;
    height: 100%;
    object-fit: contain;
}
.box-preview>img{
    width:100%;
    height: 100%;
    object-fit: contain;
}