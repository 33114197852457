.table-container.no-list-item:not(.tab-pane .tab-content-body .table-container.no-list-item) {
    height: 75vh;
    min-height: 386px;
  }
  .table-container.no-list-item .no-list-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 292px;
    margin: auto;   
    text-align: center;
    height: 100%;
}
.no-list-item-container .preview-img {
    width: 116px !important;
}
.no-list-item-container .preview-img {
    display: block;
    height: auto;
    object-fit: contain;
    margin: 0 auto 16px auto;
    clear: both;
}
.no-list-item-container h3 {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    text-align: center;
    margin: 0 0 8px 0 !important;
}
@media (max-width: 1024px) {
    .table-container.no-list-item:not(.tab-pane .tab-content-body .table-container.no-list-item) {
        height: 70vh;
    }
    .no-list-item .no-list-item-container {      
        display: flex;
        align-items: center;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .table-container.no-list-item:not(.tab-pane .tab-content-body .table-container.no-list-item) {
        height: 64vh;
    }
}