

.billed-to,
.ship-to {
  width:34.4%;
  /* padding-right: 9.5%;  */
  padding-right: 12px;
  flex: 0 0 34.4%;
}


#invoice .table-section .table.bills-preview-table thead th:nth-child(3),
#invoice .table-section .table.bills-preview-table tr td:nth-child(3),
#invoice .table-section .table.bills-preview-table thead th:nth-child(7),
#invoice .table-section .table.bills-preview-table tr td:nth-child(7) {
  text-align: left !important;
}
#invoice .table-section .table.bills-preview-table thead th:nth-child(6),
#invoice .table-section .table.bills-preview-table tr td:nth-child(6) {
  padding-right: 12px !important;
}
#invoice .table-section .table.bills-preview-table thead th:nth-child(7),
#invoice .table-section .table.bills-preview-table tr td:nth-child(7) {
  padding-left: 12px !important;
}
#invoice .table-section .table.bills-preview-table tr th.discount,
#invoice .table-section .table.bills-preview-table tr td.discount {
  padding-right: 24px !important;
}
 /* .tax-section div:first-child {
  width: 15%;
  padding-left: 25px;
} */

.bill-details-section .inv-date-wrap {
  width: 23%;
  min-width: 100px;
  flex: 0 0 23%;
}
.bill-details-section .payment-terms {
  width: 100%;
  min-width: 153px;
}
.bill-detail-right-align{
  text-align:right;
}

#bills-detail-card .info-tag {
  top: -4px;
  margin-left: 13px;
}