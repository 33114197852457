/* add_payment_form .unpaid-items-table.unpaid-inv-table thead tr th.withholding-tax-col {
  text-align: right;
  min-width: 160px !important;
  width: 160px !important;
  text-align: right;
}
.add_payment_form .unpaid-items-table.unpaid-inv-table tbody tr td.withholding-tax-col {
  padding-top: 12px !important;
  min-width: 160px !important;
  width: 160px !important;
  text-align: left;
} */
.add_payment_form .unpaid-items-table {
  min-width: 1px !important;
}
.add_payment_form .unpaid-items-table th,
.add_payment_form .unpaid-items-table td {
  white-space: nowrap;
}
.add_payment_form .unpaid-items-table tr .item_number  {
  padding-left: 16px !important;
  padding-right: 20px !important;
}
.add_payment_form .unpaid-items-table tr .item_date,
.add_payment_form .unpaid-items-table tr .item_amount,
.add_payment_form .unpaid-items-table tr .amount_due {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.add_payment_form .unpaid-items-table tr .item_number, 
.add_payment_form .unpaid-items-table tr .item_date,
.add_payment_form .unpaid-items-table tr .item_amount,
.add_payment_form .unpaid-items-table tr .amount_due {
  width: auto !important;
}
.add_payment_form .unpaid-items-table tr .item_number {
  min-width: 110px;
}
.add_payment_form .unpaid-items-table tr .item_date {
  min-width: 107px;
}
.add_payment_form .unpaid-items-table tr .item_amount {
  min-width: 113px;
}
.add_payment_form .unpaid-items-table tr .amount_due  {
  min-width: 114px;
}
.add_payment_form .unpaid-items-table tr .payment-col {
  min-width: 176px;
  width: 176px;
  /* width: 16.2212% !important; */
  text-align: right;
}
.add_payment_form .unpaid-items-table tr .payment-col .payment-entry {
  margin-left: auto;
}
.add_payment_form .unpaid-items-table tr .forex_gain_loss {
  min-width: 176px;
  /* width: 16.2212% !important; */
  text-align: right;
}
add_payment_form .unpaid-items-table tr .withholding-tax-col {
  text-align: right;
  min-width: 160px !important;
  /* width: 160px !important; */
  text-align: right;
}
/* .add_payment_form .unpaid-items-table.unpaid-inv-table tbody tr td.withholding-tax-col {
  padding-top: 12px !important;
  min-width: 160px !important;
  width: 160px !important;
  text-align: left;
} */
.add_payment_form .unpaid-items-table tr .allocation-date-col {
  text-align: left;
  min-width: 172px !important;
  width: 172px !important;
}
/* .add_payment_form .unpaid-items-table.unpaid-inv-table tbody tr td.allocation-date-col {
  padding-top: 12px !important;
  min-width: 160px !important;
  width: 160px !important;
  text-align: left;
} */
.add_payment_form .unpaid-items-table.unpaid-inv-table tr .payment-col {
  text-align: right;
  min-width: 170px !important;  
}
/* .add_payment_form .unpaid-items-table tr .payment-col {
  padding-top: 12px !important;
  min-width: 170px !important;
  text-align: right;
  vertical-align: middle;
} */
.add_payment_form .unpaid-inv-table tbody tr td.withholding-tax-col {
  padding-top: 12px !important;
  min-width: 172px !important;
  /* width: 170px !important; */
  text-align: right;
}

.add_payment_form .unpaid-items-table.unpaid-inv-table tbody tr td .payment-entry {
  margin-left: auto;
}
.add_payment_form .unpaid-items-table tr .forex_gain_loss {
  text-align: right;
  min-width: 133px !important;
  width: auto !important;
}
thead.unpaid-payments-header th {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
}
span.total-unpaid-error {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 50px;
}
span.inv-balance {
  padding-top: 4px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.add_payment_form .unpaid-items-table .date-container.allocation_date {
  width: 160px !important;
}
.add_payment_form .unpaid-items-table .MuiInputAdornment-root {
  padding-right: 10px !important;
}
.add_payment_form .unpaid-items-table .date-container .MuiFormControl-root .MuiInputBase-formControl {
  background-color: var(--white-fill);
}