.fiscal-year-popup-form {
  min-width: 35px;
  width: fit-content;
  max-width: 400px;
  height: fit-content;
  padding: 20px 20px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fiscal-year-popup-form .custom-select {
  height: 50px !important;
}

.fiscal-year-popup-form .fiscal-year-popup-submit-btn {
  margin-top: 30px;
  width: 100%;
}
.MuiDialogTitle-root {
  color: var(--charcoal-grey) !important;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 900 !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  margin-bottom: 0 !important;
}
.fiscal-year-popup-dialog .MuiDialog-paper {
  overflow-y: visible !important;
}
.fiscal-year-info-message {
  width: fit-content;
  
}
