.taxcode-dropdown {
  width: 100%;
}
.taxcode-dropdown .search-dropdown {
  width: 100% !important;
  height: 41px;
  border-radius: 0 6px 6px 0 !important;
  border-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 41px !important;
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left !important;
  background: var(--white-fill)
    url("../../../../../../assets/images/arrow-drop-right.svg") no-repeat right
    4px center !important;
}
.taxcode-dropdown .dropdown-menu {
  min-width: 100%;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  padding: 5px 0;
  text-align: left;
  inset: initial !important;
  transform: translate(0, 44px) !important;
  padding: 0;
  right: -1px !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 198px;
  overflow-y: auto;
  z-index: 10;
}
.taxcode-dropdown .dropdown-menu li {
  width: 100%;
  padding: 0;
  margin: 0 !important;
}
.taxcode-dropdown .dropdown-menu li:first-child,
.taxcode-dropdown .dropdown-menu li:first-child a {
  border-radius: 4px 4px 0 0 !important;
}
.taxcode-dropdown .dropdown-menu li:last-child,
.taxcode-dropdown .dropdown-menu li:last-child a {
  border-radius: 0 0 4px 4px !important;
}
.taxcode-dropdown .dropdown-menu li:last-child a {
  border-bottom: 0 !important;
}
.taxcode-dropdown .dropdown-menu a {
  margin-left: 0;
  padding: 6px 12px 6px 14px;
}
.taxcode-dropdown .reusable-modal-btn button {
  padding: 0;
}
.taxcode-dropdown .dropdown-menu a,
.taxcode-dropdown .dropdown-menu a span {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left;
}
.taxcode-dropdown .dropdown-menu .add-link-option {
  padding-top: 12px;
}

.taxcode-dropdown .dropdown-menu a.active,
.taxcode-dropdown .dropdown-menu a.active span,
.taxcode-dropdown .dropdown-menu a:hover,
.taxcode-dropdown .dropdown-menu a:hover span {
  color: var(--cerulean-blue);
  background-color: transparent;
}
.taxcode-dropdown .dropdown-menu a span {
  display: block;
  width: 100%;
  padding: 2px 1px 2px 0;
}
.taxcode-dropdown .dropdown-menu span.no-result {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-align: center;
  cursor: text !important;
}
.taxcode-dropdown .dropdown-menu a:hover span.no-result {
  color: rgba(var(--charcoal-grey-rgb), 0.55) !important;
}

.taxcode-dropdown .dropdown-menu a.add-link-option,
.taxcode-dropdown .dropdown-menu a.add-link-option .itemLabel {
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
}
.taxcode-dropdown .dropdown-menu a.add-link-option .itemLabel {
  position: relative;
  padding-left: 18px;
}
.taxcode-dropdown .dropdown-menu a.add-link-option .itemLabel:before {
  content: "+";
  position: absolute;
  width: 16px;
  height: 17px;
  margin: 0;
  top: 1px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  text-align: center;
}
.taxcode-dropdown
  .dropdown-menu
  .reusable-modal-btn
  button
  a.dropdown-item
  .itemLabel {
  text-align: left;
}

.exemption-popup,
.exemption-popup-content {
  width: 20% !important;
  padding: 0;
}
.exemption-popup .popup-heading,
.exemption-popup-content .popup-heading {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9fb;
}
.exemption-popup .popup-heading-text,
.exemption-popup-content .popup-heading-text {
  font-size: 16px;
  padding: 10px 0px 0px 8px;
  color: #7c8192;
}
.exemption-popup .popup-content-body,
.exemption-popup-content .popup-content-body {
  padding: 20px 15px 15px 15px;
}
.exemption-popup .popup-select-heading,
.exemption-popup-content .popup-select-heading {
  width: fit-content;
}
.exemption-popup .popup-button-group,
.exemption-popup-content .popup-button-group {
  padding: 0px 0px 20px 20px;
  display: flex;
}
.tax-code-input {
  width: 100% !important;
  height: 41px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 41px !important;
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left !important;
  background: var(--white-fill)
    url(../../../../../../assets/images/arrow-drop-right.svg) no-repeat right
    4px center !important;
  max-width: 325px;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 0px 6px 6px 0px !important;
  border: solid 1px var(--new-whisper);
  border-left: 0;
  background-color: var(--white-fill);
  position: relative !important;
  padding: 0 26px 0 12px !important;
}
