.search-highlighted-text {
  background-color: var(--champagne);
  text-align: center;
  vertical-align: middle;
  white-space: pre-wrap !important;
}
.highlight {
  background-color: var(--champagne);
}

.search-data span {
  font-size: 15px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  white-space: pre !important;
}
.search-data .label {
  font-weight: normal;
  white-space: pre-wrap !important;
}
.card-search-results .search-data .value,
.card-search-results .search-data .value * {
  font-weight: bold !important;
}
.text-highlighted {
  display: flex;
  flex-direction: row;
}
