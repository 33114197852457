/* .option-div {
  width: fit-content;
} */
.pricing-form .radio-container {
  display: flex;
  width: 100%;
}

.pricing-form .radio-div {
  width: 15%;
  /* display: flex; */
  text-align: center;
}

.pricing-form .trial-period-count {
  /* width: 71px !important; */
  width: 89px !important;
}

.pricing-form .trial-period-error {
  width: 200px !important;
}

.pricing-form .trial-period-count-div {
  width: 100px !important;
}
.pricing-form .trial-period-count-radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.pricing-form .trial-radio {
  transform: scale(1.5);
}

.pricing-form .trial-period-height > .css-1s2u09g-control,
.trial-period-height > .css-1pahdxg-control {
  height: 42px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.pricing-form .show-desc-inv {
  margin-left: 18px;
}

.pricing-form .show-desc-container {
  margin-top: 10px;
}

.pricing-form .show-desc-inv-chk {
  transform: scale(1.3);
}

.pricing-form .pricing-model-dp {
  width: 293px !important;
}

.pricing-form .pricint-model-wrap .relative-wrapper {
  margin-left: 15px;
}

.pricing-form .item-container {
  min-height: 128px;
}

.pricing-form .item-cont-width {
  min-width: 100%;
}

.pricing-form .item-cart {
  min-height: 128px;
  background-color: #e9f2fd;
  max-width: 100%;
  /* margin-left: 19px; */
}

.pricing-form .border-container {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 21px;
}

.pricing-form .border-div {
  width: 90%;
  border-bottom: 1px solid #d6e8fd;
}

.pricing-form .item-header {
  padding: 10px;
}

.pricing-form .item-price {
  width: 115px;
}

.pricing-form .range-item-price {
  width: 115px;
}

.pricing-form .item-price-cont {
  display: flex;
  padding-bottom: 10px;
  width: inherit;
}

.pricing-form .pricing-field-wrap {
  display: flex;
  width: inherit;
}

.pricing-form .item-price-cont .form-input-icon-wrapper {
  margin-top: 0px;
}

.pricing-form .total-container {
  padding-left: 18px;
  margin-top: 10px;
}

.pricing-form .item-price-cont .reusable-form-input-field {
  width: inherit;
}

.pricing-form .item-price-cont .reusable-form-input-field .priceErrorMessage {
  width: 115px;
}
.pricing-form .close-row-container {
  display: flex !important;
  text-align: center;
  justify-content: center;
  padding-top: 37px;
}

.pricing-form .add-row-container {
  padding-left: 3px;
}

.pricing-form .close-row {
  cursor: pointer;
}

.pricing-form .pricing-field-wrap-per-unit .reusable-form-input-field {
  width: 150px;
}

.pricing-form .pricing-field-wrap-per-unit {
  position: relative;
}

.pricing-form .pricing-field-wrap-per-unit > img {
  position: absolute;
  right: 8px;
  top: 1px;
}

.pricing-form .trial_count_wrapper_plan_price {
  display: flex;
  margin-top: 10px;
}

.pricing-form .trial_count_wrapper_plan_price .trial-period-count-radius {
  height: 42px;
}

.pricing-form .trial_count_wrapper_plan_price .css-b62m3t-container {
  margin-left: -12px;
  width: 130px !important;
}

#plan_trial_frequency .css-1s2u09g-control {
  width: 115px !important;
  border: solid 1px var(--new-whisper);
}
#plan_trial_frequency .css-26l3qy-menu {
  top: 38px !important;
}
#plan_trial_frequency .css-1pahdxg-control {
  width: 115px !important;
}

.pricing-form .relative-wrapper .custom-tooltip-wrapper .info-btn,
.pricing-form .reusable-form-input-field .custom-tooltip-wrapper .info-btn {
  left: 0;
}

.pricing-form .relative-wrapper .custom-tooltip-wrapper {
  float: right;
  margin-right: 20px;
}

.pricing-form .reusable-form-input-field .custom-tooltip-wrapper {
  float: right;
  margin-right: 2px;
}

@media (max-width: 1024px) {
  .pricing-form .relative-wrapper .custom-tooltip-wrapper {
    float: right;
    margin-right: 12px;
  }
}

@media (max-width: 1052px) {
  .pricing-form .item-cart {
    max-width: 80%;
  }
}

@media (max-width: 952px) {
  .pricing-form .item-cart {
    max-width: 90%;
  }
}

@media (max-width: 877px) {
  .pricing-form .item-cart {
    max-width: 100%;
  }
  .pricing-form .range-item-price,
  .pricing-form .item-price-cont .reusable-form-input-field .priceErrorMessage {
    width: 100px;
  }
}

@media (max-width: 776px) {
  .pricing-form .range-item-price,
  .pricing-form .item-price-cont .reusable-form-input-field .priceErrorMessage {
    width: 90px;
  }
}
@media (max-width: 500px) {
  .pricing-form .range-item-price,
  .pricing-form .item-price-cont .reusable-form-input-field .priceErrorMessage {
    width: 70px;
  }
}

.pricing-form .add-module-item-form input[type="number"] {
  max-width: 325px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

.pricing-form .row.custom-row {
  margin-left: -2rem;
  margin-right: -1rem;
}

.subscription-price-popover .item-cart {
  min-height: 128px;
  max-height: 325px;
  overflow: hidden;
  background-color: #e9f2fd;
  max-width: 100%;
}

.subscription-price-popover .item-cart .item-cart-price-range {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
} 

.subscription-price-popover-create {
  right: -376px !important;
  max-width: 518px;
}