.journal-view-container {
  width: 100%;
  position: relative;
  clear: both;
  padding-top: 30px;
}
.journal-view-container h2 {
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  clear: both;
  margin-bottom: 0;
  max-width: 500px;
  float: left;
}
.journal-view-container .table-section {
  width: 100%;
  clear: both;
  margin-top: 16px;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 0 !important;
  float: left;
}
.journal-view-container .journal-preview-table {
  margin-bottom: 0;
  width: auto;
  min-width: 100%;
}
.journal-view-container .table-section .journal-preview-table thead th {
  line-height: 24px !important;
  border-top: 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
  border-bottom: 1px solid #eee !important;
}
.journal-view-container
  .table-section
  .journal-preview-table
  thead
  th:first-child,
.journal-view-container
  .table-section
  .journal-preview-table
  tr
  td:first-child {
  width: 57.3055% !important;
  padding-left: 24px !important;
  text-align: left !important;
}
.journal-view-container
  .table-section
  .journal-preview-table
  thead
  th:last-child,
.journal-view-container .table-section .journal-preview-table tr td:last-child {
  width: 21.3472% !important;
  max-width: 250px !important;
  padding-right: 24px !important;
  text-align: right !important;
}
.journal-view-container .table-section .journal-preview-table tr td:last-child {
  font-weight: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
}
.journal-view-container
  .table-section
  .journal-preview-table
  thead
  th:nth-child(2),
.journal-view-container
  .table-section
  .journal-preview-table
  tr
  td:nth-child(2) {
  width: 21.3472% !important;
  max-width: 250px !important;
  text-align: right !important;
}
.journal-view-container .table-section .journal-preview-table tr:last-child td {
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  border-bottom: 0 !important;
}
.journal-view-container .table-section {
  border-radius: 4px;
  border: solid 1px #eee;
}
.journal-view-container .table-section .journal-preview-table tr td,
#invoice .journal-view-container .table-section .journal-preview-table tr td {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 24px !important;
  border-bottom: 1px solid #eee !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.journal-view-container tbody tr:nth-child(2n) td {
  background-color: transparent;
}

#invoice .journal-view-container .table-section {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
#invoice .journal-view-container .table-section .table thead :first-child span,
#invoice .journal-view-container .table-section .table tr td:first-child {
  min-width: 140px !important;
}
#invoice
  .journal-view-container
  .table-section
  .table
  thead
  th:nth-child(2)
  span,
#invoice .journal-view-container .table-section .table tr td:nth-child(2),
#invoice .journal-view-container .table-section .table thead th:last-child span,
#invoice .journal-view-container .table-section .table tr td:last-child {
  white-space: nowrap;
}

.journal-view-container
  .table-section
  .journal-preview-table
  tr
  td:nth-child(2),
.journal-view-container
  .table-section
  .journal-preview-table
  tr
  td:nth-child(2)
  span,
.journal-view-container .table-section .journal-preview-table tr td:last-child,
.journal-view-container
  .table-section
  .journal-preview-table
  tr
  td:last-child
  span {
  white-space: nowrap !important;
}
.payments-postings-section {
  margin-top: 30px;
  width: 100%;
  float: left;
}

.jv-postings-section {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}
.journal-view-container .payments-postings-section {
  margin-top: 0;
}
.journal-view-container .jv-postings-section + .payments-postings-section,
.journal-view-container
  .payments-postings-section
  + .payments-postings-section {
  margin-top: 30px;
}
.cash-posting-wrapper {
  position: absolute;
  top: 24px;
  right: 0;
  float: right;
}
.journal-entry-void-state-hr {
  margin-top: 16px;
  margin-bottom: 10px;
}
.journal-entry-void-state {
  font-size: 16px;
  color: var(--battleship-grey);
  padding-bottom: 20px;
}
/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .journal-view-container .journal-preview-table {
    min-width: 650px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .journal-view-container h2 {
    max-width: 360px;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
