.coupon-form .item-container {
  min-height: 80px;
  width: 31%;
}

.coupon-form .item-number {
  font-weight: bold;
  margin-bottom: 5px;
}

.coupon-form .item-details {
  display: flex;
  color: grey;
  margin-bottom: 5px;
}

.coupon-form .item-details span {
  font-size: 12px
}

.coupon-form .item-flex-container {
  display: flex;
  justify-content: space-between;
}

.coupon-form .box {
  margin-top: 25px;
}

.coupon-form .wd-33 {
  width: 33%
}

.form-coupon-header {
  display: inline-flex;
}

.form-coupon-header .coupon-header-org-span {
  margin-top: 6px;
  margin-left: 16px;
}

.item-details .coupon-item-rate {
  padding-left: 10px;
}

.item-details .coupon-item-rate span {
  font-weight: bold;
}

.coupon-form .link-label-grouped .reusable-form-input-field .custom-tooltip-wrapper .info-btn {
  left: 0;
}

.coupon-form .link-label-grouped .reusable-form-input-field .custom-tooltip-wrapper {
  float: right;
  margin-right: 2px;
}

.coupon-form .custom-tooltip-wrapper {
  float: right;
  margin-right: 2px;
}

.coupon-form .custom-tooltip-wrapper .info-btn {
  left: 0;
}
.coupon-form .form-input-icon-wrapper {
  margin-top: 0px;
}

.coupon-form .discount_count-div {
  max-width: 163px !important;
}
.coupon-form .discount_count-div .prefix_span_org {
  width: 50px !important;
  text-align: center;
  padding-top: 10px;
  height: 38.5px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
}

.coupon-form .discount_type_inputprefix {
  border: solid 1px var(--new-whisper);
  height: 41px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.coupon-form .discount_type_inputprefix input[type="text"]:focus {
  border: 0px !important;
}

.coupon-form .focused{
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.coupon-form .discount_count-div .prefix_span_per {
  width: 50px !important;
  text-align: center;
  padding-top: 10px;
  height: 38.5px !important;
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
}

.coupon-form .discount_count_wrapper {
  display: flex;
}
.coupon-form .discount-period-count {
  max-width: 163px !important;
}

.coupon-form #discount_count.discount_field_pre{
  max-width: 113px !important;
  border: 0px;
  height: 39px;
}


.coupon-form .trial-period-count-radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.coupon-form .discount_count_wrapper .trial-period-count-radius {
  height: 40px !important;
}

.coupon-form .trial-period-type-radius>.css-1erofb6-control,
.trial-period-type-radius>.css-g4nsph-control {
  height: 40px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.coupon-form .accounts-dropdown .search-dropdown {
  width: 100% !important;
}

.coupon-form .pleft-4 {
  padding-left: 4px;
}

.coupon-form .pleft-16 {
  padding-left: 16px;
}

.coupon-form .duration-label {
  padding-left: 0px !important;
  max-width: 335px;
}

.coupon-form .coupon-discount {
  max-width: 350px;
}

.coupon-form .duration-count-div {
  max-width: 70px !important;
}

.coupon-form .duration-period-count {
  max-width: 70px !important;
}
.coupon-form .duration-unit-count {
  max-width: 118px !important;
}
.coupon-form .duration_count_wrapper {
  display: flex;
}

.coupon-form .duration_count_wrapper .trial-period-count-radius {
  height: 40px !important;
}

.coupon-form .trial-period-type-radius>.css-1s2u09g-control,
.trial-period-type-radius>.css-1pahdxg-control{
  height: 40px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: solid 1px var(--new-whisper);
}

.coupon-form .accounts-dropdown {
  width: 100% !important;
  clear: both;
}

.coupon-form .accounts-dropdown input.search-dropdown.error {
  border: 0 !important;
  width: 100% !important;
  background-color: var(--rose-white) !important;
}

.coupon-form .accounts-dropdown .dropdown-menu {
  left: 0 !important;
  right: auto !important;
  padding: 0 !important;
  top: 0 !important;
}

.coupon-form .trial-period-error {
  width: 200px !important;
}
.coupon-currency-dropdown{
 max-width: 325px;
 margin-bottom: 20px;
}

#discount_type .css-1afrgxj-menu {
  top: 40px !important;
}
#limited_duration_unit .css-1h2v4zo-menu {
  top: 40px !important;
}