.alert-modal-pop-up-wrap {
  width: 500px;
  height: "fit-content";
  padding: 20px 30px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
}
.alert-modal-popup-message {
  color: var(--charcoal-grey) !important;
  font-size: 16px !important;
  margin-left: 5px;
}
.alert-modal-popup-btn-group {
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: flex-end;
}
.alert-modal-popup-save-ok-button {
  width: auto;
  min-width: 124px;
}
.alert-modal-popup-wrapper-class {
  margin: 0px 5px !important;
}
