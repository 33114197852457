.variance-cell-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.variance-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.variance-icon-positive {
    margin-left: 5px;
    font-weight: bold;
    color: green;
    font-size: 14px !important;
}
.variance-icon-negative {
    margin-left: 5px;
    font-weight: bold;
    color: red;
    font-size: 14px !important;
}