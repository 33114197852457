.pagination-header-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.pagination-header-result-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.pagination-header-result-info span {
  margin-right: 8px;
}
.results-per-page-label {
  color: var(--slate) !important;
  float: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
  line-height: 1.71 !important;
  margin: 0 !important;
  text-align: left !important;
  white-space: nowrap !important;
}
