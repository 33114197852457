.bargraph-container {
  flex-direction: column;
}
/** edit by dhivya **/
.bargraph-container .graph-legend-filter-section {
  width: 100%;
  flex: 0 0 63%;
  padding: 21px 24px 0 24px;
}
.bargraph-container .graph-legend-filter-section .header-wrapper {
  width: 100%;
  float: left;
}
.bargraph-container .graph-legend-filter-section .header-wrapper .header-legend-wraper {
  width: 63%;
  float: left;
  padding-right: 24px;
}

.bargraph-container .graph-legend-filter-section .legend-list {
  float: right;
}
.bargraph-container .graph-legend-filter-section .select-wrapper {
  display: inline-block;
  width: 160px;
  padding-left: 8px;
}
.bargraph-container .graph-legend-filter-section .select-wrapper .selectpicker {
  margin-top: 0px;
}
.bargraph-container .graph-legend-section {
  display: flex;
  padding-bottom: 25px;
}

/** edit by dhivya **/
.bargraph-container .graph-legend-filter-section .action-wrapper {
  margin-top: -6px;
}
.bargraph-container .graph-section {
  width: 78%;
  /* width: 63%; */
  /* flex: 0 0 63%; */
  padding: 21px 24px 0 24px;
}
.bargraph-container .graph-section .header-wrapper {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}
.bargraph-container .legend-section {
  /* width: 37%; */
  width: auto;
  display: flex;
  flex-direction: column;
  /* flex: 0 0 37%; */
  /* padding: 0 24px 25px 24px; */
  padding: 0 24px;
  border-left: 1px solid var(--approx-whisper);
  margin-top: 4px;
}
.graph-container.bargraph-container .graph-section .legend-list {
  display: inline-block;
  top: -34px;
  right: 8px;
}
.bargraph-container .graph-section .select-wrapper {
  display: inline-block;
  width: 160px;
  padding-left: 8px;
}
.bargraph-container .summary-container {
  width: 100%;
  min-height: 130px;
  border-radius: 6px;
  padding: 27px 20px 31px 20px;
  margin-bottom: 24px;
  background-size: cover !important;
  float: left;
}
.bargraph-container .summary-container .img-container {
  width: 90px;
  height: 90px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 4px;
  float: left;
}
.bargraph-container .summary-container .img-container img {
  width: 100%;
  height: 100%;
  float: left;
}
.bargraph-container .summary-container .summary-section {
  width: calc(100% - 90px);
  padding-left: 5px;
  float: left;
}
.bargraph-container .summary-container:last-child {
  margin-bottom: 0;
}
.bargraph-container .summary-container .summary-section span {
  display: block;
  width: 100%;

  line-height: normal;
  float: left;
}
.bargraph-container .summary-container .summary-section span.title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.bargraph-container .summary-container .summary-section span.amt-summary {
  /* font-size: 26px; */
  font-size: 1.25rem;
  font-weight: 900;
  letter-spacing: -0.01px;
  color: var(--charcoal-grey);
  margin-top: 4px;
  white-space: nowrap;
  cursor: pointer;
  width: auto;
  max-width: 100%;
}
.bargraph-container .summary-container .summary-section span.daterange-summary {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: normal;
  color: var(--battleship-grey);

  margin-top: 4px;
}
.bargraph-container .graph-wrapper {
  width: 100%;
  clear: both;
  height: 270px !important;
}
.bargraph-container .graph-wrapper canvas {
  width: 100% !important;
  height: 270px !important;
}
.bargraph-container .summary-container.blue-cloud-bg {
  background: url("../../../../assets/images/blue-cloud-bg.svg") no-repeat
    center;
}
.bargraph-container .summary-container.green-cloud-bg {
  background: url("../../../../assets/images/green-cloud-bg.svg") no-repeat
    center;
}
.chartjs-tooltip {
  white-space: pre-line;
}
/* tooltip in bar graph */
.tooltip-title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
}
.custom-tooltip {
  position: absolute;
  z-index: 9999;
  display: none;
  padding: 8px;
  font-size: 14px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  pointer-events: none;
}

.tooltip-arrow {
  position: absolute;
  width: 15px;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 8px solid #000;
  margin-left: -7px;
}
.tooltip {
  position: relative;
}

.tooltip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.tooltip-top::before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  top: -5px;
}

.tooltip-bottom::before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  bottom: -5px;
}

.tooltip-left::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #000;
  left: -5px;
}

.tooltip-right::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000;
  right: -5px;
}
.bargraph-container .summary-container .summary-section span.custom-tooltip-wrapper {
  width: auto !important;
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bargraph-container .graph-legend-section {
    flex-direction: column;
  }
  .bargraph-container .graph-section {
    width: 100%;
    flex: 1;
  }
  .bargraph-container .legend-section {
    width: 100%;
    flex: 1;
    margin-top: 24px;
  }  
  .graph-container.bargraph-container .graph-section .legend-list {
    right: 196px;
  }
  
}
@media (min-width: 768px) and (max-width: 991px) {
  .bargraph-container .graph-legend-filter-section .header-wrapper .header-legend-wraper {
    width: 61%;
  }
  /* .graph-container.bargraph-container .graph-section .legend-list {
    left: 28px;
    top: -7px;
  } */
  .bargraph-container .graph-section {
    padding-top: 28px;
  }
  
}
@media (max-width: 991px) {
  .bargraph-container .graph-legend-section {
    flex-direction: column;
    position: relative;
    padding-bottom: 80px;
  }
  .bargraph-container .graph-section {
    width: 100%;
    position: static !important;
    flex: 1;   
    order: 2; 
  }
  .graph-container .graph-section .legend-list li {
    font-size: 15px !important;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .graph-container.bargraph-container .graph-section .legend-list {  
    right: auto !important;
    left: 50%;
    top: auto !important;
    bottom: 14px !important;
    transform: translate(-50%, 0) !important;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  
  .bargraph-container .graph-legend-section {
    flex-direction: column;
  }
  .bargraph-container .graph-section {
    width: 100%;
    flex: 1;
  }
  .bargraph-container .legend-section {
    width: 100%;
    flex: 1;
    margin-top: 24px;
  }
  /* .bargraph-container .graph-legend-filter-section .header-wrapper .header-legend-wraper {
    width: 70%;
  } */
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (max-width: 767px) {
  .bargraph-container .graph-legend-section {
    flex-direction: column;
    position: relative;
    padding-bottom: 80px;
  }
  .bargraph-container .graph-section {
    width: 100%;
    position: static !important;
    flex: 1;   
    order: 2; 
  }
  .bargraph-container .legend-section {
    width: 100%;
    flex: 1;
    margin-top: 18px;
    flex-direction: row;
    justify-content: space-between;
  }
 
  /* .graph-container.bargraph-container .graph-section .legend-list {
    right: auto !important;
    left: 50%;
    top: auto !important;
    bottom: 14px !important;
    transform: translate(-50%, 0) !important;
  } */
  .bargraph-container .graph-legend-filter-section .header-wrapper {
    display: flex;
    flex-direction: column;
  }
  .bargraph-container .graph-legend-filter-section .header-wrapper .header-legend-wraper {
    width: 100%;
    padding-right: 0;
    margin-bottom: 16px;
  }
  .bargraph-container .graph-legend-filter-section .select-wrapper {
    width: 100%;
    padding-left: 0;
  }
  .bargraph-container .graph-legend-filter-section .action-wrapper {
    width: 100%;
    margin-top: 0;
    float: left;
  }
  .graph-container .graph-section .legend-list li {
    font-size: 15px !important;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .graph-container .graph-section .legend-list li:last-child {
    margin-right: 0;
  }
  /* .dashboard-tab-content .graph-container {
    flex-direction: column;
  } */
  .dashboard-tab-content .pie-container .left-section {
    /* order: 2;
    width: 100%; */
    width: calc(100% - 150px);
  }
  .dashboard-tab-content .pie-container .right-section {
    padding: 0 14px 0 0;
    margin: 0 auto;
    width: 162px;
  }
  .dashboard-tab-content .graph-container .graph-wrapper {
    margin-top: 20px;
  }
  .dashboard-tab-content .pie-container .inner-pie-container .inner-round {
    width: 84px;
    height: 84px;
  }  
  .bargraph-container .graph-legend-filter-section {
    padding: 16px 10px 0 10px;
    max-width: 400px;
  }
  .bargraph-container .legend-section {
    margin-top: 12px;
    padding: 0 10px;
  }
  .bargraph-container .graph-section {
    padding: 12px 10px 0 10px;
  }
  .bargraph-container .summary-container {
    padding: 14px 10px;
    width: calc(50% - 6px);
    min-height: 100px;
    margin-bottom: 16px !important;
  }
  .bargraph-container .summary-container .img-container {
    width: 70px;
    height: 70px;
  }
  .bargraph-container .summary-container .summary-section span.amt-summary {
    font-size: 18px;
  }
  .bargraph-container .summary-container .summary-section span.daterange-summary {
    font-size: 14px;
  }
}
@media (max-width: 569px) {
  .bargraph-container .legend-section {
    flex-direction: column;
  }
  .bargraph-container .summary-container {
    width: 100%;
  }
}
@media (max-width: 509px) {
  .dashboard-tab-content .pie-container {
    flex-direction: column;
  }
  .dashboard-tab-content .pie-container .right-section {
    order: 1;
    padding-right: 0;
    width: 150px;
  }
  .dashboard-tab-content .pie-container .left-section {
    order: 2;
    width: 100%;
  }
  .dashboard-tab-content .graph-container .graph-wrapper {
    margin-top: 14px;
  }
}

