.cn-pr-detail-invoice thead th{
    border-top: none !important;
}
.transaction-number-cn-pr{
    color: var(--cerulean-blue);
    text-decoration: none;
}
.cn-pr-action-btn{
    width:75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.cn-pr-detail-invoice{
    width: 100%;
    height: fit-content;
    border: 1px solid var(--warm-white);
    border-radius: 10px;
    padding-top: 10px;
    margin-bottom: 30px;
}
.cn-pr-detail-invoice .MuiLinearProgress-bar {
    height: 10px !important;
    background-color: var(--white-smoke) !important;
    background-image: none !important;
}
/* #horizontalTab .tab-content-body .infinite-scroll-component {
    height: auto;
    max-height: 300px;
}
#horizontalTab .tab-content-body .infinite-scroll-component .table-container {
    overflow: initial;
    max-height: none;
} */
