.card-gst-profile {
  margin-bottom: 54px;
}
.preferences-settings-form {
  width: 100%;
  float: left;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.preferences-settings-form .fields-wrapper {
  padding: 28px 40px 0 40px !important;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}
.preferences-settings-form .date-container .MuiInputBase-input {
  border: 0 !important;
}
.preferences-settings-form .form-logo-section {
  width: 100%;
  padding: 0 0 16px 0;
  border-bottom: 1px solid var(--approx-whisper);
}
.preferences-settings-form .form-fields-section {
  width: 100%;
  padding: 20px 0 23px 0;
}
.preferences-settings-form .form-button-section {
  position: relative;
  padding: 20px 40px 30px 40px;
}
.preferences-settings-form .form-button-section .alert-msg {
  margin-top: 0;
  margin-bottom: 20px;
}
.preferences-settings-form .form-button-section:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  border-top: 1px solid var(--approx-whisper);
}
.preferences-settings-form .fields-wrapper .form-fields {
  display: inline-block;
  width: 100%;
  max-width: 522px;
}
.preferences-settings-form .fields-wrapper .image-upload-container {
  width: 100%;
  min-height: 163px;
}
/* .preferences-settings-form .fields-wrapper .row.custom-row {
    margin-left: -1rem;
    margin-right: -1prem;
} */
.mw-500 {
  max-width: calc(500px + 2rem) !important;
  width: 100%;
}
.pr-14 {
  padding-right: 14px !important;
}
.pl-14 {
  padding-left: 14px !important;
}
.preferences-settings-form .fields-wrapper .form-fields label {
  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
}
.preferences-settings-form .fields-wrapper .form-fields input {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  height: 40px;
  line-height: 40px;
  color: var(--charcoal-grey);
  padding: 0 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
}
.preferences-settings-form
  .fields-wrapper
  .form-fields
  input:not(.error input) {
  background-color: var(--white-fill);
}
.preferences-settings-form .fields-wrapper .form-fields .custom-select input {
  height: auto !important;
}

/* .preferences-settings-form .fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.preferences-settings-form .fields-wrapper .form-fields input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.preferences-settings-form .fields-wrapper .form-fields input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.preferences-settings-form .fields-wrapper .form-fields input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
.preferences-settings-form .fields-wrapper .form-fields .row {
  margin-bottom: 20px;
}
.preferences-settings-form .form-fields-section .form-fields .row:last-child {
  margin-bottom: 0;
}
.preferences-settings-form .fields-wrapper .form-fields input:disabled {
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
  border: 0 !important;
}
.preferences-settings-form
  .form-button-section
  .button-fields.form-button-wrapper {
  border-top: 0;
  margin: 0;
  padding: 0;
}
.preferences-settings-form .alert-msg {
  width: 100%;
  max-width: 522px;
  min-height: 26px;
  border-radius: 4px;
  padding: 2px 10px;
  margin-top: 8px;
  flex: 0 0 100%;
}
.preferences-settings-form .alert-msg.info-alert-masg {
  background-color: var(--pale-conflower-blue);
}
.preferences-settings-form .alert-msg img {
  width: 16px;
  height: 16px;
  margin: 3px 6px 3px 0;
  object-fit: contain;
}
.preferences-settings-form .alert-msg p {
  min-height: 22px;
  margin: 0 0 0 6px;

  font-size: 13px;
  font-weight: normal;

  line-height: 22px;
  letter-spacing: normal;
  color: var(--chambray);
}
.form-logo-section .image-upload-container h5 {
  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  margin-bottom: 15px;
}

.preferences-settings-form input.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}

.gst-profile-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}
.preferences-settings-form .radio-label {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-right: -4px;
  padding: 13px 12px 13px 0;
}
.preferences-settings-form .MuiFormControl-root {
  float: left;
}
.preferences-settings-form
  .MuiFormControl-root
  .MuiFormControlLabel-root:last-child {
  margin-right: 0;
}
#crop_modal .image-crop-modal {
  width: 40% !important;
}
.preferences-settings-form .MuiInputBase-root {
  padding-right: 0 !important;
}
.preferences-settings-form .MuiInputBase-input {
  width: calc(100% - 62px) !important;
}
.line-height-18 {
  line-height: 18px;
}
.d-inline-flex {
  display: inline-flex;
}
.roundoff-preference .accounts-dropdown .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}

.roundoff-preference .accounts-dropdown {
  width: 100% !important;
  clear: both;
}
.roundoff-preference .accounts-dropdown input.search-dropdown.error {
  border: 0 !important;
  background-color: var(--rose-white) !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #crop_modal .image-crop-modal {
    width: 60% !important;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
  #crop_modal .image-crop-modal {
    width: 60% !important;
  }
}
.recurring-invoice-radio {
  width: 100%;
}

.configure-transaction-form .border-bottom {
  margin-top: 30px;
}