:root {
  /* Reds */
  --red: #ed2a2a;
  --coral-red: #ff4040;
  --tomato: #ff5f4c;
  --salmon: #ff7979;
  --pink: #ffc7c7;

  /* Blues */
  --atlantis: #0041b5;
  --dodger-blue: #0d6efd;
  --cerulean-blue: #0d5ae5;
  --navy-blue: #1660e6;
  --cornflower-blue: #5491ff;
  --new-cornflower-blue: #4c85eb;
  --approx-cornflower-blue: #70a3ff;
  --lavender-blue: #847aff;
  --amethyst: #a879d5;
  --pale-conflower-blue: #bad7ff;
  --pattens-blue: #d0e6f7;
  --audit-blue: #f5f8fe;
  --lavender: #e5e9f9;
  --alice-blue: #e7f1ff;
  --new-alice-blue: #f3f8ff;
  --approx-alice-blue: #f7fcff;
  --new-approx-alice-blue: #f9fbff;
  --new-approx-alice-blue2: #f6faff;
  --new-approx-alice-blue-2: #f7f9fb;
  --new-approx-alice-blue3: #f8fbff;

  /* Greens */
  --deep-green: #026e26;
  --crusoe: #026e26;
  --apple: #6ab04c;
  --medium-sea-green: #20bf6b;
  --approx-apple: #4eae37;
  --yellow-green: #58cf41;
  --booger: #9ac23c;
  --vista-blue: #a3d7b5;
  --granny-apple: #c4eed2;

  /* Oranges/Yellows */
  --gamboge: #e89c05;
  --macaroni-cheese: #ffbe76;

  /* Greys/Blacks */
  --black: #000;
  --rich-black: #020202;
  --night-rider: #333333;
  --bunting: #28344a;
  --charcoal-grey: #323e48;
  --arsenic: #323e48;
  --chambray: #3c5578;
  --battleship-grey: #747d84;
  --nobel: #9b9b9b;
  --heather: #adb5bd;
  --slate: #91919c;
  --link-water: #c6cbd4;
  --tinted-grey: #d4e2e8;
  --light-grey: #d4d4d4;
  --very-light-grey: #ccc;

  /* Whites */
  --white: #ffffff;
  --white-fill: #ffffff;
  --white-smoke: #f5f5f5;
  --warm-white: #f2f2f2;
  --new-white-smoke: #efefef;
  --light-white-smoke: #f1f1f1;
  --snow-white: #fafafa;
  --approx-white: #fefefe;
  --rose-white: #fff7f7;
  --ghost-white: #eef0ff;
  --approx-whisper: #eee;
  --champagne: #fbe7cf;
  --blanched-almond: #fbe7cf;
  --new-whisper: #e7e7e7;
  --new-whisper2: #e6e6e6;
  --whisper: #e9e9e9;
  --solitude: #e3efff;

  /* Shadows */
  --black-shadow0: rgba(0, 0, 0, 0);
  --black-shadow1: rgba(0, 0, 0, 0.01);
  --black-shadow2: rgba(0, 0, 0, 0.02);
  --black-shadow3: rgba(0, 0, 0, 0.03);
  --black-shadow5: rgba(0, 0, 0, 0.05);
  --black-shadow6: rgba(0, 0, 0, 0.06);
  --black-shadow8: rgba(0, 0, 0, 0.08);
  --black-shadow11: rgba(0, 0, 0, 0.11);
  --black-shadow15: rgba(0, 0, 0, 0.15);
  --black-rgb-percent1: rgb(0 0 0 / 1%);
  --peak-point-shadow7: rgba(116, 125, 132, 0.07);
  --white-shadow8: rgba(255, 255, 255, 0.8);
  --crayola-percent25: rgb(13 110 253 / 25%);
  --cerulean-blue-shadow2: rgba(13, 90, 229, 0.2);
  --cerulean-blue-shadow4: rgba(13, 90, 229, 0.4);
  --black-rgb: 0, 0, 0;
  --white-rgb: 255, 255, 255;
  --charcoal-grey-rgb: 50, 62, 72;
  --peak-point-rgb: 116, 125, 132;
  --slate-rgb: 145, 145, 156;
  --cerulean-blue-rgb: 13, 90, 229;
  --salmon-rgb: 255, 165, 165;
  --lavendar-blush-rgb: 255, 99, 132;
  --alice-blue-rgb: 53, 162, 235;
  --padua-approx: 196, 238, 210;
  --battleship-grey-rgb: 116, 125, 132;
}
