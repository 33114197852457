.void-confirmation-wrapper {
  height: fit-content;
  padding: 0px 40px;
  min-width: 500px;
}
.void-confirmation-btn-group {
  width: 100%;
  padding: 10px 0px 20px 0px;
  display: flex;
  justify-content: end;
}
.void-confirmation-content {
  padding: 20px 0px;
}
