.create-invoice-container {
  width: 292px;
  margin: 52px auto 117px auto;
  text-align: center;
}
.no-list-item .create-invoice-container {  
  /* margin: 98px auto 117px auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 292px;
  margin: auto;   
  text-align: center;
  height: 100%;
}
.align-center {
  text-align: center;
}
.create-invoice-container .preview-img {
  display: block;
  width: 108px;
  height: auto;
  object-fit: contain;
  margin: 0 auto 16px auto;
  clear: both;
}
.create-invoice-container h3 {
  font-size: 16px;
  font-weight: 900;

  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: center;
  margin: 0 0 8px 0 !important;
}
.create-invoice-container p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var(--battleship-grey);
  text-align: center;
  margin-bottom: 24px !important;
}
.create-invoice-container button {
  height: 42px !important;
  margin: 0 auto !important;
  padding: 10px 15px 10px 13px !important;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white-fill);
}
.create-invoice-container button img {
  margin-top: -3px !important;
  margin-right: 3px;
}
.no-list-item .no-allocation {
  padding: 10px 0 20px 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: center;
  border-top: 1px solid var(--approx-whisper);
}
.no-list-item .no-allocation.no-custom-fields {
  padding-top: 20px;
}
@media (max-width: 1024px) {
  .no-list-item .create-invoice-container {  
    /* margin: 80px auto 100px auto; */
  }
}