.integrated-dropdown-component {
    display: flex;
    background-color: white;
    min-width: 300px;
}
.integrated-dropdown-component-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    width: 100%;
}
.integrated-dropdown-component .reverse-order {
    flex-direction: row-reverse !important;
}
.integrated-dropdown-component-wrapper .input-field {
    border: 2px solid var(--new-whisper) !important;
    border-radius: 6px;
}
.integrated-dropdown-component-wrapper .remove-top-left-border-radius {
    border-top-left-radius: 0px !important;
}
.integrated-dropdown-component-wrapper .remove-bottom-left-border-radius {
    border-bottom-left-radius: 0px !important;
}
.integrated-dropdown-component-wrapper .remove-top-right-border-radius {
    border-top-right-radius: 0px !important;
}
.integrated-dropdown-component-wrapper .remove-bottom-right-border-radius {
    border-bottom-right-radius: 0px !important;
}
.integrated-dropdown-component-wrapper .remove-right-border {
    border-right: none !important;
}
.integrated-dropdown-component-wrapper .remove-left-border {
    border-left: none !important;
}
.integrated-dropdown-component-wrapper .right-autocomplete {
    border: 1px solid var(--new-whisper);
    border-radius: 6px;
    width: 100%;
}
.integrated-dropdown-component-wrapper .error {
    border-color: var(--red) !important;
    background-color: var(--rose-white) !important;
}
.integrated-dropdown-component .error-message {
    margin-top: 1px;
    margin-left: 3px;
    color: var(--red);
    font-size: 13px;
    display: block;
    line-height: 19px;
}
.integrated-dropdown-component-wrapper .MuiAutocomplete-popper {
    inset: 0 !important;
    max-height: 210px !important;
    overflow: hidden !important;
}
.integrated-dropdown-component-wrapper .MuiAutocomplete-listbox {
    max-height: 210px !important;
    overflow: visible !important;
}
.integrated-dropdown-component-wrapper input {
    border: 0 !important;
    height: 18px;
}
.integrated-dropdown-component-wrapper input:focus {
    border: 0 !important;
}
.integrated-dropdown-component-wrapper .MuiOutlinedInput-root {
    padding: 2px !important;
}
#integrated-dropdown-component-popper {
    inset: 0 !important;
    overflow: visible !important;
}
#integrated-dropdown-component-paper .MuiAutocomplete-listbox {
    overflow: visible !important;
}