.pills-tab {
    width: 100%;
    margin: 12px 0 0 0;
    padding: 7px 23px 6px 24px;
    background-color: rgba(var(--peak-point-rgb), 0.07);
  }
  .pills-tab .nav-link {
    min-width: 139px;
    height: 32px;
    margin: 0 32px 0 0;
    padding: 7px 14px;
    border-radius: 5px;
  
    font-size: 15px;
    font-weight: bold;
  
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background-color: transparent;
    color: var(--cerulean-blue);
  }
  .pills-tab .nav-link.active {
    background-color: var(--cerulean-blue);
    color: var(--white-fill);
  }
  .pills-tab .nav-link .error-count {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    top: -1px;
    background-color: var(--red);
  
    font-size: 11px;
    font-weight: 900;
  
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-fill);
    border-radius: 50%;
  }
  .pills-tab .nav-link.active .error-count {
    background-color: var(--white-fill);
    color: var(--red);
  }
  .tab-content {
    padding: 24px;
  }
  .tab-content .row:last-child {
    margin-bottom: 0;
  }