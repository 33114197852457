.plan-item-add-row-container {
  text-decoration: none;
  margin-left: 12px;
}
.plan-item-add-row-img {
  margin-bottom: 2px;
}
.plan-item-add-row-text {
  margin-left: 4px;
}
.form-control:disabled {
  background-color: white;
}
.item-header {
  position: relative;
}
.removeItem {
  margin-right: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.removeItem:hover {
  opacity: 0.8;
}
