.tab-button-group-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border-radius: 6px;
  border: 1px solid var(--booger); */
}
.primary-normal-button-class {
    
  background-color: var(--booger);
  color: var(--white-fill);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--booger);
}
.primary-normal-button-class:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
}
.primary-normal-button-class:focus{
  box-shadow: none;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px;
}
.primary-normal-button-class:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
}
/* .primary-normal-button-class:focus {
  box-shadow: none;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px;
} */
.primary-normal-button-class:hover {
  cursor: pointer;
}
.secondary-normal-button-class {
  background-color: var(--white-fill);
  color: var(--booger);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--booger);
}
.secondary-normal-button-class:focus{
  box-shadow: none;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px;
}
.secondary-normal-button-class:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
}
.secondary-normal-button-class:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
}
/* .secondary-normal-button-class:focus {
  box-shadow: none;
  outline: 2px solid var(--booger);
  outline-offset: 3px;
} */
.secondary-normal-button-class:hover {
  cursor: pointer;
}
