.card-currency-config .currency-table th {
    border-top: 0 !important;
}
.list-table td span {
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}
.card-currency-config .list-table td span.base-currency,
.card-currency-config .list-table td span.base-currency span.notify {   
    font-weight: bold;
}
.card-currency-config .list-table td span.base-currency span.notify {
    font-style: italic;
    color: var(--battleship-grey);
    display: inline-block;
    margin-left: 5px;
}
.card-currency-config .list-table td span.base-currency-symbol {    
    font-weight: 600;
}
#addCurrencyModal {
    right: 0;
    width: 480px;
}
.addCurrency-form-head h4 {
    margin-bottom: 26px;
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}

#addCurrencyModal div[class$="-container"] {
    margin-top: 0 !important;
}
#addCurrencyModal div[class$="-container"] input {
    height: auto !important;
}
#addCurrencyModal .form-outline {
    width: 100%;
    clear: both;
    margin-bottom: 24px !important;
}
#addCurrencyModal .form-outline:last-child {
    margin-bottom: 30px !important;
}
#settings_table.currency-table {
    min-width: 740px !important;
}
#settings_table.currency-table th.name,
#settings_table.currency-table td.name {
    width: 50%;
}
#settings_table.currency-table th.symbol,
#settings_table.currency-table td.symbol {
    width: 48%;
}
.currency-list-search-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}