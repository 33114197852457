#custom-date-picker-dropdown {
    margin-top: 15px !important;
}

.custom-date-picker-popper {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(1080px, 362.4px, 0px);
    overflow-x: hidden;
    width: 320px;
    max-height: 358px;
    display: flex;
    flex-direction: column;
    z-index: 1800;
    background-color: var(--white-fill);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    transform-origin: center top;
    outline: 0px;
    opacity: 1;
    transform: none;
    padding: 16px;
    /* transition: opacity 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 213ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.custom-date-picker-popper .custom-calendar-header {
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 12px;
    max-height: 30px;
    min-height: 30px;
    cursor: pointer;
    width: min-content;
}

.custom-calendar-header .custom-calendar-header-label {
    display: block;
    max-height: 30px;
    overflow: hidden;
    align-items: center;
    margin-right: auto;
    margin-right: 6px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--charcoal-grey);
    letter-spacing: 0.00938em;
}

.custom-calendar-header .custom-calendar-header-down-arrow:hover {
    width: 24px;
    height: 24px !important;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 45%;
}

.custom-calendar-header .custom-calendar-header-down-arrow:hover {
    background-color: var(--approx-whisper);
}

.custom-date-picker-popper .paper-day-main-grid {
    display: block;
    position: relative;
    min-height: 240px
}

.custom-date-picker-popper .paper-day-main-grid .custom-day-picker-paper {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    width: 36px;
    height: 36px !important;
    border-radius: 50%;
    padding: 0px;
    background-color: var(--white-fill);
    color: rgba(0, 0, 0, 0.87);
    margin: 0px 2px;
    box-shadow: none;
}

.custom-date-picker-popper .paper-day-main-grid .custom-day-picker-paper:hover {
    background-color: var(--approx-whisper);
}

.custom-date-picker-popper .paper-day-main-grid .disabled-days {
    opacity: 0.5;
    cursor: default
}
.custom-date-picker-popper .paper-day-main-grid .disabled-days:hover {
    background-color: var(--white-fill);
}

.custom-date-picker-popper .paper-day-main-grid .day-selected {
    color: var(--white-fill);
    background-color: #1976d2;
}

.custom-date-picker-popper .paper-day-main-grid .day-selected:hover{
    color: var(--white-fill);
    background-color: #1565c0;;
}

.custom-date-picker-popper .paper-month-main-grid {
    width: auto;
    max-height: 358px;
}

.custom-date-picker-popper .paper-month-main-grid .custom-month-picker-paper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border: 0;
    outline: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin: 8px 0;
    height: 36px;
    border-radius: 18px !important;
    box-shadow: none !important;
    color: var(--battleship-grey);
}

.custom-date-picker-popper .paper-month-main-grid .custom-month-picker-paper:hover {
    background-color: var(--approx-whisper);
}

.custom-date-picker-popper .paper-month-main-grid .month-selected {
    color: var(--white-fill)  !important;
    background-color: #1976d2  !important;
}

.custom-date-picker-popper .paper-month-main-grid .month-selected:hover {
    color: var(--white-fill) !important;
    background-color: #1565c0  !important;
}