.user-transaction-limit-wrap {
  width: 500px;
  height: "fit-content";
  padding: 20px 30px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
}
.user-transaction-main-alert-message {
  color: var(--charcoal-grey) !important;
  font-size: 16px !important;
  margin-left: 5px;
}
.user-transaction-limit-btn-group {
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: flex-end;
}
.transaction-limit-ok-button {
  width: auto;
  min-width: 124px;
}
.user-transaction-limit-wrapper-class {
  margin: 0px 5px !important;
}
