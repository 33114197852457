.subscription-impact-details-container {
  margin-bottom: 11px;
  padding: 0px 20px 0px 20px;
  --bs-gutter-x: 0 !important;
  border-radius: 3px;
  min-height: 49px;
  background-color: var(--alice-blue);
  border: 1px solid var(--pale-conflower-blue);
  align-items: center;
  display: flex;
  max-width: max-content !important;
}
.subscription-impact-update-conatiner {
  padding: 3px 20px 3px 20px;
  --bs-gutter-x: 0 !important;
  border-radius: 3px;
  background-color: #b4dcff;
  align-items: center;
  display: flex;
  max-width: max-content !important;
}
.m-l-10 {
  margin-left: 10px;
}
.m-t-24 {
  margin-top: 24px;
}
.invoice-now-button {
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  padding-right: 8px;
  padding-bottom: 9px;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  text-decoration: none;
}
.unbilled-charges-list .delete-col span {
  position: absolute;
  right: 0 !important;
  margin-right: 23px;
}
.scheduler-cancel-btn:hover,
.scheduler-cancel-btn:focus,
.scheduler-cancel-btn:active {
  background-color: transparent !important;
}
.info-container {
  background-color: var(--alice-blue);
  border: 1px solid var(--lavender);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin-bottom: 50px;
}
.info-content {
  display: flex;
  gap: 12px;
}
#message-img {
  width: 24px;
  height: 24px;
}
.regenerate-invoice-text-and-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.info-text {
  font-size: 14px;
  color: var(--charcoal-grey);
  line-height: 1.5;
}
.regenerate-button {
  font-size: 14px;
  color: var(--cerulean-blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  margin-left: 40px;
}
.regenerate-button:hover {
  color: #0a58ca;
}
