.row-wise-field-wrapper{
    width:100%;
    flex-wrap: wrap;
    clear:both;
    margin-top: 30px;

}
.row-wise-field-item{
    flex:0 0 25%;
    margin-bottom: 30px;
}
.row-wise-field-item-header{
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
}
.row-wise-field-item-details{
    font-size: 16px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}