.payment-action-table#invoice_list_table th.pay_num,
.payment-action-table#invoice_list_table td.pay_num {
  min-width: 150px !important;
}
.payment-action-table#invoice_list_table th.pay_status,
.payment-action-table#invoice_list_table td.pay_status {
  min-width: 107px !important;
}
.payment-action-table#invoice_list_table th.amt_recived,
.payment-action-table#invoice_list_table td.amt_recived {
  min-width: 110px !important;
}
.payment-action-table#invoice_list_table th.amt_excess,
.payment-action-table#invoice_list_table td.amt_excess {
  min-width: 148px !important;
}
.payment-action-table#invoice_list_table td.amt_excess,
.payment-action-table#invoice_list_table td.amt_recived {
  white-space: nowrap;
}

.payment-action-table td .excess {
  color: var(--red);
}
.payment-action-table td .allocated {
  color: var(--apple);
}

#upload_section {
  padding: 30px 24px 14px;
  border-bottom: 1px solid var(--approx-whisper);
}

.upload-header {
  
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  padding-bottom: 21px;
}


.radio-button-wrap {
  display: flex;
  flex-direction: column;
}

.radio-button-wrap .form-check-label {
  margin-right: 12px;
}

.radio-button-wrap .tax-label {
  margin-bottom: 22px !important;
}

.customer-dropdown {
  z-index: 11;
}

.email-reciepients-wrapper {
  padding-top: 30px;
}

.payment-form {
  justify-content: space-between;
}

.form-button-wrapper .delete-button {
  color: var(--red);
  border-color: var(--red);
}

.form-button-wrapper .delete-button:hover {
  background-color: var(--red);
}

#tax_category.selectpicker {
  display: block !important;
}

.add_payment_form .unavailable-span {
  width: 100%;
}

.received-table.payment-action-table#invoice_list_table th:nth-child(5) {
  width: 150px !important;
}

.pr-preview .bill-addr-label,
.pr-preview .bill-address {
  text-align: right;
}

.bank-tax-input {
  height: 40px;
  margin-left: 0;
}

/* .create-pr-form.add_payment_form .inv-prefix, */
.create-pr-form.add_payment_form input#payment_number,
.create-pr-form.add_payment_form input#amount_received,
.create-pr-form.add_payment_form .inv-group input {
  height: 40px;
}
textarea {
  resize: none;
}
.cheque-class{
  transition: all 0.7s;
  transition-timing-function: ease-in-out;
}
.payment-details-form{
  max-width: 800px !important;
}
.unpaid-error{
  display: block;
  white-space: normal;
  float: right;
  text-align: right;
}