.tcs-form-modal-wrapper {
  right: 0;
  width: 480px;
}
.tcs-edit-side-modal .reusable-modal-btn > button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: left;
}
.tcs-form-body-wrapper {
  padding: 0 40px 40px 40px;
}
.tcs-form-input-wrapper {
  width: 100%;
  margin-bottom: 20px;
  clear: both;
}
.tcs-form-input-wrapper .form-check-input {
  margin-right: 16px;
}
.tcs-form-input-wrapper .terms-and-condition-label {
  display: inline-block;
  width: calc(100% - 32px);
}
.tcs-radio-wrapper {
}
.tcs-form input.error,
.tcs-form select.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
#tcs-form input,
#tcs-form .form-select,
#tcs-form label {
  clear: both;
}
#tcs-form input,
#tcs-form .form-select {
  width: 100%;
}
#tcs-form input:focus,
#tcs-form input[type="text"]:focus,
#tcs-form .form-select:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
#tcs-form label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
  width: fit-content;
}
#tcs-form input[type="text"] {
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.tcs-radio-wrapper {
  display: flex;
}
.tcs-radio-wrapper .form-check-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
.tcs-radio-wrapper .form-check-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
.tcs-radio-wrapper .form-check-inline:last-child {
  padding-right: 0;
}
.tcs-radio-wrapper .form-check-input[type="radio"] {
  margin-right: 6px;
}
.tcs-radio-wrapper label {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey);
  float: none !important;
}
.tcs-terms-and-condition {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48 !important;
  padding: 0 0 8px 0;
}
.terms-and-condition-label {
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #323e48;
}
.tcs-form-button-wrapper {
  padding: 24px 50px 0;
  padding: 24px 0px 0;
  margin-top: 4px;
  font-size: 0;
}
.tcs-form-button-wrapper .cancel-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.radio-btn-tds {
  color: var(--cerulean-blue) !important;
}
.tds-checkbox-wrapper {
  display: flex;
}
.tds-terms-checkbox {
  margin-top: 6px !important;
}
