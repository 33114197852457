.info-popup-wrapper {
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}
.info-popup-tab-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.info-popup-tab-content {
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
}
.info-popup-conversion-list-item {
  font-size: 14px;
  /* font-weight: bold; */
  margin-bottom: 10px;
}
