.graph-container,
.dashboard-half-box {
  display: flex;
  width: 100%;
  display: flex;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.dashboard-half-box {
  height: 100%;
}
.graph-container .header-wrapper h3,
.dashboard-half-box .header-wrapper h3 {
  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin: 0 !important;
}
/*edit by dhivya*/
.graph-container .graph-legend-filter-section .action-wrapper,
.dashboard-half-box .graph-legend-filter-section .action-wrapper {
  float: right;
}
.graph-container .graph-legend-filter-section .legend-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 !important;
}
.graph-container .graph-legend-filter-section .legend-list li,
.dashboard-half-box .legend-list li {
  position: relative;
  display: inline-block;

  font-size: 12px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-right: 16px;
}
/*edit by dhivya*/

.graph-container .graph-section .action-wrapper,
.dashboard-half-box .graph-section .action-wrapper {
  float: right;
}
.graph-container .graph-section .legend-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 !important;
}
.graph-container .graph-section .legend-list li,
.dashboard-half-box .legend-list li {
  position: relative;
  display: inline-block;

  font-size: 12px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-right: 16px;
}
.graph-container .legend-list .legend-color,
.dashboard-half-box .legend-list .legend-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 6px;
}
.graph-container .graph-wrapper {
  width: 100%;
}
.mt-32 {
  margin-top: 32px;
}
.p-rl-12 {
  margin-left: -12px;
  margin-right: -12px;
}
.p-rl-12 .col {
  padding-right: 12px;
  padding-left: 12px;
}

.table-wraper-section {
  width: 100%;
  padding: 21px 24px;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  height: 100%;
}
.table-wraper-section .header-wrapper {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}
.table-wraper-section .header-wrapper h3 {
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin: 0 !important;
}
.table-wraper-section .table-section {
  width: 100%;
  clear: both;
  margin-top: 16px;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 0 !important;
}
.table-wraper-section table td:first-child {
  width: 57.3055%;
  padding-left: 24px !important;
  text-align: left !important;
}
.table-wraper-section table td:last-child {
  width: 57.3055%;
  padding-left: 24px !important;
  text-align: right !important;
  font-weight: 900;
  padding-right: 24px !important;
  white-space: nowrap;
}
.card-dashboard .dashboard-org-tag {
  display: flex;
  margin: 0px !important;
  clear: both;
  width: 100%;
  padding: 10px 0 0 0;
}
.card-dashboard .dashboard-org-tag span.me-2 {
  margin-left: 0 !important;
}


/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  
  .two-column-row .col {
    width: 100%;
    flex: 1 0 100%;
  }
  
  .two-column-row .col .dashboard-half-box {    
    margin-bottom: 16px;
  }
  .two-column-row .col:last-child .dashboard-half-box {
    margin-bottom: 0;
  }
  .table-wraper-section {
    height: auto;
  }
  
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .two-column-row .col {
    width: 100%;
    flex: 1 0 100%;
  }
  
  .two-column-row .col .dashboard-half-box {    
    margin-bottom: 16px;
  }
  .two-column-row .col:last-child .dashboard-half-box {
    margin-bottom: 0;
  }
  .table-wraper-section {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (min-width: 1025px) {
}
@media (min-width: 1025px) and (max-width: 1280px) {
}
@media (min-width: 1281px) {
}

@media (max-width: 767px) {
  .two-column-row .col {
    width: 100%;
    flex: 1 0 100%;
  }
  
  .two-column-row .col .dashboard-half-box {    
    margin-bottom: 16px;
  }
  .two-column-row .col:last-child .dashboard-half-box {
    margin-bottom: 0;
  }
  .table-wraper-section {
    height: auto;
  }
}