.associated-tag-list-page-wrapper {
  width: 600px;
  height: 450px;
}
.associated-tag-list-mapping-wrapper {
  height: fit-content;
  max-height: 400px;
  /* max-width: 600px; */
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid whitesmoke !important;
  border-radius: 5px !important;
}
.associated-tag-list-summary-MUI-wrapper.even {
  background-color: whitesmoke !important;
}
.associated-tag-list-item-options {
  height: fit-content;
  line-height: 30px;
  padding: 0px 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--warm_white);
  background-color: white;
  /* border: 0.5px solid whitesmoke; */
}
.associated-tag-list-item-options:nth-child(even) {
  background-color: whitesmoke;
}
.associated-tag-list-item-options:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.associated-tag-list-item-options:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.associated-tag-list-summary-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.associated-tag-list-summary-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red !important; */
}

.associated-tag-MUI-accordion:nth-child(even) {
  background-color: whitesmoke !important;
}
.associated-tag-MUI-accordion.Mui-expanded {
  background-color: var(--approx-alice-blue) !important;
  border: 1px solid var(--pattens-blue) !important;
}
.associated-tag-MUI-accordion.Mui-expanded:last-child {
  background-color: var(--approx-alice-blue) !important;
  border: 1px solid var(--pattens-blue) !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.associated-tag-MUI-accordion.Mui-expanded:first-child {
  background-color: var(--approx-alice-blue) !important;
  border: 1px solid var(--pattens-blue) !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.associated-tag-MUI-accordion .Mui-expanded {
  background-color: var(--approx-alice-blue) !important;
}
.associated-tag-list-header-wrapper {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  margin-bottom: 20px;
}
.associated-tag-list-summary-tag-name {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.associated-tag-list-summary-name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
