.subscription-invoice-preview-skeleton-loader-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.subscription-invoice-preview-basic-details-skeleton-loader-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.subscription-invoice-preview-basic-description-skeleton-loader-wrapper{
    width: 100%;
    padding: 10px;
    background-color: var(--warm-white);
    border-radius: 5px ;
}
.subscription-invoice-preview-item-table-skeleton-loader-wrapper {
  width: 100%;
}
.subscription-invoice-preview-total-section-skeleton-loader-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
