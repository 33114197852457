.login-loader-wrapper {
  width: 60vw;
  height: fit-content;
  padding: 50px 160px 90px;
  border-radius: 16px;
  background-color: var(--white-fill);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-loader-icon-wrapper {
  width: 250px;
  height: 180px;
  display: flex;
  justify-content: center;
}
.login-loader-info-mesasage {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal-grey);
  margin: 60px 0px;
}
.login-loader-linear-progress {
  width: 33%;
}
.login-loader-linear-progress-wrapper {
  column-gap: 30px;
}
.MuiLinearProgress-bar {
  height: 10px !important;
  background-image: linear-gradient(to right, #40e495, #2bb673) !important;
}
.login-loader-progress-time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.login-loader-est-time {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: rgba(50, 62, 72, 0.6);
  margin-top: 25px;
}
.login-loader-circular-progress-icon-wrap {
  position: relative;
}
.login-loader-circular-progress-steps-wrap {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.login-loader-circular-progress-icon-message-wrapper {
  width: 100%;
  justify-content: start;
  column-gap: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.login-loader-circular-progress-message-wrap {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: left;
  color: rgba(50, 62, 72, 0.3);
}
.login-loader-circular-progress-message-wrap.in-progress {
  /* font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: left; */
  color: var(--charcoal-grey);
}
.login-loader-circular-progress-container.incomplete {
  width: 32px;
  height: 32px;
  border: 2px solid var(--light-white-smoke);
  background-color: transparent;
  border-radius: 16px;
}
.login-loader-circular-progress-container.progress {
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 16px;
}
.login-loader-circular-progress-container.complete {
  width: 32px;
  height: 32px;
  background-color: var(--light-white-smoke);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .login-loader-wrapper {
    width: 80vw;
    height: fit-content;
  }
}
