.customer-notes label {
  clear: both;
}
.customer-notes .notes-textarea {
  width: 100%;
  max-width: 420px !important;
  height: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  clear: both;
  float: left;
}

@media screen and (max-width: 1100px) {
  .customer-notes .notes-textarea {
    max-width: 320px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .customer-notes .notes-textarea {
    max-width: none !important;
  }
}
