.payment-terms-list-wrapper {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.payment-terms-list-wrapper.add-new, 
#addNewButtonPaymentTerms {
  margin-left: 40px !important;
}
.payment-terms-list-wrapper .title {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey);
  text-transform: uppercase;
}
.configure-payment-term-modal .reusable-modal-btn {
  justify-content: flex-end !important;
}
.payment-term-modal-btn{
  margin-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  box-shadow: none;
  padding-right: 0px;
  background-color: transparent;
  color: var(--cerulean-blue);
}
.payment-term-modal-btn img{
  margin-right: 5px;
}
.edit-form-wrap .col:first-child {
  padding-left: 40px;
  padding-right: 10px;
}
.edit-form-wrap .col:last-child {
  padding-right: 10px;
  padding-left: 10px;
}


.edit-payment-term-form-wrap .tagName.hidden {
  display: none;
}

.edit-form-wrap .row:not([class^="title-row"]):hover {
  background-color: var(--solitude);
}
  