.email-modal.modal .modal-dialog {
    top: 200px;
}
.email-modal .modal-dialog {
    max-width: 742px;
}
.email-modal h5 {
    font-size: 16px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.2px;
    color: var(--charcoal-grey);
}
.email-modal .modal-header {
    padding: 30px 30px 12px;
}
.email-modal .modal-footer {
    padding: 24px 30px 30px;
    border-top: 0;
}

.email-modal .modal-footer > * {
    margin: 0;
}
.modal-body-send-email {
    margin-left: 30px;
}
.email-modal .btn.send-button,
.email-modal .btn.cancel-button {
    padding: 9.5px 23px;
    border-radius: 6px;
    font-size: 15px;  
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
}

.email-modal .btn.send-button {
    position: relative;
    background-color: var(--booger);
    color: var(--white-fill);
    margin-right: 20px !important;
}
.email-modal .btn.send-button:hover {
    background-color: var(--booger);
    color: var(--white-fill);
}
.email-modal .btn.send-button:hover:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: rgba(var(--black-rgb), 0.08);
}

.email-modal .btn.cancel-button {
    background-color: var(--white-fill);
    color: var(--booger);
    border: 1px solid var(--booger);
}




