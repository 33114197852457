.right-details {
  min-width: calc(388px + 1.5rem);
  margin-left: auto;
  max-width: 570px;
}
.total-wrapper {
  border-bottom: 1px solid var(--approx-whisper);
  padding: 0;
}

.total-wrapper > div {
  margin-bottom: 20px;
}
.sub-total-label,
.sub-total,
.discount-wrap,
.discount-label,
.discount-amount {
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.2px;
}
.sub-total,
.discount-amount,
#sum-total {
  white-space: nowrap;
}
.sub-total-label,
.sub-total,
.sub-total span {
  font-weight: bold;
  color: var(--charcoal-grey);
}
.sub-total span:first-child {
  font-weight: 600;
}
.i-gst-label,
.c-gst-label {
  width: 55%;
  padding-left: 25px;
}

.c-gst-amount,
.i-gst-amount,
.i-gst-label,
.c-gst-label {
  font-size: 14px;
}

.gst-label, .gst-amount{
  font-size: 14px;
}
.gst-label{
  padding-left: 25px;
}

.discount-wrap {
  color: var(--battleship-grey);
}

.discount-wrap a {
  margin-left: 12px;
}

.discount-label {
  font-weight: 500;
  color: var(--battleship-grey);
}
.discount-amount,
.discount-amount span {
  text-align: right;
  font-weight: 600;
  color: var(--charcoal-grey);
}
.discount-label .info-btn {
  top: 2px;
}

.discount-tooltip {
  padding: 0;
  border: none;
  background: none;
}
.total-wrapper .tds-input-container .tds-label span {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--battleship-grey);
}
.total-wrapper .tds-input-container input {
  display: inline-block !important;
  width: 80px;
  height: 32px;
  padding: 0 12px !important;
  font-size: 14px;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-left: 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.total-wrapper .tds-input-container .tds-label span.error {
  display: block;
  width: 100%;
  color: var(--red);
  font-size: 13px;
  font-weight: normal;
  letter-spacing: normal;
  display: block;
  line-height: 19px;
  clear: both;
}
.total-label,
.sum-total {
  font-size: 20px;
  line-height: normal;
  color: var(--charcoal-grey);
}

.total-label {
  font-weight: bold;
  letter-spacing: -0.25px;
  /* color: var(--battleship-grey); */
}

.sum-total,
.sum-total span {
  font-weight: 900;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
}
.sum-total span:first-child {
  font-weight: 600;
}
.sum-total span.error {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
  font-weight: normal;
} 
.discount-label .tds-fields-wrapper {
  width: 315px !important;
}
.discount-label .tds-fields-wrapper .removeItem {
  line-height: 27px;
}
/* .discount-label .tds-dropdown {
  width: calc(100% - 82px) !important;
}
.discount-label .tds-entry {
  width: 82px !important;
} */
.tds-tcs-radio-button-wrapper {
  display: flex;
}
.tds-tcs-radio-button-wrapper .tds-tcs-radio-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
.tds-tcs-radio-button-wrapper .tds-tcs-radio-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
.tds-tcs-radio-button-wrapper .tds-tcs-radio-inline:last-child {
  padding-right: 0;
}
.tds-tcs-radio-button-wrapper .tds-tcs-check-input[type="radio"] {
  margin-right: 6px;
}
.tds-tcs-radio-button-wrapper label {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
@media (max-width: 1350px) {
  .right-details {
    min-width: calc(450px + 1.5rem);
    margin-left: auto;
  }
  .total-wrapper .tds-input-container .tds-label span,
  .sub-total-label,
  .sub-total,
  .discount-wrap,
  .discount-label,
  .discount-amount {
    font-size: 14px !important;
  }
  .total-label,
  .sum-total {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1100px) {
  .right-details {
    min-width: 420px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1100px) {
  .discount-label .tds-fields-wrapper {
    width: 240px !important;
    min-width: auto !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .discount-label .tds-fields-wrapper {
    min-width: auto !important;
    max-width: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .discount-label .tds-fields-wrapper {
    width: 300px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .discount-label {
    padding-right: 10px;
  }
  .discount-label .tds-fields-wrapper {
    width: 235px !important;
  }
}
