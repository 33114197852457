.toggle-btn-wrapper {
    width: auto;
    min-width: 82px;
    height: 20px;
    float: right;
    -webkit-box-shadow: 0 2px 1px 0 var(--black-shadow6);
    box-shadow: 0 2px 1px 0 var(--black-shadow6);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    border-radius: 4px;
  }
  .toggle-btn-wrapper button {
    width: 50%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
    background-color: var(--white-fill);
    transition: 0.2s background ease-in-out;
    border-radius: 4px 0 0 4px;
    float: left;   
  }
  .toggle-btn-wrapper button.active {
    border-radius: 3px;
    background-color: var(--cerulean-blue);
    color: var(--white-fill);
  }
  .toggle-btn-wrapper button:first-child {
    border-radius: 4px 0 0 4px !important;
  }
  .toggle-btn-wrapper button:last-child {
    border-radius: 0 4px 4px 0 !important;
  }

  .cash-posting-toggle.toggle-btn-wrapper,
  .cash-posting-toggle.toggle-btn-wrapper button {
    height: 35px;
  } 