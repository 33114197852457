.navbar-wrapper {
  height: 75px;
  padding: 17px 67px 17px 35px;
  background-color: var(--cerulean-blue);
  z-index: 1315;
  flex-wrap: nowrap !important;
}


/* .searchbar-section {
  position: fixed;
  display: flex;
  width: 100%;
  height: 75px;
  top: 0; 
  left: 0;
  right: 0;
  width: 100%; 
  background-color: var(--white-fill);
  align-items: center;
  align-content: center;
  justify-content: center; 
  box-shadow: 0 8px 16px 0 var(--black-shadow5);
} */
.header-search-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  background-color: var(--white-fill);
}
.header-search-button svg path:last-child {
  fill: var(--cerulean-blue);
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .navbar-wrapper .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-wrapper .navbar-nav {
    flex-direction: row;
  }
  .navbar-wrapper .navbar-toggler {
    display: none;
  }
  .navbar-wrapper .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navbar-wrapper .organization-dropdwon .dropdown-menu {
    position: absolute;
  }
  .navbar-wrapper .navbar-nav .dropdown-menu {
    position: absolute;
  }
}
@media (max-width: 1024px) {
  #navbarSupportedContent {
    display: flex !important;
  }
  /* #navbarSupportedContent ul {
    float: right;
  } */
  .header-search-button {
    margin-right: 25px;
  }
  .nav-item.add-items-link {
    margin-right: 25px;
  }
  .navbar-wrapper {
    padding: 17px 67px 17px 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #navbarSupportedContent .navbar-nav {
    flex-direction: row !important;
  }
  /* .nav-item.add-items-link {
    margin-right: 30px !important;
  } */
  .nav-item.add-items-link img {
    margin-left: 6px;
  }
}
@media (max-width: 767px) {
  #navbarSupportedContent {
    display: block !important;
    float: right;
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: 24px;
  }
  #navbarSupportedContent .navbar-nav {
    flex-direction: row !important;
    float: right;
    padding-left: 0;
  }
  .navbar-wrapper {
    padding: 17px 14px;
    justify-content: left;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-wrapper .organization-dropdwon,
  .header-search-button,
  .nav-item.add-items-link {
    margin-right: 24px !important;
  }  
  
  
}
@media (max-width: 464px) {
  .navbar-wrapper .organization-dropdwon,
  .header-search-button,
  .nav-item.add-items-link {
    margin-right: 14px !important;
  } 
}
@media (max-width: 420px) {
  /* #searchbar_wrapper .close-btn {
    width: 28px;
    height: 28px;
  } */
}
@media (max-width: 360px) {
  .navbar-wrapper {
    padding: 17px 10px;
  }  
  .navbar-wrapper .organization-dropdwon,
  .header-search-button,
  .nav-item.add-items-link {
    margin-right: 10px !important;
  }
  #searchbar_wrapper .close-btn {
    margin-right: 6px;
  }
}
@media (max-width: 340px) {
  .navbar-wrapper {
    padding: 17px 10px;
  }
  /* #searchbar_wrapper {
    padding: 0 10px 0 5px;
  } */
  .navbar-wrapper .organization-dropdwon,
  .header-search-button,
  .nav-item.add-items-link {
    margin-right: 8px !important;
  }
}
