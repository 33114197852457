.taxcode-dropdown.tds-tcs-dropdown-total .search-dropdown {
    border-radius: 6px 0 0 6px !important;
    border-left: solid 1px var(--new-whisper) !important;  
    padding-right: 22px;  
}
.taxcode-dropdown.tds-tcs-dropdown-total .search-dropdown.chosen {
    border-radius: 6px !important;
}
.taxcode-dropdown.tds-tcs-dropdown-total .search-dropdown:focus {
    border-left: 1px solid var(--cerulean-blue) !important;
}
.taxcode-dropdown.tds-tcs-dropdown-total .dropdown-menu {
    bottom: 0 !important;
    transform: translate(0, -44px) !important;
}