.subscription-invoice-preview-body-wrapper {
  max-height: 60dvh;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 50dvw;
  min-width: 50dvw;
}
.subscription-items-name-wrappper {
  font-size: 16px;
  color: var(--charcoal-grey);
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
.subscription-items-description-table-wrappper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
}
.subscription-items-description-wrappper{
  /* background-color: var(--warm-white); */
  padding-left: 0px !important;
  border-radius: 5px;
  padding-top: 0px !important;
}
.subscription-items-description-wrappper span{
  color: var(--battleship-grey)
}
.subscription-invoice-preview-modal-btn{
  font-size: 14px;
}
.subscription-items-coupon-description-container{
  border-radius: 5px;
  width: 100%;
  display: flex ;
  justify-content: space-between ;
  margin-bottom: 20px;
}
.subscription-preview-total-section{
  min-width: fit-content !important;
}
.subscription-invoice-preview-body-wrapper .tds-column-min-wdith{
  min-width: 130px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .subscription-invoice-preview-body-wrapper {
    max-width: 70dvw;
  }
}
