.reportingtag-jv .table-section table.inv-preview-table,
#invoice .table-section table.bills-preview-table,
#invoice .table-section table.cnote-preview-table,
#invoice .table-section table.dnote-preview-table {
  width: 100%;
  min-width: 1054px;
  margin-bottom: 0;
}

.reportingtag-paper {
  z-index: 1200000000 !important;
}
.report-tags-table {
  min-width: auto;
}
.report-tags-table td span.account_name,
.report-tags-table td .select-reporting-tag-modal {
  display: inline-block;
}
.report-tags-table td span.account_name {
  max-width: 320px;
}
.report-tags-table td .select-reporting-tag-modal {
  margin-left: 12px;
}
.report-tags-table .select-reporting-tag-modal .open-reporting-tag-modal-btn {
  position: relative;
  top: -3px;
}
.report-tags-table tr th:nth-child(1),
.report-tags-table tr th:nth-child(2),
.report-tags-table tr td:nth-child(1),
.report-tags-table tr td:nth-child(2) {
  text-align: left !important;
}
.report-tags-table tr th:nth-child(3),
.report-tags-table tr th:nth-child(4),
.report-tags-table tr td:nth-child(3),
.report-tags-table tr td:nth-child(4) {
  text-align: right !important;
}
.report-tags-table tr th .report-tags-table tr td {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.report-tags-table tr th:first-child .report-tags-table tr td:first-child {
  padding-left: 24px !important;
}
.report-tags-table tr th:last-child .report-tags-table tr td:last-child {
  padding-right: 24px !important;
}
.reporting-tag-footer-btn-grp {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  padding: 24px 0px;
}

.report-tags-more-tags {
  display: flex;
  padding: 3px 7px;
  margin-bottom: 5px;
  width: fit-content;
  padding: 5px;
}

.reporting-chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}
.journal-list-table-mapping-wrapper {
  margin: 0px 30px;
}
.disabled-bill-item {
  pointer-events: none !important;
  opacity: 0.5 !important;
}
.disabled-bill-item-info-wrapper {
  width: 500px;
}
.card-body.journal-list-table h2 {
  color: var(--charcoal-grey);
  font-size: 18px;
  font-weight: 900;
  letter-spacing: -0.23px;
  line-height: normal;
  margin-bottom: 10px;
  max-width: 500px;
}
.journal-list-table {
  margin-bottom: 30px;
}
.journal-list-table:first-child {
  margin-top: 30px;
}
