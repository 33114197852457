.report-table-accordion {
  /* border-radius: 4px !important; */
  min-width: 1030px;
}
.report-table-outline {
  border-radius: 4px !important;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 24px;
}
.report-table-accordion.grid-table {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 0 !important;
}
.report-table-accordion .table-header {
  border-bottom: 1px solid var(--approx-whisper);
}
.report-table-accordion .table-header .item-row .item-col {
  line-height: 32px;
}
.report-table-accordion .table-header .item-row .item-col:first-child {
  padding-left: 0 !important;
}
.report-table-accordion .category-col {
  width: 100%;
  height: 32px;
  padding: 9px 0;

  font-size: 14px;
  font-weight: bold;

  line-height: 1;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.report-table-accordion .account-col {
  width: calc(100% - 300px) !important;
}
.report-table-accordion .total-col {
  min-width: 300px !important;
  padding-right: 24px;
  padding-left: 8px;
}
.report-table-accordion .item-row {
  padding-left: 20px;
}
.report-table-accordion .btn-container {
  width: 22px;
  padding: 9px 6px 9px 0;
  width: 18px;
}
.report-table-accordion .item-row .account-col .item-label {
  padding: 0 !important;
  border: 0 !important;
  line-height: 32px;
}
.report-table-accordion .item-row,
.report-table-accordion .item-row .item-col {
  height: 32px !important;
}
.report-table-accordion .item-row .item-col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 32px;
}
.report-table-accordion .item-row .item-col span {
  padding: 0 !important;
}
.report-table-accordion .summary-row {
  height: 36px !important;
  background-color: rgba(var(--peak-point-rgb), 0.07);
  border-bottom: 1px solid var(--approx-whisper);
}
.report-table-accordion .table-footer:last-child .summary-row:last-child {
  border-bottom: 0;
}
.report-table-accordion .summary-row .item-col {
  height: 36px !important;
  line-height: 36px !important;
}
.report-table-accordion .summary-row.text-bold .item-col {
  font-weight: 900 !important;
}
.report-table-accordion .parent-row.expanded .item-col:first-child .item-label {
  font-weight: normal !important;
}

.report-table-accordion .item-row.empty-row {
  height: auto !important;
  line-height: 1 !important;
}
.report-table-accordion .item-row.empty-row .item-col.noitem-col {
  display: flex;
  width: 100%;
  height: 351px !important;
  line-height: normal !important;
  align-items: center;
}

.report-table-accordion .table-body .item-row.empty-row:hover {
  background-color: transparent;
}

.filter-box .filter-wrapper.three-col-filters .filter-item {
  /* margin-right: 6.3%; */
  /* width: 29.9824%; */
  width: 22.045%;
  margin-bottom: 20px;
}
.filter-box .filter-wrapper.three-col-filters .filter-item:last-child {
  margin-right: 0;
}
.report-table-accordion .account-node {
  cursor: pointer;
}
.report-table-accordion .account-node:hover div,
.report-table-accordion .account-node:hover div {
  color: var(--cerulean-blue) !important;
}
.customer-vendor-balance-date-filter {
  margin-right: 30px !important;
}
.customer-vendor-balance-save-btn {
  padding: 11px 24px;
  border-radius: 6px;
  background-color: var(--booger);
  color: var(--white-fill);
  width: fit-content !important;
  min-width: 100px !important;
}
.customer-vendor-balance-btn-wrapper {
  margin-bottom: 20px !important;
}
.customer-vendor-balance-date-currency-filter-wrapper {
  margin-bottom: 20px !important;
}
