.acnt-name,
.ledger-root {
  width: auto;
}
.acnt-name {  
  line-height: 24px !important;  
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.ledger-root {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  margin-top: 4px;
  white-space: normal;
}