.reusable-popover-content{
    width:100%;
    height: 100%;
}
.MuiPopover-paper{
    width:fit-content !important;
    height: fit-content !important;
}
.text-button-normal-class{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;           
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0px;
}
.text-button-normal-class.blue{
    color: var(--cerulean-blue);
}