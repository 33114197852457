#dataTable th {
  text-transform: uppercase;
  color: var(--battleship-grey) !important;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: left;
}
#dataTable {
  width: 100%;
  border: 1px solid var(--warm-white);
  border-radius: 5px;
}
#dataTable th {
  max-width: 250px;
}
#dataTable tr td {
  max-width: 250px;
}
#dataTable th.left-aligned {
  text-align: left;
}
#dataTable th.right-aligned {
  text-align: right;
}
#dataTable tr td.left-aligned {
  text-align: left;
}
#dataTable tr td.right-aligned {
  text-align: right;
}
