#taxCreateModal {
  right: 0;
  width: 480px;
}
#settings_table th {
  padding: 12px 24px !important;
}
#settings_table tr td {
  padding: 13px 24px !important;
  box-shadow: none !important;
}
#settings_table.tax-conf-table tr th:first-child,
#settings_table.tax-conf-table tr td:first-child,
#settings_table.tax-conf-table tr th:nth-child(2),
#settings_table.tax-conf-table tr td:nth-child(2) {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
#settings_table.tax-conf-table tr th:first-child,
#settings_table.tax-conf-table tr td:first-child {
  padding-left: 24px !important;
  min-width: 150px !important;
}
#settings_table.tax-conf-table tr th:nth-child(2),
#settings_table.tax-conf-table tr td:nth-child(2) {
  min-width: 180px !important;
}
#settings_table.tax-conf-table tr th:nth-child(3),
#settings_table.tax-conf-table tr td:nth-child(3) {
  width: 370px !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
}
#settings_table tr th.action,
#settings_table tr td.action {
  width: 53px !important;
  min-width: 53px !important;
  padding-left: 0 !important;
  padding-right: 24px !important;
}
#settings_table tr td.action .dropstart {
  width: 20px !important;
}
#taxCreateModal #form-btn-tax {
  margin-top: 30px;
  width: 100%;
  float: left;
}
#taxCreateModal .save-butn {
  width: 80px;
}
.radio-wrapper {
  display: flex;
}
.radio-wrapper .form-check-inline {
  margin-left: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  float: left;
  line-height: 1;
}
.radio-wrapper .form-check-inline:first-child {
  padding-right: 15px;
  padding-left: 0;
}
.radio-wrapper .form-check-inline:last-child {
  padding-right: 0;
}
.radio-wrapper .form-check-input[type="radio"] {
  margin-right: 6px;
}
.radio-wrapper label {
  font-size: 14px;
  font-weight: normal;

  line-height: 16px;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.create-user-form-wrap.create-settings-form-wrap {
  margin-top: 12px;
}
.create-settings-form-wrap.disabled {
  position: relative;
}
.create-settings-form-wrap.disabled:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 184px;
  background: rgba(var(--white-rgb), 0.5);
  top: 0;
  left: 0;
}
.cancel-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.create-settings-form-wrap label {
  margin-bottom: 12px;
}
.create-settings-form-wrap input {
  width: 100% !important;
  max-width: none !important;
  height: 41px;
  margin-top: 0 !important;
  padding: 8px 12px 9px 12px;

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
}
#taxCreateModal .custom-select input {
  height: auto !important;
}
.info-icn {
  width: 16px;
  height: auto;
  margin-right: 4px;
  position: relative;
  top: -1px;
}
.create-settings-form-wrap .custom-select {
  width: 100% !important;
  clear: both !important;
}
.settings-list-wrapper th span {
  display: inline-block;
  color: var(--battleship-grey) !important;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 2;
  text-transform: uppercase;
  white-space: nowrap;
}
#taxCreateModal.add-tax-form {
  padding: 0 40px 40px;
}

.tax-edit-side-modal .reusable-modal-btn {
  justify-content: flex-start !important;
  width: 100% !important;
}

.tax-edit-side-modal .reusable-modal-btn > button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: left;
}

.tax-edit-side-modal .reusable-modal-btn .green-button {
  padding: 0 15px 0 8px;
}

.tax-edit-side-modal .reusable-modal-btn .green-button svg {
  margin-right: 8px;
}

.tax-edit-side-modal .reusable-modal-btn .green-button
.radio-btn-tds{
  color: var(--cerulean-blue) !important;
}

.tax-edit-side-modal .reusable-modal-btn .green-button img {
  position: relative;
  top: -1px;
  margin-right: 8px;
}
.mw-148 {
  max-width: 148px;
}