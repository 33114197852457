/* Bank Details Header Card */
.banking-header-card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #f2f2f2;
  min-height: 120px;
  padding: 22px 29px;
}
.banking-header-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background: #fff;
  box-shadow: 0 6px 12px 0 #00000003;
  border-radius: 12px;
  overflow: hidden;
}
.banking-header-card-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 56px;
  row-gap: 20px;
}
/* .banking-header-info-popover{
    margin-top: 10px;
  } */
