.access-denied-wrapper {
  width: 450px;
  height: 427px;
  margin: 175px auto 50px auto;
  padding: 70px 62px 70px 63px;
  border-radius: 4px;
  -webkit-box-shadow: 0 12px 30px 0 var(--black-shadow2);
  box-shadow: 0 12px 30px 0 var(--black-shadow2);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  text-align: center;
}
.access-denied-wrapper.error-boundary {
  width: 450px;
  height: fit-content;
  margin: 175px auto 50px auto;
  padding: 70px 62px 70px 63px;
  border-radius: 4px;
  -webkit-box-shadow: 0 12px 30px 0 var(--black-shadow2);
  box-shadow: 0 12px 30px 0 var(--black-shadow2);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  text-align: center;
}


.access-denied-wrapper * {
  letter-spacing: normal;
  text-align: center;
}
.access-denied-wrapper img {
  width: 108px;
  height: auto;
  margin: 0 auto 32px auto;
  object-fit: contain;
}
.access-denied-wrapper h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  color: var(--charcoal-grey);
  margin: 0 auto 16px auto;
}
.access-denied-wrapper .sub {
  display: block;
  font-size: 15px;
  line-height: 1.6;
  color: var(--battleship-grey);
  margin: 0 auto 32px auto;
  clear: both;
}
.access-denied-wrapper button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0 !important;
  box-shadow: none !important;
  margin: 0;
  min-width: 109px;
  height: auto;
  padding: 9px 8px 9px 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  color: var(--white-fill);
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
}
.access-denied-wrapper button img {
  width: 24px;
  margin: 0 !important;
  margin-right: 6px !important;
}
.error-code{
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: center;
  margin-top: 16px;
  
}
.copy-text-btn{
  margin-left: 5px;
}
.access-denied-wrapper button.copy-text-btn {
  margin-left: 6px;
  padding: 0;
  min-width: auto !important;
  width: 14px;
  height: auto;
  margin-top: -4px;
}
.access-denied-wrapper button.copy-text-btn svg {
  width: 14px;
  height: 16px;
}

