.tab-container.dashboard-tab-content {
    width: 100%;
    padding: 21px 24px;
    border-radius: 12px;
    -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
}
.tab-link-wrapper {
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 5px;
}
.dashboard-tab-content .pills-tab {
    background-color: var(--white-fill);
    border-bottom: 2px solid var(--warm-white);
    padding: 0;
    flex-wrap: nowrap;
}
.dashboard-tab-content  .pills-tab .nav-link {
    color: var(--battleship-grey);
    height: auto;
    margin: 0;
    min-width: auto;
    position: relative;
    padding: 8px 35px;
    white-space: nowrap;
}
.dashboard-tab-content  .pills-tab .nav-link.active, 
.dashboard-tab-content  .pills-tab .nav-link:focus, 
.dashboard-tab-content  .pills-tab .nav-link:hover {
    background: transparent;
    color: var(--cerulean-blue);
}
.dashboard-tab-content .pills-tab .nav-link.active:after {
    background-color: var(--cerulean-blue);
    bottom: -2px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
}
.dashboard-tab-content .tab-content {
    padding: 30px 0 0 0;
}
.dashboard-tab-content .graph-container {
    border-radius: 12px 12px 0 0;
    border-bottom: 0;
    box-shadow: none;
}
.dashboard-tab-content .table-wraper-section {
    border-radius: 0 0 12px 12px;
    box-shadow: none;
}
@media (max-width: 767px) {
    .dashboard-tab-content .pie-container .tot-summary p {
        font-size: 18px;
    }
    .dashboard-tab-content .pie-container .legends-list li .sum-amnt {
        font-size: 16px;
    }
    .tab-container.dashboard-tab-content {
        padding: 16px 14px;
    }
}