.export-dropdown-container {
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
}

.export-dropdown-container .export-dropdown-button {
  background-color: #fff;
  padding: 8px 6px 8px 8px;
  cursor: pointer;
  border: none;
  color: #0d5ae5;
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
}

.export-dropdown-container .export-dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
}

.export-dropdown-container .export-option {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #0d5ae5;
}

.export-dropdown-container .excel-icon {
  margin-right: 8px;
}

.export-dropdown-container .export-option:hover {
  background-color: #f5f5f5;
}
