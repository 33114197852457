.right-aligned-checkbox {
    display: inline-block;   
    margin-bottom: 12px; 
    margin-top: 3px;
}
.add_vendor_form .right-aligned-checkbox label {
    
    font-size: 14px;
    font-weight: normal;   
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    padding-left: 0;
    float: left;
    position: relative;
    top: -1px;
}
.add_vendor_form .right-aligned-checkbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px var(--slate);
    /* background-color: var(--white-fill); */
    float: left;
    margin-top: 0;
    margin-right: 8px;
}
.header-with-checkbox-wrapper {
    min-width: 325px !important;
}


.billing-address {    
    position: relative;
}
.billing-address:after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc((100%) - 20px);
    top: 0;
    right: 0;
    border-right: 1px solid var(--approx-whisper);
}


/** Media Queries **/

@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}
@media (min-width: 768px) and (max-width: 991px) {   
   
    .add_vendor_form .row {
        margin-bottom: 0;
    }
    .add_vendor_form .row .col-12 ,
    .add_vendor_form[name="add_vendor_form"] .address-tab-pane .address-wrapper .row .col{
        margin-bottom: 20px !important;
    }
    .add_vendor_form[name="add_vendor_form"] .address-tab-pane .address-wrapper {
        padding-left: 0 !important;        
    }
    .add_vendor_form[name="add_vendor_form"] .address-tab-pane h2 {
        padding-left: 0;
    }
    .add_vendor_form .shipping-address {
        position: relative;
    }
    .add_vendor_form .shipping-address:before {
        content: '';
        width: 100%;
        position: absolute;
        width: 100%;
        height: 1px;        
        left: 0;
        top: -14px;
        right: 0;
        border-top: 1px solid var(--approx-whisper);
        
    }
    .add_vendor_form .billing-address:after {
        display: none;
    }
    .address-fields-row {
        flex-direction: column !important;
    }
   
    .address-fields-wrapper {
        width: 100%;
        clear: both;
    }
    
   
}

@media (min-width: 768px) and (max-width: 1024px) {    
    .add_vendor_form .right-aligned-checkbox {
        padding-right: 0;
    }
    
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
}
@media (max-width: 1040px) {
}