.ri-form .add-module-item-form {
    width: 100%;
    border-radius: 12px;
    -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
  }
  
  .ri-form #invoice-create.fields-wrapper {
    padding: 0 0 24px;
    box-shadow: none;
    border: none;
    background-color: var(--white-fill);
  }
  
  .ri-form #invoice-create.form-top-section {
    padding: 24px 24px 12px 24px;
    border-bottom: 1px solid var(--approx-whisper);
  }
  .ri-form .add-module-item-form .form-top-section {
    padding: 24px 24px 12px 24px;
    border-bottom: 1px solid var(--approx-whisper);
  }
  .ri-form .add-module-item-form .form-tab-section {
    padding: 30px 24px 32px 24px;
  }
  
  .ri-form .mw-325 {
    max-width: calc(325px + 2rem) !important;
    width: 100%;
  }  
  
  .ri-form .input-with-loader input {
    height: 41px;
  }
  
  /* .add-module-item-form label,
  #editAddresModal label,
  .sidebar-modal label, */
  .ri-form .email-label {
    
    font-size: 14px;
    font-weight: bold;
    
    
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin-bottom: 12px;
    float: left;
  }
  
  .ri-form #editAddresModal input[type="text"],
  .sidebar-modal input[type="text"],
  .custom-select > .dropdown-toggle {
    appearance: none;
    box-shadow: none;
    padding: 11px 12px;
    border-radius: 6px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 0.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  
  .ri-form #editAddresModal .custom-select > .dropdown-toggle,
  .sidebar-modal .custom-select > .dropdown-toggle {
    max-width: 100%;
  }
  
  .ri-form #editAddresModal .row,
  .sidebar-modal .row {
    margin-bottom: 20px;
    clear: both;
  }
  
  .ri-form .user-form-head h4 {
    margin-bottom: 30px;
  }
  
  .ri-form .add-module-item-form .row {
    margin-bottom: 20px;
    clear: both;
  }
  
  .ri-form .add-module-item-form input[type="text"],
  .add-module-item-form input[type="email"],
  .add-module-item-form textarea,
  .currency-select > .dropdown-toggle,
  .custom-select > .dropdown-toggle {
    max-width: 325px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    padding: 11px 12px;
    border-radius: 6px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 0.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  .ri-form .currency-select > .dropdown-toggle,
  .custom-select > .dropdown-toggle {
    padding: 0 12px;
  }
  
  .ri-form .custom-select.bootstrap-select .dropdown-menu {
    left: 0 !important;
    top: -2px !important;
  }
  
  .ri-form .item-details-wrapper {
    padding: 30px 24px 0;
  }
  
  .ri-form .form-tab-section {
    padding: 24px 24px 32px;
    border-bottom: 0;
  }
  
  .ri-form .form-tab-section .billing-address::after {
    height: 100%;
  }
  
  .ri-form #add_customer_form .link-label-grouped label {
    float: left;
  }
  
  .ri-form .link-label-grouped {
    width: 100%;
    max-width: 325px;
    float: left;
  }  
  
  .ri-form .bill-address {
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 155%;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  
  .ri-form .address-tab-pane h3 {
    
    font-size: 16px;
    font-weight: 900;
    
    
    line-height: normal;
    letter-spacing: -0.2px;
    color: var(--charcoal-grey);
    margin-bottom: 24px;
    margin-right: 17px;
    float: left;
  }
  
  .ri-form .ship-form #s_region, .ship-form .region-select {
    min-height: 40px;
  }
  
  .ri-form .item-details-wrapper h2,
  .payment-details-wrapper h2 {
    
    font-size: 18px;
    font-weight: 900;
    
    
    line-height: normal;
    letter-spacing: -0.23px;
    color: var(--charcoal-grey);
  }
  
  .ri-form .add-customer-link,
  .manage-tags-link,
  .edit-address-link {
    position: relative;
    display: block;
    float: right;
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--cerulean-blue);
    text-decoration: none;
    position: relative;
    padding-left: 20px;
  }
  
  .ri-form .add-customer-link:before,
  .manage-tags-link::before,
  .edit-address-link::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0;
    object-fit: contain;
    background-size: 14px 14px;
    left: 0;
  }

  .ri-form .manage-tags-link::before {
    background: url("../../../../../assets/images/settings-icon-blue-bg.svg") no-repeat
      center;
  }
  
  .ri-form .edit-address-link::before {
    background: url("../../../../../assets/images/edit-icon-blue.svg") no-repeat center;
  }
  
  .ri-form .edit-address-link {
    float: left;
    margin-top: 2px;
  }
  
  
  
  /* add customer add contact common */
  .ri-form .add-module-item-form .form-button-wrapper {
    padding-bottom: 39px;
  }
  
  .ri-form-footer .add-module-item-form .form-button-wrapper.docked-button-container,
  #add_customer_form .form-button-wrapper.docked-button-container,
  #add_vendor_form .form-button-wrapper.docked-button-container  {
    position: fixed;
    width: calc(100% - 96px) !important;
    padding-left: 64px !important;
    right: 0 !important;
    bottom: 0 !important;
    -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
    border-top: solid 1px var(--warm-white);
    background-color: var(--white-fill) !important;
    opacity: 1 !important;
    z-index: 99;
    padding-top: 16px !important;
    padding-bottom: 20px !important;
    transition: 0.5s;
  }
  .ri-form-footer .add-module-item-form .form-button-wrapper.docked-button-container.customer-form-section-disable:before,
  #add_customer_form .form-button-wrapper.docked-button-container.customer-form-section-disable:before,
  #add_vendor_form .form-button-wrapper.docked-button-container.customer-form-section-disable:before  {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--white-fill);
    opacity: 0.5;
    z-index: 1;
  }
  .ri-form-footer .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container,
  .expanded-submenu #add_customer_form .form-button-wrapper.docked-button-container,
  .expanded-submenu #add_vendor_form .form-button-wrapper.docked-button-container { 
    /* width: calc(100% - 226px) !important;   */
    width: calc(100% - 236px) !important;  
  }
  .ri-form-footer .add-module-item-form .form-button-wrapper.docked-button-container .dropdown-menu {
    position: absolute !important;
    inset: auto auto 0px 0px !important;
    margin: 0px !important;
    transform: translate3d(8px, -47px, 0px) !important;
  }
  .ri-form #editAddresModal .form-button-wrapper,
  .sidebar-modal .form-button-wrapper {
    border-top: 0;
    padding-top: 0;
  }
  
  
  
  .ri-form .form-check .form-check-input {
    float: left;
    margin-right: 0.4375em;
    margin-top: 0.05em;
  }
  
  .ri-form .right-aligned-checkbox {
    display: inline-block;
    margin-bottom: 12px;
    margin-top: 3px;
    position: absolute;
    min-height: 1.5rem;
    padding-right: 1.5em;
  }
  
  .ri-form #same_as_billing {
    margin-top: 1px;
    margin-right: 6px;
    float: left;
  }
  
  .ri-form .dropdown-item {
    padding: 8px 14px;
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 0.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  
  .ri-form .mw-250 {
    max-width: 250px !important;
  }
  
  .ri-form .date-selector {
    padding: 14.5px 10px 14.5px 12px;
    border-radius: 6px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 0.71;
    letter-spacing: normal;
    width: 100%;
    text-align: start;
    color: var(--charcoal-grey);
    position: relative;
  }
  
  .ri-form .date-selector img {
    position: absolute;
    right: 12px;
    top: 2px;
    bottom: 0;
    margin: auto;
  }
  
  .ri-form .items-entry-wrapper {
    border: 1px solid var(--warm-white);
    padding-bottom: 26px;
    border-radius: 4px;
    margin-bottom: 30px;
  }
  
  .ri-form .bill-item-container {
    overflow-x: auto;
    width: 100%;
  }
  
  .ri-form .item-table .accounts-dropdown input, .item-table .accounts-dropdown .dropdown-menu {
    width: 230px !important;
  }
  
  .ri-form .item-table-wrapper.item-table-wrapper-invoice {
    min-width: 1150px;
  }
  
  .ri-form .taxable-amount {
    width: 60%;
    margin-left: auto;
  }
  
  .ri-form .amount-taxable-nil,
  .amount-taxable {
    font-size: 14px;   
  
  }
  
  .ri-form .amount-taxable-nil {
    font-weight: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  }
  
  .ri-form .amount-taxable {
    font-weight: bold;
    color: var(--charcoal-grey);
  }
  
  .ri-form .tax-amount-label {
    
    font-size: 12px;
    font-weight: 500;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
  }
  
  .ri-form .add-tags-button {
    
    font-size: 14px;
    font-weight: bold;
    
    
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--cerulean-blue);
    text-decoration: none;
  }
  
  .ri-form .add-line {
    border-bottom: 1px solid var(--approx-whisper);
  }
  .ri-form .add-tax-button {
    width: 18px;
    height: 18px;
  }  
  .ri-form .discount-input {
    margin-left: 30px;
    width: 40%;
    height: 40px;
    border-right: 0 !important;
    border-radius: 6px 0 0 6px !important;
    font-size: 17px !important;
    line-height: normal !important;
  }
  
  .ri-form .discount-wrap .input-group input {
    max-width: 43px !important;
    width: 100%;
    line-height: normal !important;
  }
  
  .ri-form .discount-wrap .input-group-append div {
    border-radius: 0 6px 6px 0;
    background-color: rgba(var(--peak-point-rgb), 0.08);
    border: solid 1px var(--new-whisper);
    border-left: 0;
    
    font-size: 16px;
    font-weight: bold;
    
    
    line-height: 0.77;
    letter-spacing: normal;
    color: var(--battleship-grey);
    padding: 14px 12px;
    height: 40px;
  }
  
  .ri-form .discount-wrap .input-group {
    width: auto;
  }
  
  .ri-form .discount-wrap .input-group input {
    border-radius: 5px 0 0 5px !important;
    border: solid 1px var(--new-whisper);
    border-right: 0;
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 0.71;
    letter-spacing: normal;
    height: 40px;
  }
  
  .ri-form .discount-selector input {
    border-radius: 6px 0 0 6px !important;
  }
  
  .ri-form .discount-selector select { 
    border-radius: 0 6px 6px 0 !important;
    border-color: var(--new-whisper);
    background-color: var(--white-fill);
    border-left: 0;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
    font-size: 14px;
    font-weight: 600;
    
    
    line-height: normal;
    letter-spacing: normal;
  }
  
  .ri-form .discount-selector .option-icon {
    font-family: PingFangSC;
    font-size: 16px;
    font-weight: 600;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
  }
  
  .ri-form .discount-selector .option-value {
    
    font-size: 14px;
    font-weight: normal;    
    line-height: 0.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    padding-left: 14px;
  }
  
  .ri-form .discount-selector a {
    text-decoration: none;
    margin-left: 14px;
  }
  
  .ri-form .discount-selector li {
    margin-bottom: 16px !important;
  }
  
  .ri-form .discount-selector li:last-child {
    margin-bottom: 0 !important;
  }
  
  /* .discount-selector .dropdown-menu {
    transform: translate(-13px, 21px) !important;
  } */
  
  .ri-form .tax-selector {
    margin-top: 14px;
  }
  
  .ri-form .tax-selector:first-child {
    margin-top: 0;
  }
  .ri-form .tax-selector .percent-label {
    width: 24px;
    height: 40px;
    background-color: var(--white-fill);   
    font-size: 12px;
    font-weight: bold;    
    line-height: normal;
    letter-spacing: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
    border: 1px solid var(--new-whisper);
    border-left: 0;
    border-radius: 0 6px 6px 0;
    padding: 0 6px !important;
  }
  
  .ri-form #dropdownDiscount {
    height: 40px;
    border-radius: 0 6px 6px 0;
    border: 1px solid var(--new-whisper);
    border-left: 0;
  }
  
  .ri-form .tax-type {
    width: 15%;
    margin-left: 70px;
    height: 40px;
  }
  
  .ri-form .tax-input {
    width: 80px;
    margin-left: 16px;
  }  
  
  .ri-form .add-tax-wrapper {
    padding-left: 123px;
  }   
  
  .ri-form .email-reciepients-wrapper textarea {
    width: 682px;
    height: 94px;
    max-width: 682px !important;
  }
  
  .ri-form .email-reciepients-wrapper {
    margin-bottom: 30px;
    padding-top: 30px !important;
  } 
  
  .ri-form .payment-details-wrapper,
  .email-reciepients-wrapper {
    padding: 0 24px;
  }
  
  .ri-form .automaticReminderEmailsGrid .MuiGrid-root .MuiGrid-item {
    display: flex;
    justify-content: flex-end !important;
  }
  .ri-form .MuiSwitch-root .MuiSwitch-thumb {
    margin-top: 2px;
    margin-left: 2px;
    width: 15px;
    height: 15px;
  }

  .ri-form .MuiSwitch-root .MuiSwitch-switchBase:hover {
    background-color: rgba(0, 0, 0, 0)
  }
  
  .ri-form .MuiFormControlLabel-root {
    margin: 0px;
  }

  .ri-form .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin: 0px;
  } 

  .ri-form #editAddresModal, .sidebar-modal {
    right: 0;
    width: 480px;
  }
  
  .ri-form #editAddresModal .modal-dialog,
  .sidebar-modal .modal-dialog {
    right: 0;
    position: fixed;
    width: 480px !important;
  }
  
  .ri-form .form-button-wrapper .card-user-management .dropdown-menu {
    top: 0;
  }
  
  .ri-form .form-button-wrapper .dropdown-toggle-split {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .ri-form .form-button-wrapper .btn.save-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  
  .ri-form .form-button-wrapper .dropdown-toggle {
    background-color: var(--booger);
    border-left: 1px solid var(--white-fill);
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .ri-form .form-button-wrapper .dropdown-toggle:hover {
    color: var(--white-fill);
  }
  .ri-form .form-button-wrapper .dropdown-toggle:after {
    border: 0 !important;
    vertical-align: auto;
  }
  .ri-form .form-button-wrapper .dropdown-toggle:hover:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: rgba(var(--black-rgb), 0.08); 
  }
  
  
  .ri-form .form-button-wrapper .dropdown-menu {
    transform: translate(-30px, 21px) !important;
  }
  
  .ri-form .form-button-wrapper .cancel-button:hover {
    background-color: var(--booger);
    color: var(--white-fill);
  }
  
  .ri-form .add-module-item-form .multiSelectContainer {
    top: -5px;
  }
  .ri-form .add-module-item-form .multiSelectContainer .searchBox {
    border: 0 !important;
    width: 105px;
    padding: 0px !important;
  }
  .ri-form .add-module-item-form .multiSelectContainer .searchWrapper {
    border: solid 1px var(--new-whisper);
    padding: 4px 34px 4px 12px !important;
  }
  
  .ri-form #invoice-create.card-create-item {
    margin-bottom: 120px !important;
  }
  
  .ri-form #invoice table tbody tr:nth-child(2n) td {
    background-color: transparent;   
  }
  
  .ri-form .modal-wrap {
    right: 0;
    width: 480px;
  }
  
  .ri-form .modal-wrap .modal-dialog {
    right: 0;
    position: fixed;
    width: 480px !important;
  }
  
  .ri-form #customiseView .modal-dialog {
    max-width: 854px;
  }
  
  .ri-form #invoice_list_table tbody tr {
    cursor: pointer;
  }  
 
  .ri-form .no-data .table-info {
    display: none !important;
  }
  .ri-form .no-data .list-table thead,
  .no-data .list-table th {
    border-top: 0;
  }
  .ri-form .no-data {
    padding-top: 0 !important;
  }
  
  .ri-form .bill-item-container table th:last-child .info-btn {
    top: -4px;
  }
  
  .ri-form .invoice-table tbody th {
    background-color: var(--white-fill);
  }
  .ri-form .form-control.error {
    border: solid 1px var(--red) !important;
    background-color: var(--rose-white) !important;
  }
  .ri-form .add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option {
    flex-direction: row;
  }
  .ri-form .add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option span{
    color: var(--cerulean-blue) !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .ri-form-footer .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container,
    .expanded-submenu #add_customer_form .form-button-wrapper.docked-button-container,
    .expanded-submenu #add_vendor_form .form-button-wrapper.docked-button-container { 
      padding-left: 50px !important;
    }
  }
  @media (max-width: 991px) {
    .ri-form .add-module-item-form .address-tab-pane .billing-address,
    .ri-form .add-module-item-form .address-tab-pane .shipping-address {
      padding: 0;
      width: 100%;
      max-width: none !important;
    }
    .ri-form .add-module-item-form .address-tab-pane .shipping-address {
      position: relative;
    }
    .ri-form .add-module-item-form .address-tab-pane .billing-address:after {
      display: none;
    }
    .ri-form .add-module-item-form .address-tab-pane .shipping-address:before {
      content: "";
      position: absolute;
      border-top: 1px solid var(--approx-whisper);
      width: 100%;
      height: 1px;      
      left: 0;
      top: -20px;
      
    }
    .ri-form .add-module-item-form .address-tab-pane .billing-address a > div,
    .add-module-item-form .address-tab-pane .shipping-address a > div {
      margin-bottom: 0 !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ri-form-footer .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container {
      flex-wrap: wrap;
    }
    .ri-form-footer .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container {
      padding-bottom: 10px;
    }
    .ri-form-footer .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container > button,
    .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container .btn-group > button,
    .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container > a {
      white-space: nowrap;
      margin-bottom: 10px;
    }    
    
  }
  
  @media (min-width: 768px) and  (max-width: 850px) {
    .ri-form .expanded-submenu .add-module-item-form {
      
    }
    .ri-form-footer .expanded-submenu .add-module-item-form#add_invoice_form .form-button-wrapper.docked-button-container .delete-item-button {
      margin-left: 0 !important;
      float: left;
    }
  }

  .ri-form .discount-type-wrapper {
    width: fit-content !important;
    height: 25px !important;
  }
  .ri-form .discount-type-wrapper button {
    height: 23px !important;
  }
  .ri-form .discount-type-wrapper {
    width: 82px !important;
    height: 20px;
    float: right;
    /* -webkit-box-shadow: 0 2px 1px 0 var(--black-shadow6);
    box-shadow: 0 2px 1px 0 var(--black-shadow6); */
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    border-radius: 4px;
  }
  .ri-form .discount-type-wrapper button {
    width: 50%;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
    background-color: var(--white-fill);
    transition: 0.2s background ease-in-out;
    border-radius: 4px 0 0 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 0;
  }
  .ri-form .discount-type-wrapper button.active {
    border-radius: 3px;
    background-color: var(--cerulean-blue);
    color: var(--white-fill);
  }