.card-customer-header h1 {
  font-size: 24px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 0;
  margin-top: 5px;
}


.add-customer-button {
  width: auto;
  padding: 0 15px 0 13px;
}

.create-invoice-container {
  margin: 72px auto 120px auto;
}
.mw-349 {
  max-width: 349px !important;
}
.mw-545 {
  max-width: 545px !important;
}
.rp-24 {
  padding-right: 24px;
}
.lp-24 {
  padding-left: 24px;
}
.loader-small {
  display: none;
  position: absolute;
  width: 18px;
  height: 18px;
  background: url("../../../assets/images/loader-image-small.svg") no-repeat
    center;
  background-size: 18px 18x;
  /* left: 148px; */
  bottom: 11px;
  right: 10px;
  animation: rotation 1.5s infinite linear;
}
.loader-small.show {
  display: block;
}