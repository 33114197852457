.multi-select-label-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.multi-select-main-label-bold {
  font-size: "14px";
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  text-transform: none;
}
.multi-select-main-label {
  font-size: "14px";
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  text-transform: none;
}
.multi-select-label-sub-root {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--charcoal-grey) !important;
  margin-top: 4px;
  white-space: normal;
}
.multi-select-label-root {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  margin-top: 4px;
  white-space: normal;
}
.multiSelectContainer input[type="checkbox"]{
    margin: 5px 0px 0px 0px;
}