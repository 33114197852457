.pie-container .header-wrapper {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 24px;
}
.pie-container .left-section {
  padding: 29px 24px 24px 24px;
  width: calc(100% - 200px);
  float: left;
  display: flex;
  flex-direction: column;
}
.pie-container .tot-summary {
  width: 100%;
  clear: both;
  /* margin: 43px 0 24px 0; */
  margin: 0 0 24px 0;
}
.pie-container .tot-summary .title-link,
.pie-container .tot-summary .title {
  width: auto;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--battleship-grey);
  margin-bottom: 6px;
  float: left;
  clear: both;
}
.pie-container .tot-summary .title-link {
  text-decoration: none;
}
.pie-container .tot-summary .title-link:hover span {
  color: var(--cerulean-blue);
}
.pie-container .tot-summary .title-link img,
.pie-container .legends-list li .title-link img {
  position: relative;
  top: -2px;
  margin-left: 6px;
  width: 12px;
  height: auto;
}
.pie-container .tot-summary p {
  font-size: 32px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.04px;
  color: var(--charcoal-grey);
  margin: 0;
  clear: both;
}
.pie-container .legends-list {
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
}
.pie-container .legends-list li {
  display: inline-block;
  margin: 0 17px 8px 0;
}
.pie-container .legends-list li span {
  display: block;
  width: 100%;
  position: relative;
}
.pie-container .legends-list li span,
.pie-container .legends-list li .title-link {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  text-decoration: none;
}
.pie-container .legends-list li .title-link:hover {
  color: var(--cerulean-blue);
}
.pie-container .legends-list li .legend-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 6px;
}
.pie-container .legends-list li .sum-amnt {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.03px;
  color: var(--charcoal-grey);
  margin-top: 6px;
}
.pie-container .right-section {
  width: 200px;
  padding: 24px 24px 24px 0;
  float: right;
}
.pie-container .add-new-btn {
  display: inline-block;
  padding: 0 10px 0 8px;

  font-size: 15px;
  font-weight: bold;

  line-height: 32px;
  letter-spacing: normal;
  color: var(--white-fill);
  text-decoration: none;
  height: 32px;
}
.pie-container .add-new-btn img {
  width: 20px;
  height: auto;
  position: relative;
  top: -2px;
}
.pie-container .add-new-btn .add-button-text {
  font-size: 15px;
  font-weight: bold;

  line-height: 0.67;
  letter-spacing: normal;
  color: var(--white-fill);
}
.pie-container .graph-wrapper {
  clear: both;
  margin-top: 42px;
}
.pie-container .doughnut-outer-container {
  position: relative;
}
.pie-container .inner-pie-container {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pie-container .inner-pie-container .inner-round {
  width: 98px;
  height: 98px;
  border: 16px solid var(--ghost-white);
  background: var(--white-fill);
  border-radius: 100%;
  position: relative;
  left: -6px;
}
.pie-container .tot-summary .amount-link {
  text-decoration: none;
}
.pie-container .tot-summary .amount-link:hover p {
  color: var(--cerulean-blue);
}
