.aging-report .page-type-label {
  font-size: 24px;
}
.multi-line-title {
  display: flex;
  flex-direction: column;
}
.aging-report tr:last-child td {
  font-weight: bold;
}
.aging-report .customRowTitle td {
  font-weight: bold;
}
.report-table.balance-report-table tr th {
  vertical-align: top;
  padding-top: 12px !important;
  padding-bottom: 4px !important;
}
/* .report-table.balance-report-table tr td:nth-child(2),
.report-table.balance-report-table tr td:nth-child(3),
.report-table.balance-report-table tr th:nth-child(3),
.report-table.balance-report-table tr td:nth-child(4),
.report-table.balance-report-table tr th:nth-child(4),
.report-table.balance-report-table tr td:nth-child(5),
.report-table.balance-report-table tr th:nth-child(5),
.report-table.balance-report-table tr td:nth-child(6),
.report-table.balance-report-table tr th:nth-child(6),
.report-table.balance-report-table tr td:nth-child(7),
.report-table.balance-report-table tr th:nth-child(7),
.report-table.balance-report-table tr td:last-child,
.report-table.balance-report-table tr th:last-child {
  white-space: nowrap !important;
} */
/* .report-table.balance-report-table tr td:nth-child(3),
.report-table.balance-report-table tr th:nth-child(3),
.report-table.balance-report-table tr td:nth-child(4),
.report-table.balance-report-table tr th:nth-child(4),
.report-table.balance-report-table tr td:nth-child(5),
.report-table.balance-report-table tr th:nth-child(5),
.report-table.balance-report-table tr td:nth-child(6),
.report-table.balance-report-table tr th:nth-child(6),
.report-table.balance-report-table tr td:nth-child(7),
.report-table.balance-report-table tr th:nth-child(7) {
  text-align: right;
} */
.report-table.balance-report-table tbody tr td.column-navigation {
  cursor: pointer;
}
.report-table.balance-report-table
  tbody
  tr.customRowTitle:hover
  td.column-navigation {
  color: var(--charcoal-grey) !important;
  cursor: default;
}
.report-table.balance-report-table tbody tr:hover td.column-navigation {
  color: var(--cerulean-blue) !important;
}
.report-table.balance-report-table tr th:last-child {
  line-height: 2;
}
.vendor-balance .customRowTitle td,
.customer-balance .customRowTitle td {
  font-weight: bold;
}
.vendor-balance tbody tr.customRowTitle td {
  font-weight: bold;
  text-align: left;  
  color: var(--charcoal-grey) !important;
}
.report-table.balance-report-table tr th,
.report-table.balance-report-table tr td {
  width: auto !important;
}
