.write-off-radio-btn-wrapper{
    width:auto;   
    min-width: 160px; 
    height: 42px;
    padding: 0px 12px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center; 
    border: solid 1px var(--tinted-grey);
    border-radius: 4px;
    background-color: var(--white-fill);
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    line-height: 42px;
    white-space: nowrap;
}
.write-off-radio-btn.Mui-checked{
    color:var(--cerulean-blue) !important;
    margin-right: 20px;
    color:var(--cerulean-blue);
}
.write-off-radio-btn{
    pointer-events: none;
}
.write-off-radio-btn-wrapper .write-off-radio-btn-label{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey) ;
}
.selected-radio-btn{
    background-color: var(--new-alice-blue);
    border: 1px solid var(--cerulean-blue-shadow2);
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
}
.write-off-form-control-label{
 margin-right: 0px !important;
}
.write-off-radio-group{
    margin-right: 0px !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    justify-content: space-between !important;
}