.excess-amount-allocation-wrap {
  width: 500px;
  height: "fit-content";
  padding: 20px 30px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
}
.main-alert-message {
  width: 100%;
  color: var(--charcoal-grey) !important;
  font-size: 16px !important;
  margin-left: 5px;
  white-space: wrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.excess-amount-allocation-btn-group {
  width: 100%;
  display: flex;
  margin-top: 30px;
}
.excess-amount-note {
  color: var(--charcoal-grey) !important;
  font-size: 15px !important;
  margin-top: 20px;
}
.excess-amount-deposit-account {
  color: var(--charcoal-grey);
  font-size: 15px !important;
  font-weight: bold;
}
.main-alert-message-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
