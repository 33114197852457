.common-modal-body-btn-wrapper{
  position: relative;
}
.common-modal-body.center-modal {
    width: auto;
    height: auto;
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: calc(0.7rem - 1px);
    position: absolute;
  }
  .common-modal-body.alert-modal {
    width: auto;
    height: auto;
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: calc(0.7rem - 1px);
    position: absolute;
  }
  .common-modal-body.side-modal {
    width: auto;
    height: 100dvh;
    min-width: 300px;
    top: 0%;
    right: 0%;
    transform: translate(-0%, -0%);
    background-color: white;
    position: absolute;
  }
  .common-modal-title-wrapper {
    padding: 20px 30px 5px 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
  .common-modal-close-btn-span{
    /* width: 100%; */
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #commonAlertModal .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0.5;
  }
  .resusable-common-modal {
    box-shadow: none;
    background-color: white;
  }
  .common-modal-heading  {
    font-stretch: normal;
    font-weight: 900;
    color: var(--charcoal-grey);
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: start;
    width: 100%;
    display: flex;
    padding-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .common-modal-body.alert-modal .common-modal-heading{
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    text-align: start;
    width: 100%;
    display: flex;
    padding-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  } 
  .display-flex-align-center{
    display: flex;
    align-items: center;
  }
  .is-disabled-modal-btn {
    opacity: 0.5;
  }
  /** Media queries **/
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  @media (max-width: 1040px) {
  }
  @media (min-width: 1025px) and (max-width: 1040px) {
  }
  