.report-card {
  padding-left: 15px;
  padding-right: 15px;
}
.report-card .card-header {
  background: transparent;
  width: 100%;
  float: left;
}
.report-card .header-wrap h1 {
  margin-bottom: 30px;
}

.report-body {
  padding: 24px 30px 0 24px;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  clear: both;
  float: left;
}
.report-section {
  width: 100%;
  position: relative;
  /* border-top: 1px solid #eee; */
}
.report-section:before {
  content: "";
  position: absolute;
  width: calc(100% + 54px);
  height: 1px;
  left: -24px;
  top: 0;
  border-top: 1px solid var(--approx-whisper);
}
.report-body-links {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 30px 0 16px 0;
}
.report-header,
.report-table-section {
  width: 100%;
  padding: 0 10px 0 16px;
}
.report-table-section {
  width: 100%;
}
.report-table-section {
  margin-top: 30px;
}
.report-table-section tr td {
  line-height: normal !important;
}
.soa-report-card
  .report-table-section
  tbody
  tr:nth-last-child(2)
  td:nth-last-child(4) {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey) !important;
}
.soa-report-card
  .report-table-section
  tbody
  tr:nth-last-child(2)
  td:nth-last-child(3),
.soa-report-card
  .report-table-section
  tbody
  tr:nth-last-child(2)
  td:nth-last-child(2) {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
}
.soa-report-card
  .report-table-section
  tbody
  tr:nth-last-child(1)
  td:nth-last-child(2) {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.23px;
  color: var(--battleship-grey) !important;
}
.soa-report-card
  .report-table-section
  tbody
  tr:nth-last-child(1)
  td:nth-last-child(1) {
  font-size: 18px;
  font-weight: 900;
  color: var(--charcoal-grey);
}

.report-table-section tr th:nth-child(1),
.report-table-section tr td:nth-child(1) {
  width: 148px !important;
  min-width: 148px;
  padding-right: 10px !important;
}
.report-table-section tr th:nth-last-child(4),
.report-table-section tr td:nth-last-child(4) {
  width: 13%;
  min-width: 120px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.report-table-section tr th:nth-last-child(3),
.report-table-section tr td:nth-last-child(3) {
  width: 14%;
  min-width: 147px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.report-table-section tr th:nth-last-child(2),
.report-table-section tr td:nth-last-child(2) {
  width: 14%;
  min-width: 147px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.report-table-section tr th:nth-last-child(1),
.report-table-section tr td:nth-last-child(1) {
  width: 14%;
  min-width: 147px;
  line-height: 1;
  padding-left: 10px !important;
}
.balance-sheet-report-card .report-header,
.balance-sheet-report-card .report-table-section,
.tb-report-card .report-header,
.tb-report-card .report-table-section {
  padding: 0;
}
/* soa */
.report-table {
  margin-bottom: 0;
}
.report-table tbody tr:nth-child(even) td {
  background-color: transparent;
}
.report-table th {
  border-top: 0 !important;
  box-shadow: none;

  font-size: 12px;
  font-weight: bold;

  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
}
.report-table thead th:first-child {
  border-radius: 4px 0 0 0;
}
.report-table thead th:last-child {
  border-radius: 0 4px 0 0;
}
.report-table tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
.report-table tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}
.report-table tr td,
.report-table tr td span {
  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
}
.report-table tr td,
.report-table tr td:not(.status) span {
  color: var(--charcoal-grey) !important;
}
.report-table thead th:last-child,
.report-table tr td:last-child {
  text-align: right;
  padding-right: 24px !important;
}
.report-table tr td,
.report-table tr th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: auto !important;
  border-bottom: 1px solid var(--approx-whisper) !important;
  vertical-align: middle;
  box-shadow: none;
}
.report-table tr th {
  white-space: nowrap;
}
.report-table thead,
.report-table tr:last-child td {
  border-bottom: 0 !important;
}
.report-table tr td span {
  display: inline-block;
}
.soa-table .trans-date {
  width: 160px !important;
}
.soa-table .trans-type {
  width: 28.20513%;
}

.report-table td.right-aligned {
  text-align: right !important;
}
.report-table td .total-label,
.report-table td .due-text {
  width: 100%;
  text-align: right;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey) !important;
}
.report-table td .total-value {
  width: 100%;

  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
  text-align: left !important;
}
.report-table td .due-text {
  display: inline;
  width: 100%;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.23px;
  color: var(--battleship-grey) !important;
  margin-right: 56px;
}
.report-table td .due-amt {
  display: inline;
  font-size: 18px;
  font-weight: 900;
  text-align: right;
  color: var(--charcoal-grey) !important;
}

.tb-report-card .report-header {
  margin-bottom: 30px;
}
.tb-report-card .report-table-section {
  margin-top: 0;
}
.filter-box .button-container button:focus,
.filter-box .button-container a:focus {
  box-shadow: none !important;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px !important;
}
.report-body .top-filter-container .organization-dropdwon,
.report-body .top-filter-container .organization-dropdwon .dropdown-toggle {
  width: 100% !important;
  margin-right: 0;
}
.report-body .top-filter-container .organization-dropdwon .dropdown-toggle {
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.top-filter-container .organization-dropdwon .dropdown-toggle .oraganization {
  font-size: 14px;
  font-weight: normal;
  color: var(--charcoal-grey);
  margin-top: 1px;
  padding-left: 13px;
}
.report-body .top-filter-container .organization-dropdwon .dropdown-menu {
  width: auto;
  min-width: 100%;
  inset: 0px auto auto 0px !important;
  padding: 0 !important;
  transform: translate3d(0px, 44px, 0px) !important;
}
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle:focus {
  box-shadow: none;
}
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle:after {
  background: var(--white-fill)
    url("../../../assets/images/arrow-drop-right.svg") no-repeat right 4px
    center !important;
  background-size: 20px 20px !important;
}
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle[aria-expanded="true"]:after {
  right: 14px;
}
.top-filter-container .organization-dropdwon .dropdown-menu li a {
  padding: 9px 9px 10px 9px;
}
.report-body
  .top-filter-container
  .organization-dropdwon
  .dropdown-toggle.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.report-card .table-wrapper {
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  overflow-x: auto;
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
}
.report-note {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 16px;
}

.report-note span {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-left: 8px;
  display: inline-block;
}

.report-table-section .payable-summary tr th:nth-child(2) {
  width: 140px !important;
}
.payable-summary tr:last-child td:first-child {
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: var(--battleship-grey) !important;
}
.report-table-section .payable-summary tr td.status {
  white-space: nowrap !important;
}

.payable-summary tr:last-child td:nth-last-child(2),
.payable-summary tr:last-child td:nth-last-child(1) {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  width: 10% !important;
}

.payable-summary tr:last-child td:first-child:hover,
.payable-summary tr:last-child td:nth-last-child(2):hover,
.payable-summary tr:last-child td:nth-last-child(1):hover {
  cursor: default !important;
}

.payable-summary tr:last-child:hover td:nth-last-child(2),
.payable-summary tr:last-child td:nth-last-child(2):hover,
.payable-summary tr:last-child:hover td:nth-last-child(1),
.payable-summary tr:last-child td:nth-last-child(1):hover {
  color: var(--charcoal-grey) !important;
}

.report-table-section .payable-summary tr th:nth-last-child(1),
.report-table-section .payable-summary tr td:nth-last-child(1) {
  width: 11% !important;
  min-width: 120px !important;
  padding-left: 10px !important;
  padding-right: 23px !important;
}
.report-table-section .payable-summary tr th:nth-last-child(2),
.report-table-section .payable-summary tr td:nth-last-child(2) {
  width: 11% !important;
  min-width: 120px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.report-table-section .receivable-summary tr th:nth-child(2) {
  width: 140px !important;
}
.receivable-summary tr:last-child td:first-child {
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: var(--battleship-grey) !important;
}

.receivable-summary tr:last-child td:nth-last-child(2),
.receivable-summary tr:last-child td:nth-last-child(1) {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  width: 10% !important;
}
.report-table-section .receivable-summary tr th:nth-last-child(1),
.report-table-section .receivable-summary tr td:nth-last-child(1) {
  width: 11% !important;
  min-width: 120px !important;
  padding-left: 10px !important;
  padding-right: 23px !important;
}
.report-table-section .receivable-summary tr th:nth-last-child(2),
.report-table-section .receivable-summary tr td:nth-last-child(2) {
  width: 11% !important;
  min-width: 120px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.report-table-section .receivable-summary tr td.status {
  white-space: nowrap !important;
}
.report-table tbody .total-row td {
  font-size: 16px;
  font-weight: bold;
  line-height: normal !important;
  letter-spacing: -0.2px;
}
.battleship-grey-color {
  color: var(--battleshipGrey) !important;
}
.charcole-grey-color {
  color: var(--charcoal-grey) !important;
}
.light-offwhite-bg {
  background-color: rgba(var(--battleship-grey-rgb), 0.07) !important;
}

.customer-balance-report .total-row .balance-fcy,
.customer-balance-detail-report .total-row .balance-fcy,
.vendor-balance-report .total-row .balance-fcy,
.vendor-balance-detail-report .total-row .balance-fcy,
.report-table.aging-report-table .total-row .consumer-name {
  color: var(--battleship-grey) !important;
}
.account-transaction-card .report-table-section tr td.table_account_name {
  width: 220px !important;
  min-width: 220px !important;
  max-width: none !important;
}

.empty-report-table-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 712px);
  min-height: 280px;
  align-items: center;
  align-content: center;
}
.report-table tr:last-child .accordion-collapsible-body-table td {
  border-bottom: 1px solid var(--approx-whisper) !important;
}
.report-table tr:last-child .accordion-collapsible-body-table tr:last-child td {
  border-bottom: 0 !important;
}
.filter-item.toggle-button-filter {
  width: auto !important;
  margin: -12px 0 0 0;
}
.top-controls-wrapper {
  display: flex !important;
  gap: 15px;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .report-header,
  .report-table-section {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .report-body .top-filter-container .organization-dropdwon .dropdown-menu {
    right: auto !important;
    left: 0 !important;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .report-card {
    padding-left: 14px;
    padding-right: 14px;
  }
}
