.organization-dropdwon {
    width: 181px;
    margin-right: 30px;
}
.organization-dropdwon .dropdown-toggle {
    position: relative;
    width: 181px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--new-cornflower-blue);
    padding: 10px 32px 10px 8px;
}
.organization-dropdwon .dropdown-toggle:after {
    content: "";
    position: absolute;
    border: 0;
    width: 20px;
    height: 20px;
    background: url("../../../../../assets/images/arrow_dropdown.svg") no-repeat right
        center;
    background-size: 20px 20px;
    margin: 0;
    right: 8px;
    top: 50%;
    margin-top: -10px;
}
.organization-dropdwon .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(-180deg);
}
.organization-dropdwon .dropdown-toggle .oraganization {
    display: block;
    width: calc(100% - 20px);
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: normal;
    color: var(--white-fill);
    margin-top: 3px;
    padding-left: 6px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.organization-dropdwon .dropdown-toggle svg,
.organization-dropdwon .dropdown-toggle span {
    float: left;
}
.organization-dropdwon .dropdown-menu {
    position: absolute !important;
    min-width: 260px;    
    max-height: 320px;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    padding: 0;
    margin-top: 4px;
    overflow: hidden;
    overflow-y: auto;
}
.organization-dropdwon .dropdown-menu li {
    display: block;
    width: 100%;
    float: left;
    border-bottom: solid 1px var(--approx-whisper);
}
.organization-dropdwon .dropdown-menu li:first-child,
.organization-dropdwon .dropdown-menu li:first-child a {
    border-radius: 5px 5px 0 0;
}
.navbar-nav .organization-dropdwon .dropdown-menu {
    width: 200%;
}
/* .organization-dropdwon .dropdown-menu li a {
background-color: var(--white-fill);
} */
.organization-dropdwon .dropdown-menu li:hover {
    border-bottom: solid 1px var(--alice-blue);
}
.organization-dropdwon .dropdown-menu li:hover a,
.organization-dropdwon .dropdown-menu li a:active,
.organization-dropdwon .dropdown-menu li a.active {
    background-color: var(--alice-blue);
}
.organization-dropdwon .dropdown-menu li a.active {
    position: relative;
}
.organization-dropdwon .dropdown-menu li a.active:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: url("../../../../../assets/images/white-tick-with-blue-bg-icon.svg")
        no-repeat center;
    background-size: 14px 14px;
    top: 10px;
    right: 9px;
}
.organization-dropdwon .dropdown-menu li:last-child,
.organization-dropdwon .dropdown-menu li:last-child a {
    border-bottom: 0;
    border-radius: 0 0 5px 5px;
}
.organization-dropdwon .dropdown-menu li a {
    width: 100%;
    float: left;
    padding: 10px 32px 9px 10px;
    display: flex;
    min-height: 50px;
    flex-direction: column;
    justify-content: center;
    white-space: normal;
    background-color: var(--white-fill);
}
.organization-dropdwon .dropdown-menu li a span {
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    overflow: hidden;   
}
.organization-dropdwon .dropdown-menu li a .organization {
    padding-right: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: -0.11px;
    color: var(--charcoal-grey);
}
.organization-dropdwon .dropdown-menu li a .org-location {
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: var(--battleship-grey);
    margin-top: 6px;
    white-space: normal;
}
.top-filter-container .organization-dropdwon .dropdown-toggle:focus {
    border: 1px solid var(--cerulean-blue) !important;
    box-shadow: none !important;
    outline: 0 !important;
}
.organization-dropdwon .dropdown-menu li a:focus {
    outline: 0 !important;
}
.organization-dropdwon .dropdown-menu li a:focus {
    background-color: var(--alice-blue);
}
@media (max-width: 1180px) {
    .organization-dropdwon {
        margin-right: 15px;
    }
}
@media (max-width: 1024px) {
    .organization-dropdwon {
        margin-right: 25px;
    }
}
@media (max-width: 991px) {
    .navbar-nav .organization-dropdwon .dropdown-menu {
        width: 180%;
    }
}
@media (max-width: 767px) {
    .organization-dropdwon {
        width: auto;
    }
    .organization-dropdwon .dropdown-toggle {
        width: auto;
        height: auto;
        border-radius: 50%;
        flex: 0 0 auto;
        padding: 10px;
    }
    .organization-dropdwon .dropdown-toggle .oraganization,
    .organization-dropdwon .dropdown-toggle:after {
        display: none;
    }
    .navbar-nav .organization-dropdwon .dropdown-menu {
        width: 320px;
    }
}
@media (max-width: 440px) {
    .navbar-nav .organization-dropdwon .dropdown-menu {
        width: 100%;
    }
}
@media (max-width: 375px) {
    .organization-dropdwon {
        position: static;
    }
    .organization-dropdwon .dropdown-menu {
        top: 66px;
        right: 50%;
        transform: translate(50%, 0);
        max-width: none;
        width: calc(100% - 20px);
        margin: 0;
    }
}