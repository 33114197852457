.proforma-modal {
    width: 742px;
}
.invoice-creation-modal {
    width: 620px !important;
}
.mark-as-cancelled-modal {
    width: 650px !important;
}
.proforma-modal .modal-dialog {
    max-width: 742px;
    width: 650px !important;
}
.proforma-modal h5 {
    font-size: 16px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.2px;
    color: var(--charcoal-grey);
}
.proforma-modal .modal-header {
    padding: 30px 30px 12px;
}
.proforma-modal .modal-footer {
    padding: 20px 40px 30px;
    border-top: 0;
    width: 100%;
}
.proforma-modal .modal-body {
    margin: 10px 20px 10px!important;
}

.proforma-modal .modal-footer > * {
    margin: 0;
}
.modal-body-send-email {
    margin-left: 40px;
}
.proforma-modal .btn.send-button,
.proforma-modal .btn.cancel-button {
    padding: 9.5px 23px;
    border-radius: 6px;
    font-size: 15px;  
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
}

.proforma-modal .btn.send-button {
    position: relative;
    background-color: var(--booger);
    color: var(--white-fill);
    margin-right: 20px !important;
}
.proforma-modal .btn.send-button:hover {
    background-color: var(--booger);
    color: var(--white-fill);
}
.proforma-modal .btn.send-button:hover:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: rgba(var(--black-rgb), 0.08);
}

.proforma-modal .btn.cancel-button {
    background-color: var(--white-fill);
    color: var(--booger);
    border: 1px solid var(--booger);
}
.proforma-modal .text-area-wrapper {
    display: flex !important;
    flex-direction: column !important;
}
.proforma-modal .text-area {
    border-radius: 4px;
    width: 655px;
    height: 80px;
    padding: 5px;
}
.proforma-modal .proforma-modal-body {
    margin: 1.75rem auto;
}