.audit-view-container {
  width: 100%;
  position: relative;
  clear: both;
  padding: 30px 0;
}
.audit-view-container h2 {
  font-size: 18px;
  font-weight: 900;
  color: var(--charcoal-grey);
  margin-bottom: 0;
  max-width: 500px;
  float: left;
}
.audit-view-container .table-section {
  width: 100%;
  clear: both;
  margin-top: 16px;
  overflow-x: auto;
  float: left;
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
  padding-bottom: 0px !important;
}
.audit-log-date-string {
  color: var(--charcoal-grey);
}
.audit-log-time-string {
  color: var(--battleship-grey);
}
.audit-view-container .audit-details-table {
  border-radius: 18px !important;
  border: 1px solid var(--approx-whisper);
  z-index: 1000;
}
.audit-log-container .audit-log-table-cell-header,
.audit-details-table-head-cell-head {
  color: var(--battleship-grey) !important;
  font-weight: bold !important;
  padding-left: 24px !important;
}
.audit-log-table-row .audit-log-table-row-cell-open {
  font-weight: bold !important;
  line-height: 1.2 !important;
  padding: 20px 24px !important;
}
.audit-log-table-row .audit-log-table-row-cell-closed {
  padding: 20px 24px !important;
}
.audit-details-table tr:nth-child(odd) td {
  background-color: var(--white) !important;
}
.audit-log-container .audit-trail-link {
  color: var(--cerulean-blue) !important;
  font-weight: 600 !important;
  cursor: pointer;
}
.ArrowDropBlue {
  width: 24px;
  transition: transform 0.3s ease-in-out;
}
.ArrowDropBlue.open {
  transform: rotate(180deg);
}
.ArrowDropBlue.closed {
  transform: rotate(0deg);
}
#audit-log-container.audit-log-container .audit-details-table-wrapper-padding {
  padding-top: 0px !important;
}
.audit-log-container {
  width: 100%;
  min-width: 800px;
  overflow: hidden;
}
.audit-log-container .audit-details-table-wrapper {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: var(--audit-blue);
  column-span: all;
}
#audit-view-container .audit-log-table-collapse-row {
  height: 0px;
}
.audit-log-container .audit-details-table-head {
  background-color: var(--white);
}
.audit-log-container .audit-details-table-head tr:not(tr.disabled):hover {
  box-shadow: inset 0 0 0 9999px var(--white) !important;
}
.audit-log-table-row .MuiTableCell-root:nth-of-type(1),
.audit-log-table-row .MuiTableCell-root:nth-of-type(2),
.audit-log-table-row .MuiTableCell-root:nth-of-type(3),
.audit-log-table-row .MuiTableCell-root:nth-of-type(4) {
  line-height: 1.2 !important;
  width: 25% !important;
}
.audit-details-table-body tr:nth-child(even) td {
  box-shadow: none !important;
  background-color: var(--white);
}
.audit-details-table-body tr:not(tr.disabled):hover {
  box-shadow: inset 0 0 0 9999px var(--white) !important;
}
.audit-log-container .audit-details-table-head-cell-head:nth-of-type(1),
.audit-details-table-head-cell-head:nth-of-type(3) {
  padding: 20px 32px !important;
}
.audit-log-container .audit-details-table-head-cell-head:nth-of-type(2) {
  padding: 20px 24px !important;
}
#audit-view-container
  .audit-details-table-body
  .audit-details-table-head-cell:nth-of-type(1) {
  line-height: 1.2 !important;
  width: 50% !important;
  min-width: 50% !important;
  word-break: break-all;
  word-wrap: break-word;
  padding: 20px 32px !important;
}
#audit-view-container
  .audit-details-table-body
  .audit-details-table-head-cell:nth-of-type(2) {
  line-height: 1.2 !important;
  width: 25% !important;
  min-width: 25% !important;
  word-break: break-all;
  word-wrap: break-word;
  padding: 20px 24px !important;
}
#audit-view-container
  .audit-details-table-body
  .audit-details-table-head-cell:nth-of-type(3) {
  line-height: 1.2 !important;
  width: 25% !important;
  min-width: 25% !important;
  word-break: break-word;
  word-wrap: break-word;
  padding: 20px 32px !important;
}
.audit-trail-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.ArrowDropBlue {
  width: 24px;
  transition: transform 0.3s ease-in-out;
}
.ArrowDropBlue.open {
  transform: rotate(180deg);
}
.ArrowDropBlue.closed {
  transform: rotate(0deg);
}
