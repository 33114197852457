.accounts-dropdown .dropdown-menu {
  width: 100% !important;
}
.itemselect-wrapper,
.itemselect-wrapper input,
.itemselect-wrapper .iteminput {
  width: 100% !important;
  clear: both;
}

.itemselect-wrapper a.add-link-option {
  flex-direction: row;
}

.itemselect-wrapper a.add-link-option .reusable-modal-btn {
  width: 100%;
  justify-content: flex-start !important;
}

.itemselect-wrapper a.add-link-option .reusable-modal-btn button span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.itemselect-wrapper .add-customer-link {
  padding-left: 0px !important;
}
.itemselect-wrapper .add-customer-link:before,
.manage-tags-link::before,
.edit-address-link::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 16px;
  margin: 0;
  object-fit: contain;
  background-size: 14px 14px;
  left: 0;
}
.itemselect-wrapper ul {
  z-index: 10;
}
.itemselect-wrapper {
  flex-direction: column;
}
.itemselect-wrapper input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
