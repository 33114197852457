.jv_notes {
  line-height: 1.43 !important;
}
.journal-voucher-form .exchange-rate-wrapper {
  margin-top: 10px;
}
.add-module-item-form.journal-voucher-form .form-top-section {
  padding-bottom: 8px;
}
.form-error-container {
  flex-wrap: wrap;
}
.unavailable-jv-number {
  width: 217px;
}
#invoice-table-wrapper td,
#invoice-table-wrapper td .jv_notes {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  box-shadow: none;
}
#invoice-table-wrapper td .jv_notes {
  display: block;
  width: 100%;
}
.journal_currency .exchange-rate-display-wrapper {
  justify-content: left;
}
.journal-voucher-form .jv-notes-row {
  margin-bottom: 20px;
}
.journal-voucher-form .form-check {
  /* padding-top: 22px; */
}
.journal-voucher-form .form-check-label {
  font-weight: normal;
}
.jv-type-check-box-wrapper {
  display: flex;
  height: 2.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.jv-type-check-box-wrapper label {
  margin-bottom: 0;
}
.journal-voucher-form-wrapper {
  margin-bottom: 75px !important;
}
