#subscription-add-coupon-button {
  line-height: 20px !important;
  margin: 0 !important;
  padding: 0px 0px 0px 10px !important;
  border: none;
  background: transparent;
  color: var(--cerulean-blue);
  font-weight: 700;
}
.coupon-list-skeleton-loader{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 35px 0px 40px;
}