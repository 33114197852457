.billed-to,
.ship-to {
  width: 34.4%;
  /* padding-right: 9.5%;  */
  padding-right: 12px;
  flex: 0 0 34.4%;
}

.credits-used div {
  font-size: 18px;
  font-weight: bold !important;

  line-height: normal !important;
  letter-spacing: -0.23px !important;
}
.credits-used div:first-child {
  color: var(--battleship-grey) !important;
}

.credits-used div:last-child {
  text-align: right;
  color: var(--red) !important;
}

.bill-details-section .payment-terms {
  width: 100%;
  min-width: 153px;
}

#bills-detail-card .info-tag {
  top: -4px;
  margin-left: 13px;
}
#address-name {
  font-size: 16px !important;
  font-weight: 900 !important;
  color: var(--charcoal-grey) !important;
}
#customer-trn {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--charcoal-grey) !important;
}
#applyCreditModal input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.delete-allocation {
  float: right;
}
.cn-preview .loader-container {
  z-index: 1326 !important;
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cn-preview .billed-to {
    width: 220px !important;
    flex: 0 0 220px !important;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

.bill-details-section .cn-supply-wrap {
  width: 23%;
  min-width: 100px;
  flex: 0 0 19%;
}
.bill-details-section .cn-supply .cn-details-header {
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.cn-supply-wrap .cn-supply div {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-top: 11px;
}
