.empty-report-container {
  margin: 0 auto;
  text-align: center;
  width: 292px;
  margin: 52px auto 117px auto;
}
.empty-report-container .preview-img {
  display: block;
  width: 60px;
  height: auto;
  object-fit: contain;
  margin: 0 auto 20px auto;
  clear: both;
}
.empty-report-container h3 {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: center;
  margin: 0 !important;
}
