.cancel-details-modal-content {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-details-modal-content .container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  text-align: center;
}
.cancel-details-modal-content .container p {
  font-size: 16px;
  margin-bottom: 24px;
  text-align: left;
}
.cancel-details-modal-content .buttons {
  display: flex;
  justify-content: right;
}
.cancel-details-modal-content .btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  height: 42px;
  font-weight: 700;
}
.cancel-details-modal-content .btn-cancel {
  background-color: var(--booger);
}
.cancel-details-modal-content .btn-delete {
  background-color: #ed2a2a;
}
.cancel-details-modal-content .proceed-btn {
  padding: 11px 24px;
}
.cancel-details-modal-content .cancel-btn {
  padding: 11px 24px;
  background-color: var(--white-fill);
  color: var(--booger);
  border: solid 1px var(--booger);
  display: flex;
  justify-content: center;
}

.cancel-scheduler-cancel-details-textarea {
  max-width: 600px !important;
  width: 451px !important;
  height: 101px !important;
}

.cancel-scheduler-cancel-details-billing-cycle {
  max-width: 600px !important;
  width: 451px !important;
}

.btn-cancel-proceed {
  background: var(--bs-blue);
}

.cancel-details-modal-content .cancel-btn-proceed {
  padding: 9px 27px;
  background-color: var(--white-fill);
  color: var(--bs-blue);
  border: solid 1px var(--bs-blue);
  display: flex;
  justify-content: center;
}

.cancel-details-modal-content .cancel-btn-proceed:focus {
  outline: 2px solid var(--bs-blue) !important;
}

.cancel-details-modal-content .css-1izyhm9-singleValue {
  text-align: justify;
}

.cancel-details-modal-content .cancel-scheduler-cancel-details-error {
  text-align: justify;
  padding-top: 5px;
}

.cancel-impact-details-modal h5.modal-title {
  font-size: 23px !important;
}

.cancel-impact-details-modal .modal-header {
  margin-left: 4px;
  padding-bottom: 0px !important;
}
