#customFieldModal{
    width: auto;
}

.custom-field-modal-btn{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 0;
    background: transparent;
    display: flex;
  flex-direction: row;
  justify-content: end !important;
  width: 100% !important;
}
#customFieldModal .reusable-modal-btn .add-new-item{
  margin: 0px;
  padding: 0px;  
  width: 175px;
  display: flex;
  flex-direction: row;
  justify-content: end !important;
}
#customFieldModal .reusable-modal-btn .dropdown-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
