.info-message-box span {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--battleship-grey);
  display: inline-block;
}
.info-alert {
  width: 100%;
  display: flex;
  padding: 12px 10px;
  align-content: center;
  align-items: center;
}
.info-alert.email-info-alert {
  max-width: 682px;
  border-radius: 4px;
  background-color: rgba(var(--peak-point-rgb), 0.07);
  margin-top: 20px;
}
.info-alert.email-info-alert .message {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.info-alert img {
  width: 18px;
  height: 18px;
  margin: 0 8px 0 0;
  object-fit: contain;
}
.info-alert.info-alert-messages {
  width: 100%;
  margin: 6px 0 0 0;
  padding: 7px 5px 9px 6px;
  border-radius: 4px;
  background-color: rgba(var(--peak-point-rgb), 0.07);
  align-items: flex-start;
}
.info-alert.info-alert-messages img {
  position: relative;
  top: 3px;
  margin-right: 6px;
}
.info-alert.info-alert-messages .message {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
