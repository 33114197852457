.reusable-primary-button-normal{
    background-color: var(--booger);
    color: var(--white-fill);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px;
    width: fit-content;
    height: fit-content;
    min-width: 125px;
    border-radius: 6px;
    border: none;
}
.reusable-primary-button-normal.disabled-btn{
    opacity: 0.7;
}
.reusable-primary-button-normal:focus{
    box-shadow: none;
    outline: 2px solid var(--booger) !important;
    outline-offset: 3px;
}
.reusable-primary-button-normal:hover{
    cursor: pointer;
}
.reusable-primary-button-normal.disabled-btn:hover{
    cursor:not-allowed;
}
.reusable-primary-button-danger{
    padding: 10px 10px;
    width: fit-content;
    height: fit-content;
    min-width: 125px;
    border-radius: 6px;
    background-color: var(--red);
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white-fill);
    border: none;
}
.reusable-primary-button-danger.disabled-btn{
    opacity: 0.7;
}
.reusable-primary-button-danger:focus{
    box-shadow: none;
    outline: 2px solid var(--red) !important;
    outline-offset: 3px;
}
.reusable-primary-button-danger:hover{
    cursor: pointer;
}
.reusable-primary-button-danger.disabled-btn:hover{
    cursor:not-allowed;
}
