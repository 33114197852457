.main {
  background-color: var(--approx-white);
}
.card-role-management {
  margin-bottom: 138px;
}
.card-permission-matrix {
  border: 0;
  border-radius: 0;
  margin: 48px 0;
}
.card-permission-matrix .card-header {
  border: 0px;
  background-color: var(--approx-white);
  padding: 0 0 24px 0;
}
.card-permission-matrix .card-header .back-button {
  padding: 0;
  margin-right: 8px;
}
.card-permission-matrix .card-header h1 {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-decoration: none;
  box-shadow: none;
}
.permission-control-form .role-management {
  padding: 8px 24px 24px 24px;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.role-management .role-data {
  width: 100%;
  max-width: 400px;
}
.permission-control-form .control-group {
  padding: 16px 0 0 0;
}
.permission-control-form .control-group label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
}
.permission-control-form .control-group .form-control {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-top: 12px;
}
/* .permission-control-form input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
/* .permission-control-form textarea::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form textarea::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form textarea:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.permission-control-form textarea:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
.permission-control-form textarea {
  resize: none;
}
.permission-control-form .form-check {
  padding: 17px 0 0 0;
  margin-bottom: 0;
}
.permission-control-form .form-check .form-check-input {
  float: left;
  margin-right: 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  cursor: pointer;
}
.permission-control-form .form-check label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  cursor: pointer;
  padding-left: 8px;
}
.permission-section {
  padding: 32px 0;
}
.permission-section h3 {
  float: left;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.legends {
  list-style: none;
  display: block;
  width: auto;
  float: right;
  padding: 0;
  margin-right: 5px;
}
.legends li {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding-right: 32px;
  padding-left: 16px;
  margin-right: -4px;
}
.legends li:last-child {
  padding-right: 0;
}
.legends li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  top: 3px;
  border-radius: 50%;
}
.legends li.full-access:before {
  background-color: var(--apple);
}
.legends li.part-access:before {
  background-color: var(--macaroni-cheese);
}
.legends li.no-access:before {
  background-color: var(--salmon);
}
.button-section {
  padding-bottom: 24px;
}
.form-btn {
  border: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-right: 16px;
}
.form-btn:last-child {
  margin-right: 0;
}
.save-btn {
  position: relative;
  min-width: 80px;
  padding: 11px 24px;
  border-radius: 6px;
  background-color: var(--booger);
  color: var(--white-fill);
}
.save-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.save-btn:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}
.role-cancel.cancel-btn {
  min-width: 95px;
  padding: 11px 24px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--booger);
  color: var(--booger);
  background: var(--white-fill);
}
.permission-control-form .role-management input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.role-create-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}
/* Permissions */
.vertical-tab {
  width: 189px;
  flex-direction: column;
  border-bottom: 0 !important;
  background-color: var(--white-fill);
  padding: 0 !important;
}
.vertical-tab .nav-link {
  border: 0 !important;
  border-bottom: solid 1px var(--warm-white) !important;
}
.vertical-tab li:last-child .nav-link {
  border-bottom-color: transparent !important;
}
.vertical-tab li,
.vertical-tab li button {
  width: 100%;
  text-align: left;
}
.vertical-tab li button,
.vertical-tab li button span {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding: 16px 8px 16px 22px;
  cursor: pointer;
  border-radius: 0 !important;
}
.vertical-tab li button span {
  position: relative;
  display: block;
  padding: 0 0 0 16px;
}
.vertical-tab li button span,
.vertical-tab li button div {
  float: left;
  display: block;
}
.vertical-tab li:first-child button {
  border-radius: 12px 0 0 0 !important;
}
.vertical-tab li button.nav-link.active,
.vertical-tab li button.nav-link:hover {
  background-color: var(--solitude) !important;
  color: var(--cerulean-blue) !important;
}
.vertical-tab li button span:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0;
  top: 50%;
  margin-top: -4px;
  border-radius: 50%;
}
.vertical-tab li button.full-access span:before {
  background-color: var(--apple);
}
.vertical-tab li button.part-access span:before {
  background-color: var(--macaroni-cheese);
}
.vertical-tab li button.no-access span:before {
  background-color: var(--salmon);
}
.message-pills,
.vertical-tab li button span.message-pills {
  width: 54px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: normal;
  padding: 0 4px 0 6px;
  border-radius: 4px !important;
  margin-left: 8px;
}
.unsaved {
  color: var(--white-fill);
  background-color: var(--slate);
}
.tab-wrapper {
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  flex-direction: column;
}
.tab-wrapper .tab-container {
  width: 190px;
  flex: 0 0 190px;
  border-right: solid 1px var(--warm-white);
}
#permissionTab {
  width: 189px;
}
#permission-container .tab-content {
  width: calc(100% - 189px);
  padding: 0 !important;
}
#permission-container .tab-content .tab-pane {
  height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.permission-table {
  border-spacing: 0 !important;
  min-width: 734px;
}
.permission-table thead {
  border: 0 !important;
}
.permission-table tr {
  height: auto !important;
}
.permission-table thead th {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  padding: 13px 17px !important;
  background-color: rgba(var(--peak-point-rgb), 0.08) !important;
  border: 0 !important;
  text-align: center;
  vertical-align: middle;
}
.permission-table thead th:first-child,
.permission-table tbody tr td:first-child {
  width: 285px;
}
.permission-table thead th:nth-child(2),
.permission-table tbody tr td:nth-child(2) {
  width: 103px;
}
.permission-table thead th:last-child {
  border-radius: 0 12px 0 0;
}
.permission-table tbody td {
  font-size: 14px;
  font-weight: normal;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-align: center !important;
  padding: 16px 16px 16px 17px !important;
  background-color: var(--white-fill) !important;
}
.permission-table tbody tr:first-child td {
  padding: 18px 16px 16px 17px !important;
}
.permission-table tbody tr:nth-last-child(2) td {
  padding: 16px 16px 20px 17px !important;
}
.permission-table thead th:first-child,
.permission-table tbody td:first-child {
  text-align: left !important;
}
.permission-table thead th:last-child,
.permission-table tbody tr td:last-child {
  width: 129px !important;
  padding-right: 42px !important;
}
.permission-table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 12px !important;
}
.permission-table tbody tr:nth-last-child(2) td:last-child {
  border-radius: 0 !important;
}
.permission-table tr:hover {
  box-shadow: none !important;
}
.permission-table thead th label {
  cursor: pointer;
}
.permission-table thead th label span {
  position: relative;
  top: -1px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.permission-table input[type="checkbox"] {
  position: relative;
  left: 50%;
  top: 0;
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  box-shadow: none;
  border: solid 1px var(--slate) !important;
  background-color: var(--white-fill) !important;
  cursor: pointer;
  float: left;
  margin-left: -8px !important;
}
.permission-table input[type="checkbox"]:focus {
  -webkit-box-shadow: 0 0 0 0.25rem var(--crayola-percent25);
  box-shadow: 0 0 0 0.25rem var(--crayola-percent25);
}
.permission-table thead th label input[tyep="checkbox"] {
  margin-right: 8px;
}
.permission-control-form .form-check .form-check-input:checked,
.permission-table input[type="checkbox"]:checked {
  background-color: var(--cerulean-blue) !important;
  background-image: url("../../../../../../assets/images/checkbox-checked.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 6px;
}
.permission-table tbody input[type="checkbox"] {
  margin: 0;
}
.permission-table tbody tr.resetCheckallrow td label {
  float: left;
  cursor: pointer;
  margin-left: 28px !important;
}
.permission-table tbody tr.resetCheckallrow td input[type="checkbox"] {
  float: left;
  position: static;
  left: 0;
  top: 0;
  margin-left: 0 !important;
  margin-right: 8px;
}
.permission-table tbody tr.resetCheckallrow td {
  border-top: solid 1px var(--warm-white) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.permission-table-footer {
  flex: 0 0 100%;
  padding: 10px 0 10px 190px;
  border-top: 1px solid var(--warm-white) !important;
}
.permission-table-footer .permission-full-controls {
  padding-left: 302px;
}
.permission-table-footer .permission-full-controls label {
  float: left;
  cursor: pointer;
  margin-left: 28px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.permission-table-footer .permission-full-controls input[type="checkbox"] {
  float: left;
  position: static;
  left: 0;
  top: 0;
  margin: 0 !important;
  margin-right: 8px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  box-shadow: none;
  height: 16px;
  width: 16px;
  background-color: var(--white-fill) !important;
  border: 1px solid var(--slate) !important;
  cursor: pointer;
}
.permission-table-footer .permission-full-controls .permission-reset {
  margin-bottom: 10px;
  margin-top: 10px;
}
.permission-table tr th:first-child,
.permission-table tr td:first-child {
}
.permission-table tbody tr.resetCheckallrow td:first-child {
  border-left: 0 !important;
}
.btn-container {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
#permission-container .btn-container {
  border: 0;
  border-top: solid 1px var(--warm-white);
}
.permission-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  text-decoration: underline;
  background: url("../../../../../../assets/images/reset-icon.svg") no-repeat
    left center;
  background-size: 15px 15px;
  margin-left: 40px;
  padding: 0 0 0 23px;
  float: left;
}
.form-check-label span {
  max-width: calc(100% - 24px);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
}
.form-check-label span.category {
  float: initial;
}
.full-access-check input[type="checkbox"] {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  margin: 1px 8px 0 0;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  float: left;
}
.full-access-check input[type="checkbox"]:checked {
  background-color: var(--cerulean-blue) !important;
  background-image: url("../../../../../../assets/images/checkbox-checked.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 6px;
}
/** Media Queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 992px) {
  .permission-table-footer {
    justify-content: center;
  }
  .permission-table-footer .permission-full-controls {
    padding-left: 0;
    width: 100%;
  }
  .permission-table-footer .permission-full-controls label {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .permission-table-footer .permission-full-controls {
    padding-left: 6%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .permission-reset {
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: 838px) {
  .permission-table-footer .permission-full-controls label {
    margin-left: 0 !important;
  }
  .permission-reset {
    margin-left: 0 !important;
    clear: both;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .permission-table-footer .permission-full-controls {
    padding-left: 22%;
  }
}
