.migration-wrapper {
  padding: 32px;
}
.migration-wrapper .migration-page-details {
  font-size: 16px;
  color: var(--charcoal-grey);
}
.migration-wrapper .migration-month-and-year {
  display: flex;
  padding-bottom: 20px;
  margin-top: 24px;
}
.migration-wrapper .migration-month {
  font-size: 32px;
  margin-right: 28px;
}
.migration-wrapper .migration-month label {
  font-weight: 700;
}
.migration-wrapper .opening-balances {
  font-size: 14px;
  color: var(--charcoal-grey);
}
.migration-wrapper .state-select-form-select-custom-select {
  width: 247px;
}
.migration-wrapper .button-fields.form-button-wrapper {
  border-top: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  height: auto;
}
.migration-wrapper .form-button-section {
  position: relative;
  padding: 80px 0px 0px 0px;
}
.migration-wrapper .form-button-section .alert-msg {
  margin-top: 0;
  margin-bottom: 20px;
}
.migration-wrapper .form-button-section:before {
  content: "";
  width: calc(100% + 64px);
  height: 1px;
  position: absolute;
  top: 50px;
  left: -32px;
  right: 0;
  border-top: 1px solid var(--approx-whisper);
}
.migration-wrapper .save-button:disabled {
  cursor: not-allowed;
}
