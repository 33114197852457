.taxcode-dropdown .gst-tax-cancel-button {
  padding-top: 10px !important;
}
.gst-tax-input {
  width: 100% !important;
  height: 41px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 41px !important;
  background-position: right 6px center !important;
  background-size: 24px 24px !important;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left !important;
  background: var(--white-fill)
    url(../../../../../assets/images/arrow-drop-right.svg) no-repeat right 4px
    center !important;
  max-width: 325px;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  position: relative !important;
  padding: 0 26px 0 12px !important;
}

.taxcode-dropdown .delete-container-btn {
  background: transparent;
  border: 0;
}
