.subscription-preview-note-wrapper {
  width: 682px;
  padding: 20px 39px 15px 20px;
  border-radius: 4px;
  background-color: rgba(var(--peak-point-rgb), 0.07);
  margin: 0 24px 30px;
}

.subscription-org .note-header {
  font-size: 15px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

.subscription-preview-note-wrapper p {
  font-size: 14px;
  font-weight: normal;

  line-height: 1.57;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

.subscription-preview-note-wrapper .notes-link {
  color: var(--cerulean-blue);
  text-decoration: none;
}

.subscription-preview-container .subscription-preview-cart-container {
  display: flex;
  justify-content: space-between;
}

.subscription-preview-container .subscription-preview-cart {
  width: 48%;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  padding: 15px;
  background-color: white;
}

.subscription-preview-container .subscription-preview-cart-header-container {
  display: flex;
}

.subscription-preview-container .subscription-preview-cart-header {
  width: 50%;
}

.subscription-preview-container .subscription-preview-cart-header-heading {
  font-weight: 700 !important;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
}

.subscription-preview-container .subscription-preview-cart-header a {
  text-decoration: none;
  float: right;
}

.subscription-preview-container .subscription-preview-cart-data-cols {
  display: flex;
}

.subscription-preview-container .subscription-preview-cart-data-col-value {
  margin-bottom: 8px;
}

.subscription-preview-container .subscription-preview-cart-data-col-value-left {
  font-weight: 500;
  color: var(--battleship-grey);
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.2px;
  width: 45%;
}

.subscription-preview-container .subscription-preview-cart-data-col-value-right {
  font-size: 13px !important;
  width: 55%;
}

.subscription-preview-container .subscription-popup-preview-cart {
  width: 80%;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  padding: 15px;
  background-color: white;
}

.subscription-invoice-preview-status {
  padding: 0px 39px 0px 0px;
  margin: 0 24px 30px;
}

.non-renewing-note-wrapper {
    width: 362px !important;
}

.non-renewing-note-wrapper .subscription-preview-cart {
  width: 100%;
}