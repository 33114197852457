html {
  scroll-behavior: smooth;
}
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--white-fill);
}
* {
  box-sizing: border-box;
}
body {  
  font-size: 14px;
  font-weight: normal;
  line-height: normal; 
  letter-spacing: normal;
}
body,
body * {
  width: auto;
  font-family: "Lato", sans-serif !important;
  font-stretch: normal !important;
  font-style: normal !important;    
  -webkit-font-smoothing: antialiased !important; 
  -moz-osx-font-smoothing: grayscale !important; 
  text-rendering: optimizeLegibility;   
} 
input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.select-error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
  border-radius: 6px;
}
.mandatory {
  color: var(--red);
  margin-left: 2px;
}
.error {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
}
.d-init {
  display: initial !important;
}
.Toastify__toast-theme--light {
  width: 400px !important;
  min-height: 45px !important;
  border-radius: 6px !important;
  background-color: var(--granny-apple) !important;
  color: var(--deep-green) !important;
  font-size: 14px;
}
.Toastify__toast-container--top-right {
  top: 2.5em !important;
  right: 22.3em !important;
}
.Toastify__progress-bar--success {
  background-color: rgba(var(--black-rgb), 0.15) !important;
}
.Toastify__close-button--light {
  color: #d32f2f !important;
  opacity: 0.7 !important;
  align-self: center !important;
  margin-right: 4px !important;
}
.Toastify__close-button--light:hover {
  color: #d32f2f !important;
  opacity: 1 !important;
  align-self: center !important;
  margin-right: 4px !important;
}
.Toastify__toast-icon {
  width: 24px !important;
}
.toast-error.Toastify__toast-theme--light {
  width: 400px !important;
  min-height: 45px !important;
  border-radius: 6px !important;
  background-color: var(--pink) !important;
  color: var(--red) !important;
  font-size: 14px;
}
.toast-error.Toastify__toast-container--top-right {
  top: 2.5em !important;
  right: 22.3em !important;
}
.toast-error.Toastify__progress-bar--error {
  background-color: rgba(var(--black-rgb), 0, 0, 0, 0.15) !important;
}
.toast-info {
  color: var(--chambray) !important;
  border-radius: 6px;
  background-color: var(--pale-conflower-blue) !important;
}
.toast-warn {
  border-radius: 6px;
  background-color: var(--pale-conflower-blue) !important;
}
.Toastify__progress-bar--info {
  background-color: rgba(var(--black-rgb), 0.15) !important;
}
.toast-custom-style {
  white-space: pre-line !important;
  line-height: 19px;
}
.toast-copy-button {
  background-color: transparent;
  border: none;
  color: var(--black);
  padding: 2px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
}
.toast-copy-button .MuiSvgIcon-root {
  width: 1.25em !important;
  height: 1.25em !important;
  color: var(--red);
  opacity: 0.7;
  margin-top: -4px;
}
.toast-error-copy {
  color: var(--red);
  font-size: 12px;
  opacity: 1;
}
@media (max-width: 420px) {
  .Toastify__toast-container--top-center {
    width: 100%;
  }
  .toast-error.Toastify__toast-theme--light {
    width: calc(100% - 10px) !important;
    margin-right: auto;
    margin-left: auto;
  }
}