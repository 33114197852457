.vendor-select {
    border-radius: 4px !important;
  }
  .custom-select-option-account-name {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .custom-select-option-account-sub-root {
    /* width: fit-content;
    min-width: 100px;
    background-color: var(--warm-white);
    border-radius: 5px;
    padding: 5px; */
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    color: var(--charcoal-grey) !important;
    margin-top: 4px;
    white-space: normal;
  }
  