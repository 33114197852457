#sidebar .accordion-item,
#sidebar .accordion-button {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}
#sidebar .accordion-item {
    position: relative;
    width: 100%;
}
#sidebar .accordion-item h2 {
    width: 100%;
}
#sidebar .accordion-button {
    font-size: 15px;
    font-weight: bold;
    line-height: 0.8;
    letter-spacing: -0.19px;
    color: var(--charcoal-grey);
}
#sidebar .accordion-button:after {
    position: absolute;
    right: 10px;
    width: 24px;
    height: 24px;
    background: url("../../../../../assets/images/arrow_down.svg") no-repeat center;
    background-size: 24px 24px;

}
#sidebar .accordion-button:not(.collapsed):after {
    transform: rotate(180deg);
}
#sidebar .nav-item .nav-link.accordion-button.menu-item {
    background: var(--solitude);
}
#sidebar .nav-item .nav-link.accordion-button.menu-item.collapsed {
    background: none;
}
.accordion .menu-text {
    margin-top: 7px;
}

#sidebar .nav-item .dropdown-menu {
    padding: 10px 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow5);
    box-shadow: 0 6px 12px 0 var(--black-shadow5);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
}
#sidebar .nav-item .dropdown-menu li {
     
}
#sidebar .nav-item .dropdown-menu a {
    position: relative;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    padding: 10px 15px 10px 13px;   
}
#sidebar .nav-item.dropend,
#sidebar .nav-item.dropend .nav-link {
    width: 100%;
}
#sidebar .submenu .dropdown-menu li a:hover, 
#sidebar .submenu .dropdown-menu li a:focus,
#sidebar .submenu .dropdown-menu li.selected a {
    background-color: var(--solitude);
    color: var(--charcoal-grey);
}
#sidebar .nav-item.dropend > .nav-link:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background: url("../../../../../assets/images/arrow_down.svg") no-repeat center;
    background-size: 24px 24px;
    transform: rotate(-90deg);    
    margin-right: 0;    
    border: 0 !important;
}
/* #sidebar .nav-item.dropend > .nav-link.show:after {
    transform: rotate(-90deg);    
} */

.settings-sidebar #sidebar .accordion-button:after {
    top: 8px;
}
.settings-sidebar #sidebar .nav-item .submenu .nav-link.accordion-button.menu-item {
    background: none;    
}
.settings-sidebar #sidebar .nav-item .submenu .nav-link.accordion-button.menu-item,
.settings-sidebar #sidebar .nav-item .submenu .nav-link.accordion-button.menu-item span {
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.19px;
    color: var(--charcoal-grey);
    padding: 6px 0;
}
.settings-sidebar #sidebar .nav-item .submenu li.selected a.nav-link.accordion-button, 
.settings-sidebar #sidebar .nav-item .submenu li a.nav-link.accordion-button:hover, 
.settings-sidebar #sidebar .nav-item .submenu li a.nav-link.accordion-button:focus,
.settings-sidebar #sidebar .nav-item .submenu li.selected a.nav-link.accordion-button span, 
.settings-sidebar #sidebar .nav-item .submenu li a.nav-link.accordion-button:hover span, 
.settings-sidebar #sidebar .nav-item .submenu li a.nav-link.accordion-button:focus span {
    color: var(--cerulean-blue);
}
.settings-sidebar #sidebar .nav-item .submenu .nav-link.accordion-button.menu-item:before {
    display: none;
}
@media (max-width: 767px) {
    #sidebar .nav-item.dropend .dropdown-menu {
        position: static !important;
        transform: translate(0, 0) !important;
        width: 100%;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    #sidebar .nav-item.dropend > .nav-link:after {
        transform: rotate(0);
    }
    #sidebar .nav-item.dropend > .nav-link.show:after {
        transform: rotate(-180deg);   
    }
    #sidebar .submenu {
        width: 100%;
    }
}