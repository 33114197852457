.boxed-item {
  padding: 16px 16px 18px 20px;
  border-radius: 5px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  box-shadow: none;
}

.boxed-item.address-item {
  /* width: 100%; */
  /* width: 250px; */
  /* min-height: 245px; */
  width: 100%;
  min-height: 265px;
}
.address-box-wrapper,
.boxed-item.add-address-card {  
  width: 47%;
  min-width: 210px;
  max-width: 250px;  
  margin: 0 16px 16px 0;
  min-height: 265px;
  flex: 1;
}
.shipping-address .boxed-item.address-item {
  margin: 0 0 16px 16px;
}
.boxed-item.address-item.selected {
  border: solid 1px var(--cerulean-blue);
  background-color: var(--new-approx-alice-blue2);
}
.address-item .card-header {
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px 0 !important;
  margin: 0 !important;
  background: transparent;
  border-bottom: 0 !important;
}
.boxed-item .card-header h5 {
  display: block;
  width: calc(100%-20px);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin: 0;
}
.address-item .radio-wrapper {
  display: block;
  width: 16px;
}
.address-item .card-body {
  padding: 0 0 10px 0 !important;
  padding-right: 20px !important;
  height: auto;
  min-height: 70%;
}
.address-item .card-body span,
.address-item .card-body p {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin: 0 !important;
}
.address-item .card-body p {
  max-width: 132px;
}
.address-item .card-body .contact-dets {
  max-width: none;
  line-height: 1.5;
  margin-top: 9px !important;
}
.address-item .card-footer {
  padding: 8px 20px 0 0;
  background-color: transparent;
}
.address-item .card-footer .edit-address-link {
  margin-right: 20px;
}
.address-item .card-footer .delete-item img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}
.add-address-card {
  border-radius: 4px;
  border: dashed 2px var(--approx-cornflower-blue);
  background-color: var(--white-fill);
}
.add-address-card img {
  width: 80px;
  height: auto;
  margin: 43px auto 30px auto;
}
.add-address-card .card-body {
  align-items: center;
  flex-direction: column;
  padding: 0 !important;
}
.add-address-link {
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.19px;
  color: var(--cerulean-blue);
}
.shipping-address h2 {
  padding-left: 20px;
}
.address-tab-pane .mw-545 {
  width: auto;
  max-width: 544px;
}


/** Media Queries **/
@media (max-width: 1040px) {
  #pills-other .mw-325 {
    max-width: calc(295px + 2rem) !important;
  }

  #pills-other .contact-persons-wrapper .mw-325 {
    max-width: calc(300px + 2rem) !important;
  }

  .mw-325 {
    max-width: calc(318px + 2rem) !important;
  }
}
@media (min-width: 768px) {  
}
@media (min-width: 992px) {  
}


@media (min-width: 768px) and (max-width: 991px) {
  
  
}
@media (min-width: 992px) and (max-width: 1024px) {
 
}
@media (min-width: 768px) and (max-width: 1024px) {
  .address-tab-pane h2 {
    padding-left: 0;
  }
  .shipping-address .boxed-item.add-address-card {
    margin-left: 0 !important;
  }
  .shipping-address .boxed-item.address-item {
    margin: 0 16px 16px 0;
  }
  .address-container {
    justify-content: flex-start;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
 
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {  
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {  
}