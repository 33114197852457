#notificationModal {
    width: 400px;
    height: calc(100% - 75px);
    right: 0;
    top: 75px;  
    padding: 0;
    margin: 0;  
}
#notificationModal .close-button-container {
    justify-content: space-between;
    padding: 16px 15px;
}
#notificationModal .modal-header h4 {
    
    font-size: 20px;
    font-weight: bold;
    
    
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}
#notificationModal .modal-body {
    padding: 0 0 16px 0;
}
.notification-conatiner {
    width: 100%;
}
.notification-conatiner .notification-header {
    width: 100%;
    padding: 6px 15px;
    background-color: rgba(var(--peak-point-rgb), 0.08);
    
    font-size: 10px;
    font-weight: 900;
    
    
    line-height: normal;
    letter-spacing: 0.22px;
    color: var(--battleship-grey);
    text-transform: uppercase;
}
.notification-list {
    width: 100%;
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}
.notification-list li {
    width: 100%;
    padding: 0 15px;
}
.notification-list li .notification {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: solid 1px var(--approx-whisper);
    padding: 13px 0;
}
.notification-list li:last-child .notification {
    border-bottom: 0;
}
.notification-list li .notification img {
    width: 28px;
    height: auto;
    object-fit: contain;
}
.notification-list li .notification .notification-details {
    width: calc(100%-28px);
    padding: 0 0 0 12px;    
}
.notification .notification-details span {
    width: 100%;
    display: block;
    float: left;
    
    
    
}
.notification .notification-details .notification-title,
.notification .notification-details .notification-desc {
    
    font-size: 14px;
    font-weight: bold;
    line-height: 1.07;
    letter-spacing: -0.11px;
    color: var(--charcoal-grey);
    margin-bottom: 4px;
}
.notification .notification-details .notification-desc {   
    font-weight: normal;    
}
.notification .notification-details .notification-date {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.09px;
    color: var(--battleship-grey);
    margin-top: 2px;
}