.list-action-items-mapping-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: max-content;
  height: fit-content;
  padding: 10px 0px;
  background-color: var(--white-fill);
}
.list-action-item-wrapper {
  padding: 0px 0px;
}
.list-action-divider {
  width: 100%;
}
.list-action-item-wrapper {
  width: 100%;
  height: 100%;
}
.list-action-item-wrapper:hover {
  color: var(--cerulean-blue) !important;
}
.list-action-item-wrapper:focus {
  color: var(--cerulean-blue) !important;
}
.list-action-item-button {
  padding-left: 20px;
  padding-right: 20px;
  color: var(--charcoal-grey) !important;
}
.list-action-item-button:hover {
  color: var(--cerulean-blue) !important;
  background-color: var(--warm-white) !important;
}
.list-action-item-button:focus {
  color: var(--cerulean-blue) !important;
  background-color: var(--warm-white) !important;
}
