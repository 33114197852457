.smtp-settings-config-and-form-fields-wrapper{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--warm-white);
    border-radius: 5px;
    padding: 30px 30px;
    background-color: var(--white-fill);
    box-shadow: 0 8px 20px 0 var(--black-shadow3);
}
.smtp-server-settings-selection{
    display: flex;
    flex-direction: column;
    
}
.smtp-server-settings-selection span{
    font-weight: 600;
}
.smtp-server-configuration-form-wrapper{
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-top: 30px;
}