.unlock-all-transacts .detail-section .left-sect {
    max-width: 454px;
}
.unlock-all-transacts .detail-section .btn-sect .edit-button {
    margin-right: 20px;
}
.unlock-all-transacts .more-detail-section {
    flex-direction: column;
    padding-top: 18px;
    margin-top: 10px;
    border-top: 1px solid var(--approx-whisper);
}
.lock-modal .modal-dialog {
    width: 380px;
}
.lock-modal .modal-header,
.lock-modal .modal-body,
.lock-modal .modal-footer {
    padding: 0 40px;
    clear: both;
}
.lock-modal .modal-header {
    padding-top: 30px;
    padding-bottom: 24px;
}
.lock-modal .modal-footer {
    padding-top: 0;
    padding-bottom: 30px;
    border-top: 0;
}
.lock-modal .modal-header h1 {
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 0;
    float: left;
}
.lock-modal .modal-header .close-button-container {
    position: relative;
    top: -20px;
    right: -30px;
    padding: 0;
    float: right;
}
.lock-modal .modal-header .close-button-container button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 0;
    background: transparent;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
}
.lock-modal .modal-header .close-button-container button svg {
    width: 24px;
    height: 24px;
}
.lock-modal .date-container {
    height: 40px;
}
.lock-modal .lock-item-form .form-outline {
    margin-bottom: 24px;
}
.lock-modal .lock-item-form .form-outline:last-child {
    margin-bottom: 0;
}
.lock-modal .lock-item-form label {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.18px;
    color: var(--charcoal-grey);
    margin-bottom: 12px;
}
.lock-modal .lock-item-form textarea {
    padding: 15px 12px;
    border-radius: 6px;    
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    resize: none;
}
.lock-modal .lock-item-form textarea:not(.error) {
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
}
.lock-modal .lock-item-form textarea:focus {
    border: 1px solid var(--cerulean-blue) !important;
    box-shadow: none !important;
    outline: 0 !important;
}

.lock-modal .modal-footer .form-button-wrapper {
    margin: 0;
    padding-top: 20px;
    border-top: 0;
}

#partiallyUnlockModal .modal-dialog {
    width: 436px;
}
.lock-modal .left-calendar,
.lock-modal .right-calendar {
    width: 163px;
    flex: 0 0 163px;
}
.lock-modal .hiphen {
    position: relative;
    width: 28px;
    flex: 0 0 28px;
    text-align: center;
    color: var(--slate);
    vertical-align: middle;
}
.lock-modal .hiphen:before {
    content: "";
    position: absolute;
    width: 8px;
    border-top: 2px solid var(--slate);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#partiallyUnlockModal .MuiInputBase-input {
    width: 100px !important;
}
.lock-modal .modal-footer .info-block {
    padding: 12px 15px 11px 15px;
    border-radius: 4px;
    background-color: rgba(var(--peak-point-rgb), 0.07);
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--battleship-grey);
    margin-top: 20px;
}
.lock-with-list-modal .modal-dialog {
    width: 436px;
}
.lock-with-list-modal .locked-items-list {
    list-style: none;
}
.lock-with-list-modal .locked-items-list li {
    width: 100%;
    border-radius: 8px;
    border: solid 1px var(--tinted-grey);
    background-color: var(--white-fill);
    margin-bottom: 10px;
}
.lock-with-list-modal .locked-items-list li:last-child {
    margin-bottom: 0;
}
.lock-with-list-modal .locked-items-list li .item-wrapper {
    padding: 14px 16px;
    min-height: 70px;
    align-content: center;
}
.lock-with-list-modal .locked-items-list li img {
    width: 32px;
    height: auto;
    margin-right: 11px;
}
.lock-with-list-modal .locked-items-list li .item-det-sect {
    display: flex;
    width: calc(100% - 44px);
    align-content: center;
    flex-wrap: wrap;
}
.lock-with-list-modal .locked-items-list li .item-det-sect h5 {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin-bottom: 4px;
}
.lock-with-list-modal .locked-items-list li .item-det-sect p {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
    margin: 0;
}
.lock-with-list-modal .modal-header {
    padding-bottom: 12px;
}
.lock-with-list-modal .modal-body p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: normal;
    color: var(--battleship-grey);
    margin-bottom: 16px;
}
.lock-with-list-modal .modal-footer .form-button-wrapper {
    padding-top: 20px;
}
.lock-with-list-modal .modal-body {
    max-height: 48.5vh;
    overflow: hidden;
    overflow-y: auto;
}
.lock-modal .lock-item-form .attention-txt {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    clear: both;
    margin-bottom: 16px;
}
.lock-modal .lock-item-form .attention-txt + p {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    clear: both;
}
.lock-modal.unlock_all_notify_modal .modal-dialog {
    width: 444px;
}
.lock-modal .row {
    clear: both;
}
