.login-loader-page-wrapper {
  background-image: url(../../../../assets/images/LoginLoaderBg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
