.card-org-profile {
    margin-bottom: 54px;
}
.org-settings-form {
  width: 100%;
  float: left;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.org-settings-form .fields-wrapper {
  padding: 28px 40px 0 40px !important;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}
.org-settings-form .form-logo-section {
  width: 100%;
  padding: 0 0 16px 0;
  border-bottom: 1px solid var(--approx-whisper);
}
.org-settings-form .form-fields-section {
  width: 100%;
  padding: 20px 0 23px 0;
}
.org-settings-form .form-button-section {
  position: relative;
  padding: 20px 40px 30px 40px;
}
.org-settings-form .form-button-section .alert-msg {
  margin-top: 0;
  margin-bottom: 20px;
}
.org-settings-form .form-button-section:before {
  content: "";
  width: calc(100% + 80px);
  height: 1px;
  position: absolute;
  top: -1px;
  left: -40px;
  right: -40px;
  border-top: 1px solid var(--approx-whisper);
}
.org-settings-form .fields-wrapper .form-fields {
  display: inline-block;
  width: 100%;
  max-width: 522px;
}
.org-settings-form .fields-wrapper .image-upload-container {
  width: 100%;
  min-height: 163px;
}
/* .org-settings-form .fields-wrapper .row.custom-row {
    margin-left: -1rem;
    margin-right: -1prem;
} */
.mw-500 {
  max-width: calc(500px + 2rem) !important;
  width: 100%;
}
.pr-14 {
  padding-right: 14px !important;
}
.pl-14 {
  padding-left: 14px !important;
}
.org-settings-form .fields-wrapper .form-fields label {
  
  font-size: 14px;
  font-weight: bold;
  
  
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
}
.org-settings-form .fields-wrapper .form-fields input {  
  font-size: 14px;
  font-weight: normal;  
  letter-spacing: normal;
  height: 40px;
  line-height: 20px;
  color: var(--charcoal-grey);
  padding: 10px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.org-settings-form .fields-wrapper .form-fields .custom-select input {
  height: auto !important;
}
.org-settings-form .form-fields div[class$="-container"].custom-select,
.org-settings-form .form-fields .custom-select div[class$="-control"] {
  height: 40px !important;
}
/* .org-settings-form .fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.org-settings-form .fields-wrapper .form-fields input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.org-settings-form .fields-wrapper .form-fields input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.org-settings-form .fields-wrapper .form-fields input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
} */
.org-settings-form .fields-wrapper .form-fields .row {
  margin-bottom: 20px;
}
.org-settings-form .form-fields-section .form-fields .row:last-child {
  margin-bottom: 0;
}
.org-settings-form .fields-wrapper .form-fields input:disabled {
  background-color: var(--warm-white) !important;
  color: var(--battleship-grey) !important;
  border: 0 !important;
}
.org-settings-form .fields-wrapper .form-fields input:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.org-settings-form .form-button-section .button-fields.form-button-wrapper {
  border-top: 0;
  margin: 0;
  padding: 0;
}
.org-settings-form .alert-msg {
  width: 100%;
  max-width: 522px;
  min-height: 26px;
  border-radius: 4px;
  padding: 2px 10px;
  margin-top: 8px;
  flex: 0 0 100%;
}
.org-settings-form .alert-msg.info-alert-masg {
  background-color: var(--pale-conflower-blue);
}
.org-settings-form .alert-msg img {
  width: 16px;
  height: 16px;
  margin: 3px 6px 3px 0;
  object-fit: contain;
}
.org-settings-form .alert-msg p {
  min-height: 22px;
  margin: 0 0 0 6px;
  
  font-size: 13px;
  font-weight: normal;
  
  
  line-height: 22px;
  letter-spacing: normal;
  color: var(--chambray);
}
.form-logo-section .image-upload-container h5 {
  
  font-size: 18px;
  font-weight: 900;
  
  
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  margin-bottom: 15px;
}

.org-settings-form input.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}

.org-profile-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}
#crop_modal .image-crop-modal {
  width: 40% !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  #crop_modal .image-crop-modal {
    width: 60% !important;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
  #crop_modal .image-crop-modal {
    width: 60% !important;
  }
}