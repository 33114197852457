.card .card-body {
  height: auto;
  flex: 0 0 auto;
}
.list-card-header {
  background-color: var(--approx-white) !important;
  padding-bottom: 23px !important;
  margin-top: 7px !important;
  border: 0px;
  background-color: var(--approx-white);
  padding: 0 0 24px 0;
}

.list-card-header h1 {
  font-size: 24px;
  font-weight: 900 !important;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
  float: left;
  margin-bottom: 0;
  margin-top: 5px;
}
.reusable-card-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}