.add-module-item-form {
  width: 100%;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}
.add-module-item-form.with-form-error-alert {
  margin-bottom: 50px;
}

#invoice-create.fields-wrapper {
  padding: 0 0 24px;
  box-shadow: none;
  border: none;
  background-color: var(--white-fill);
}

#invoice-create.form-top-section {
  padding: 24px 24px 12px 24px;
  border-bottom: 1px solid var(--approx-whisper);
}
.add-module-item-form .form-top-section {
  padding: 24px 24px 12px 24px;
  border-bottom: 1px solid var(--approx-whisper);
}
.add-module-item-form .form-tab-section {
  padding: 30px 24px 32px 24px;
}
.input-with-loader input {
  height: 41px;
}

/* .add-module-item-form label,
  #editAddresModal label,
  .sidebar-modal label, */
.email-label {
  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
}

#editAddresModal input[type="text"],
.sidebar-modal input[type="text"],
.custom-select > .dropdown-toggle {
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

#editAddresModal .custom-select > .dropdown-toggle,
.sidebar-modal .custom-select > .dropdown-toggle {
  max-width: 100%;
}

#editAddresModal .row,
.sidebar-modal .row {
  margin-bottom: 20px;
  clear: both;
}

.user-form-head h4 {
  margin-bottom: 30px;
}

.add-module-item-form .row {
  margin-bottom: 20px;
  clear: both;
}

.add-module-item-form input[type="text"],
.add-module-item-form input[type="email"],
.add-module-item-form textarea,
.currency-select > .dropdown-toggle,
.custom-select > .dropdown-toggle {
  max-width: 325px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.currency-select > .dropdown-toggle,
.custom-select > .dropdown-toggle {
  padding: 0 12px;
}

.custom-select.bootstrap-select .dropdown-menu {
  left: 0 !important;
  top: -2px !important;
}

.item-details-wrapper {
  padding: 30px 24px 0;
}

.form-tab-section {
  padding: 24px 24px 32px;
  border-bottom: 0;
}

.form-tab-section .billing-address::after {
  height: 100%;
}

#add_customer_form .link-label-grouped label {
  float: left;
}

.link-label-grouped {
  width: 100%;
  max-width: 325px;
  float: left;
}

.bill-address {
  font-size: 14px;
  font-weight: normal;

  line-height: 155%;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

.address-tab-pane h3 {
  font-size: 16px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--charcoal-grey);
  margin-bottom: 24px;
  margin-right: 17px;
  float: left;
}

.ship-form #s_region,
.ship-form .region-select {
  min-height: 40px;
}

.item-details-wrapper h2,
.payment-details-wrapper h2 {
  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
}

.add-customer-link,
.manage-tags-link,
.edit-address-link {
  position: relative;
  display: block;
  float: right;

  font-size: 14px;
  font-weight: normal;

  line-height: normal;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  text-decoration: none;
  position: relative;
  padding-left: 20px;
}

.add-customer-link:before,
.manage-tags-link::before,
.edit-address-link::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 0;
  object-fit: contain;
  background-size: 14px 14px;
  left: 0;
}

.manage-tags-link::before {
  background: url("../../../../../assets/images/settings-icon-blue-bg.svg")
    no-repeat center;
}

.edit-address-link::before {
  background: url("../../../../../assets/images/edit-icon-blue.svg") no-repeat
    center;
}

.edit-address-link {
  float: left;
  margin-top: 2px;
}

/* add customer add contact common */
.add-module-item-form .form-button-wrapper {
  padding-bottom: 39px;
}

.add-module-item-form .form-button-wrapper.docked-button-container,
#add_customer_form .form-button-wrapper.docked-button-container,
#add_vendor_form .form-button-wrapper.docked-button-container,
.form-button-wrapper.docked-button-container {
  position: fixed;
  width: calc(100% - 96px) !important;
  padding-left: 64px !important;
  right: 0 !important;
  bottom: 0 !important;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border-top: solid 1px var(--warm-white);
  background-color: var(--white-fill) !important;
  opacity: 1 !important;
  z-index: 99;
  padding-top: 16px !important;
  padding-bottom: 20px !important;
  transition: 0.5s;
}
.add-module-item-form .form-button-wrapper.docked-button-container.customer-form-section-disable:before,
#add_customer_form .form-button-wrapper.docked-button-container.customer-form-section-disable:before,
#add_vendor_form .form-button-wrapper.docked-button-container.customer-form-section-disable:before,
.form-button-wrapper.docked-button-container.customer-form-section-disable:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--white-fill);
  opacity: 0.5;
  z-index: 1;
}
.expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container,
.expanded-submenu #add_customer_form .form-button-wrapper.docked-button-container,
.expanded-submenu #add_vendor_form .form-button-wrapper.docked-button-container,
.expanded-submenu .form-button-wrapper.docked-button-container {
  /* width: calc(100% - 226px) !important; */
  width: calc(100% - 236px) !important;
}
.add-module-item-form .form-button-wrapper.docked-button-container .dropdown-menu {
  position: absolute !important;
  inset: auto auto 0px 0px !important;
  margin: 0px !important;
  transform: translate3d(8px, -47px, 0px) !important;
}
#editAddresModal .form-button-wrapper,
.sidebar-modal .form-button-wrapper {
  border-top: 0;
  padding-top: 0;
}

.form-check .form-check-input {
  float: left;
  margin-right: 0.4375em;
  margin-top: 0.05em;
}

.right-aligned-checkbox {
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 3px;
  position: absolute;
  min-height: 1.5rem;
  padding-right: 1.5em;
}

#same_as_billing {
  margin-top: 1px;
  margin-right: 6px;
  float: left;
}

.dropdown-item {
  padding: 8px 14px;

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

.mw-250 {
  max-width: 250px !important;
}

.date-selector {
  padding: 14.5px 10px 14.5px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  width: 100%;
  text-align: start;
  color: var(--charcoal-grey);
  position: relative;
}

.date-selector img {
  position: absolute;
  right: 12px;
  top: 2px;
  bottom: 0;
  margin: auto;
}

.items-entry-wrapper {
  border: 1px solid var(--warm-white);
  padding-bottom: 26px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.bill-item-container {
  overflow-x: auto;
  width: 100%;
}

.item-table .accounts-dropdown input,
.item-table .accounts-dropdown .dropdown-menu {
  width: 230px !important;
}

.item-table-wrapper.item-table-wrapper-invoice {
  min-width: 1150px;
}

.taxable-amount {
  width: 60%;
  margin-left: auto;
}

.amount-taxable-nil,
.amount-taxable {
  font-size: 14px;
}

.amount-taxable-nil {
  font-weight: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}

.amount-taxable {
  font-weight: bold;
  color: var(--charcoal-grey);
}

.tax-amount-label {
  font-size: 12px;
  font-weight: 500;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}

.add-tags-button {
  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  text-decoration: none;
}

.add-line {
  border-bottom: 1px solid var(--approx-whisper);
}
.add-tax-button {
  width: 18px;
  height: 18px;
}
.discount-input {
  margin-left: 30px;
  width: 40%;
  height: 40px;
  border-right: 0 !important;
  border-radius: 6px 0 0 6px !important;
  font-size: 17px !important;
  line-height: normal !important;
}

.discount-wrap .input-group input {
  max-width: 43px !important;
  width: 100%;
  line-height: normal !important;
}

.discount-wrap .input-group-append div {
  border-radius: 0 6px 6px 0;
  background-color: rgba(var(--peak-point-rgb), 0.08);
  border: solid 1px var(--new-whisper);
  border-left: 0;

  font-size: 16px;
  font-weight: bold;

  line-height: 0.77;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding: 14px 12px;
  height: 40px;
}

.discount-wrap .input-group {
  width: auto;
}

.discount-wrap .input-group input {
  border-radius: 5px 0 0 5px !important;
  border: solid 1px var(--new-whisper);
  border-right: 0;

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  height: 40px;
}

.discount-selector input {
  border-radius: 6px 0 0 6px !important;
}

.discount-selector select {
  border-radius: 0 6px 6px 0 !important;
  border-color: var(--new-whisper);
  background-color: var(--white-fill);
  border-left: 0;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  font-size: 14px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
}

.discount-selector .option-icon {
  font-family: PingFangSC;
  font-size: 16px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}

.discount-selector .option-value {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-left: 14px;
}

.discount-selector a {
  text-decoration: none;
  margin-left: 14px;
}

.discount-selector li {
  margin-bottom: 16px !important;
}

.discount-selector li:last-child {
  margin-bottom: 0 !important;
}

/* .discount-selector .dropdown-menu {
    transform: translate(-13px, 21px) !important;
  } */

.tax-selector {
  margin-top: 14px;
}

.tax-selector:first-child {
  margin-top: 0;
}
.tax-selector .percent-label {
  width: 24px;
  height: 40px;
  background-color: var(--white-fill);
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  border: 1px solid var(--new-whisper);
  border-left: 0;
  border-radius: 0 6px 6px 0;
  padding: 0 6px !important;
}

#dropdownDiscount {
  height: 40px;
  border-radius: 0 6px 6px 0;
  border: 1px solid var(--new-whisper);
  border-left: 0;
}

.tax-type {
  width: 15%;
  margin-left: 70px;
  height: 40px;
}

.tax-input {
  width: 80px;
  margin-left: 16px;
}

.add-tax-wrapper {
  padding-left: 123px;
}

.email-reciepients-wrapper textarea {
  width: 682px;
  height: 94px;
  max-width: 682px !important;
}

.email-reciepients-wrapper {
  margin-bottom: 30px;
  padding-top: 30px !important;
}

.payment-details-wrapper,
.email-reciepients-wrapper {
  padding: 0 24px;
}

#editAddresModal,
.sidebar-modal {
  right: 0;
  width: 480px;
}

#editAddresModal .modal-dialog,
.sidebar-modal .modal-dialog {
  right: 0;
  position: fixed;
  width: 480px !important;
}

.form-button-wrapper .card-user-management .dropdown-menu {
  top: 0;
}

.form-button-wrapper .dropdown-toggle-split {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-button-wrapper .btn.save-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.form-button-wrapper .dropdown-toggle {
  background-color: var(--booger);
  border-left: 1px solid var(--white-fill);
  padding-left: 7px;
  padding-right: 7px;
}

.form-button-wrapper .dropdown-toggle:hover {
  color: var(--white-fill);
}
.form-button-wrapper .dropdown-toggle:after {
  border: 0 !important;
  vertical-align: auto;
}
.form-button-wrapper .dropdown-toggle:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}

.form-button-wrapper .dropdown-menu {
  transform: translate(-30px, 21px) !important;
}

.form-button-wrapper .cancel-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}

.add-module-item-form .multiSelectContainer {
  top: -5px;
}
.add-module-item-form .multiSelectContainer .searchBox {
  border: 0 !important;
  width: 105px;
  padding: 0px !important;
}
.add-module-item-form .multiSelectContainer .searchWrapper {
  border: solid 1px var(--new-whisper);
  padding: 4px 34px 4px 12px !important;
}

#invoice-create.card-create-item {
  margin-bottom: 120px !important;
}

#invoice table tbody tr:nth-child(2n) td {
  background-color: transparent;
}

.modal-wrap {
  right: 0;
  width: 480px;
}

.modal-wrap .modal-dialog {
  right: 0;
  position: fixed;
  width: 480px !important;
}

#customiseView .modal-dialog {
  max-width: 854px;
}

#invoice_list_table tbody tr {
  cursor: pointer;
}

.no-data .table-info {
  display: none !important;
}
.no-data .list-table thead,
.no-data .list-table th {
  border-top: 0;
}
.no-data {
  padding-top: 0 !important;
}

.bill-item-container table th:last-child .info-btn {
  top: -4px;
}

.invoice-table tbody th {
  background-color: var(--white-fill);
}
.form-control.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
.add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option {
  flex-direction: row;
}
.add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option span {
  color: var(--cerulean-blue) !important;
}
.add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option .reusable-modal-btn {
  width: 100%;
  justify-content: flex-start !important;
}
.add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option .reusable-modal-btn button {
  float: left;
  padding-left: 0;
}
.add-module-item-form .accounts-dropdown .dropdown-menu a.add-link-option .reusable-modal-btn button span {
  display: flex;
  align-items: center;  
  white-space: nowrap;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container,
  .expanded-submenu #add_customer_form .form-button-wrapper.docked-button-container,
  .expanded-submenu #add_vendor_form .form-button-wrapper.docked-button-container,
  .expanded-submenu .form-button-wrapper.docked-button-container {
    padding-left: 50px !important;
  }
}
@media (max-width: 991px) {
  .add-module-item-form .address-tab-pane .billing-address,
  .add-module-item-form .address-tab-pane .shipping-address {
    padding: 0;
    width: 100%;
    max-width: none !important;
  }
  .add-module-item-form .address-tab-pane .shipping-address {
    position: relative;
  }
  .add-module-item-form .address-tab-pane .billing-address:after {
    display: none;
  }
  .add-module-item-form .address-tab-pane .shipping-address:before {
    content: "";
    position: absolute;
    border-top: 1px solid var(--approx-whisper);
    width: 100%;
    height: 1px;
    left: 0;
    top: -20px;
  }
  .add-module-item-form .address-tab-pane .billing-address a > div,
  .add-module-item-form .address-tab-pane .shipping-address a > div {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container,
  .expanded-submenu .form-button-wrapper.docked-button-container .form-btn-container {
    flex-wrap: wrap;
  }
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container,
  .expanded-submenu .form-button-wrapper.docked-button-container {
    padding-bottom: 10px;
  }
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container > button,
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container .btn-group > button,
  .expanded-submenu .add-module-item-form .form-button-wrapper.docked-button-container .form-btn-container > a,
  .expanded-submenu .form-button-wrapper.docked-button-container .form-btn-container > button,
  .expanded-submenu .form-button-wrapper.docked-button-container .form-btn-container .btn-group > button,
  .expanded-submenu .form-button-wrapper.docked-button-container .form-btn-container > a {
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .expanded-submenu .add-module-item-form {
  }
  .expanded-submenu .add-module-item-form#add_invoice_form .form-button-wrapper.docked-button-container .delete-item-button,
  .expanded-submenu .form-button-wrapper.docked-button-container .delete-item-button  {
    margin-left: 0 !important;
    float: left;
  }
}
