.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
.drawer {
  width: auto;
  box-shadow: none;
}
.modal-close-btn {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}

.add-icon-pointer {
  cursor: pointer;
}
.side-modal-title {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  padding: 40px;
  width: 100%;
  display: flex;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.side-modal-title h2 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  margin: 0;
}
.reusable-modal-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.button-at-start .reusable-modal-btn {
  justify-content: flex-start !important;
}

#reusableSideModalBody {
  padding: 0px !important;
}

.button-bottom {
  height: 100% !important;
}
.button-bottom #reusableSideModalBody {
  height: calc(100% + 1.5rem);
}
.button-bottom #reusableSideModalBody .modal-btn-docked-bottom {
  position: absolute;
  width: calc(100% - 80px) !important;
  left: 40px;
  bottom: 0;
  border-top: 0;
}
.is-disabled-side-modal-btn {
  opacity: 0.5;
}
