.dropzone-container {
  width: 100%;
  clear: both;
}
.dropzone-container .dropzone {
  width: 567px;
  max-width: 100%;
  border-radius: 4px;
  border: dashed 1.5px var(--approx-cornflower-blue);
  background-color: var(--new-approx-alice-blue);
  text-align: center;
  padding: 22px;
}

.dropzone-container .upload-msg {
  padding: 14px 0 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.19px;
  color: var(--charcoal-grey);
}

.dropzone-container .upload-msg a {
  color: var(--cerulean-blue);
  text-decoration: none;
}

.dropzone-container .max-size {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--battleship-grey);
}

.dropzone-container h4 {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--battleship-grey);
  margin-bottom: 8px !important;
}

#uploaded_files_list {
  list-style: none;
  max-width: 570px;
}

#uploaded_files_list li {
  padding-left: 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.18px;
  color: var(--battleship-grey);
  border-bottom: solid 1px var(--approx-whisper);
  margin-bottom: 3px !important;
  padding: 16px 0 0 0 !important;
}
#uploaded_files_list li:last-child {
  border-bottom: 0;
}

#uploaded_files_list .file-name {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
}
#uploaded_files_list .file-item-choose {
  text-decoration: none;
  cursor: default;
}
#uploaded_files_list .file-item-inserted {
  text-decoration: none;
}
#uploaded_files_list .file-item-inserted .file-name {
  color: var(--cerulean-blue);
  cursor: pointer;
}

#uploaded_files_list .file-size {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.15px;
  color: var(--battleship-grey);
}

#uploaded_files_list .remove-icon {
  width: 18px;
  height: 18px;
}

.delete-button {
  cursor: pointer;
}

#uploaded_files_list .css-1hxt08m-MuiLinearProgress-root {
  height: 2px;
  background: var(--approx-whisper);
}

.css-1e7jehc-MuiLinearProgress-bar2,
.css-1u7opxh-MuiLinearProgress-bar1 {
  background-color: var(--approx-apple) !important;
}
#uploaded_files_list .file-details-container {
  padding-bottom: 16px !important;
}
#uploaded_files_list .file-loader {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: -25px;
  z-index: 10;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
#uploaded_files_list .file-error {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
}
.rejected-files .file-name,
.rejected-files .file-size,
.rejected-files .file-error {
  color: var(--red) !important;
}
.uploaded-files-section {
  clear: both;
  padding: 24px 0 14px 0;
  border-bottom: 1px solid var(--approx-whisper);
}