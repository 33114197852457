.address-container
  .plan-box-wrapper {
  width: fit-content;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 12px 0px;
}

.plan-add-line {
  border-top: solid 1px var(--approx-whisper);
}
.plan-box-wrapper .plan-item .plan-item-header {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin: 0;
  padding: 0px;
}

.pricing-table .list-table th.price-item-col,
.pricing-table .list-table td.price-item-col {  
  max-width: 80px
}

.pricing-table .list-table th.price-Units-col,
.pricing-table .list-table td.price-Units-col {  
  max-width: 100%;
}
.pricing-table .list-table th.popover_price,
.pricing-table .list-table td.popover_price {
  width: 155px;
}

.each-pricing-grid .pricing-responsive{
  word-break: break-all
}

.item-pricing-grid .price-item-responsive {
  display: inline-block;
  overflow-wrap: break-word;
  max-width: 100%; 
  white-space: normal;
  width: 87px;
  hyphens: auto;
}

.plan-item-detail p {
  width: 100%;
  font-size: 14px !important;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  margin: 0 !important;
}

.plan-item-detail .plan-item-detail-data .plan-item-rate {
 padding-left: 10px;
}
.plan-item-detail .plan-item-detail-data .plan-item-rate span{
  font-weight: bold;
 }

 .plan-item-detail .plan-item-detail-data .remove-padding {
  padding-left: 0px;
 }
 .plan-item-detail .plan-item-detail-data .remove-padding span{
   font-weight: bold;
  }

 .plan-table .table tbody tr:nth-child(even) td, 
 .plan-table .list-table tbody tr:nth-child(even) td {
  box-shadow: none !important;
  border-top:  solid 1px var(--approx-whisper) !important;
  border-bottom:  solid 1px var(--approx-whisper) !important;
  background-color: transparent !important;
}

.pricing-table .table tbody tr:nth-child(even) td,
.pricing-table .list-table tbody tr:nth-child(even) td {
  box-shadow: none !important;
  border-top: solid 1px var(--approx-whisper) !important;
  border-bottom: solid 1px var(--approx-whisper) !important;
  background-color: transparent !important;
}

.plan-table .table tbody tr:last-child td, 
 .plan-table .list-table tbody tr:last-child td {
  border-bottom:  0px !important;
 }

.tab-content-wrapper .plan-blocks {
  padding-bottom: 12px !important;
} 
.tab-content-wrapper .plan-blocks .section-content-wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 0px !important;
} 

.plan-table .list-table {
  min-width: 1000px;
}

.no_range_popover .pricing-table .list-table {
  min-width: 200px !important;
}

.range_popover .pricing-table .list-table {
  min-width: 500px !important;
}
.customer-detail-card .plan-overview {
 padding-bottom: 32px !important;
}

.plan-pricing-button {
  font-weight: bold !important;
  color: var(--cerulean-blue) !important;
  border: none;
  background-color: inherit;
  padding: 0px 0px;
} 

.plan-pricing-item {
  font-weight: bold !important;
}

.plan-table .list-table th.plan_price {
 text-align: center;
}

.plan-table .list-table td.plan_price {
  max-width: 230px;
  min-width: 120px;
 }
.plan-pricing-item-grid {
  padding: 0px 0px !important;
}
.plan-pricing-button-grid {
  padding: 0px 0px 0px 10px !important;
}

.plan-table .dropdown-menu {
  transform: translate3d(-30px, 0, 0) !important; 
}

.pricing-item-col-popper {
  padding: 15px 15px !important;
  background-color: var(--white-fill);
  border-radius: 12px;
  border: solid 1px var(--warm-white);
  -webkit-box-shadow: 0 4px 12px 0 var(--black-shadow15);
  box-shadow: 0 4px 12px 0 var(--black-shadow15);
}

.set-price-container {
  margin: 117px auto;
  text-align: center;
}

.set-price-container-div {
  display: inline;
}

.set-price-container-span {
  font-size: 16px;
  font-weight: bold;
  color: var(--battleship-grey);
  margin-right: 4px;
}
.set-pricing-button {
  font-size: 16px;
  font-weight: bold !important;
  color: var(--cerulean-blue) !important;
  border: none;
  border-bottom: solid 1px;
  background-color: inherit;
  padding: 0px 0px;
} 

.plan-table .list-table td .plan-pricing-item {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  display: flow-root;
  margin-top: 5px;
}

.plan-table .list-table th.plan_table_col,
.plan-table .list-table td.plan_table_col {  
  min-width: 50px !important;
}

.pricing-table .table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 200px;
  max-height: 300px;
  border-bottom-right-radius: 0px;
}

.pricing-table .table tbody {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.pricing-table .table tr {
  display: table;
  width: 100%;
}

.pricing-table .table td {
  flex: 1;
}

.pricing-table .is-scroll-bar thead tr{
  width: calc(100% - 17px);
  border-bottom: 0px;
}

.pricing-table .is-scroll-bar thead tr{
  border-bottom: solid 1px var(--battleship-grey) !important;
}
.pricing-table .is-scroll-bar thead:hover {
  background-color: var(--alice-blue) !important;
}

.pricing-table .is-scroll-bar thead tr:hover {
  background-color: transparent !important;
  opacity: 0.5 !important;
}

.pricing-table .is-scroll-bar thead tr:hover > th > span{
  color: rgb(23, 22, 22) !important;
}
.plan-detail-header .detail-top-action-menu > ul > li img {
  transform: translate(0px, 3px);
}

.plan-detail-header a.dropdown-item {
  margin: 0px !important;
  height: auto !important;
}

@media (max-width: 820px) {
  .range_popover .pricing-table .list-table {
    min-width: 200px !important;
  }
  .pricing-table .list-table th.popover_price,
.pricing-table .list-table td.popover_price {
  width: 115px;
}
}

.plan-overview .jv-postings-section{
  float: none !important;
  }